import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { uniqueId } from 'lodash';
import { VscDebugBreakpointLogUnverified } from 'react-icons/vsc';
import api from '../services/api'

let idsUnicosEnviados = [];
let countDown = localStorage.getItem('countDown') == null ? 0 : Number(localStorage.getItem('countDown'));

export const requestMessageSlice = createSlice({
  name: 'messages',
  initialState: {
    messages: [],
    rascunhos: [],
    rascunhosEditor: [],
    error: null
  },
  reducers: {
    storeMessage: (state, action) => {

      if (action.payload.mensagem && action.payload.mensagem.length > 0 || action.payload.anexos.length > 0) {
        const id = uniqueId('requisicao');
        state.messages.push({ ...action.payload, countDown, memoria: true, id });
      }

      state.rascunhos = state.rascunhos.filter(x => x.idRequisicao !== action.payload.idRequisicao);
      state.rascunhosEditor = state.rascunhosEditor.filter(x => x.idRequisicao !== action.payload.idRequisicao);
    },
    decrement: (state) => {
      state.messages?.forEach(m => {
        if (m.countDown > 0)
          m.countDown -= 1
      });
    },
    decrementAllMessagesToZero: (state) => {
      state.messages?.forEach(m => {
        if (m.countDown > 0)
          m.countDown = 0
      });
    },
    removeMessage: (state, action) => {
      if (idsUnicosEnviados.indexOf(action.payload) < 0)
        idsUnicosEnviados.push(action.payload)

      state.messages = state.messages?.filter(m => m.id !== action.payload);
    },
    updateCountDown: () => {
      countDown = localStorage.getItem('countDown') == null ? 0 : Number(localStorage.getItem('countDown'));
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    rascunho: (state, action) => {
      if (action.payload.mensagem && action.payload.mensagem.length > 0) {
        const rascunhoIncluido = state.rascunhos.find(x => x.idRequisicao === action.payload.idRequisicao)

        if (rascunhoIncluido) {
          rascunhoIncluido.mensagem = action.payload.mensagem;
        } else {
          state.rascunhos.push({ ...action.payload });
        }
      } else {
        state.rascunhos = state.rascunhos.filter(x => x.idRequisicao !== action.payload.idRequisicao);
      }
    },
    rascunhoEditor: (state, action) => {
      if (action.payload.mensagem && action.payload.mensagem.length > 0) {
        const rascunhoIncluido = state.rascunhosEditor.find(x => x.idRequisicao === action.payload.idRequisicao)

        if (rascunhoIncluido) {
          rascunhoIncluido.mensagem = action.payload.mensagem;
        } else {
          state.rascunhosEditor.push({ ...action.payload });
        }
      } else {
        state.rascunhosEditor = state.rascunhosEditor.filter(x => x.idRequisicao !== action.payload.idRequisicao);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.error = action.payload;
    });
  }
});

const sendMessage = createAsyncThunk(
  'messages/sendMessage',
  async (message, { rejectWithValue }) => {

    let data = new FormData()

    if (message.anexos.length > 0) {
      message.anexos.map(attach => {
        const byteCharacters = atob(attach.url.split(',')[1]);

        // Converte os bytes em um objeto Blob
        const mimeType = attach.url.split(';')[0].split(':')[1];
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
        }
        const blob = new Blob([new Uint8Array(byteArrays)], { type: mimeType });

        data.append('Arquivos', new File([blob], attach.nome, { type: mimeType }))
      })
    }

    data.append('RequisicaoId', String(message.idRequisicao))
    data.append('Mensagem', message.mensagem)
    data.append('UsuarioId', message.usuarioId)
    data.append('TipoPrivacidade', message.tipoPrivacidade)


    try {
      const res = await api({
        method: 'post',
        url: '/jucerja/v1/mensagens/atendente',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      })

      return res;
    }
    catch (error) {
      switch (error.response.status) {
        case 422:
          return rejectWithValue(error.response.data.messages[0].mensagem);
        default:
          return rejectWithValue('Não foi possível enviar sua mensagem. Por favor tente novamente');
      }
    }


  });

// Action creators are generated for each case reducer function
export const { storeMessage, decrement, removeMessage, updateCountDown, decrementAllMessagesToZero, clearError, setError, rascunho, rascunhoEditor } = requestMessageSlice.actions

export const startInterval = () => (dispatch, getState) => {
  setInterval(async () => {

    dispatch(decrement());
    const state = getState();
    const messagesToSend = state.requestMessage.messages?.filter(m => m.countDown <= 0);

    for (const message of messagesToSend) {
      if (idsUnicosEnviados.indexOf(message.id) < 0) {
        idsUnicosEnviados.push(message.id)
        try {
          dispatch(sendMessage(message));
          dispatch(removeMessage(message.id));
        } catch (error) {
          console.log(error);
        }
      } else {
        dispatch(removeMessage(message.id));
      }
    }
  }, 1000);
};

export default requestMessageSlice.reducer