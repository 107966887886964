import { useState, useEffect, forwardRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'
import { Confirmation } from './styles'
import api from '../../../services/api'
import { v4 as uuid } from 'uuid'
import Draggable from 'react-draggable'
import HtmlParser from 'react-html-parser'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogConfirmation({
    openDialog,
    setOpenDialog,
    setConfirm,
    setCancel,
    txtTitle,
    txtConfirm,
    txtCancel,
    txtContainer,
    closeControlOutsideOnConfirm,
    setConfirmTwo,
    txtConfirmTwo,
    disableBackdropClick
}) {

    const [loadingConfirm, setLoadingConfirm] = useState(false);

    const handleClose = (e) => {
        e?.preventDefault();

        if (loadingConfirm)
            return;

        if (setCancel)
            setCancel(true);
        if (setOpenDialog)
            setOpenDialog(false);
    }

    const handleConfirm = async (e) => {
        e?.preventDefault();

        setLoadingConfirm(true);
        setConfirm(true);

        if (setOpenDialog && !closeControlOutsideOnConfirm)
            setOpenDialog(false);
    }

    const handleConfirmTwo = async (e) => {
        e?.preventDefault();

        setLoadingConfirm(true);
        setConfirmTwo(true);

        if (setOpenDialog && !closeControlOutsideOnConfirm)
            setOpenDialog(false);
    }

    useEffect(() => {
        if (!openDialog){
            setLoadingConfirm(false);
            setConfirm(false);
        }
    }, [openDialog])

    return (
        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            open={openDialog}
            TransitionComponent={Transition}
            disableBackdropClick={disableBackdropClick}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-change-subject"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">{txtTitle}</DialogTitle>
            <DialogContent>
                <Confirmation id="ChangeSubject">
                    <Confirmation.Container id="ChangeSubject_Container">
                        {HtmlParser(txtContainer)}
                    </Confirmation.Container>
                </Confirmation>
            </DialogContent>
            <DialogActions>
                <Confirmation.Actions id="DialogConfirmation.Actions">
                    {
                        txtCancel ?
                            <div className={!loadingConfirm ? "mostrarAnimacao" : "esconderAnimacao"}>
                                <Button onClick={handleClose} className="btn-close-actions">{txtCancel}</Button>
                            </div>
                            :
                            <></>
                    }
                    {
                        loadingConfirm
                            ?
                            <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                            :
                            <Button className="btn-primary-actions" onClick={handleConfirm}>{txtConfirm}</Button>
                    }
                    {
                        txtConfirmTwo ?
                            loadingConfirm
                                ?
                                <></>
                                :
                                <Button className="btn-primary-actions" onClick={handleConfirmTwo}>{txtConfirmTwo}</Button>
                            :
                            <></>
                    }

                </Confirmation.Actions>
            </DialogActions>
        </Dialog >
        </Draggable>
    )
}