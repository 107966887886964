import Container from "../../components/layout/Container";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grow from "@mui/material/Grow";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import { Card } from "../../components/elements-ui/Card";
import { Badge } from "../../components/elements-ui/Badge";
import { ActionTable } from "../../components/elements-ui/ActionTable";
import { Button } from "@material-ui/core";
import { Assunto } from "./style";
import {
  AiOutlineEye,
  AiOutlineEdit,
  BsFillStarFill,
  BsFillClockFill,
  IoIosArrowBack,
  IoIosArrowForward,
  FaUserAlt,
  FaClock,
  MdTableRows,
  FaTable,
} from "react-icons/all";
import { Link, useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { Requests } from "../../pages/Requisicoes/styles";
import api from "../../services/api";
import { useSnackbar } from "notistack";
import Skeleton from "react-loading-skeleton";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import _ from "lodash";
import DialogAssuntoSetorConfirm from "../../components/elements-ui/DialogAssuntoSetorConfirm";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { TransitionGroup } from "react-transition-group";
import Avatar from "@material-ui/core/Avatar";
import { useDebounce } from "../../functions";

const PrimaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--primary)",
    color: "var(--white)",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: "#bdbdbd",
    fontWeight: "900",
    display: "inline-flex",
    alignItems: "center",
    padding: "0 15px",
    height: "32px",
    overflow: "hidden",
    fontSize: "0.8125rem",
    borderRadius: "16px",
    backgroundColor: "#f1f1f1",
    border: "1px solid #bdbdbd",
    "& .Abreviacao": {
      maxWidth: "100%",
      maxHeight: "100%",
      overflow: "hidden",
    },
    "& .Esconder": {
      maxWidth: "0",
      opacity: 0,
      maxHeight: "0",
      overflow: "hidden",
    },
    "& .MostrarNome": {
      opacity: 1,
      maxWidth: "100%",
      maxHeight: "100%",
      transition: "opacity 1s,max-height 0.75s ease-in-out",
      overflow: "hidden",
    },
  },
  card: {
    "&:hover": {
      transform: "scale(1.019)",
      transition: "all ease .35s",
      borderRadius: "8px",
    },
    cursor: "pointer",
  },
}));

const NomesBalao = ({ names }) => {
  const classes = useStyles();
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleNameClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    if (index === expandedIndex) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div>
      {names.map((supervisor, index) => (
        <div
          key={supervisor.id}
          className={classes.chip}
          onClick={(e) => handleNameClick(e, index)}
        >
          {
            <>
              <span
                className={expandedIndex === index ? "MostrarNome" : "Esconder"}
              >
                {supervisor.descricao}
              </span>

              <span
                className={expandedIndex === index ? "Esconder" : "MostrarNome"}
              >
                {supervisor.descricao.trim().split(" ")[0]}
              </span>
            </>
          }
        </div>
      ))}
    </div>
  );
};

export default function Setores() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [assuntos, setAssuntos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialogAssuntoSetorConfirm, setOpenDialogAssuntoSetorConfirm] =
    useState(false);
  const [assuntoSetorConfirm, setAssuntoSetorConfirm] = useState(undefined);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const [assuntoSearch, setAssuntoSearch] = useState("");
  const searchQuery = useDebounce(assuntoSearch, 500);
  const [opcoesFiltro, setOpcoesFiltro] = useState({
    filtros: [],
    aplicarUnionCondicional: true,
    firstResult: 1,
    maxResults: 10,
  });

  const stringAvatar = (name) => {
    return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`.toUpperCase();
  };

  useEffect(() => {
    let opcoes = { ...opcoesFiltro };
    setLoading(true);
    opcoes.firstResult = paginaAtual == 1 ? 1 : (paginaAtual - 1) * 10;
    api
      .post("/jucerja/v1/assuntos/filtro", opcoes)
      .then((response) => {
        const assuntos = response.data?.assuntos;

        setAssuntos(assuntos ?? []);
        setTotalPaginas(Math.ceil(response.data.totalRecords / 10));
        setTotalRegistros(response.data.totalRecords);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  }, [opcoesFiltro]);

  useEffect(() => {
    if (paginaAtual != opcoesFiltro.firstResult)
      setOpcoesFiltro((filtro) => ({ ...filtro, firstResult: paginaAtual }));
  }, [paginaAtual]);

  useEffect(() => {
    if (!assuntoSetorConfirm) return;

    const assuntoObj = {
      ...assuntoSetorConfirm,
      funcionariosId: assuntoSetorConfirm?.funcionarios?.map((fun) => fun.id),
      descricao: assuntoSetorConfirm.assuntoDescricao,
      mensagemId: assuntoSetorConfirm.idMensagemAutomatica,
      mensagemAutomatica: assuntoSetorConfirm.mensagemAutomatica,
      Setores:
        assuntoSetorConfirm.setorId == 0 || !assuntoSetorConfirm.setorId
          ? []
          : [assuntoSetorConfirm.setorId],
      habilitar: true,
    };

    api
      .post(`/jucerja/v1/Assuntos/novos-assunto`, assuntoObj)
      .then((response) => {
        enqueueSnackbar("Assunto foi cadastrado com sucesso.", {
          variant: "success",
        });
      })
      .catch((e) =>
        enqueueSnackbar("Houve um erro ao adicionar um novo assunto.", {
          variant: "error",
        })
      )
      .finally(() => {
        setOpenDialogAssuntoSetorConfirm(false);
        setAssuntoSetorConfirm(undefined);
      });
  }, [assuntoSetorConfirm]);

  useEffect(() => {
    if (searchQuery.trim().length <= 0) {
      setOpcoesFiltro((filtro) => ({
        ...filtro,
        filtros: [],
        firstResult: 1,
      }));

      return;
    }

    setOpcoesFiltro((filtro) => ({
      ...filtro,
      filtros: [
        {
          Coluna: [28],
          Comparador: 8,
          Valores: [`${searchQuery.toLowerCase()}%`],
        },
      ],
      firstResult: 1,
    }));
  }, [searchQuery]);

  return (
    <>
      <DialogAssuntoSetorConfirm
        openDialogAssuntoSetorConfirm={openDialogAssuntoSetorConfirm}
        setOpenDialogAssuntoSetorConfirm={setOpenDialogAssuntoSetorConfirm}
        setAssuntoSetorConfirm={setAssuntoSetorConfirm}
        selecionarSetor={true}
      />
      <div
        style={{
          width: "100%",
          position: "fixed",
          zIndex: "100",
        }}
      >
        <Requests.BarraSlastic>
          <Requests.AttachmentListAddAndMessageBox>
            <TextField
              style={{ zIndex: 100 }}
              id="txt-send-message-request"
              variant="outlined"
              onKeyUp={(e) => {
                setAssuntoSearch(e.target.value);
              }}
              placeholder="Pesquisar Assuntos"
              disabled={loading}
            />
          </Requests.AttachmentListAddAndMessageBox>

          {/* </Grid> */}
        </Requests.BarraSlastic>
      </div>

      <Container title="Assuntos">
        <Assunto.Action>
          <Grid container direction="row" justifyContent="space-between">
            {loading ? (
              <Skeleton width={190} height={36} />
            ) : (
              <Button
                className="btn-backround-primary-actions"
                onClick={() => setOpenDialogAssuntoSetorConfirm(true)}
                style={{ background: "var(--primary)!important" }}
              >
                Adicionar Assunto
              </Button>
            )}
          </Grid>
          <Assunto.Pagination id="Assuntos_Pagination">
            {loading ? (
              <Skeleton width={120} height={35} />
            ) : assuntos.lenght === 0 ? (
              <></>
            ) : (
              <>
                <Typography style={{ flex: "none" }}>
                  Página {paginaAtual} de {totalPaginas} de {totalRegistros}{" "}
                  {totalRegistros <= 1 ? "registro" : "registros"}
                </Typography>
                <Button
                  className="btnPagination btnPaginationPrev"
                  onClick={() => setPaginaAtual(paginaAtual - 1)}
                  disabled={paginaAtual === 1 ? true : false}
                >
                  <IoIosArrowBack />
                </Button>
                <Button
                  className="btnPagination btnPaginationNext"
                  onClick={() => setPaginaAtual(paginaAtual + 1)}
                  disabled={
                    paginaAtual === totalPaginas || totalPaginas === 0
                      ? true
                      : false
                  }
                >
                  <IoIosArrowForward />
                </Button>
              </>
            )}
          </Assunto.Pagination>
        </Assunto.Action>

        <Grid container spacing={4} style={{ marginBottom: "30px" }}>
          {loading
            ? [1, 2, 3, 4, 5, 6].map((x) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.card}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                      bgcolor: "background.paper",
                    }}
                  >
                    <Box sx={{ py: 3, px: 2, pt: 3, pl: 3, pr: 3 }}>
                      <Grid
                        style={{ minHeight: "4.5rem" }}
                        container
                        alignItems="center"
                      >
                        <Grid item xs>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            color="black"
                          >
                            <Skeleton />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box sx={{ mb: 1 }}>
                        <Typography color="text.secondary" variant="body2">
                          <Skeleton width={100} />
                        </Typography>
                      </Box>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                          <Skeleton width={150} />
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider variant="fullWidth" />
                    <Box sx={{ mt: 3, mx: 2, pl: 3, pr: 3 }}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        color="black"
                        height={35}
                      >
                        <Skeleton width={150} />
                      </Typography>
                      <Grid>
                        <Typography
                          gutterBottom
                          variant="body1"
                          color="black"
                          height={50}
                        >
                          <Skeleton />
                        </Typography>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              ))
            : assuntos.map((assunto) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.card}
                >
                  <TransitionGroup style={{ height: "100%" }}>
                    <Grow
                      key={assunto.id}
                      timeout={1000}
                      style={{ height: "100%" }}
                    >
                      <Box key={assunto.id}>
                        <Link to={`/Assunto/${assunto.id}`}>
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                              bgcolor: "background.paper",
                              paddingTop: "10px",
                            }}
                          >
                            <Box sx={{ py: 3, px: 2, pt: 3, pl: 3, pr: 3 }}>
                              <Grid container alignItems="center">
                                <Grid item xs>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    color="black"
                                    display="grid"
                                  >
                                    <b
                                      style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        height: "63px",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                      }}
                                    >
                                      {assunto.assuntoDescricao}
                                    </b>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    color={
                                      assunto.status == "ativo"
                                        ? "success"
                                        : "error"
                                    }
                                    label={
                                      assunto.status == "ativo"
                                        ? "Ativo"
                                        : "Inativo"
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Box sx={{ mb: 1 }}>
                                <Typography
                                  color="text.secondary"
                                  variant="body2"
                                >
                                  Setor: {assunto.setorDescricao ?? "N/A"}
                                </Typography>
                              </Box>
                              <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                  <BsFillStarFill
                                    style={{
                                      marginTop: "3px",
                                      color: "#ffd233",
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {" "}
                                    {assunto.nota}
                                  </Typography>
                                </Grid>

                                <Grid item>
                                  <FaUserAlt
                                    style={{
                                      marginTop: "3px",
                                      color: "#4354a6",
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {" "}
                                    {
                                      assunto.quantidadeSolicitacoesAssociadasAssunto
                                    }
                                  </Typography>
                                </Grid>

                                <Grid item>
                                  <BsFillClockFill
                                    style={{
                                      marginTop: "3px",
                                      color: "#fd4444",
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {" "}
                                    {assunto.quantidadeSolicitacoesAtrasadas}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                            <Divider variant="fullWidth" />
                            <Grid xs={12}>
                              <Box sx={{ mt: 3, mx: 2, pl: 3, pr: 3 }}>
                                <Typography
                                  gutterBottom
                                  variant="body1"
                                  color="black"
                                >
                                  <b>Funcionários</b>
                                </Typography>
                                <Grid
                                  xs={12}
                                  style={{
                                    marginLeft: "-4px",
                                    minHeight: "4rem",
                                  }}
                                >
                                  <NomesBalao names={assunto.funcionarios} />
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>
                        </Link>
                      </Box>
                    </Grow>
                  </TransitionGroup>
                </Grid>
              ))}
        </Grid>
      </Container>
    </>
  );
}
