import { init as initApm } from "@elastic/apm-rum";

// const apm = initApm({
//   serviceName: 'faleconoscoFrontEnd',
//   serverUrl: 'http://localhost:8200',
//   serviceVersion:'',
//   environment:'production'
// });

const apm = {
  startTransaction(transacao) {
    // Implementação mockada, se necessário
    return apm;
  },
  addContext(options) {},

  captureError(options) {},
  end() {},
};

export default apm;
