import { useState, useEffect, forwardRef, useRef } from "react";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "react-loading-skeleton";
import { DialogSetorFuncionario } from "./styles";
import api from "../../../services/api";
import TextField from "@material-ui/core/TextField";
import { Autocomplete, Chip, Stack } from "@mui/material";
import { v4 as uuid } from "uuid";
import { setRef, Grid } from "@material-ui/core";
import Draggable from "react-draggable";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSetorFuncionarioConfirm({
  filtro,
  setorId,
  selecaoFuncionario,
  setSelecaoFuncionario,
  openDialogSetorFuncionario,
  setOpenDialogSetorFuncionario,
  setSetorFuncionarioConfirm,
  idsFuncionariosDesconsiderar,
  naoApresentarChecks,
}) {
  const filtroPadrao = {
    maxResults: 20,
    gerarDadosComplementares: false,
    firstResult: 1,
  };
  const [errorFuncionario, setErrorFuncionario] = useState(false);
  const [funcionario, setFuncionario] = useState(selecaoFuncionario ?? {});
  const [listaFuncionario, setListaFuncionario] = useState([]);
  const [inputFuncionario, setInputFuncionario] = useState("");
  const [loading, setLoading] = useState(false);
  const [limpar, setLimpar] = useState(false);
  const [filtroFuncionario, setFiltroFuncionario] = useState(null);

  const handleChangeFuncionario = (e, v) => {
    e?.preventDefault();
    setErrorFuncionario(false);
    setFuncionario({
      ...funcionario,
      id: v ? v?.id : undefined,
      nome: v ? v?.nome : "",
    });
  };

  const handleClose = (e) => {
    e.preventDefault();

    if (loading) return;

    setOpenDialogSetorFuncionario(false);
    if (setSelecaoFuncionario) setSelecaoFuncionario({});
    setLimpar(!limpar);
    setFuncionario({ id: undefined, nome: "" });
    setFiltroFuncionario(null);
  };

  const handleConfirm = async (e) => {
    e?.preventDefault();

    if (!funcionario.id) {
      setErrorFuncionario(true);
      return;
    }

    setLoading(true);
    setSetorFuncionarioConfirm(funcionario);
  };

  const obterFuncionarios = async () => {
    setLoading(true);
    await api
      .post("/jucerja/v1/funcionario/filtro", filtroFuncionario)
      .then((response) => {
        setListaFuncionario(
          response.data?.funcionarios.filter(
            (fun) => !idsFuncionariosDesconsiderar.find((id) => id == fun.id)
          ) ?? []
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (openDialogSetorFuncionario) {
      setLoading(false);
      setFuncionario(selecaoFuncionario ?? {});
      setErrorFuncionario(false);
      setFiltroFuncionario({
        filtros: [
          filtro ?? {
            coluna: [24],
            comparador: 5,
            valores: [`${setorId}`],
          },
        ],
        ...filtroPadrao,
      });
    }
  }, [openDialogSetorFuncionario]);

  useEffect(() => {
    let timeoutConsulta;
    setLoading(true);

    if (inputFuncionario.length > 0 && inputFuncionario !== funcionario.nome) {
      timeoutConsulta = setTimeout(
        () =>
          setFiltroFuncionario((filtro) => ({
            ...filtro,
            filtros: [
              filtro ?? {
                coluna: [24],
                comparador: 5,
                valores: [setorId],
              },
              { coluna: [28], comparador: 8, valores: [inputFuncionario] },
            ],
          })),
        500
      );
    } else {
      setLoading(false);
    }

    return () => clearTimeout(timeoutConsulta);
  }, [inputFuncionario]);

  useEffect(() => {
    if (filtroFuncionario == null) return;

    obterFuncionarios();
  }, [filtroFuncionario]);

  return (
    <Draggable
      handle={'[class*="MuiDialog-root"]'}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Dialog
        hideBackdrop
        disableBackdropClick
        open={openDialogSetorFuncionario}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="dialog-detail-request-slide-title"
        aria-describedby="dialog-detail-request-dialog-slide-description"
        id="dialog-setor"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {selecaoFuncionario?.id
            ? "Alterar Vínculo Funcionário"
            : "Vincular Funcionário"}
        </DialogTitle>
        <DialogContent>
          <DialogSetorFuncionario id="DialogSetor">
            <DialogSetorFuncionario.Container id="DialogSetor_Container">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Stack paddingTop={1}>
                    <Autocomplete
                      key={limpar}
                      title="Funcionários"
                      id="tags-standard"
                      options={listaFuncionario}
                      getOptionLabel={(option) => option.nome}
                      onInputChange={(e, v) => setInputFuncionario(v)}
                      loading={loading}
                      value={funcionario.id ? funcionario : null}
                      getOptionKey={(option) => option.id}
                      onChange={(e, v) => handleChangeFuncionario(e, v)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant={"outlined"}
                          error={errorFuncionario}
                          label="Selecione o funcionário"
                        />
                      )}
                    />
                  </Stack>

                  {!!naoApresentarChecks ? (
                    <></>
                  ) : (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedSupervisor"
                            color="primary"
                            checked={funcionario.supervisor ?? false}
                            onChange={(e) =>
                              setFuncionario((func) => ({
                                ...func,
                                supervisor: e.target.checked,
                              }))
                            }
                          />
                        }
                        label="Supervisor"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedRecebeTarefas"
                            color="primary"
                            checked={funcionario.recebeTarefas ?? false}
                            onChange={(e) =>
                              setFuncionario((func) => ({
                                ...func,
                                recebeTarefas: e.target.checked,
                              }))
                            }
                          />
                        }
                        label="Apto a receber tarefas"
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogSetorFuncionario.Container>
          </DialogSetorFuncionario>
        </DialogContent>
        <DialogActions>
          <DialogSetorFuncionario.Actions id="DialogSetor.Actions">
            <div className={!loading ? "mostrarAnimacao" : "esconderAnimacao"}>
              <Button onClick={handleClose} className="btn-close-actions">
                Cancelar
              </Button>
            </div>
            {loading ? (
              <Button className="btn-primary-actions">
                <CircularProgress size={22} style={{ color: "var(--white)" }} />
              </Button>
            ) : (
              <Button className="btn-primary-actions" onClick={handleConfirm}>
                Confirmar
              </Button>
            )}
          </DialogSetorFuncionario.Actions>
        </DialogActions>
      </Dialog>
    </Draggable>
  );
}
