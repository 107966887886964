import styled from 'styled-components'

export const DuvidasComponente = styled.div`


.btn-git{
    border-color: var(--alternative7) !important;
    color: var(--alternative7) !important;
}

.btn-active{
    border-color: var(--green) !important;
    color: var(--green) !important;
}
.btn-copy{
    border-color: var(--purple) !important;
    color: var(--purple) !important;
}

.btn-desative{
    border-color: var(--orange) !important;
    color: var(--orange) !important;
}
`

DuvidasComponente.Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;

      button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background: #FFFFFF;
        border: 1px solid #E3E3E3;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 100px;
        padding: 8px 25px 6px 25px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text svg {
        margin: 0 7px 0 0;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text span.MuiButton-label {
        text-transform: capitalize;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnPagination {
        min-width: 0;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`