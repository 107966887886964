import styled from "styled-components";

export const Funcionario = styled.div``;

Funcionario.Box = styled.div`
  @media (min-width: 420px) {
    height: 50px;
    overflow: auto;
  }
`;

Funcionario.Pagination = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 0 10px 0;

  @media (max-width: 600px) {
    margin: 15px 0 0 0;
    font-size: 13px;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnPagination.btnPaginationPrev {
    margin: 0 0 0 15px;
  }
`;

Funcionario.Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 40px 0;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnFilter {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 100px;
    padding: 8px 25px 6px 25px;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnApresentacao {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 100px;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnFilter svg {
    margin: 0 7px 0 0;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnFilter
    span.MuiButton-label {
    text-transform: capitalize;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnPagination {
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
