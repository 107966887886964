import styled from 'styled-components'

export const ComponenteAssunto = styled.div`
.mostrarAnimacao {
    opacity: 1;
    transition: opacity 0.6s, visibility 0.3s;
  }
  
  .esconderAnimacao {
    opacity: 0;
    transition: opacity 0.6s, visibility 0.3s;
    visibility: hidden;
  }
.btn-arrow-back {
    z-index: 15;
    min-width: 0;
    width: 42px;
    height: 42px;
    align-items: center;
    justify-content: center;
    background: #bdbdbd4f;
    color: #383838;
    border-radius: 100%;
    padding: 0;
    font-size: 20px;
    margin: 0 15px 0 0;
}

.card_left_padding{
    
    border-radius: 0 15px 15px 15px;
}

.count_center{
  text-align:center;
  margin-bottom: 15px;
}

.title_bottom_count{
  text-align: center;
  font-weight: 900;
  font-size: x-large;
  color:var(--black)
}

.icon_size{
  font-size: 25px;
  color: #a1aad3
}
`

ComponenteAssunto.KPI=styled.div`
    @media (max-width: 1199px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: stretch;
        flex-wrap: wrap;
    }

    @media (max-width: 600px) {
        .Kpi_Card_1,
        .Kpi_Card_2,
        .Kpi_Card_3,
        .Kpi_Card_4,
        .Kpi_Card_5 {
            width: 100%;
        }

        .Kpi_Card_1,
        .Kpi_Card_2,
        .Kpi_Card_3,
        .Kpi_Card_4 {
            margin-bottom: 20px;
        }
    }

    @media (min-width: 601px) and (max-width: 763px) {
        .Kpi_Card_1 {
            width: calc((100% / 2) - 10px);
            margin: 0 10px 20px 0;
        }

        .Kpi_Card_2 {
            width: calc((100% / 2) - 10px);
            margin: 0 0 20px 10px;
        }

        .Kpi_Card_3 {
            width: calc((100% / 2) - 10px);
            margin: 0 10px 20px 0;
        }

        .Kpi_Card_4 {
            width: calc((100% / 2) - 10px);
            margin: 0 0 20px 10px;
        }

        .Kpi_Card_5 {
            width: 100%;
            margin-left: 0px;
        }
    }

    @media (min-width: 764px) and (max-width: 1199px) {
        .Kpi_Card_1 {
            width: calc((100% / 3) - 10px);
            margin: 0 10px 20px 0;
        }

        .Kpi_Card_2 {
            width: calc((100% / 3) - 10px);
            margin: 0 10px 20px 10px;
        }

        .Kpi_Card_3 {
            width: calc((100% / 3) - 10px);
            margin: 0 0 20px 10px;
        }

        .Kpi_Card_4 {
            width: calc((100% / 2) - 10px);
            margin-right: 10px;
        }
        .Kpi_Card_5 {
            width: calc((100% / 2) - 10px);
            margin-left: 10px;
        }
    }

    @media (min-width: 1200px) {
       display: grid;
       grid-template-columns: repeat(6, 1fr);
       grid-gap: 20px;
    }
`
ComponenteAssunto.KPI_Content = styled.div`
    padding: 10px 15px;
    min-height:200px;
    cursor:pointer;

    .RiUserUnfollowLine span {
        color: var(--dark-yellow);
    }

    .BiMessageSquareDetail span {
        color: var(--dark-orange);
    }
    
    .RiUserVoiceLine span {
            color: var(--dark-blue);
    }
    
    .BiMessageCheck span {
        color: var(--dark-gree);
    }
    
    .BiMessageError span {
        color: var(--red);
    }

    .ImClipboard span {
        color: var(--dark-purple);
    }

    &:hover {
        transform: scale(0.950);
        transition: all ease 0.35s;
      }

`
ComponenteAssunto.informacao = styled.div`
display: flex;
margin-bottom: 10px;
`

ComponenteAssunto.title = styled.div`
display:flex;
font-size: 1.55rem;
font-weight: bold;
margin: 1rem 0 0 0;
width: 100%;
text-align: center;

.titulo{
    border-bottom: 2px solid transparent;
    border-radius: 15px 15px 0 0;
    padding: 6px 10px 0 10px;
    background: var(--white);
}
`

ComponenteAssunto.label = styled.div`
margin: 0 70px 0 0;
align-self: center;
font-weight: bold;
width:50px;
`

ComponenteAssunto.grid = styled.div`
height:510px;
`

ComponenteAssunto.distanciaDivisor = styled.div`
margin: 15px 0 15px 0;
`

ComponenteAssunto.FuncionarioBadge = styled.div`
width: 100%;
background-color: transparent;
border: ${props => props.type == "ATIVO" ? '1px solid var(--green)' : props.type == "INATIVO" ? '2px solid var(--red)':props.type == "AUSENTE" ? '2px solid var(--blue)' : '2px solid var(--orange)'};
color: ${props => props.type == "ATIVO" ? 'var(--green)' : props.type == "INATIVO" ? 'var(--red)':props.type == "AUSENTE" ? 'var(--blue)' : 'var(--orange)'};
padding: 5px 10px;
text-transform: uppercase;
transition: all ease .35s;
display: flex;
align-items: center;
justify-content: center;
padding: 1px 12px 0 12px;
height: 20px;
font-weight: 500;
border-radius: 100px;
font-size: 13px;
`

ComponenteAssunto.Actions = styled.div`
    margin-right: 0.7rem;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-primary-actions {
        width: 87px;
        height: 36px;
    }
    


`