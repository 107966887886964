import { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  TiEquals,
  BsBuildingGear,
  AiOutlineDashboard,
  TbArrowsRandom,
  HiOutlineArrowsExpand,
  HiOutlineClock,
  FiDatabase,
  IoExitOutline,
  IoGitPullRequestOutline,
  IoArrowUpOutline,
  HiOutlineClipboardCheck,
  IoDocumentTextOutline,
  HiOutlineOfficeBuilding,
  IoDocumentsOutline,
  GoTasklist,
  IoPeopleOutline,
  VscQuestion,
  BiIntersect,
  HiOutlineChat,
} from "react-icons/all";
import Skeleton from "@material-ui/lab/Skeleton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import { v4 as uuid } from "uuid";

import { ComponentMenu } from "./styles";

import { logout, getPerfisUsuario } from "../../../services/auth";
import api from "../../../services/api";
import SessionCountdown from "../../elements-ui/SessionCountdown";

export default function Menu(props) {
  const COLLAPSE_MENU = "COLLAPSE_MENU";
  const valorStorage =
    localStorage.getItem(COLLAPSE_MENU) == null
      ? {
          openRequisicoes: false,
          openDashBoards: true,
          openTarefas: false,
          openConfiguracoes: false,
          openBaseConhecimento: false,
          openRedistribuicao: false,
        }
      : JSON.parse(localStorage.getItem(COLLAPSE_MENU));

  const [loading] = useState(false);
  const [loadingLogOff, setLoadingLogOff] = useState(false);
  const [openRequisicoes, setOpenRequisicoes] = useState(
    valorStorage.openRequisicoes
  );
  const [openDashBoards, setOpenDashBoards] = useState(
    valorStorage.openDashBoards
  );
  const [openTarefas, setOpenTarefas] = useState(valorStorage.openTarefas);
  const [openConfiguracoes, setOpenConfiguracoes] = useState(
    valorStorage.openConfiguracoes
  );
  const [openRedistribuicao, setOpenRedistribuicao] = useState(
    valorStorage.openRedistribuicao
  );
  const [openBaseConhecimento, setBaseConhecimento] = useState(
    valorStorage.openBaseConhecimento
  );

  const [permissoes, setPermissoes] = useState({
    dashboard: false,
    dashboardSup: false,
    dashboardAtend: false,
    requisicoes: false,
    tarefas: false,
    configuracoes: false,
  });

  const getPermissoes = async () => {
    await api.post("/jucerja/v1/Autenticacao/users").then((response) => {
      let permissoesInterno = { ...permissoes };

      response.data.rules.forEach((obj) => {
        switch (obj) {
          case "JFCDASHSUPERVISOR_CONSULTAR":
            permissoesInterno = { ...permissoesInterno, dashboardSup: true };
            break;

          case "JFCDASHATENDENTE_CONSULTAR":
            permissoesInterno = { ...permissoesInterno, dashboardAtend: true };
            break;

          case "JFCREQ_CONSULTAR":
            permissoesInterno = { ...permissoesInterno, requisicoes: true };
            break;

          case "JFCTAR_CONSULTAR":
            permissoesInterno = { ...permissoesInterno, tarefas: true };
            break;

          case "JFCCONSISTEMA_CONSULTAR":
            permissoesInterno = { ...permissoesInterno, configuracoes: true };
            break;
        }
      });

      permissoesInterno = {
        ...permissoesInterno,
        dashboard:
          permissoesInterno.dashboardAtend || permissoesInterno.dashboardSup,
      };

      setPermissoes(permissoesInterno);
    });
  };

  const logOff = () => {
    setLoadingLogOff(true);
    logout();
  };

  useEffect(() => {
    getPermissoes();
  }, []);

  useEffect(() => {
    let valor = {
      openRequisicoes,
      openDashBoards,
      openTarefas,
      openConfiguracoes,
      openBaseConhecimento,
      openRedistribuicao,
    };
    localStorage.setItem(COLLAPSE_MENU, JSON.stringify(valor));
  }, [
    openRequisicoes,
    openDashBoards,
    openTarefas,
    openConfiguracoes,
    openBaseConhecimento,
    openRedistribuicao,
  ]);

  return (
    <ComponentMenu id="ComponentMenu" drawer={props.drawer}>
      <ComponentMenu.MenuList id="MenuList" className="no-select">
        {loading ? (
          <a className="active link-navigation-loading">
            <Skeleton animation="wave" />
          </a>
        ) : permissoes.dashboard && getPerfisUsuario().length > 0 ? (
          <>
            <a onClick={() => setOpenDashBoards(!openDashBoards)}>
              {openDashBoards ? (
                <ExpandLess id="caret-link-nav" />
              ) : (
                <ExpandMore id="caret-link-nav" />
              )}
              <AiOutlineDashboard id="icon-link-nav" />
              Dashboard
            </a>
            <ComponentMenu.Link>
              <Collapse in={openDashBoards} timeout="auto" unmountOnExit>
                {permissoes.dashboardAtend ? (
                  <NavLink
                    key={uuid()}
                    activeClassName="active"
                    to={`/dashboard/atendente`}
                  >
                    <div id="align-menu"></div>
                    Atendente
                  </NavLink>
                ) : (
                  <></>
                )}
                {permissoes.dashboardSup ? (
                  <NavLink
                    key={uuid()}
                    activeClassName="active"
                    to={`/dashboard/supervisor`}
                  >
                    <div id="align-menu"></div>
                    Supervisor
                  </NavLink>
                ) : (
                  <></>
                )}
              </Collapse>
            </ComponentMenu.Link>
          </>
        ) : null}

        {loading ? (
          <a className="active link-navigation-loading">
            <Skeleton animation="wave" />
          </a>
        ) : permissoes.requisicoes ? (
          <>
            <a onClick={() => setOpenRequisicoes(!openRequisicoes)}>
              {openRequisicoes ? (
                <ExpandLess id="caret-link-nav" />
              ) : (
                <ExpandMore id="caret-link-nav" />
              )}
              <IoGitPullRequestOutline id="icon-link-nav" />
              Solicitações
            </a>
            <ComponentMenu.Link>
              <Collapse in={openRequisicoes} timeout="auto" unmountOnExit>
                <NavLink activeClassName="active" to="/requisicoes/1">
                  <IoArrowUpOutline id="icon-link-nav" />
                  Ativas
                </NavLink>
                <NavLink activeClassName="active" to="/requisicoes/3">
                  <HiOutlineClipboardCheck id="icon-link-nav" />
                  Resolvidas
                </NavLink>
                <NavLink activeClassName="active" to="/requisicoes/4">
                  <IoDocumentTextOutline id="icon-link-nav" />
                  Meus Assuntos
                </NavLink>
                <NavLink activeClassName="active" to="/requisicoes/2">
                  <HiOutlineOfficeBuilding id="icon-link-nav" />
                  Meu Setor
                </NavLink>
                {/* <NavLink
                                            activeClassName="active"
                                            to="/requisicoes/todas"
                                        >
                                            <IoDocumentsOutline id="icon-link-nav" />
                                            Todas
                                        </NavLink > */}
              </Collapse>
            </ComponentMenu.Link>
          </>
        ) : null}

        {loading ? (
          <a className="active link-navigation-loading">
            <Skeleton animation="wave" />
          </a>
        ) : permissoes.tarefas ? (
          <>
            <a onClick={() => setOpenTarefas(!openTarefas)}>
              {openTarefas ? (
                <ExpandLess id="caret-link-nav" />
              ) : (
                <ExpandMore id="caret-link-nav" />
              )}
              <GoTasklist id="icon-link-nav" />
              Tarefas
            </a>
            <ComponentMenu.Link>
              <Collapse in={openTarefas} timeout="auto" unmountOnExit>
                <NavLink activeClassName="active" to="/tarefas/1">
                  <IoArrowUpOutline id="icon-link-nav" />
                  Ativas
                </NavLink>
                <NavLink activeClassName="active" to="/tarefas/2">
                  <HiOutlineOfficeBuilding id="icon-link-nav" />
                  Do Setor
                </NavLink>
                <NavLink activeClassName="active" to="/tarefas/3">
                  <HiOutlineClipboardCheck id="icon-link-nav" />
                  Resolvidas
                </NavLink>
                {/* <NavLink
                                            activeClassName="active"
                                            to="/tarefas/todas"
                                        >
                                            <IoDocumentsOutline id="icon-link-nav" />
                                            Todas
                                        </NavLink > */}
              </Collapse>
            </ComponentMenu.Link>
            <ComponentMenu.Link>
              <NavLink activeClassName="active" to="/Historico">
                <HiOutlineClock id="icon-link-nav" />
                Histórico de Visualização
              </NavLink>
            </ComponentMenu.Link>
          </>
        ) : (
          <ComponentMenu.Link>
            <NavLink activeClassName="active" to="/Historico">
              <HiOutlineClock id="icon-link-nav" />
              Histórico de Visualização
            </NavLink>
          </ComponentMenu.Link>
        )}
        {loading ? (
          <a className="active link-navigation-loading">
            <Skeleton animation="wave" />
          </a>
        ) : permissoes.configuracoes ? (
          <>
            <a onClick={() => setOpenConfiguracoes(!openConfiguracoes)}>
              {openConfiguracoes ? (
                <ExpandLess id="caret-link-nav" />
              ) : (
                <ExpandMore id="caret-link-nav" />
              )}
              <BsBuildingGear id="icon-link-nav" />
              Configurações
            </a>
            <ComponentMenu.Link>
              <Collapse in={openConfiguracoes} timeout="auto" unmountOnExit>
                <NavLink activeClassName="active" to="/Funcionarios">
                  <IoPeopleOutline id="icon-link-nav" />
                  Funcionários
                </NavLink>
                <NavLink activeClassName="active" to="/Setores">
                  <HiOutlineOfficeBuilding id="icon-link-nav" />
                  Setores
                </NavLink>
                <NavLink activeClassName="active" to="/Assuntos">
                  <HiOutlineChat id="icon-link-nav" />
                  Assuntos
                </NavLink>
              </Collapse>
            </ComponentMenu.Link>
            <a onClick={() => setOpenRedistribuicao(!openRedistribuicao)}>
              {openRedistribuicao ? (
                <ExpandLess id="caret-link-nav" />
              ) : (
                <ExpandMore id="caret-link-nav" />
              )}
              <TbArrowsRandom id="icon-link-nav" />
              Redistribuição
            </a>
            <ComponentMenu.Link>
              <Collapse in={openRedistribuicao} timeout="auto" unmountOnExit>
                <NavLink activeClassName="active" to="/Redistribuicoes">
                  <HiOutlineArrowsExpand id="icon-link-nav" />
                  Redistribuir
                </NavLink>
                <NavLink activeClassName="active" to="/Equalizar">
                  <TiEquals id="icon-link-nav" />
                  Equalizar
                </NavLink>
              </Collapse>
            </ComponentMenu.Link>
            {true ? (
              <></>
            ) : (
              <>
                <a onClick={() => setBaseConhecimento(!openBaseConhecimento)}>
                  {openBaseConhecimento ? (
                    <ExpandLess id="caret-link-nav" />
                  ) : (
                    <ExpandMore id="caret-link-nav" />
                  )}
                  <FiDatabase id="icon-link-nav" />
                  Dúvidas Frequentes
                </a>
                <ComponentMenu.Link>
                  <Collapse
                    in={openBaseConhecimento}
                    timeout="auto"
                    unmountOnExit
                  >
                    <NavLink activeClassName="active" to="/Secao">
                      <BiIntersect id="icon-link-nav" />
                      Seções
                    </NavLink>
                    <NavLink activeClassName="active" to="/Duvidas">
                      <VscQuestion id="icon-link-nav" />
                      Dúvidas
                    </NavLink>
                  </Collapse>
                </ComponentMenu.Link>
              </>
            )}
          </>
        ) : null}

        {loadingLogOff ? (
          <div className="btn-loading-logout">
            <CircularProgress size={30} style={{ color: "var(--primary)" }} />
            Saindo...
          </div>
        ) : (
          <Link
            to={window.location.href.replace(window.location.origin, "")}
            onClick={() => logOff()}
          >
            <IoExitOutline id="icon-link-nav" />
            Sair
          </Link>
        )}
      </ComponentMenu.MenuList>
      <SessionCountdown />
    </ComponentMenu>
  );
}
