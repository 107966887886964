import { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Requests } from "../../pages/Requisicoes/styles";
import { Button, TextField } from "@material-ui/core";
import Container from "../../components/layout/Container";
import TableEmployees from "../../components/elements-ui/TableEmployees";
import Stack from "@mui/material/Stack";
import Grid from "@material-ui/core/Grid";
import api from "../../services/api";
import Collapse from "@mui/material/Collapse";
import Skeleton from "react-loading-skeleton";
import Box from "@mui/material/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import _ from "lodash";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { TransitionGroup } from "react-transition-group";
import {
  BsInfoCircleFill,
  BsFillStarFill,
  FaUser,
  FaUserClock,
  IoIosArrowBack,
  IoIosArrowForward,
  FaWindowRestore,
  FaTable,
  FaUserCheck,
  FaUserAlt,
  BsFillClockFill,
  FaClock,
  MdTableRows,
  FaUserTimes,
  BsFillClipboard2CheckFill,
  BsFillCheckCircleFill,
  FaUserLock,
  FaUserTie,
  AiOutlineEdit,
} from "react-icons/all";
import Grow from "@mui/material/Grow";
import { useDebounce } from "../../functions";
import FilterFuncionarios from "../../components/elements-ui/FilterFuncionarios";
import { Funcionario } from "./style";
import DialogFuncionario from "../../components/elements-ui/DialogFuncionario";

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: "286px",
    "&:hover": {
      transform: "scale(1.019)",
      transition: "all ease .35s",
      borderRadius: "8px",
    },
    cursor: "pointer",
  },
}));

const statusStyle = {
  ATIVO: {
    border: "2px solid var(--green)",
    backgroundColor: "var(--green)",
    color: "white",
  },
  INATIVO: {
    border: "2px solid var(--red)",
    backgroundColor: "var(--red)",
    color: "white",
  },
  AUSENTE: {
    border: "2px solid var(--blue)",
    backgroundColor: "var(--blue)",
    color: "white",
  },
  FERIAS: {
    border: "2px solid #1c8ccb",
    backgroundColor: "#1c8ccb",
    color: "white",
  },
};

const PrimaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--primary)",
    color: "var(--white)",
  },
}))(Tooltip);

export default function Funcionarios() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [funcionarios, setFuncionarios] = useState([]);
  const [utilizarChips, setUtilizarChips] = useState(true);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [nomePesquisa, setNomePesquisa] = useState("");
  const [openDialogFuncionario, setOpenDialogFuncionario] = useState(false);
  const searchQuery = useDebounce(nomePesquisa, 500);
  const [opcoesFiltro, setOpcoesFiltro] = useState({
    filtros: [
      {
        coluna: ["SetorId"],
        comparador: "IsNotNull",
        agruparCondicional: true,
        valores: [""],
      },
      {
        coluna: ["StatusFuncionario"],
        comparador: "In",
        agruparCondicional: true,
        valores: ["inativo", "ativo", "ferias", "ausente"],
      },
    ],
    aplicarUnionCondicional: true,
    utilizarCache: true,
    firstResult: 1,
    maxResults: 8,
  });

  const obterFuncionarios = async () => {
    let opcoes = { ...opcoesFiltro };
    setLoading(true);
    opcoes.firstResult = paginaAtual == 1 ? 1 : (paginaAtual - 1) * 8;
    await api
      .post(`/jucerja/v1/funcionario/filtro`, opcoes)
      .then((response) => {
        setFuncionarios(response.data.funcionarios);
        setTotalPaginas(Math.ceil(response.data.totalRecords / 8));
        setTotalRegistros(response.data.totalRecords);
      })
      .finally(() => setLoading(false));
  };

  const informarDescricaoNome = () => {
    let filtros =
      searchQuery && searchQuery.length > 0
        ? [
            {
              coluna: ["28"],
              comparador: "8",
              agruparCondicional: true,
              valores: [searchQuery],
            },
            {
              coluna: ["SetorId"],
              comparador: "IsNotNull",
              agruparCondicional: true,
              valores: [""],
            },
          ]
        : [
            {
              coluna: ["SetorId"],
              comparador: "IsNotNull",
              agruparCondicional: true,
              valores: [""],
            },
            {
              coluna: ["StatusFuncionario"],
              comparador: "In",
              agruparCondicional: true,
              valores: ["inativo", "ativo", "ferias", "ausente"],
            },
          ];

    setOpcoesFiltro((filtro) => ({
      ...filtro,
      filtros: filtros,
      firstResult: 1,
    }));

    setPaginaAtual(1);
  };

  useEffect(() => {
    if (paginaAtual != opcoesFiltro.firstResult)
      setOpcoesFiltro((filtro) => ({ ...filtro, firstResult: paginaAtual }));
  }, [paginaAtual]);

  useEffect(() => {
    obterFuncionarios();
  }, [opcoesFiltro]);

  useEffect(() => {
    informarDescricaoNome();
  }, [searchQuery]);

  return (
    <>
      <div
        style={{
          width: "100%",
          position: "fixed",
          zIndex: "100",
        }}
      >
        <DialogFuncionario
          openDialogFuncionario={openDialogFuncionario}
          setOpenDialogFuncionario={setOpenDialogFuncionario}
        />

        <Requests.BarraSlastic>
          <Requests.AttachmentListAddAndMessageBox>
            <TextField
              style={{ zIndex: 100 }}
              id="txt-send-message-request"
              variant="outlined"
              onKeyUp={(e) => {
                setNomePesquisa(e.target.value.trim());
              }}
              on
              placeholder="Pesquisar Funcionários"
              disabled={loading}
            />
          </Requests.AttachmentListAddAndMessageBox>
        </Requests.BarraSlastic>
      </div>
      <Container title="Funcionários">
        {true ? (
          <Funcionario.Action>
            <Grid container direction="row" justifyContent="space-between">
              {loading ? (
                <Skeleton width={190} height={36} />
              ) : (
                <Button
                  onClick={() => setOpenDialogFuncionario(true)}
                  className="btn-backround-primary-actions"
                  style={{ background: "var(--primary)!important" }}
                >
                  Vincular Funcionário
                </Button>
              )}
            </Grid>
            <Funcionario.Pagination id="Funcionario_Pagination">
              {loading ? (
                <Skeleton width={120} height={35} />
              ) : funcionarios.lenght === 0 ? (
                <></>
              ) : (
                <>
                  <Typography style={{ flex: "none" }}>
                    Página {paginaAtual} de {totalPaginas} de {totalRegistros}{" "}
                    {totalRegistros <= 1 ? "registro" : "registros"}
                  </Typography>
                  <Button
                    className="btnPagination btnPaginationPrev"
                    onClick={() => setPaginaAtual(paginaAtual - 1)}
                    disabled={paginaAtual === 1 ? true : false}
                  >
                    <IoIosArrowBack />
                  </Button>
                  <Button
                    className="btnPagination btnPaginationNext"
                    onClick={() => setPaginaAtual(paginaAtual + 1)}
                    disabled={paginaAtual === totalPaginas ? true : false}
                  >
                    <IoIosArrowForward />
                  </Button>
                </>
              )}
            </Funcionario.Pagination>
          </Funcionario.Action>
        ) : (
          <Funcionario.Action>
            <Grid container direction="row" justifyContent="space-between">
              <div>
                <div
                  style={{ display: "inline-flex", padding: "0 5px 10px 0" }}
                >
                  <FilterFuncionarios />
                  {/* // k  aplicarFiltro={aplicarFiltro} setAplicarFiltro={setAplicarFiltro} setSalvarFiltro={setSalvarFiltro} /> */}
                </div>
              </div>
              <Button
                onClick={() => setUtilizarChips(!utilizarChips)}
                className="btnApresentacao"
                style={{ fontSize: "20px" }}
              >
                {utilizarChips ? <MdTableRows /> : <FaTable />}
              </Button>
            </Grid>
          </Funcionario.Action>
        )}

        {utilizarChips ? (
          <Grid container spacing={4} style={{ marginBottom: "40px" }}>
            {loading
              ? [1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    md={6}
                    lg={4}
                    xl={3}
                    className={classes.card}
                  >
                    <TransitionGroup>
                      <Collapse key={uuid()}>
                        <Box key={uuid()}>
                          <Box
                            sx={{
                              borderRadius: "20px",
                              bgcolor: "background.paper",
                              paddingTop: "10px",
                            }}
                          >
                            <Box sx={{ pt: 1, pl: 2, pr: 3 }}>
                              <Grid container alignItems="center">
                                <Grid container>
                                  <Grid item xs={4}>
                                    <Skeleton
                                      variant="circular"
                                      width={50}
                                      height={50}
                                    />
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Stack
                                      paddingBottom={1}
                                      direction="row"
                                      spacing={2}
                                    >
                                      <Skeleton width={200} height={20} />
                                    </Stack>

                                    <Stack
                                      paddingBottom={1}
                                      direction="column"
                                      spacing={-0.5}
                                    >
                                      <Typography
                                        paddingBottom={0}
                                        noWrap
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        color="black"
                                      >
                                        <Skeleton width={200} height={20} />
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid alignItems="center" xs>
                                <Grid xs>
                                  <Typography
                                    paddingTop={3}
                                    gutterBottom
                                    variant="body2"
                                    component="div"
                                    color="black"
                                    display="grid"
                                  >
                                    <Skeleton width={100} height={20} />
                                  </Typography>
                                </Grid>
                                <Funcionario.Box>
                                  <Grid container>
                                    <Grid item xs={6}>
                                      <Typography
                                        noWrap
                                        paddingLeft={2}
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        color="black"
                                      >
                                        <span
                                          style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                          }}
                                        >
                                          <Skeleton width={200} height={20} />
                                        </span>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Funcionario.Box>
                              </Grid>
                              <Grid alignItems="center" xs>
                                <Grid xs>
                                  <Typography
                                    paddingTop={3}
                                    gutterBottom
                                    variant="body2"
                                    component="div"
                                    color="black"
                                    display="grid"
                                  >
                                    <Skeleton width={100} height={20} />
                                  </Typography>
                                </Grid>
                                <Funcionario.Box>
                                  <Grid container>
                                    <Grid item xs={6}>
                                      <Typography
                                        noWrap
                                        paddingLeft={2}
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        color="black"
                                      >
                                        <span
                                          style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                          }}
                                        >
                                          <Skeleton width={200} height={20} />
                                        </span>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Funcionario.Box>
                              </Grid>
                              <Grid alignItems="center" xs>
                                <Grid xs>
                                  <Typography
                                    paddingTop={3}
                                    gutterBottom
                                    variant="body2"
                                    component="div"
                                    color="black"
                                    display="grid"
                                  >
                                    <Skeleton width={200} height={20} />
                                  </Typography>
                                </Grid>
                                <Grid container>
                                  <div
                                    style={{
                                      paddingTop: "10px",
                                      paddingLeft: "16px",
                                    }}
                                  >
                                    <Stack
                                      display={"contents"}
                                      color="#4354a6"
                                      lineHeight={"0px"}
                                    >
                                      <Skeleton width={220} height={20} />
                                    </Stack>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid alignItems="center" xs>
                                <Grid xs>
                                  <Typography
                                    paddingTop={3}
                                    gutterBottom
                                    variant="body2"
                                    component="div"
                                    color="black"
                                    display="grid"
                                  >
                                    <Skeleton width={100} height={20} />
                                  </Typography>
                                </Grid>

                                <Grid container alignItems="center">
                                  <Skeleton width={230} height={10} />
                                </Grid>
                                <Divider
                                  sx={{ marginTop: "5px", marginBottom: "5px" }}
                                  variant="fullWidth"
                                />
                                <Grid container alignItems="center">
                                  <Skeleton width={230} height={10} />
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Collapse>
                    </TransitionGroup>
                  </Grid>
                ))
              : funcionarios.map((funcionario) => (
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    md={6}
                    lg={4}
                    xl={3}
                    className={classes.card}
                  >
                    <TransitionGroup>
                      <Collapse key={funcionario.id}>
                        <Box key={funcionario.id}>
                          <Link to={`/Funcionario/${funcionario.id}`}>
                            <Box
                              sx={{
                                borderRadius: "20px",
                                bgcolor: "background.paper",
                                paddingTop: "10px",
                              }}
                            >
                              <Box sx={{ pt: 1, pl: 2, pr: 3 }}>
                                <Grid container alignItems="center">
                                  <Grid container>
                                    <Grid item xs={4}>
                                      <Avatar
                                        alt={funcionario.nome}
                                        sx={{ width: "80px", height: "80px" }}
                                        style={
                                          statusStyle[
                                            funcionario.status.toUpperCase()
                                          ]
                                        }
                                        // src='https://ichef.bbci.co.uk/news/304/amz/worldservice/live/assets/images/2014/06/24/140624081310_google_glass_app_304x171_google_nocredit.jpg'
                                      >
                                        {`${
                                          funcionario.nome
                                            .trim()
                                            .split(" ")[0][0]
                                        }${
                                          funcionario.nome.trim().split(" ")[
                                            funcionario.nome.trim().split(" ")
                                              .length - 1
                                          ][0]
                                        }`.toUpperCase()}
                                      </Avatar>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Stack
                                        paddingBottom={1}
                                        direction="row"
                                        spacing={2}
                                      >
                                        <Chip
                                          style={
                                            statusStyle[
                                              funcionario.status.toUpperCase()
                                            ]
                                          }
                                          size="small"
                                          variant="filled"
                                          label={
                                            funcionario.status.toUpperCase() ==
                                            "ATIVO"
                                              ? "Ativo"
                                              : funcionario.status.toUpperCase() ==
                                                "FERIAS"
                                              ? "Férias"
                                              : funcionario.status.toUpperCase() ==
                                                "AUSENTE"
                                              ? "Ausente"
                                              : "Inativo"
                                          }
                                        />
                                        {funcionario.avaliacao <= 0 ? (
                                          <></>
                                        ) : (
                                          <Chip
                                            style={{
                                              border: "1px solid #e5a900",
                                              backgroundColor: "#e5a900",
                                              color: "white",
                                            }}
                                            size="small"
                                            variant="filled"
                                            label={
                                              <>
                                                <BsFillStarFill
                                                  style={{
                                                    marginTop: "3px",
                                                    color: "white",
                                                  }}
                                                />{" "}
                                                <span>
                                                  {funcionario.avaliacao.toFixed(
                                                    2
                                                  )}
                                                </span>
                                              </>
                                            }
                                          />
                                        )}
                                      </Stack>

                                      <Stack
                                        paddingBottom={1}
                                        direction="column"
                                        spacing={-0.5}
                                      >
                                        <Typography
                                          paddingBottom={0}
                                          noWrap
                                          gutterBottom
                                          variant="body2"
                                          component="div"
                                          color="black"
                                        >
                                          <b
                                            style={{
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              WebkitLineClamp: 2,
                                              WebkitBoxOrient: "vertical",
                                            }}
                                          >
                                            {funcionario.nome}
                                          </b>
                                        </Typography>
                                        <Typography
                                          fontSize={10}
                                          noWrap
                                          gutterBottom
                                          variant="subtitle2"
                                          component="span"
                                          color="black"
                                        >
                                          <b
                                            style={{
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                            }}
                                          >
                                            Email:
                                          </b>
                                          {funcionario.email ?? "-"}
                                        </Typography>
                                        <Typography
                                          fontSize={10}
                                          noWrap
                                          gutterBottom
                                          variant="subtitle2"
                                          component="span"
                                          color="black"
                                        >
                                          <b
                                            style={{
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                            }}
                                          >
                                            Login:
                                          </b>
                                          {funcionario.login}
                                        </Typography>
                                        <Typography
                                          fontSize={10}
                                          noWrap
                                          gutterBottom
                                          variant="subtitle2"
                                          component="span"
                                          color="black"
                                        >
                                          <b
                                            style={{
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                            }}
                                          >
                                            Perfis:
                                          </b>
                                          {funcionario.informacoes.perfis
                                            ?.length > 0
                                            ? funcionario.informacoes.perfis
                                                ?.map((x) => x.descricao)
                                                .join(", ")
                                            : "-"}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid alignItems="center" xs>
                                  <Grid xs>
                                    <Typography
                                      paddingTop={3}
                                      gutterBottom
                                      variant="body2"
                                      component="div"
                                      color="black"
                                      display="grid"
                                    >
                                      <b>Setor</b>
                                    </Typography>
                                  </Grid>
                                  <Funcionario.Box>
                                    <Grid container>
                                      {funcionario.informacoes.setores?.map(
                                        (setor) => (
                                          // <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
                                          //     {setor.descricao}
                                          // </span>

                                          <PrimaryTooltip
                                            title={setor.descricao}
                                            placement="top"
                                          >
                                            <Grid item xs={6}>
                                              <Typography
                                                noWrap
                                                paddingLeft={2}
                                                gutterBottom
                                                variant="body2"
                                                component="div"
                                                color="black"
                                              >
                                                <span
                                                  style={{
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: "vertical",
                                                  }}
                                                >
                                                  {setor.descricao}
                                                </span>
                                              </Typography>
                                            </Grid>
                                          </PrimaryTooltip>
                                        )
                                      )}
                                    </Grid>
                                  </Funcionario.Box>
                                </Grid>
                                <Grid alignItems="center" xs>
                                  <Grid xs>
                                    <Typography
                                      paddingTop={3}
                                      gutterBottom
                                      variant="body2"
                                      component="div"
                                      color="black"
                                      display="grid"
                                    >
                                      <b>Assuntos</b>
                                    </Typography>
                                  </Grid>
                                  <Funcionario.Box>
                                    <Grid container>
                                      {funcionario.informacoes.assuntos.map(
                                        (x) => (
                                          <PrimaryTooltip
                                            title={x.descricao}
                                            placement="top"
                                          >
                                            <Grid item xs={6}>
                                              <Typography
                                                noWrap
                                                paddingLeft={2}
                                                gutterBottom
                                                variant="body2"
                                                component="div"
                                                color="black"
                                              >
                                                <span
                                                  style={{
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: "vertical",
                                                  }}
                                                >
                                                  {x.descricao}
                                                </span>
                                              </Typography>
                                            </Grid>
                                          </PrimaryTooltip>
                                        )
                                      )}
                                    </Grid>
                                  </Funcionario.Box>
                                </Grid>
                                <Grid alignItems="center" xs>
                                  <Grid xs>
                                    <Typography
                                      paddingTop={3}
                                      gutterBottom
                                      variant="body2"
                                      component="div"
                                      color="black"
                                      display="grid"
                                    >
                                      <b>Solicitações</b>
                                    </Typography>
                                  </Grid>
                                  <Grid container>
                                    <div
                                      style={{
                                        paddingTop: "10px",
                                        paddingLeft: "16px",
                                      }}
                                    >
                                      <Stack
                                        display={"contents"}
                                        color="#4354a6"
                                        lineHeight={"0px"}
                                      >
                                        <PrimaryTooltip
                                          title={`Solicitações ${funcionario.informacoes.quantidadeSolicitacoes}`}
                                          placement="top"
                                        >
                                          <span style={{ width: "70px" }}>
                                            <FaUser
                                              style={{
                                                fontSize: "25px",
                                                paddingRight: "10px",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "20.5px",
                                                fontWeight: "bold",
                                                paddingRight: "10px",
                                              }}
                                            >
                                              {
                                                funcionario.informacoes
                                                  .quantidadeSolicitacoes
                                              }
                                            </span>
                                          </span>
                                        </PrimaryTooltip>
                                        <PrimaryTooltip
                                          title={`Tarefas ${funcionario.informacoes.quantidadeTarefasAssociadas}`}
                                          placement="top"
                                        >
                                          <span style={{ width: "70px" }}>
                                            <FaWindowRestore
                                              style={{
                                                fontSize: "25px",
                                                paddingRight: "10px",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "20.5px",
                                                fontWeight: "bold",
                                                paddingRight: "10px",
                                              }}
                                            >
                                              {
                                                funcionario.informacoes
                                                  .quantidadeTarefasAssociadas
                                              }
                                            </span>
                                          </span>
                                        </PrimaryTooltip>
                                        <PrimaryTooltip
                                          title={`Solicitações em Verificação Interna ${funcionario.informacoes.quantidadeSolicitacoesEmVerificacaoInterna}`}
                                          placement="top"
                                        >
                                          <span style={{ width: "70px" }}>
                                            <BsFillClipboard2CheckFill
                                              id="RiUserUnfollowLine"
                                              style={{
                                                fontSize: "25px",
                                                paddingRight: "10px",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "20.5px",
                                                fontWeight: "bold",
                                                paddingRight: "10px",
                                              }}
                                            >
                                              {
                                                funcionario.informacoes
                                                  .quantidadeSolicitacoesEmVerificacaoInterna
                                              }
                                            </span>
                                          </span>
                                        </PrimaryTooltip>
                                        <PrimaryTooltip
                                          title={`Solicitações Atrasadas ${funcionario.informacoes.quantidadeSolicitacoesAtrasadas}`}
                                          placement="top"
                                        >
                                          <span style={{ width: "70px" }}>
                                            <FaClock
                                              id="RiUserUnfollowLine"
                                              style={{
                                                fontSize: "25px",
                                                paddingRight: "10px",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "20.5px",
                                                fontWeight: "bold",
                                                paddingRight: "10px",
                                              }}
                                            >
                                              {
                                                funcionario.informacoes
                                                  .quantidadeSolicitacoesAtrasadas
                                              }
                                            </span>
                                          </span>
                                        </PrimaryTooltip>
                                      </Stack>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid alignItems="center" xs>
                                  <Grid xs>
                                    <Typography
                                      paddingTop={3}
                                      gutterBottom
                                      variant="body2"
                                      component="div"
                                      color="black"
                                      display="grid"
                                    >
                                      <b>Atribuições</b>
                                    </Typography>
                                  </Grid>

                                  <Grid container alignItems="center">
                                    <Grid item xs>
                                      <Typography
                                        noWrap
                                        paddingLeft={2}
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        color="black"
                                        display="grid"
                                      >
                                        Recebe Tarefas
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Chip
                                        sx={{ width: "80px" }}
                                        size="small"
                                        variant="outlined"
                                        color={
                                          funcionario.respondeTarefa
                                            ? "success"
                                            : "error"
                                        }
                                        label={
                                          funcionario.respondeTarefa
                                            ? "Sim"
                                            : "Não"
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <Divider
                                    sx={{
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                    variant="fullWidth"
                                  />
                                  <Grid container alignItems="center">
                                    <Grid item xs>
                                      <Typography
                                        noWrap
                                        paddingLeft={2}
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        color="black"
                                        display="grid"
                                      >
                                        Distribuição
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Chip
                                        sx={{ width: "80px" }}
                                        size="small"
                                        variant="outlined"
                                        color={
                                          funcionario.recebeDistribuicao
                                            ? "success"
                                            : "error"
                                        }
                                        label={
                                          funcionario.recebeDistribuicao
                                            ? "Sim"
                                            : "Não"
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Link>
                        </Box>
                      </Collapse>
                    </TransitionGroup>
                  </Grid>
                ))}
          </Grid>
        ) : (
          <></>
        )}
        {!utilizarChips ? (
          <TransitionGroup>
            <Grow>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TableEmployees />
                </Grid>
              </Grid>
            </Grow>
          </TransitionGroup>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
}
