import Container from '../../components/layout/Container'
import Grid from '@material-ui/core/Grid'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Card } from "../../components/elements-ui/Card"
import { Badge } from "../../components/elements-ui/Badge"
import { ActionTable } from "../../components/elements-ui/ActionTable"
import { Button, Checkbox } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { AiOutlineEdit, AiOutlineCopy, CgAdd, AiOutlineDelete, AiOutlinePoweroff } from 'react-icons/all'
import { HiOutlineArrowsExpand } from 'react-icons/all'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import api from '../../services/api'
import { Requests } from '../../pages/Requisicoes/styles'

import DialogConfirmation from '../../components/elements-ui/DialogConfirmation'
import { DuvidasComponente } from './styles'
import { convertISODateToDateView } from '../../functions'
import DialogVersoesArtigo from '../../components/elements-ui/DialogVersoesArtigo'


const PrimaryTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
    },
}))(Tooltip);

export default function Duvidas() {
    const { enqueueSnackbar } = useSnackbar()
    const [registros, setRegistros] = useState([])
    const [pesquisa, setPesquisa] = useState('');
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(undefined);
    const [rowCountState, setRowCountState] = useState(rowCount || 0);
    const [rowsState, setRowsState] = useState({
        page: 1,
        pageSize: 10,
    });


    const [artigoSelecionado, setArtigoSelecionado] = useState({});

    const [openDialogVersoes, setOpenDialogVersoes] = useState(false);

    const [openDialogExclusao, setOpenDialogExclusao] = useState(false);
    const [exclusaoConfirm, setExclusaoConfirm] = useState(false);

    const [openDialogHabilitarDesabilitar, setOpenDialogHabilitarDesabilitar] = useState(false);
    const [habilitarDesabilitarConfirm, setHabilitarDesabilitarConfirm] = useState(false);


    const getRegistros = async () => {
        let options = {
            firstResult: ((rowsState.page - 1) * rowsState.pageSize) + 1,
            maxResults: rowsState.pageSize,
            pesquisa
        }

        setLoading(true);
        setRowCount(undefined);
        await api.post('/jucerja/v1/artigo/listagem-artigos', options)
            .then(response => {
                setRegistros(response?.data?.artigos ?? []);
                setRowCount(response?.data?.totalRecords ?? 0);
            })
            .catch(error => {

                enqueueSnackbar(error.toString(), { variant: 'error' })
            })
            .finally(() => {
                setLoading(false);
            });

        setLoading(false);
    }

    const columns = [
        {
            field: 'titulo',
            headerName: 'Título',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'secao',
            flex: 1,
            headerName: 'Seção',
            width: 180,
        },
        {
            field: 'dataPublicacao',
            headerName: 'Publicação',
            renderCell: (params) => (<b>{convertISODateToDateView(params.value)}</b>),
            width: 195
        },
        {
            field: "ativo",
            headerName: "Ativo",
            align: "center",
            headerAlign: "center",
            width: 165,
            editable: false,
            valueGetter: (params) => params.value ? 'Sim' : 'Não',
            renderCell: (params) =>

                params.row.ativo
                    ?
                    <PrimaryTooltip title="Desabilitar" placement="top">
                        <Badge botao type={params.row.ativo} onClick={() => { setArtigoSelecionado(params.row); setOpenDialogHabilitarDesabilitar(true) }} >{params.value}</Badge>
                    </PrimaryTooltip>
                    :
                    <PrimaryTooltip title="Habilitar" placement="top">
                        <Badge botao type={params.row.ativo} onClick={() => { setArtigoSelecionado(params.row); setOpenDialogHabilitarDesabilitar(true) }} >{params.value}</Badge>
                    </PrimaryTooltip>



        },
        {
            field: 'acao',
            headerName: 'Ações',
            width: 190,
            filterable: false,
            renderCell: (params) => {
                return (
                    <ActionTable >
                        <PrimaryTooltip title="Editar Artigo" placement="top">
                            <Button onClick={() => { setArtigoSelecionado(params.row); setOpenDialogVersoes(true); }} className="btn-edit"><AiOutlineEdit /></Button>
                        </PrimaryTooltip>


                        <PrimaryTooltip title="Excluir" placement="top">
                            <Button onClick={() => { setArtigoSelecionado(params.row); setOpenDialogExclusao(true) }} className="btn-delete"><AiOutlineDelete /></Button>
                        </PrimaryTooltip>

                    </ActionTable>
                )
            }
        }
    ]

    useEffect(() => {
        if (rowsState.page === 0) {

            setRowsState(prev => ({ ...prev, page: 1 }));
            return;
        }

        getRegistros();

    }, [rowsState.page])


    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);

    useEffect(async () => {
        if (!habilitarDesabilitarConfirm)
            return;

        const habilitarDesabilitarAction =
            artigoSelecionado.ativo
                ? 'desabilitar'
                : 'habilitar';

        await api.put(`/jucerja/v1/artigo/${habilitarDesabilitarAction}/${artigoSelecionado.id}`)
            .then(response => {
                let registrosCache = registros;
                let registroSelecionado = registrosCache.find(r => r.id == response.data.artigoId);

                if (registroSelecionado) {
                    registroSelecionado.ativo = !registroSelecionado.ativo;
                    setRegistros(registrosCache);
                    enqueueSnackbar(`Artigo '${registroSelecionado.titulo}' ${artigoSelecionado.ativo ? 'habilitado' : 'desabilitado'} !`, { variant: 'success' })
                }

            })
            .catch(() =>
                enqueueSnackbar("Não foi possível alterar o status do artigo.", { variant: 'error' }))
            .finally(() => {
                setHabilitarDesabilitarConfirm(false);
                setOpenDialogHabilitarDesabilitar(false);
            });

    }, [habilitarDesabilitarConfirm])

    useEffect(async ()=>{
        if(!exclusaoConfirm)
        return;

        await api.delete(`/jucerja/v1/artigo/${artigoSelecionado.id}`)
        .then(response => 
            enqueueSnackbar("Artigo excluído com sucesso.", { variant: 'success' }))
        .catch((error) =>{
            var firstMensagem = error?.response?.data?.messages?.reverse().pop();

            if (firstMensagem?.campos) {
                firstMensagem?.campos.forEach(campo => enqueueSnackbar(campo.mensagem ?? campo.Mensagem, { variant: 'error' }))
                return
            }

            if (firstMensagem?.mensagem) {
                enqueueSnackbar(firstMensagem?.mensagem, { variant: 'error' })
            } else {
                enqueueSnackbar('Não foi possível escluir o Artigo. Por favor tente novamente', { variant: 'error' })
            }
        })
        .finally(async () => {
            setExclusaoConfirm(false);
            setOpenDialogExclusao(false);
            await getRegistros();
        });

    },[exclusaoConfirm])

    return (
        <>
            <div style={{
                width: "100%",
                position: "fixed",
                zIndex: "100"
            }}>
                <Requests.BarraSlastic>
                    <Requests.AttachmentListAddAndMessageBox>
                        <TextField
                            style={{ zIndex: 100 }}
                            id="txt-pesquisa-duvidas"
                            variant="outlined"
                            value={pesquisa}
                            inputProps={{maxLength:500}}
                            onChange={e => setPesquisa(e.target.value)}
                            onKeyUp={e => {

                                if (e?.code &&
                                    (e.code.toLowerCase() === 'enter' || e.code.toLowerCase() === 'numpadenter')) {

                                    setRowsState(prev => ({ ...prev, page: 1 }));

                                    if (rowsState.page == 1)
                                        getRegistros();
                                }
                            }}
                            placeholder="Pesquisar em dúvidas"
                            disabled={loading}
                        />
                    </Requests.AttachmentListAddAndMessageBox>

                    {/* </Grid> */}
                </Requests.BarraSlastic>
            </div>
            <Container title="Dúvidas">

                <DuvidasComponente>

                    <DialogVersoesArtigo
                        idArtigo={artigoSelecionado?.id}
                        openDialog={openDialogVersoes}
                        setOpenDialog={setOpenDialogVersoes}
                    />

                    <DialogConfirmation
                        openDialog={openDialogExclusao}
                        setOpenDialog={setOpenDialogExclusao}
                        setConfirm={setExclusaoConfirm}
                        txtConfirm="Sim"
                        txtCancel="Não"
                        txtTitle="Confirmar exclusão do artigo"
                        txtContainer={`Deseja realmente excluir o artigo '${artigoSelecionado?.titulo}' ?`}
                        closeControlOutsideOnConfirm={true}
                    />

                    <DialogConfirmation
                        openDialog={openDialogHabilitarDesabilitar}
                        setOpenDialog={setOpenDialogHabilitarDesabilitar}
                        setConfirm={setHabilitarDesabilitarConfirm}
                        txtConfirm="Sim"
                        txtCancel="Não"
                        txtTitle={`Confirmar ${artigoSelecionado?.ativo ? "desabilitação" : "habilitação"} do artigo`}
                        txtContainer={`Deseja realmente  ${artigoSelecionado?.ativo ? "desabilitar" : "habilitar"} o artigo '${artigoSelecionado?.titulo}' ?`}
                        closeControlOutsideOnConfirm={true}
                    />

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card>
                                <Card.Content>
                                    <div style={{ height: 600, width: '100%' }}>
                                        <DataGrid
                                            getRowId={row => row.id}
                                            rowCount={rowCountState}
                                            rows={registros}
                                            columns={columns}
                                            disableColumnMenu
                                            hideFooterPagination={loading}
                                            disableSelectionOnClick
                                            loading={loading}
                                            pageSize={rowsState.pageSize}
                                            page={rowsState.page > 0 ? rowsState.page - 1 : 0}
                                            pagination
                                            hideFooterSelectedRowCount
                                            disableExtendRowFullWidth={false}
                                            paginationMode="server"
                                            onPageChange={(page) => {
                                                setRowsState((prev) => ({ ...prev, page: page + 1 }));
                                            }}
                                            localeText={ptBR.props.MuiDataGrid.localeText}
                                        />

                                    </div>
                                    <DuvidasComponente.Actions>
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={{
                                            display: "contents"
                                        }}>
                                            <Link
                                                to="/Duvida/0"
                                            >
                                                <Button className="btnNovo" style={{ background: '#66c55b', color: 'white' }} >Novo</Button>
                                            </Link>
                                        </Grid>
                                    </DuvidasComponente.Actions>
                                </Card.Content>
                            </Card>
                        </Grid>
                    </Grid>
                </DuvidasComponente>
            </Container >
        </>
    )
}