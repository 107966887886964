import Container from "../../components/layout/Container";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { Button, capitalize } from "@material-ui/core";
import {
  BiVerticalCenter,
  IoGitPullRequestOutline,
  BiMessageRounded,
  MdSubject,
  FaTasks,
  GrAttachment,
  RiMapPinUserLine,
  AiOutlineMessage,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/all";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { Tasks } from "../Tarefas/styles";
import { Requests } from "../Requisicoes/styles";
import Skeleton from "react-loading-skeleton";
import { convertISODateToDateView, limitChar } from "../../functions";
import NoResult from "../../components/animations/NoResults";
import { v4 as uuid } from "uuid";

export default function Historico() {
  let itemsLoading = [];

  const [loading, setLoading] = useState(false);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [qtdeRegistros, setQtdeRegistros] = useState(10);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [totalPaginas, setTotalPaginas] = useState(
    Number(Math.round(totalRegistros / qtdeRegistros))
  );

  const [historico, setHistorico] = useState([]);

  const getHistorico = async () => {
    setLoading(true);

    await api
      .get(
        `/jucerja/v1/historicovisualizacao?FirstResult=${
          (paginaAtual - 1) * qtdeRegistros + 1
        }&MaxResults=${qtdeRegistros}`
      )
      .then((response) => {
        setHistorico(response.data.retorno);
        setTotalRegistros(response.data.totalRecords);
        setTotalPaginas(
          Number(
            Math.ceil(
              Number(response.data.totalRecords) / Number(qtdeRegistros)
            )
          )
        );
      })
      .catch((error) => {
        // setLoading(false)
        // setError(true)
        // setErrorMessage(error.toString())
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const LoadingRequisicoes = () => (
    <a key={uuid()} className="no-select requests-listitem">
      <Button
        className="btn-acesso-requisicao"
        disableRipple={true}
        disableTouchRipple={true}
      >
        <Requests.ListItemContent id="Request_ListItemContent">
          <Requests.ListItemInformations id="Requests_ListItemInformations">
            <Requests.ListItemInfo id="Request_ListItemInfo">
              <Requests.ListItemTitle id="Request_ListItemTitle">
                <Skeleton width={200} height={30} />
              </Requests.ListItemTitle>
              <Requests.ListItemDescription id="Request_ListItemDescription">
                <Skeleton
                  width={250}
                  height={30}
                  style={{ position: "relative", top: "10px" }}
                />
              </Requests.ListItemDescription>
            </Requests.ListItemInfo>
            <Requests.ListItemReq id="Request_ListItemReq">
              <Requests.ListItemNumberRequest id="Request_ListItemNumberRequest">
                <Skeleton width={280} height={30} />
              </Requests.ListItemNumberRequest>
              <Requests.ListItemData id="Request_ListItemData">
                <Skeleton width={50} height={30} />
              </Requests.ListItemData>
            </Requests.ListItemReq>
          </Requests.ListItemInformations>
          <Requests.ListItemSubInfo
            id="Request_ListItemSubInfo"
            style={{ width: "100%" }}
          >
            <Skeleton
              width={180}
              height={30}
              style={{ position: "relative", top: "-10px", width: "100%" }}
            />
          </Requests.ListItemSubInfo>
        </Requests.ListItemContent>
      </Button>
    </a>
  );

  for (var i = 0; i < 4; i++) {
    itemsLoading.push(LoadingRequisicoes());
  }

  useEffect(() => {
    getHistorico();
  }, [paginaAtual]);

  return (
    <Container title="Histórico de Visualização">
      <Requests id="Requests">
        {
          <>
            <Requests.Action id="Requests_Action" style={{ float: "right" }}>
              {loading ? (
                <Skeleton width={116} height={40} />
              ) : (
                <>
                  <Grid spacing={1}>
                    <Requests.Pagination id="Request_Pagination">
                      {loading ? (
                        <Skeleton width={180} height={30} />
                      ) : historico.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          Página {paginaAtual} de {totalPaginas} de{" "}
                          {totalRegistros}{" "}
                          {totalRegistros <= 1 ? "registro" : "registros"}
                          <Button
                            className="btnPagination btnPaginationPrev"
                            onClick={() => setPaginaAtual(paginaAtual - 1)}
                            disabled={paginaAtual === 1 ? true : false}
                          >
                            <IoIosArrowBack />
                          </Button>
                          <Button
                            className="btnPagination btnPaginationNext"
                            onClick={() => setPaginaAtual(paginaAtual + 1)}
                            disabled={
                              paginaAtual === totalPaginas ? true : false
                            }
                          >
                            <IoIosArrowForward />
                          </Button>
                        </>
                      )}
                    </Requests.Pagination>
                  </Grid>
                </>
              )}
            </Requests.Action>
            <Requests.List id="Request_List" loading={String(loading)}>
              {loading ? (
                itemsLoading
              ) : historico.length === 0 ? (
                <NoResult />
              ) : (
                historico.map((obj) => (
                  <Link
                    key={uuid()}
                    to={
                      obj.idTarefa
                        ? `/tarefa/${obj.idTarefa}`
                        : `/requisicao/${obj.numeroRequisicao}`
                    }
                    style={obj.idTarefa ? { backgroundColor: "#f5ffff" } : null}
                    className="no-select requests-listitem"
                  >
                    <Button className="btn-acesso-requisicao ">
                      <Requests.ListItemContent id="Request_ListItemContent">
                        <Requests.ListItemInformations id="Requests_ListItemInformations">
                          <Requests.ListItemInfo id="Request_ListItemInfo">
                            <Requests.ListItemTitle id="Request_ListItemTitle">
                              {capitalize(
                                obj.idTarefa ? obj.assunto : obj.nomeCliente
                              )}
                            </Requests.ListItemTitle>
                            <Requests.Subject id="Requests_Subject">
                              <MdSubject />{" "}
                              {obj.assunto !== ""
                                ? capitalize(obj.assunto)
                                : obj.assunto}
                            </Requests.Subject>
                            <Requests.ListItemDescription id="Request_ListItemDescription">
                              {limitChar(100, obj.previaMensagem)}
                            </Requests.ListItemDescription>
                          </Requests.ListItemInfo>
                          <Requests.ListItemReq id="Request_ListItemReq">
                            <Requests.ListItemNumberRequest id="Request_ListItemNumberRequest">
                              {obj.idTarefa
                                ? obj.idTarefa
                                : obj.numeroRequisicaoFormatada}
                            </Requests.ListItemNumberRequest>
                            <Requests.ListItemData id="Request_ListItemData">
                              {convertISODateToDateView(obj.dataAbertura)}
                            </Requests.ListItemData>
                          </Requests.ListItemReq>
                        </Requests.ListItemInformations>
                        <Requests.ListItemSubInfo id="Request_ListItemSubInfo">
                          <Requests.ListItemStatus id="Request_ListItemStatus">
                            {obj.status}
                          </Requests.ListItemStatus>
                          {obj.idTarefa ? (
                            <>
                              <Tasks.ListItemStatus
                                id="Request_ListItemStatus"
                                task="tipo2"
                              >
                                <RiMapPinUserLine id="RiMapPinUserLine" />
                                <span
                                  style={{
                                    color: !obj.nomeAtendente ? "red" : null,
                                  }}
                                >
                                  {" "}
                                  {capitalize(
                                    obj.nomeAtendente ?? "SEM ATENDENTE"
                                  )}
                                </span>
                              </Tasks.ListItemStatus>
                              <Tasks.ListItemTask id="Request_ListItemTask">
                                <BiVerticalCenter />{" "}
                                {capitalize(obj.prioridade)}
                              </Tasks.ListItemTask>
                              <Tasks.ListItemMessage id="Request_ListItemMessage">
                                <IoGitPullRequestOutline />{" "}
                                {obj.quantidadeRequisicao} solicitações
                              </Tasks.ListItemMessage>
                              <Tasks.ListItemMessage id="Request_ListItemMessage">
                                <BiMessageRounded />{" "}
                                {
                                  obj.quantidadeMensagensAposUltimoAlteracaoStatus
                                }{" "}
                                novas mensagens
                              </Tasks.ListItemMessage>
                            </>
                          ) : (
                            <>
                              {obj.nomeAtendente &&
                              obj.nomeAtendente.length > 0 ? (
                                <Requests.ListItemResponsavel id="Request_ListItemResponsavel">
                                  <RiMapPinUserLine id="RiMapPinUserLine" />{" "}
                                  {obj.nomeAtendente}
                                </Requests.ListItemResponsavel>
                              ) : (
                                <></>
                              )}
                              {obj.quantidadeAnexos &&
                              obj.quantidadeAnexos > 0 ? (
                                <Requests.ListItemAttach id="Request_ListItemAttach">
                                  <GrAttachment /> {obj.quantidadeAnexos}{" "}
                                  arquivos
                                </Requests.ListItemAttach>
                              ) : (
                                <></>
                              )}
                              {obj.quantidadeTarefas &&
                              obj.quantidadeTarefas > 0 ? (
                                <Requests.ListItemTask id="Request_ListItemTask">
                                  <FaTasks /> {obj.quantidadeTarefas} tarefas
                                </Requests.ListItemTask>
                              ) : (
                                <></>
                              )}
                              {obj.quantidadeMensagensAposUltimoAlteracaoStatus &&
                              obj.quantidadeMensagensAposUltimoAlteracaoStatus >
                                0 ? (
                                <Requests.ListItemMessage id="Request_ListItemMessage">
                                  <AiOutlineMessage />{" "}
                                  {
                                    obj.quantidadeMensagensAposUltimoAlteracaoStatus
                                  }{" "}
                                  novas mensagens
                                </Requests.ListItemMessage>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </Requests.ListItemSubInfo>
                      </Requests.ListItemContent>
                    </Button>
                  </Link>
                ))
              )}
            </Requests.List>
          </>
        }
      </Requests>
    </Container>
  );
}
