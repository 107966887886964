import styled from "styled-components";

export const SetorVinculo = styled.div`
  div#Request_SendMessageContentDialog {
    border: 1px solid #c4c4c4;
  }

  .rdw-editor-toolbar {
    border-bottom: 1px solid #c4c4c4 !important;
  }
`;

SetorVinculo.Form = styled.div``;

SetorVinculo.Actions = styled.div``;

SetorVinculo.Attachments = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

SetorVinculo.AttachmentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 15px 0;
`;

SetorVinculo.AttachmentContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-height: 121px;
  overflow: auto;

  div#Request_AttachmentItemPdf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--red);
    background: var(--white);
    color: var(--primary);
    margin: 0 10px 15px 0;
    border-radius: 100px;
    padding: 5px 10px;
    transition: all ease 0.35s;
    cursor: pointer;

    div#Request_AttachmentItemImage svg {
      color: var(--red);
    }

    &:hover {
      background: var(--red);
      transition: all ease 0.35s;

      div#Request_AttachmentItemImage svg {
        color: var(--white);
      }

      div#Request_AttachmentItemText {
        color: var(--white);
      }

      button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Request_AttachmentItemClose
        svg {
        color: var(--white);
      }
    }
  }

  div#Request_AttachmentItemImg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--yellow);
    background: var(--white);
    color: var(--yellow);
    margin: 0 10px 15px 0;
    border-radius: 100px;
    padding: 5px 10px;
    transition: all ease 0.35s;
    cursor: pointer;

    &:hover {
      background: var(--yellow);
      transition: all ease 0.35s;

      div#Request_AttachmentItemImage svg {
        color: var(--white);
      }

      div#Request_AttachmentItemText {
        color: var(--white);
      }

      button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Request_AttachmentItemClose
        svg {
        color: var(--white);
      }
    }
  }
`;

SetorVinculo.AttachmentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary);
  background: var(--white);
  color: var(--primary);
  margin: 0 15px 15px 0;
  border-radius: 100px;
  padding: 5px 10px;

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Request_AttachmentItemClose {
    padding: 0;
    min-width: 0;
    border-radius: 100%;
    font-size: 18px;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Request_AttachmentItemClose
    svg {
    color: var(--lightgrey);
  }
`;

SetorVinculo.AttachmentItemImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

SetorVinculo.AttachmentItemText = styled.div`
  margin: 0 10px;
  color: var(--grey);
  font-size: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

SetorVinculo.container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 20px;
`;

SetorVinculo.TitleText = styled.p`
  margin: 0;
  padding: 15px 15px 0px 0px;
  font-weight: 600;
  color: var(--alternative3);
`;
SetorVinculo.grid = styled.div`
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  height: 410px;
`;
