import styled from 'styled-components'

export const Filter = styled.div`
    background-color: var(--background-body);
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnCloseOrdenacao {
        min-width: 0px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            font-size: 21px;
            position: relative;
            top: 0px;
        }
    }
`

Filter.Top = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

Filter.TopContent = styled.div`
    background: var(--white);
    margin: 0 0 25px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 4%), 0 3px 1px -2px rgb(0 0 0 / 2%), 0 1px 5px 0 rgb(0 0 0 / -4%);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnCloseFiltersDrawer {
        min-width: 0px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 20px;

        svg {
            font-size: 21px;
            position: relative;
            top: 0px;
        }
    }
`

Filter.TopInfo = styled.div`
    margin: 20px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 100px);

    svg {
        margin: 0 10px 0 0;
    }

    
`

Filter.Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 25px;
`

Filter.ContentItem = styled.div`
    background: var(--white);
    width: calc(100% - 40px);
    border-radius: 10px;
    margin-bottom: 25px;

    label.MuiFormControlLabel-root {
        width: 100%;
    }
`

Filter.ItemFilter = styled.div`
    padding: 20px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 4%), 0 3px 1px -2px rgb(0 0 0 / 2%), 0 1px 5px 0 rgb(0 0 0 / 0%);
`

Filter.ItemFilterTitle = styled.p`
    margin: 0 0 20px 0;
    font-weight: 600;
    color: var(--primary);
` 

Filter.DateFilterWithSelect = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
`

Filter.DateFilterWithSelectItem = styled.div`
    width: 100%;

    .MuiFormControl-root {
        min-width: 0;
        width: 100%;
        margin: 0;
    }
`

Filter.Bottom = styled.div`
    width: 100%;
    background: var(--white);
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 4%), 0 3px 1px -2px rgb(0 0 0 / 2%), 0 1px 5px 0 rgb(0 0 0 / 0%);
    position: absolute;
    width: 100%;
    z-index: 2;
    bottom: 0;
`

Filter.BottomContent = styled.div`
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &> button {
        width: 50%;
    }

    .btn-primary-actions {
        color: var(--white) !important;
    }
`