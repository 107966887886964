import Container from "../../components/layout/Container";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grow from "@mui/material/Grow";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import { Card } from "../../components/elements-ui/Card";
import { Badge } from "../../components/elements-ui/Badge";
import { ActionTable } from "../../components/elements-ui/ActionTable";
import { Button } from "@material-ui/core";
import {
  AiOutlineEye,
  AiOutlineEdit,
  BsFillStarFill,
  BsFillClockFill,
  FaUserAlt,
} from "react-icons/all";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { Requests } from "../../pages/Requisicoes/styles";
import api from "../../services/api";
import Skeleton from "react-loading-skeleton";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Chip from "@mui/material/Chip";
import _ from "lodash";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { TransitionGroup } from "react-transition-group";
import Avatar from "@material-ui/core/Avatar";
import DialogSetorVinculo from "../../components/elements-ui/DialogSetorVinculo";
import { useDebounce } from "../../functions";

const PrimaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--primary)",
    color: "var(--white)",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: "#bdbdbd",
    fontWeight: "900",
    display: "inline-flex",
    alignItems: "center",
    padding: "0 15px",
    height: "32px",
    overflow: "hidden",
    fontSize: "0.8125rem",
    borderRadius: "16px",
    backgroundColor: "#f1f1f1",
    border: "1px solid #bdbdbd",
    "& .Abreviacao": {
      maxWidth: "100%",
      maxHeight: "100%",
      overflow: "hidden",
    },
    "& .Esconder": {
      maxWidth: "0",
      opacity: 0,
      maxHeight: "0",
      overflow: "hidden",
    },
    "& .MostrarNome": {
      opacity: 1,
      maxWidth: "100%",
      maxHeight: "100%",
      transition: "opacity 1s,max-height 0.75s ease-in-out",
      overflow: "hidden",
    },
  },
  card: {
    "&:hover": {
      transform: "scale(1.019)",
      transition: "all ease .35s",
      borderRadius: "8px",
    },
    cursor: "pointer",
  },
}));

const NomesBalao = ({ names }) => {
  const classes = useStyles();
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleNameClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    if (index === expandedIndex) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div>
      {names.map((supervisor, index) => (
        <div
          key={supervisor.id}
          className={classes.chip}
          onClick={(e) => handleNameClick(e, index)}
        >
          {
            <>
              <span
                className={expandedIndex === index ? "MostrarNome" : "Esconder"}
              >
                {supervisor.descricao}
              </span>

              <span
                className={expandedIndex === index ? "Esconder" : "MostrarNome"}
              >
                {supervisor.descricao.trim().split(" ")[0]}
              </span>
            </>
          }
        </div>
      ))}
    </div>
  );
};

export default function Setores() {
  const classes = useStyles();
  const [setores, setSetores] = useState([]);
  const [setoresPesquisa, setSetoresPesquisa] = useState([]);
  const [openSetorVinculo, setOpenSetorVinculo] = useState(false);
  const [loading, setLoading] = useState(true);
  const [setorSearch, setSetorSearch] = useState("");
  const searchQuery = useDebounce(setorSearch, 500);
  const { enqueueSnackbar } = useSnackbar();

  const stringAvatar = (name) => {
    return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`.toUpperCase();
  };

  const carregarSetores = () => {
    setLoading(true);
    api
      .get("/jucerja/v1/Setor/gerenciamento?FirstResult=1&MaxResults=99999999")
      .then((response) => {
        const setores = response.data?.setoresGerenciamento;

        setSetores(setores ?? []);
        setSetoresPesquisa(setores.filter((setor) => setor.ativo));
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    carregarSetores();
  }, []);

  useEffect(() => {
    if (openSetorVinculo) return;

    carregarSetores();
  }, [openSetorVinculo]);

  useEffect(() => {
    if (searchQuery.trim().length <= 0) {
      setSetoresPesquisa(setores.filter((setor) => setor.ativo));
      return;
    }
    let textosLowers = searchQuery.split(" ").map((val) => val.toLowerCase());
    let setoresFilter = setores;

    textosLowers.forEach((texto) => {
      setoresFilter = setoresFilter.filter(
        (setor) =>
          `${setor.id}`.toLowerCase().indexOf(texto) > -1 ||
          `${setor.setor}`.toLowerCase().indexOf(texto) > -1 ||
          setor.supervisores.find(
            (sup) => sup.descricao.toLowerCase().indexOf(texto) > -1
          ) != undefined ||
          setor.assuntos.find(
            (ass) => ass.descricao.toLowerCase().indexOf(texto) > -1
          ) != undefined
      );
    });

    setSetoresPesquisa(setoresFilter);
  }, [searchQuery]);

  return (
    <>
      <DialogSetorVinculo
        openDialogSetorVinculo={openSetorVinculo}
        setOpenDialogSetorVinculo={setOpenSetorVinculo}
        setoresInativos={setores.filter((setor) => !setor.ativo)}
      />

      <div
        style={{
          width: "100%",
          position: "fixed",
          zIndex: "100",
        }}
      >
        <Requests.BarraSlastic>
          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> */}

          <Requests.AttachmentListAddAndMessageBox>
            <TextField
              style={{ zIndex: 100 }}
              id="txt-send-message-request"
              variant="outlined"
              onKeyUp={(e) => {
                setSetorSearch(e.target.value);
              }}
              placeholder="Pesquisar Setores"
              disabled={loading}
            />
          </Requests.AttachmentListAddAndMessageBox>

          {/* </Grid> */}
        </Requests.BarraSlastic>
      </div>

      <Container title="Setores">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          style={{ marginBottom: "30px" }}
        >
          {loading ? (
            <Skeleton width={135} height={36} />
          ) : (
            <Button
              className="btn-backround-primary-actions"
              style={{ background: "var(--primary)!important" }}
              onClick={() => setOpenSetorVinculo(true)}
            >
              Vincular Setor
            </Button>
          )}
        </Grid>
        <Grid container spacing={3} style={{ marginBottom: "40px" }}>
          {loading
            ? [1, 2, 3, 4, 5, 6].map((x) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.card}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                      bgcolor: "background.paper",
                    }}
                  >
                    <Box sx={{ py: 3, px: 2, pt: 3, pl: 3, pr: 3 }}>
                      <Grid
                        style={{ minHeight: "4.5rem" }}
                        container
                        alignItems="center"
                      >
                        <Grid item xs>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            color="black"
                          >
                            {/* <Skeleton width={200} height={30} /> */}
                            <Skeleton />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box sx={{ mb: 1 }}>
                        <Typography color="text.secondary" variant="body2">
                          <Skeleton width={100} />
                        </Typography>
                      </Box>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                          <Skeleton width={150} />
                        </Grid>
                      </Grid>
                      <Box sx={{ my: 3, pb: 5 }}>
                        <Typography gutterBottom variant="body1" color="black">
                          <Skeleton width={100} />
                        </Typography>
                        <Grid>
                          <Typography
                            gutterBottom
                            variant="body1"
                            color="black"
                          >
                            <Skeleton />
                          </Typography>
                        </Grid>
                      </Box>
                    </Box>
                    <Divider variant="fullWidth" />
                    <Box sx={{ mt: 3, mx: 2, pl: 3, pr: 3 }}>
                      <Typography gutterBottom variant="body1" color="black">
                        <Skeleton width={150} />
                      </Typography>
                      <Grid>
                        <Typography gutterBottom variant="body1" color="black">
                          <Skeleton />
                        </Typography>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              ))
            : setoresPesquisa.map((setor) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.card}
                >
                  <TransitionGroup style={{ height: "100%" }}>
                    <Grow
                      key={setor.id}
                      timeout={1000}
                      style={{ height: "100%" }}
                    >
                      <Box key={setor.id}>
                        <Link to={`/Setor/${setor.id}`}>
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                              bgcolor: "background.paper",
                              paddingTop: "10px",
                            }}
                          >
                            <Box sx={{ py: 3, px: 2, pt: 3, pl: 3, pr: 3 }}>
                              <Grid container alignItems="center">
                                <Grid item xs>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    color="black"
                                    display="grid"
                                  >
                                    <b
                                      style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        height: "63px",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                      }}
                                    >
                                      {setor.setor}
                                    </b>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    color={setor.ativo ? "success" : "error"}
                                    label={setor.ativo ? "Ativo" : "Inativo"}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                  <BsFillStarFill
                                    style={{
                                      marginTop: "3px",
                                      color: "#ffd233",
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {" "}
                                    {setor.mediaSetor}
                                  </Typography>
                                </Grid>

                                <Grid item>
                                  <BsFillClockFill
                                    style={{
                                      marginTop: "3px",
                                      color: "#fd4444",
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {" "}
                                    {setor.quantidadeReqAtrasadas}
                                  </Typography>
                                </Grid>

                                <Grid item>
                                  <FaUserAlt
                                    style={{
                                      marginTop: "3px",
                                      color: "#4354a6",
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {" "}
                                    {setor.quantidadeFuncionario}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Box sx={{ my: 3, pb: 5 }}>
                                <Typography
                                  gutterBottom
                                  variant="body1"
                                  color="black"
                                >
                                  <b>Assuntos</b>
                                </Typography>
                                <Grid
                                  xs={12}
                                  style={{
                                    marginLeft: "-4px",
                                    minHeight: "5rem",
                                  }}
                                >
                                  {setor.assuntos.map((ass) => (
                                    <Chip
                                      style={{
                                        marginTop: "4px",
                                        marginLeft: "4px",
                                      }}
                                      size="small"
                                      variant="outlined"
                                      label={ass.descricao}
                                    />
                                  ))}
                                </Grid>
                              </Box>
                            </Box>
                            <Divider variant="fullWidth" />
                            <Grid xs={12}>
                              <Box sx={{ mt: 3, mx: 2, pl: 3, pr: 3 }}>
                                <Typography
                                  gutterBottom
                                  variant="body1"
                                  color="black"
                                >
                                  <b>Supervisores</b>
                                </Typography>
                                <Grid
                                  xs={12}
                                  style={{
                                    marginLeft: "-4px",
                                    minHeight: "4rem",
                                  }}
                                >
                                  <NomesBalao names={setor.supervisores} />
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>
                        </Link>
                      </Box>
                    </Grow>
                  </TransitionGroup>
                </Grid>
              ))}
        </Grid>
      </Container>
    </>
  );
}
