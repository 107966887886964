import { useState, forwardRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Slide from "@material-ui/core/Slide";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import Grow from "@mui/material/Grow";
import Divider from "@mui/material/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import Checkbox from "@material-ui/core/Checkbox";
import { ActionTable } from "../ActionTable";
import Select from "@material-ui/core/Select";
import { Card } from "../Card";

import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Badge } from "../Badge";
import api from "../../../services/api";
import Grid from "@material-ui/core/Grid";
import { SetorVinculo } from "./styles";
import {
  GrAttachment,
  VscFilePdf,
  RiCloseCircleFill,
  BsFillCheckCircleFill,
  BsFillPlusCircleFill,
  RxLinkNone2,
  HiOutlinePlusSmall,
  BiLinkExternal,
  BsListTask,
} from "react-icons/all";
import {
  convertISODateToDateView,
  formataCPF,
  processarCatchComMensagensDeValidacao,
} from "../../../functions";
import { v4 as uuid } from "uuid";

import { Request } from "../DialogSendMessageEditor/styles";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useSnackbar } from "notistack";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { object } from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Draggable from "react-draggable";
import DialogAssuntoSetorConfirm from "../DialogAssuntoSetorConfirm";
import DialogAssuntoAssociacaoConfirm from "../DialogAssuntoAssociacaoConfirm";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PrimaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--primary)",
    color: "var(--white)",
  },
}))(Tooltip);

export default function DialogSetorVinculo({
  setOpenDialogSetorVinculo,
  openDialogSetorVinculo,
  setoresInativos,
  funcaoCallBack,
}) {
  const [setoresSearch, setSetoresSearch] = useState([]);
  const [setoresTxt, setSetoresTxt] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rowCount, setRowCount] = useState(undefined);
  const [idAssuntoEdicao, setIdAssuntoEdicao] = useState(undefined);
  const [setorId, setSetorId] = useState(undefined);
  const [openDialogAssuntoSetorConfirm, setOpenDialogAssuntoSetorConfirm] =
    useState(false);
  const [assuntoSetorConfirm, setAssuntoSetorConfirm] = useState(undefined);
  const [openDialogAssuntoAssociacao, setOpenDialogAssuntoAssociacao] =
    useState(false);
  const [assuntoAssociacaoConfirmation, setAssuntoAssociacaoConfirmation] =
    useState(undefined);

  const handleClose = (e) => {
    e?.preventDefault();
    setOpenDialogSetorVinculo(false);
  };

  useEffect(() => {
    let timer1 = setTimeout(() => {
      let textosLowers = setoresTxt.split(" ").map((val) => val.toLowerCase());
      let setoresFilter = setoresInativos;

      textosLowers.forEach((texto) => {
        setoresFilter = setoresInativos.filter(
          (setor) => `${setor.setor}`.toLowerCase().indexOf(texto) > -1
        );
      });

      setSetoresSearch(setoresFilter);
    }, 500);

    return () => {
      clearTimeout(timer1);
    };
  }, [setoresTxt]);

  useEffect(() => {
    if (!openDialogSetorVinculo) return;

    setSetoresSearch(setoresInativos);
  }, [openDialogSetorVinculo]);

  useEffect(() => {
    if (!assuntoSetorConfirm) return;

    const assuntoObj = {
      ...assuntoSetorConfirm,
      funcionariosId: assuntoSetorConfirm?.funcionarios?.map((fun) => fun.id),
      descricao: assuntoSetorConfirm.assuntoDescricao,
      mensagemId: assuntoSetorConfirm.idMensagemAutomatica,
      mensagemAutomatica: assuntoSetorConfirm.mensagemAutomatica,
      setorId: setorId,
      Setores: [setorId],
      habilitar: true,
    };

    api
      .post(`/jucerja/v1/Assuntos/novos-assunto`, assuntoObj)
      .then((response) => {
        enqueueSnackbar("Assunto foi cadastrado com sucesso.", {
          variant: "success",
        });

        setOpenDialogAssuntoSetorConfirm(false);
      })
      .catch((error) =>
        processarCatchComMensagensDeValidacao(enqueueSnackbar, error)
      )
      .finally(() => {
        setAssuntoSetorConfirm(undefined);
      });
  }, [assuntoSetorConfirm]);

  useEffect(() => {
    if (!assuntoAssociacaoConfirmation) return;

    let assuntoAssociacao = {
      AssuntoId: assuntoAssociacaoConfirmation.id,
      SetorId: setorId,
    };

    api
      .put(`/jucerja/v1/Assuntos/associar-assunto-setor`, assuntoAssociacao)
      .then((response) => {
        enqueueSnackbar("Assunto foi associado com sucesso.", {
          variant: "success",
        });
      })
      .catch((e) => {
        enqueueSnackbar("Houve um erro ao associar o assunto.", {
          variant: "error",
        });
      })
      .finally(() => {
        setOpenDialogAssuntoAssociacao(false);
        setAssuntoAssociacaoConfirmation(undefined);
      });
  }, [assuntoAssociacaoConfirmation]);

  return (
    <Draggable
      handle={'[class*="MuiDialog-root"]'}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Dialog
        hideBackdrop
        disableBackdropClick
        open={openDialogSetorVinculo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="dialog-associated-tasks-request-slide-title"
        aria-describedby="dialog-associated-tasks-request-dialog-slide-description"
        fullWidth={"md"}
        id="dialog-create-funcionario"
      >
        <DialogAssuntoSetorConfirm
          openDialogAssuntoSetorConfirm={openDialogAssuntoSetorConfirm}
          setOpenDialogAssuntoSetorConfirm={setOpenDialogAssuntoSetorConfirm}
          setAssuntoSetorConfirm={setAssuntoSetorConfirm}
          assuntoSetorConfirm={assuntoSetorConfirm}
          idAssunto={idAssuntoEdicao}
          setIdAssunto={setIdAssuntoEdicao}
          idSetor={setorId}
        />
        <DialogAssuntoAssociacaoConfirm
          openDialogAssuntoAssociacao={openDialogAssuntoAssociacao}
          setOpenDialogAssuntoAssociacao={setOpenDialogAssuntoAssociacao}
          setAssuntoAssociacaoConfirmation={setAssuntoAssociacaoConfirmation}
        />
        <DialogTitle style={{ cursor: "move" }} id="alert-dialog-slide-title">
          Vincular Setor
        </DialogTitle>
        <DialogContent>
          <TransitionGroup>
            <Grow id="growFuncionarioListagem" timeout={1000}>
              <Grid xs={12}>
                <Grid item xs={12}>
                  <FormControl variant="outlined">
                    <TextField
                      inputProps={{ maxLength: 100 }}
                      id="outlined-basic-ticket"
                      label="Nome Setor"
                      variant="outlined"
                      name="nomeFuncionario"
                      value={setoresTxt}
                      onChange={(e) => setSetoresTxt(e.target.value)}
                      disabled={loading}
                    />
                  </FormControl>
                </Grid>
                <SetorVinculo.grid>
                  <DataGrid
                    getRowId={(r) => r.id}
                    style={{ height: "100%" }}
                    pageSize={10}
                    disableSelectionOnClick={true}
                    disableColumnFilter
                    disableColumnMenu
                    columns={[
                      {
                        field: "setor",
                        headerName: "Setor",
                        width: 170,
                        flex: 1,
                        editable: false,
                        disableReorder: false,
                        filterable: false,
                        sortable: false,
                        renderCell: (param) => (
                          <Tooltip title={param.value} placement="top">
                            <Link to={`/Setor/${param.row.id}`}>
                              <span>
                                <span
                                  className="adicionar"
                                  style={{ marginRight: "10px" }}
                                >
                                  <BiLinkExternal />
                                </span>
                                <span>{param.value}</span>
                              </span>
                            </Link>
                          </Tooltip>
                        ),
                      },
                      {
                        field: "acaoVincular",
                        headerName: "Ações",
                        headerAlign: "center",
                        width: 150,
                        align: "center",
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <ActionTable>
                              <Tooltip
                                title={"Vincular Assunto"}
                                placement="top"
                              >
                                <Button
                                  className="btn-open"
                                  onClick={() => {
                                    setSetorId(params.row.id);
                                    setOpenDialogAssuntoAssociacao(true);
                                  }}
                                >
                                  <RxLinkNone2 />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                title={"Adicionar Assunto"}
                                placement="top"
                              >
                                <Button
                                  className="btn-open"
                                  onClick={() => {
                                    setSetorId(params.row.id);
                                    setOpenDialogAssuntoSetorConfirm(true);
                                  }}
                                >
                                  <HiOutlinePlusSmall />
                                </Button>
                              </Tooltip>
                            </ActionTable>
                          );
                        },
                      },
                    ]}
                    rows={setoresSearch}
                    hideFooterPagination
                    density={"small"}
                    localeText={ptBR.props.MuiDataGrid.localeText}
                  />
                </SetorVinculo.grid>
              </Grid>
            </Grow>
          </TransitionGroup>
        </DialogContent>
        <DialogActions>
          {loadingSubmit ? (
            <></>
          ) : (
            <Button
              onClick={handleClose}
              disabled={loadingSubmit}
              className="btn-close-actions"
            >
              Cancelar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Draggable>
  );
}
