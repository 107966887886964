import { useState, useEffect } from "react";
import Container from "../../../components/layout/Container";

import { Badge } from "../../../components/elements-ui/Badge";
import { useSnackbar } from "notistack";

import { ComponenteAssunto } from "./styles";
import {
  BsInfoCircleFill,
  FaUser,
  FaUserClock,
  FaWindowRestore,
  FaUserCheck,
  FaClock,
  FaUserTimes,
  BsFillClipboard2CheckFill,
  BsFillCheckCircleFill,
  BiLinkExternal,
  FaUserLock,
  RxLinkBreak2,
  RxLinkNone2,
  BsListTask,
  FaUserTie,
  FaHouseUser,
  AiOutlineEdit,
} from "react-icons/all";
import CountUp from "react-countup";

import { Button, Grid, Slide, withStyles, makeStyles } from "@material-ui/core";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import Tooltip from "@material-ui/core/Tooltip";
import { Card } from "../../../components/elements-ui/Card";
import DialogDefaultTextConfirm from "../../../components/elements-ui/DialogDefaultTextConfirm";
import DialogConfirmation from "../../../components/elements-ui/DialogConfirmation";
import DialogAssuntoSetorConfirm from "../../../components/elements-ui/DialogAssuntoSetorConfirm";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import _ from "lodash";
import api from "../../../services/api";
import { Kpi } from "../../../components/elements-ui/KPI";
import { ActionTable } from "../../../components/elements-ui/ActionTable";

import { Link } from "react-router-dom";

import { BiArrowBack } from "react-icons/all";
import DialogSetorFuncionarioConfirm from "../../../components/elements-ui/DialogSetorFuncionarioConfirm";

const PrimaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--primary)",
    color: "var(--white)",
  },
}))(Tooltip);

const PrimaryTooltipCards = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--white)",
    color: "var(--black)",
  },
}))(Tooltip);

const PrimaryTooltipVermelho = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--red)",
    color: "var(--white)",
  },
}))(Tooltip);

const PrimaryTooltipAmarelo = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#e5a900",
    color: "var(--white)",
  },
}))(Tooltip);

const PrimaryTooltipFerias = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#1c8bc9",
    color: "var(--white)",
  },
}))(Tooltip);

const PrimaryTooltipAzul = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#1c8ccb",
    color: "var(--white)",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  solicitacoes: {
    color: "var(--dark-yellow)",
  },
  solicitacoesAguardandoSolicitante: {
    color: "var(--dark-orange)",
  },
  solicitacoesEmAndamento: {
    color: "var(--dark-blue)",
  },
  solicitacoesAguardandoTarefas: {
    color: "var(--dark-gree)",
  },
  solicitacoesVerificacaoInterna: {
    color: "var(--red)",
  },
  solicitacoesAtrasadas: {
    color: "var(--grey)",
  },
}));

const useAdicionar = makeStyles((theme) => ({
  span: {
    margin: "2px 2px 2px 5px;",
    fontWeight: "400",
    backgroundColor: "#4354a6",
    borderRadius: "1000px",
    color: "var(--white)",
    padding: "0px 7px 0 7px",
    fontSize: "1.2rem",
    userSelect: "none",
    cursor: "pointer",
    "& .adicionar": {
      display: "inline-block",
      verticalAlign: "sub",
      maxWidth: "0",
      visibility: "hidden",
      opacity: "0",
      transition: "max-width 0.2s, opacity 0.2s",
    },
    "&:hover": {
      "& .adicionar": {
        verticalAlign: "sub",
        visibility: "visible",
        maxWidth: "200px",
        opacity: "1",
        transition: "max-width 0.5s, opacity 0.2s",
      },
    },
  },
  adicionar: {
    "& .adicionar": {
      display: "inline-block",
      verticalAlign: "sub",
      maxWidth: "0",
      visibility: "hidden",
      opacity: "0",
      transition: "max-width 0.2s, opacity 0.2s",
    },
    "&:hover": {
      "& .adicionar": {
        verticalAlign: "sub",
        visibility: "visible",
        maxWidth: "200px",
        opacity: "1",
        transition: "max-width 0.5s, opacity 0.2s",
      },
    },
  },
}));

const filtroTemplate = {
  filtros: [],
  firstResult: 0,
  maxResults: 0,
};

export default function Assunto(props) {
  const assuntoId = props.match.params.id;
  const setorId = props.match.params.id;
  const classesIcons = useStyles();
  const classesAdicionar = useAdicionar();
  const { enqueueSnackbar } = useSnackbar();

  const [assunto, setAssunto] = useState({});

  const [funcionarios, setFuncionarios] = useState([]);
  const [loadingFuncionario, setLoadingFuncionario] = useState(false);
  const [assuntos, setAssuntos] = useState([]);
  const [idAssuntoTextoPadrao, setIdAssuntoTextoPadrao] = useState(undefined);
  const [idAssuntoEdicao, setIdAssuntoEdicao] = useState(undefined);

  const [openDialogDefaultText, setOpenDialogDefaultText] = useState(false);

  const [openDialogAssuntoSetorConfirm, setOpenDialogAssuntoSetorConfirm] =
    useState(false);
  const [assuntoSetorConfirm, setAssuntoSetorConfirm] = useState(undefined);

  const [assuntoParaExclusao, setAssuntoParaExclusao] = useState(undefined);
  const [openAssuntoParaExclusao, setOpenAssuntoParaExclusao] = useState(false);
  const [confirmAssuntoParaExclusao, setConfirmAssuntoParaExclusao] =
    useState(false);

  const [supervisaoParaExclusao, setSupervisaoParaExclusao] =
    useState(undefined);
  const [openSupervisaoExclusao, setOpenSupervisaoExclusao] = useState(false);

  const [solicitacoesAbertas, setSolicitacoesAbertas] = useState();
  const [
    solicitacoesAguardandoSolicitante,
    setSolicitacoesAguardandoSolicitante,
  ] = useState();
  const [solicitacoesEmAndamento, setSolicitacoesEmAndamento] = useState();
  const [solicitacoesVerificacaoInterna, setSolicitacoesVerificacaoInterna] =
    useState();
  const [solicitacoesAguardandoTarefas, setSolicitacoesAguardandoTarefas] =
    useState();
  const [solicitacoesFinalizadasMes, setSolicitacoesFinalizadasMes] =
    useState();

  const [assuntoFuncionarioConfirm, setAssuntoFuncionarioConfirm] =
    useState(undefined);

  const [funcionarioParaExclusao, setFuncionarioParaExclusao] =
    useState(undefined);
  const [openFuncionarioParaExclusao, setOpenFuncionarioParaExclusao] =
    useState(false);
  const [confirmFuncionarioExclusao, setConfirmFuncionarioExclusao] =
    useState(false);
  const [openDialogSetorFuncionario, setOpenDialogSetorFuncionario] =
    useState(false);

  const payloadQuantAberto = {
    filtros: [
      { Coluna: [1], Comparador: 0, Valores: [`${assuntoId}`] },
      { Coluna: [2], Comparador: 0, Valores: ["1"] },
    ],
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };
  const payloadAguarSolicitante = {
    filtros: [
      { Coluna: [1], Comparador: 0, Valores: [`${assuntoId}`] },
      { Coluna: [2], Comparador: 0, Valores: ["4"] },
    ],
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };
  const payloadAndamento = {
    filtros: [
      { Coluna: [1], Comparador: 0, Valores: [`${assuntoId}`] },
      { Coluna: [2], Comparador: 0, Valores: ["2"] },
    ],
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };
  const payloadAguarTarefas = {
    filtros: [{ Coluna: [1], Comparador: 0, Valores: [`${assuntoId}`] }],
    Opcao: 7,
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };
  const payloadVerificacaoIntern = {
    filtros: [
      { Coluna: [1], Comparador: 0, Valores: [`${assuntoId}`] },
      { Coluna: [2], Comparador: 0, Valores: ["6"] },
    ],
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };
  const payloadFinalizadasMes = {
    filtros: [{ Coluna: [1], Comparador: 0, Valores: [`${assuntoId}`] }],
    Opcao: 6,
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };

  const obterFuncionariosAssunto = async () => {
    const opcoesFiltro = {
      filtros: [
        {
          coluna: [1],
          comparador: 0,
          agruparCondicional: false,
          valores: [`${assuntoId}`],
        },
      ],
      opcao: 1,
      aplicarUnionCondicional: true,
      utilizarCache: true,
      firstResult: 1,
      maxResults: 999999,
    };

    setLoadingFuncionario(true);

    await api
      .post(`/jucerja/v1/funcionario/filtro`, opcoesFiltro)
      .then((response) => setFuncionarios(response.data.funcionarios))
      .finally(() => setLoadingFuncionario(false));
  };

  const desvincularFuncionarioAssunto = () => {
    let idUsuarioAssunto = funcionarioParaExclusao.informacoes.assuntos.find(
      (ass) => ass.id == assuntoId
    ).assuntoUsuarioId;
    api
      .delete(`/jucerja/v1/funcionario/funcionarioassunto/${idUsuarioAssunto}`)
      .then((response) => {
        setFuncionarios(
          funcionarios.filter((fun) => fun.id != funcionarioParaExclusao.id)
        );

        enqueueSnackbar("Desvínculo realizado com sucesso.", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Houve um erro ao desvincular o funcionário.", {
          variant: "error",
        });
      })
      .finally(() => {
        setOpenFuncionarioParaExclusao(false);
        setFuncionarioParaExclusao(undefined);
      });
  };

  const funcionarioAssuntoVinculo = () => {
    api
      .post(`/jucerja/v1/funcionario/funcionarioassunto`, {
        funcionarioId: assuntoFuncionarioConfirm.id,
        Assuntos: [assuntoId],
      })
      .then((response) => {
        obterFuncionariosAssunto();

        enqueueSnackbar("Vínculo realizado com sucesso.", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Houve um erro ao vincular o funcionário.", {
          variant: "error",
        });
      })
      .finally(() => {
        setOpenDialogSetorFuncionario(false);
        setAssuntoFuncionarioConfirm(undefined);
      });
  };

  useEffect(() => {
    api.get(`/jucerja/v1/Assuntos/${assuntoId}`).then((assResponse) => {
      let assunto = assResponse.data;

      if (assunto.setorId) {
        api
          .get(`/jucerja/v1/setor/setores-configuracao/?id=${assunto.setorId}`)
          .then((setorResponse) => {
            setAssunto({
              ...assunto,
              setorDescricao: setorResponse.data[0].setor,
            });
          });
      } else {
        setAssunto(assunto);
      }
    });
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadQuantAberto)
      .then((response) => setSolicitacoesAbertas(response.data.totalRecords));
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadQuantAberto)
      .then((response) => setSolicitacoesAbertas(response.data.totalRecords));
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadAguarSolicitante)
      .then((response) =>
        setSolicitacoesAguardandoSolicitante(response.data.totalRecords)
      );
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadAndamento)
      .then((response) =>
        setSolicitacoesEmAndamento(response.data.totalRecords)
      );
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadAguarTarefas)
      .then((response) =>
        setSolicitacoesAguardandoTarefas(response.data.totalRecords)
      );
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadVerificacaoIntern)
      .then((response) =>
        setSolicitacoesVerificacaoInterna(response.data.totalRecords)
      );
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadFinalizadasMes)
      .then((response) =>
        setSolicitacoesFinalizadasMes(response.data.totalRecords)
      );

    obterFuncionariosAssunto();
  }, []);

  useEffect(() => {
    const idAssunto = assuntoParaExclusao?.assuntoId;
    if (!confirmAssuntoParaExclusao) return;

    setConfirmAssuntoParaExclusao(false);

    api
      .delete(`/jucerja/v1/Assuntos/remover-assunto?assuntoId=${idAssunto}`)
      .then((response) => {
        setAssuntos(assuntos.filter((x) => x.assuntoId != idAssunto));
        enqueueSnackbar("Assunto foi removido com sucesso.", {
          variant: "success",
        });
      })
      .catch((e) =>
        enqueueSnackbar("Houve um erro ao excluir o assunto.", {
          variant: "error",
        })
      )
      .finally(() => {
        setOpenAssuntoParaExclusao(false);
      });
  }, [confirmAssuntoParaExclusao]);

  useEffect(() => {
    if (!openAssuntoParaExclusao) setAssuntoParaExclusao(undefined);
  }, [openAssuntoParaExclusao]);

  useEffect(() => {
    if (idAssuntoTextoPadrao) setOpenDialogDefaultText(true);

    setIdAssuntoTextoPadrao(undefined);
  }, [idAssuntoTextoPadrao]);

  useEffect(() => {
    if (!!idAssuntoEdicao) setOpenDialogAssuntoSetorConfirm(true);
    setIdAssuntoEdicao(undefined);
  }, [idAssuntoEdicao]);

  useEffect(() => {
    if (assuntoParaExclusao) setOpenAssuntoParaExclusao(true);
  }, [assuntoParaExclusao]);

  useEffect(() => {
    if (supervisaoParaExclusao) setOpenSupervisaoExclusao(true);
  }, [supervisaoParaExclusao]);

  useEffect(() => {
    if (!openSupervisaoExclusao) setSupervisaoParaExclusao(undefined);
  }, [openSupervisaoExclusao]);

  useEffect(() => {
    if (!confirmFuncionarioExclusao) return;

    desvincularFuncionarioAssunto();
  }, [confirmFuncionarioExclusao]);

  useEffect(() => {
    if (!assuntoSetorConfirm) return;

    const assuntoObj = {
      ...assuntoSetorConfirm,
      funcionariosId: assuntoSetorConfirm?.funcionarios?.map((fun) => fun.id),
      descricao: assuntoSetorConfirm.assuntoDescricao,
      mensagemId: assuntoSetorConfirm.idMensagemAutomatica,
      mensagemAutomatica: assuntoSetorConfirm.mensagemAutomatica,
      setorId:
        assuntoSetorConfirm.setorId == 0 ? null : assuntoSetorConfirm.setorId,
      habilitar: true,
    };

    api
      .put(`/jucerja/v1/Assuntos/alterar-assunto`, assuntoObj)
      .then((response) => {
        obterFuncionariosAssunto();
        enqueueSnackbar("Assunto foi atualizado com sucesso.", {
          variant: "success",
        });

        setAssunto((ass) => ({
          ...ass,
          setorDescricao: assuntoObj.setorDescricao ?? null,
          setorId: assuntoObj.setorId,
        }));
      })
      .catch((e) => {
        setAssuntoSetorConfirm(undefined);
        e?.response?.data?.Messages?.forEach((me) =>
          me.FieldMessages?.forEach((fM) =>
            enqueueSnackbar(fM.Mensagem, { variant: "error" })
          )
        );
      })
      .finally(() => {
        setOpenDialogAssuntoSetorConfirm(false);
        setAssuntoSetorConfirm(undefined);
      });
  }, [assuntoSetorConfirm]);

  useEffect(() => {
    if (!assuntoFuncionarioConfirm) return;

    funcionarioAssuntoVinculo();
  }, [assuntoFuncionarioConfirm]);

  return (
    <ComponenteAssunto>
      <DialogDefaultTextConfirm
        openDialogDefaultText={openDialogDefaultText}
        setOpenDialogDefaultText={setOpenDialogDefaultText}
        idAssunto={idAssuntoTextoPadrao}
      />

      <DialogConfirmation
        openDialog={openFuncionarioParaExclusao}
        setOpenDialog={setOpenFuncionarioParaExclusao}
        setConfirm={setConfirmFuncionarioExclusao}
        txtConfirm="Sim"
        txtCancel="Não"
        txtTitle="Desvincular funcionário"
        txtContainer={`Deseja desvincular '${funcionarioParaExclusao?.nome}' do assunto?`}
        closeControlOutsideOnConfirm={true}
      />

      <DialogAssuntoSetorConfirm
        openDialogAssuntoSetorConfirm={openDialogAssuntoSetorConfirm}
        setOpenDialogAssuntoSetorConfirm={setOpenDialogAssuntoSetorConfirm}
        setAssuntoSetorConfirm={setAssuntoSetorConfirm}
        idAssunto={assuntoId}
        setIdAssunto={setIdAssuntoEdicao}
        idSetor={assunto.setorId}
        selecionarSetor={true}
      />

      <DialogSetorFuncionarioConfirm
        filtro={{
          coluna: [24],
          comparador: 0,
          valores: [`${assunto.setorId}`],
        }}
        idsFuncionariosDesconsiderar={funcionarios?.map((fun) => fun.id)}
        openDialogSetorFuncionario={openDialogSetorFuncionario}
        setOpenDialogSetorFuncionario={setOpenDialogSetorFuncionario}
        setSetorFuncionarioConfirm={setAssuntoFuncionarioConfirm}
        naoApresentarChecks={true}
      />

      <Container
        title={
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Link to="/Assuntos">
              <Button className="btn-arrow-back">
                <BiArrowBack />{" "}
              </Button>
            </Link>
            Assunto
            <Collapse in={assunto.id}>
              <div style={{ marginLeft: "56px" }}>
                {assunto.descricao}
                <Badge
                  type={assunto.habilitado}
                  className="no-select"
                  style={{ display: "inline", marginLeft: "10px" }}
                >
                  {assunto.habilitado ? "Ativo" : "Inativo"}
                </Badge>
              </div>
              <div style={{ marginLeft: "56px" }}>
                <Typography color="text.secondary" variant="h5">
                  Setor: {assunto.setorDescricao ?? "N/A"}
                </Typography>
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ float: "inline-end" }}>
                  <ActionTable>
                    <PrimaryTooltip title="Texto Padrão" placement="top">
                      <Button
                        onClick={() => setIdAssuntoTextoPadrao(assuntoId)}
                        className="btn-open"
                      >
                        <BsListTask
                          style={{ padding: "3px", fontSize: "30px" }}
                        />
                      </Button>
                    </PrimaryTooltip>
                    <PrimaryTooltip title="Editar Assunto" placement="top">
                      <Button
                        onClick={() => {
                          setIdAssuntoEdicao(assuntoId);
                        }}
                        className="btn-edit"
                      >
                        <AiOutlineEdit
                          style={{ padding: "3px", fontSize: "30px" }}
                        />
                      </Button>
                    </PrimaryTooltip>
                  </ActionTable>
                </div>
              </Grid>
            </Collapse>
          </Grid>
        }
      >
        <Grid container layout={"row"} spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ComponenteAssunto.KPI id="Kpi">
              <Slide direction="up" in={solicitacoesAbertas > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que estejam com status igual a Aberta.
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/(${JSON.stringify({
                        ...payloadQuantAberto,
                        gerarSomenteQuantidade: false,
                        gerarDadosComplementares: true,
                      })})`}
                    >
                      <ComponenteAssunto.KPI_Content
                        id="Kpi_Content"
                        className="mouse_kpi"
                      >
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <FaUser
                            id="RiUserUnfollowLine"
                            className={classesIcons.solicitacoes}
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center RiUserUnfollowLine"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesAbertas}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Solicitações Abertas
                        </Kpi.Title>
                      </ComponenteAssunto.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>
              <Slide direction="up" in={solicitacoesAguardandoSolicitante > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que estejam com status igual a Aguardando
                        Solicitante.
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/(${JSON.stringify({
                        ...payloadAguarSolicitante,
                        gerarSomenteQuantidade: false,
                        gerarDadosComplementares: true,
                      })})`}
                    >
                      <ComponenteAssunto.KPI_Content id="Kpi_Content">
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <FaUserClock
                            id="RiUserUnfollowLine"
                            className={
                              classesIcons.solicitacoesAguardandoSolicitante
                            }
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center BiMessageSquareDetail"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesAguardandoSolicitante}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Solicitações Aguardando Solicitante
                        </Kpi.Title>
                      </ComponenteAssunto.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>
              <Slide direction="up" in={solicitacoesEmAndamento > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que estejam com status igual a Andamento.
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/(${JSON.stringify({
                        ...payloadAndamento,
                        gerarSomenteQuantidade: false,
                        gerarDadosComplementares: true,
                      })})`}
                    >
                      <ComponenteAssunto.KPI_Content id="Kpi_Content">
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <FaUserCheck
                            id="RiUserUnfollowLine"
                            className={classesIcons.solicitacoesEmAndamento}
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center RiUserVoiceLine"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesEmAndamento}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Solicitações em Andamento
                        </Kpi.Title>
                      </ComponenteAssunto.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>
              <Slide direction="up" in={solicitacoesAguardandoTarefas > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que estejam associadas a pelo menos uma
                        tarefa com status igual a Aberta, Andamento ou
                        Aguardando Demandante.
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/(${JSON.stringify({
                        ...payloadAguarTarefas,
                        gerarSomenteQuantidade: false,
                        gerarDadosComplementares: true,
                      })})`}
                    >
                      <ComponenteAssunto.KPI_Content id="Kpi_Content">
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <FaWindowRestore
                            id="RiUserUnfollowLine"
                            className={
                              classesIcons.solicitacoesAguardandoTarefas
                            }
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center BiMessageCheck"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesAguardandoTarefas}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Solicitações Aguardando Tarefas
                        </Kpi.Title>
                      </ComponenteAssunto.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>

              <Slide direction="up" in={solicitacoesVerificacaoInterna > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que estejam com status igual a "Em
                        Verificação Interna".
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/(${JSON.stringify({
                        ...payloadVerificacaoIntern,
                        gerarSomenteQuantidade: false,
                        gerarDadosComplementares: true,
                      })})`}
                    >
                      <ComponenteAssunto.KPI_Content id="Kpi_Content">
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <BsFillClipboard2CheckFill
                            id="RiUserUnfollowLine"
                            className={
                              classesIcons.solicitacoesVerificacaoInterna
                            }
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center BiMessageError"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesVerificacaoInterna}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Verificação Interna
                        </Kpi.Title>
                      </ComponenteAssunto.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>

              <Slide direction="up" in={solicitacoesFinalizadasMes > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que foram alteradas para Fechada pelo
                        atendente nos últimos 30 dias.
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/(${JSON.stringify({
                        ...payloadFinalizadasMes,
                        gerarSomenteQuantidade: false,
                        gerarDadosComplementares: true,
                      })})`}
                    >
                      <ComponenteAssunto.KPI_Content id="Kpi_Content">
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <BsFillCheckCircleFill
                            id="RiUserUnfollowLine"
                            style={{ color: "var(--dark-purple)" }}
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center ImClipboard"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesFinalizadasMes}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Solicitações Finalizadas no Mês
                        </Kpi.Title>
                      </ComponenteAssunto.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>
            </ComponenteAssunto.KPI>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ComponenteAssunto.title>
              <span className="titulo">Funcionários</span>
              <span
                onClick={() => {
                  if (!loadingFuncionario) {
                    setOpenDialogSetorFuncionario(true);
                  }
                }}
                className={classesAdicionar.span}
              >
                <span style={{ verticalAlign: "-webkit-baseline-middle" }}>
                  <RxLinkNone2 />
                </span>
                <span className="adicionar"> Vincular</span>
              </span>
            </ComponenteAssunto.title>
            <ComponenteAssunto.grid>
              <Card className="card_left_padding">
                <DataGrid
                  getRowId={(r) => r.id}
                  style={{ height: "100%" }}
                  pageSize={10}
                  columns={[
                    {
                      field: "nome",
                      flex: 1,
                      headerName: " ",
                      width: 200,
                      editable: false,
                      renderCell: (param) => (
                        <Tooltip title={param.value} placement="top">
                          <Link to={`/Funcionario/${param.row.id}`}>
                            <span className={classesAdicionar.adicionar}>
                              <span
                                className="adicionar"
                                style={{ marginRight: "10px" }}
                              >
                                <BiLinkExternal />
                              </span>
                              <span>{param.value}</span>
                            </span>
                          </Link>
                        </Tooltip>
                      ),
                    },
                    {
                      field: "situacao",
                      flex: 1,
                      align: "left",
                      headerAlign: "left",
                      headerName: "Situação",

                      width: 165,
                      editable: false,
                      renderCell: (param) => (
                        <div style={{ display: "contents", lineHeight: "0px" }}>
                          {param.row?.status.toUpperCase() == "AUSENTE" ? (
                            <PrimaryTooltipAzul title="Ausente" placement="top">
                              <span>
                                <FaUserLock
                                  style={{
                                    fontSize: "31px",
                                    paddingRight: "10px",
                                    color: "#1c8ccb",
                                  }}
                                />
                              </span>
                            </PrimaryTooltipAzul>
                          ) : (
                            <></>
                          )}
                          {param.row?.status.toUpperCase() == "FERIAS" ? (
                            <PrimaryTooltipFerias
                              title="Férias"
                              placement="top"
                            >
                              <span>
                                <FaHouseUser
                                  style={{
                                    fontSize: "25px",
                                    color: "#1c8bc9",
                                    paddingRight: "10px",
                                  }}
                                />
                              </span>
                            </PrimaryTooltipFerias>
                          ) : (
                            <></>
                          )}
                          {param.row?.informacoes?.setores?.find(
                            (setor) => setor.id == setorId
                          )?.supervisor ? (
                            <PrimaryTooltipAmarelo
                              title="Supervisor"
                              placement="top"
                            >
                              <span>
                                <FaUserTie
                                  style={{
                                    fontSize: "25px",
                                    color: "#e5a900",
                                    paddingRight: "10px",
                                  }}
                                />
                              </span>
                            </PrimaryTooltipAmarelo>
                          ) : (
                            <></>
                          )}
                          {param.row?.status.toUpperCase() == "INATIVO" ? (
                            <PrimaryTooltipVermelho
                              title="Inativo"
                              placement="top"
                            >
                              <span>
                                <FaUserTimes
                                  style={{
                                    fontSize: "31px",
                                    paddingRight: "10px",
                                    color: "var(--red)",
                                  }}
                                />
                              </span>
                            </PrimaryTooltipVermelho>
                          ) : (
                            <></>
                          )}
                        </div>
                      ),
                    },
                    {
                      field: "informacoes",
                      flex: 1,
                      headerName: "Informações",
                      width: 165,
                      editable: false,
                      renderCell: (params) => (
                        <div
                          style={{
                            display: "contents",
                            color: "#4354a6",
                            lineHeight: "0px",
                          }}
                        >
                          {params.value?.quantidadeSolicitacoes != undefined &&
                          params.value?.quantidadeSolicitacoes >= 0 ? (
                            <PrimaryTooltip
                              title={`Solicitações ${params.value?.quantidadeSolicitacoes}`}
                              placement="top"
                            >
                              <span style={{ width: "70px" }}>
                                <FaUser
                                  style={{
                                    fontSize: "25px",
                                    paddingRight: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "20.5px",
                                    fontWeight: "bold",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {params.value?.quantidadeSolicitacoes}
                                </span>
                              </span>
                            </PrimaryTooltip>
                          ) : (
                            <></>
                          )}
                          {params.value?.quantidadeTarefasAssociadas !=
                            undefined &&
                          params.value?.quantidadeTarefasAssociadas >= 0 ? (
                            <PrimaryTooltip
                              title={`Tarefas ${params.value?.quantidadeTarefasAssociadas}`}
                              placement="top"
                            >
                              <span style={{ width: "70px" }}>
                                <FaWindowRestore
                                  style={{
                                    fontSize: "25px",
                                    paddingRight: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "20.5px",
                                    fontWeight: "bold",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {params.value?.quantidadeTarefasAssociadas}
                                </span>
                              </span>
                            </PrimaryTooltip>
                          ) : (
                            <></>
                          )}
                          {params.value
                            ?.quantidadeSolicitacoesEmVerificacaoInterna !=
                            undefined &&
                          params.value
                            ?.quantidadeSolicitacoesEmVerificacaoInterna >=
                            0 ? (
                            <PrimaryTooltip
                              title={`Solicitações em Verificação Interna ${params.value?.quantidadeSolicitacoesEmVerificacaoInterna}`}
                              placement="top"
                            >
                              <span style={{ width: "70px" }}>
                                <BsFillClipboard2CheckFill
                                  id="RiUserUnfollowLine"
                                  style={{
                                    fontSize: "25px",
                                    paddingRight: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "20.5px",
                                    fontWeight: "bold",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {
                                    params.value
                                      ?.quantidadeSolicitacoesEmVerificacaoInterna
                                  }
                                </span>
                              </span>
                            </PrimaryTooltip>
                          ) : (
                            <></>
                          )}
                          {params.value?.quantidadeSolicitacoesAtrasadas !=
                            undefined &&
                          params.value?.quantidadeSolicitacoesAtrasadas >= 0 ? (
                            <PrimaryTooltip
                              title={`Solicitações Atrasadas ${params.value?.quantidadeSolicitacoesAtrasadas}`}
                              placement="top"
                            >
                              <span style={{ width: "70px" }}>
                                <FaClock
                                  id="RiUserUnfollowLine"
                                  style={{
                                    fontSize: "25px",
                                    paddingRight: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "20.5px",
                                    fontWeight: "bold",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {
                                    params.value
                                      ?.quantidadeSolicitacoesAtrasadas
                                  }
                                </span>
                              </span>
                            </PrimaryTooltip>
                          ) : (
                            <></>
                          )}
                        </div>
                      ),
                    },
                    {
                      field: "status",
                      headerName: "Status",
                      width: 160,
                      editable: false,
                      renderCell: (params) => {
                        return (
                          <ComponenteAssunto.FuncionarioBadge
                            type={params.value.toUpperCase()}
                            className="no-select"
                          >
                            <span>{params.value}</span>
                          </ComponenteAssunto.FuncionarioBadge>
                        );
                      },
                    },
                    {
                      field: "acao",
                      headerName: "Ações",
                      width: 150,
                      filterable: false,
                      renderCell: (params) => {
                        return (
                          <ActionTable>
                            <PrimaryTooltip
                              title="Desvincular Funcionário"
                              placement="top"
                            >
                              <Button
                                onClick={() => {
                                  setFuncionarioParaExclusao(params.row);
                                  setOpenFuncionarioParaExclusao(true);
                                }}
                                className="btn-link-break"
                              >
                                <RxLinkBreak2 />
                              </Button>
                            </PrimaryTooltip>
                          </ActionTable>
                        );
                      },
                    },
                  ]}
                  rows={funcionarios}
                  hideFooterSelectedRowCount
                  density={"small"}
                  loading={loadingFuncionario}
                  localeText={ptBR.props.MuiDataGrid.localeText}
                />
              </Card>
            </ComponenteAssunto.grid>
          </Grid>
        </Grid>
      </Container>
    </ComponenteAssunto>
  );
}
