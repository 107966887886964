import { useState, useEffect, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FaRegUser, GrConfigure, IoExitOutline, IoMdNotificationsOutline } from 'react-icons/all'
import Button from '@material-ui/core/Button'
import Popover from "@material-ui/core/Popover"
import CircularProgress from '@material-ui/core/CircularProgress'
import { UserIcon } from './styles.jsx'
import { userData } from '../../../services/services'
import { logout, obtenhaUsuario } from '../../../services/auth'
import { Badge, Divider, Grid } from '@material-ui/core'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system'

export default function UserIconComponent() {
  const [user, setUser] = useState({
    nome: '',
    login: ''
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [loadingLogOff, setLoadingLogOff] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const loadUserData = async () => {
    const usuario = obtenhaUsuario();
    setUser({
      nome: usuario.nome,
      login: usuario.login
    });
    // await userData()
    //     .then(response => {
    //         setUser({
    //             name: response.data.name,
    //             email: response.data.email,
    //             rules: response.data.rules
    //         })
    //     })
  }

  const logOff = () => {
    setLoadingLogOff(true)
    logout()
  }

  useEffect(() => {
    loadUserData()
  }, [])

  return (
    <>
      {/* <Badge

        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        badgeContent={4}

        color="primary" style={{ fontSize: '20px' }} > */}
        <Button className="btnNavBar btnUserIcons" style={{ margin: 0 }} onClick={handleClick}>
          <FaRegUser />
        </Button>

      {/* </Badge> */}
      <Popover
        id={id}
        className="popover-usericon-info"
        open={open}
        anchorEl={anchorEl}
        onClose={loadingLogOff ? null : handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <UserIcon id="UserIcon">
          <UserIcon.Account id="UserIcon_Account">
            <UserIcon.AccountContent id="UserIcon_AccountContent">
              <UserIcon.AccountIcon id="UserIcon_AccountIcon" className="no-select">{Boolean(user.nome) ? user.nome === '' ? '-' : user.nome.slice(0, 1) : '-'}</UserIcon.AccountIcon>
              <UserIcon.AccountInfo id="UserIcon_AccountInfo">
                <UserIcon.AccountName id="UserIcon_AccountName" className="no-select">{Boolean(user.nome) ? user.nome === '' ? '-' : user.nome : '-'}</UserIcon.AccountName>
                <UserIcon.AccountEmail id="UserIcon_AccountEmail" className="no-select">{Boolean(user.nome) ? user.login === '' ? '-' : user.login : '-'}</UserIcon.AccountEmail>
              </UserIcon.AccountInfo>
            </UserIcon.AccountContent>
            <Link to={`/ConfiguracaoUsuario`}>
              <Button className="btnLogout btnLogoutLoading no-select"><GrConfigure /> Configurações</Button>
            </Link>
            {
              loadingLogOff
                ?
                <Button className="btnLogout btnLogoutLoading no-select"><CircularProgress size={19} /> <span id="text-logout-user">Saindo...</span></Button>
                :
                <Button className="btnLogout no-select" onClick={() => logOff()}><IoExitOutline /> Sair</Button>
            }


{/* 
            <Divider />
            <Grid direction='row' style={{ textAlign: 'center' }}>
              <IoMdNotificationsOutline /> Notificações
            </Grid>
            <Divider />
            <Grid>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Notificação X"
                    secondary={
                      <Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Solicitação foi fechada automaticamente...
                        </Typography>
                        {" — Número 2243516"}
                      </Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Notificação Y"
                    secondary={
                      <Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Solicitação foi fechada automaticamente...
                        </Typography>
                        {" — Número 2243516"}
                      </Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Notificação W"
                    secondary={
                      <Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Solicitação foi fechada automaticamente...
                        </Typography>
                        {" — Número 2243516"}
                      </Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Grid> */}


          </UserIcon.Account>
        </UserIcon>
      </Popover>
    </>
  )
}