import { useState, useEffect, forwardRef, useRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DialogVersoesArtigoComponente } from './styles'
import api from '../../../services/api'
import { Autocomplete, Chip, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid'
import { setRef, Grid } from '@material-ui/core'
import Draggable from 'react-draggable'
import { convertISODateToDateView } from '../../../functions'
import { useHistory } from 'react-router-dom'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const coresStatus = {
    RASCUNHO: { f: "var(--purple)", d: "Rascunho" },
    VERSAOFUTURA: {  f: "var(--dark-blue)", d: "Versão Futura" },
    VERSAOATUAL: { f: "var(--green)", d: "Versão Atual" },
    VERSAOPASSADA: {  f: "var(--orange)", d: "Versão Passada" }
};

export default function DialogVersoesArtigo({
    idArtigo,
    openDialog,
    setOpenDialog
}) {

    const [versoes, setVersoes] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleClickRow = (idArtigoHistorico) => {
        history.push(`/duvida/${idArtigoHistorico}`);
    };

    const handleClose = (e) => {
        e.preventDefault()

        if (loading) return;

        setOpenDialog(false);
        setVersoes([]);
    }

    const getVersoes = async () => {
        setLoading(true);

        await api.get(`/jucerja/v1/artigo/${idArtigo}/versoes`)
            .then(response => {
                setVersoes(response.data);
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (!openDialog)
            return;

        getVersoes();

    }, [openDialog])

    return (
        <Draggable
            handle={'[class*="MuiDialog-root"]'}
            cancel={'[class*="MuiDialogContent-root"]'}>
            <Dialog
                hideBackdrop
                disableBackdropClick
                open={openDialog}
                TransitionComponent={Transition}
                className="no-select"
                keepMounted
                maxWidth={'lg'}
                onClose={handleClose}
                aria-labelledby="dialog-detail-request-slide-title"
                aria-describedby="dialog-detail-request-dialog-slide-description"
                id="dialog-setor"
            >
                <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">Selecione a Versão</DialogTitle>
                <DialogContent>
                    <DialogVersoesArtigoComponente id="DialogSetor">
                        <DialogVersoesArtigoComponente.Container id="DialogSetor_Container">
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                    <TableContainer component={Paper} sx={{ minWidth: 650 }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell className='font-maior' style={{ fontWeight: 'bold' }}>Cadastro</TableCell>
                                                    <TableCell className='font-maior' style={{ fontWeight: 'bold' }}>Publicação</TableCell>
                                                    <TableCell className='font-maior' style={{ fontWeight: 'bold' }}>Usuário</TableCell>
                                                    <TableCell className='font-maior' style={{ fontWeight: 'bold' }} align="center">%&nbsp;Alteração</TableCell>
                                                    <TableCell className='font-maior' style={{ fontWeight: 'bold' }} align="center">Tipo</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {versoes.map((row) => (
                                                    <TableRow
                                                        style={{ borderRight: `3px ${coresStatus[row.tipoArtigo.toUpperCase()].f} solid` }}
                                                        className='grid-versoes'
                                                        key={row.artigoHistoricoId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >                                   
                                                                         <TableCell onClick={() => handleClickRow(row.artigoHistoricoId)} className='font-maior' component="th" scope="row"
                                                    style={{
                                                        "color": "var(--grey)",
                                                    }}
                                                >{convertISODateToDateView(row.dataCadastro)}</TableCell>
                                                        <TableCell onClick={() => handleClickRow(row.artigoHistoricoId)} className='font-maior' component="th" scope="row"
                                                            style={{
                                                                "color": "var(--grey)",
                                                            }}
                                                        >{convertISODateToDateView(row.dataPublicacao)}</TableCell>
                                                                                                                <TableCell onClick={() => handleClickRow(row.artigoHistoricoId)} className='font-maior' component="th" scope="row"
                                                            style={{
                                                                "color": "var(--grey)",
                                                            }}
                                                        >{row.nomeUsuario}</TableCell>
                                                        <TableCell onClick={() => handleClickRow(row.artigoHistoricoId)} className='font-maior' align="center"
                                                            style={{
                                                                "color": "var(--grey)",
                                                            }}
                                                        >{row.porcentualAlteracao} </TableCell>
                                                        <TableCell onClick={() => handleClickRow(row.artigoHistoricoId)} className='font-maior' align="center"
                                                            style={{
                                                                "color": coresStatus[row.tipoArtigo.toUpperCase()].f,
                                                                "fontWeight": 'bold'
                                                            }}
                                                        ><span>{coresStatus[row.tipoArtigo.toUpperCase()].d}</span></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </DialogVersoesArtigoComponente.Container>
                    </DialogVersoesArtigoComponente>
                </DialogContent>
                <DialogActions>
                    <DialogVersoesArtigoComponente.Actions id="DialogSetor.Actions">
                        <div className={!loading ? "mostrarAnimacao" : "esconderAnimacao"}>
                            <Button onClick={handleClose} className="btn-close-actions">Voltar</Button>
                        </div>
                    </DialogVersoesArtigoComponente.Actions>
                </DialogActions>
            </Dialog>
        </Draggable>
    )
}