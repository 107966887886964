import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    :root {
        --primary: #4354A6;
        --primary300: rgba(67, 84, 166, 0.3);
        --primary500: rgba(67, 84, 166, 0.5);
        --secondary: #9ecaec;
        --secondary300: rgba(158, 202, 236, 0.3);
        --secondary500: rgba(158, 202, 236, 0.5);
        --tertiary: #b5c8ff;
        --quarterly: #D8E4FF;
        --white: #ffffff;
        --black: #000000;
        --red: #FF7373;
        --grey: #6A6A6A;
        --lightgrey: #898989;
        --green: #009688;
        --lightgreen: #91D2BC;
        --orange: #ff9800;
        --lightorange: #FFDBAD;
        --yellow: #FFD14F;
        --brown: #0a0a0a;
        --purple: #923f82;
        --blue: #b1c1da;
        --dark-gree: #64865B;
        --dark-red: #D50000;
        --dark-purple: #4A148C;
        --dark-blue: #0D47A1;
        --dark-yellow: #827717;
        --dark-orange: #E65100;
        --dark-brown: #212121;
        --bg-green: #D4F7CB;
        --bg-red: #FFEBEE;
        --bg-purple: #F3E5F5;
        --bg-blue: #E3F2FD;
        --bg-yellow: #F9FBE7;
        --bg-orange: #FFF3E0;
        --bg-brown: #F5F5F5;
        --background-body: #F2F5FA;
        --background-input: #F9F9F9;
        --lightprimary: #CEDBFF;
        --lightsecs: #EDF2F7;
        --alternative1: #EFF3FF;
        --alternative2: #EAEAEA;
        --alternative3: #718096;
        --alternative4: #F2F4FA;
        --alternative5: #D8E2FF;
        --alternative6: #8a8a8a;
        --alternative7: #6278b5;
        --font-regular: 'Quicksand';
    }

    .background-linear-gradient {
        background: var(--primary);
        background: -moz-linear-gradient(162deg, var(--primary) 18%, var(--secondary) 74%);
        background: -webkit-linear-gradient(162deg, var(--primary) 18%, var(--secondary) 74%);
        background: linear-gradient(162deg, var(--primary) 18%, var(--secondary) 74%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4354A6", endColorstr="#9ecaec", GradientType=1);
    }

    a,
    abbr,
    address,
    area,
    article,
    aside,
    audio,
    b,
    base,
    bdi,
    bdo,
    blockquote,
    body,
    br,
    button,
    caption,
    cite,
    code,
    col,
    colgroup,
    data,
    datalist,
    dd,
    del,
    details,
    dfn,
    dialog,
    div,
    dl,
    dt,
    em,
    embed,
    fieldset,
    figure,
    footer,
    form,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    head,
    header,
    hgroup,
    hr,
    html,
    i,
    iframe,
    img,
    input,
    ins,
    kbd,
    keygen,
    label,
    legend,
    li,
    link,
    main,
    map,
    mark,
    menu,
    menuitem,
    meta,
    meter,
    nav,
    object,
    ol,
    optgroup,
    option,
    output,
    p,
    param,
    pre,
    progress,
    q,
    rb,
    rp,
    rt,
    rtc,
    ruby,
    s,
    samp,
    section,
    select,
    small,
    source,
    span,
    strong,
    style,
    sub,
    summary,
    sup,
    table,
    tbody,
    td,
    template,
    textarea,
    tfoot,
    th,
    thead,
    time,
    title,
    tr,
    track,
    u,
    ul,
    var,
    video,
    wbr {
        font-family: var(--font-regular) !important;
    }

    html,
    body,
    #root {
        height: 100%;
        background-color: var(--background-body);
        /* background-color: var(--white); */
    }

    .bg-green {
        background-color: var(--bg-green) !important;
    }
    .bg-red {
        background-color: var(--bg-red) !important;
    }
    .bg-purple {
        background-color: var(--bg-purple) !important;
    }
    .bg-blue {
        background-color: var(--lightprimary) !important;
    }
    .bg-yellow {
        background-color: var(--bg-yellow) !important;
    }
    .bg-orange {
        background-color: var(--bg-orange) !important;
    }
    .bg-brown {
        background-color: var(--bg-brown) !important;
    }
    
    .no-select {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    div#animated_div {
        overflow-x: hidden;
    }


    /* coloca o editor HTML em estado desabilitado com baixa opacidade */

    .public-DraftEditor-content[contenteditable="false"] {
        opacity: 0.5 !important;
    }

    .MuiDialog-paper h2.MuiTypography-root.MuiTypography-h6 {
        font-size: 1.85rem;
        font-weight: 700;
        color: var(--primary);
    }

    .MuiDialog-paper .MuiDialogActions-root>button {
        color: var(--white);
        background: var(--primary);
        background: -moz-linear-gradient( 330deg, var(--primary) 11%, var(--secondary) 100%);
        background: -webkit-linear-gradient( 330deg, var(--primary) 11%, var(--secondary) 100%);
        background: linear-gradient(330deg, var(--primary) 11%, var(--secondary) 100%);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5", endColorstr="#4354A6", GradientType=1);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5", endColorstr="#4354A6", GradientType=1);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5", endColorstr="#4354A6", GradientType=1);
        text-transform: capitalize !important;
        padding: 6px 20px;
    }

    .MuiAppBar-colorPrimary {
        background-color: var(--primary)
    }

    .MuiDialog-root.dialog-editor-message-request h2.MuiTypography-root.MuiTypography-h6 {
        color: var(--primary);
        font-size: 1.65rem;
    }

    .MuiDrawer-root.MuiDrawer-modal .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
        background: var(--background-body);
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiFormLabel-root {
        font-family: var(----font-regular) !important;
    }

    .MuiFilledInput-root {
        background: var(--background-input) !important;
    }

    .MuiFilledInput-underline:after {
        border-color: var(--secondary);
        top: 0;
        border: 2px solid var(--secondary);
    }

    .MuiDataGrid-root.MuiDataGrid-root .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
        overflow: hidden;
    }

    .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
        font-weight: 900 !important;
    }

    .MuiDataGrid-root.MuiDataGrid-root {
        border: 0 !important;
    }

    .MuiFilledInput-underline:before {
        border: 1px solid rgba(0, 0, 0, 0.12);
        top: 0;
        border-radius: 5px;
        -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    }

    .MuiFilledInput-root:hover {
        background: #eeeeee !important;
    }

    input.MuiInputBase-input {
        font-family: var(--font-regular);
    }

    .MuiFilledInput-underline:after {
        border-color: var(--secondary) !important;
        transition: none !important;
    }

    .MuiFilledInput-underline:before {
        transition: none !important;
    }

    .MuiDialog-paper {
        border-radius: 10px;
    }

    .buttonDefaultCancel {
        padding: 7px 20px !important;
        text-transform: capitalize !important;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white) !important;
        background: linear-gradient(137.04deg, #B8B8B8 21.21%, #868686 94.33%) !important;
        ;
        box-shadow: 0px 1px 9px -1px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }

    .MuiGridPanelContent-root .MuiFormControl-root.MuiGridFilterForm-closeIcon {
        width: inherit;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.buttonDefaultCancel {
        padding: 7px 20px;
    }

    .MuiDialogActions-root {
        padding: 20px 23px 20px 20px !important;
    }

    .MuiDialog-paper {
        border-radius: 10px !important;
    }

    .MuiDialogTitle-root h2 {
        font-family: var(--font-regular) !important;
    }

    .MuiDialogContent-root p#alert-dialog-slide-description {
        font-family: var(--font-regular) !important;
    }

    span.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnCloseSnackBar {
        background: transparent;
        padding: 0 !important;
        width: 38px !important;
        height: 38px !important;
        min-width: 0;
        border-radius: 100%;
        color: var(--white);
    }

    .buttonDefaultAction {
        padding: 7px 20px !important;
        text-transform: capitalize !important;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white) !important;
        background: linear-gradient(137.04deg, var(--primary) 21.21%, var(--secondary) 94.33%) !important;
        box-shadow: 0px 1px 9px -1px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin: 0 0 0 15px !important;
        min-width: 81px !important;
    }

    .MuiFormHelperText-root {
        color: var(--red);
    }

    .linkRememberPassword {
        text-decoration: none;
        outline: none;
        font-family: var(--font-regular);
        color: var(--primary);
        font-weight: 700;
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }

    ::-webkit-scrollbar-thumb {
        background: #4354A6;
        border: 0px none #ffffff;
        border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #9ecaec;
    }

    ::-webkit-scrollbar-thumb:active {
        background: #4354A6;
    }

    ::-webkit-scrollbar-track {
        background: #ebebeb;
        border: 0px none #ffffff;
        border-radius: 50px;
    }

    ::-webkit-scrollbar-track:hover {
        background: #ebebeb;
    }

    ::-webkit-scrollbar-track:active {
        background: #ebebeb;
    }

    ::-webkit-scrollbar-corner {
        background: transparent;
    }

    .btn-close-actions {
        color: var(--white) !important;
        background: #4e4e4e !important;
        background: -moz-linear-gradient( 330deg, #4e4e4e 11%, #cecece 100%) !important;
        background: -webkit-linear-gradient( 330deg, #4e4e4e 11%, #cecece 100%) !important;
        background: linear-gradient(330deg, #4e4e4e 11%, #cecece 100%) !important;
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cecece", endColorstr="#4e4e4e", GradientType=1) !important;
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cecece", endColorstr="#4e4e4e", GradientType=1) !important;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cecece", endColorstr="#4e4e4e", GradientType=1) !important;
        text-transform: capitalize !important;
        padding: 6px 20px !important;
    }

    .btn-primary-actions {
        color: var(--white) !important;
        background: var(--primary);
        background: -moz-linear-gradient( 330deg, var(--primary) 11%, var(--secondary) 100%);
        background: -webkit-linear-gradient( 330deg, var(--primary) 11%, var(--secondary) 100%);
        background: linear-gradient(330deg, var(--primary) 11%, var(--secondary) 100%);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5", endColorstr="#4354A6", GradientType=1);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5", endColorstr="#4354A6", GradientType=1);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5", endColorstr="#4354A6", GradientType=1);
        text-transform: capitalize !important;
        padding: 6px 20px !important;
    }

    .btn-backround-primary-actions {
        color: var(--white) !important;
        background: var(--primary);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5", endColorstr="#4354A6", GradientType=1);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5", endColorstr="#4354A6", GradientType=1);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5", endColorstr="#4354A6", GradientType=1);
        text-transform: capitalize !important;
        padding: 6px 20px !important;
    }

    .btn-secondary-actions {
        color: var(--white) !important;
        background: #006064 !important;
        background: -moz-linear-gradient( 330deg, #006064 11%, #00acc1 100%) !important;
        background: -webkit-linear-gradient( 330deg, #006064 11%, #00acc1 100%) !important;
        background: linear-gradient(330deg, #006064 11%, #00acc1 100%) !important;
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#006064", endColorstr="#00acc1 ", GradientType=1) !important;
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#006064", endColorstr="#00acc1 ", GradientType=1) !important;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#006064", endColorstr="#00acc1 ", GradientType=1) !important;
        text-transform: capitalize !important;
        padding: 6px 20px !important;
    }

    .btnDefaultSaveForm {
        width: 81px !important;
        height: 38px;
    }

    .btnDefaultCircleAdd {
        width: 56px !important;
        height: 56px !important;
        padding: 0 !important;
        min-width: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        color: var(--white) !important;
        background: linear-gradient(137.04deg, var(--primary) 21.21%, var(--secondary) 94.33%) !important;
        border-radius: 100% !important;
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
        font-size: 37px !important;
    }

    .flex {
        display: flex;
    }

    .btnDefaultPrimary {
        color: var(--white) !important;
        background: var(--primary);
        background: -moz-linear-gradient(330deg, var(--primary) 11%, var(--secondary) 100%);
        background: -webkit-linear-gradient( 330deg, var(--primary) 11%, var(--secondary) 100%);
        background: linear-gradient( 330deg, var(--primary) 11%, var(--secondary) 100%);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4354A6", endColorstr="#9ecaec", GradientType=1);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4354A6", endColorstr="#9ecaec", GradientType=1);
        text-transform: capitalize !important;
        font-size: 17px !important;
    }

    .btnDefault {
        font-family: var(--font-regular);
        color: var(--white);
        padding: 10px 34px;
        text-transform: capitalize;
        letter-spacing: 1px;
        font-size: 16px;
    }

    a {
        outline: none;
        text-decoration: none;
    }

    .btnMenuMobile {
        width: 48px;
        height: 48px;
        padding: 0;
        border-radius: 100%;
        min-width: 0;
        font-size: 13px;
        margin: 0 0 0 25px;
    }

    .btnMenuMobile img {
        width: 30px;
    }

    div#FilterListDrawer {
        position: relative;
        height: 100vh;
    }

    div#Filter_ContentFilters {
        position: absolute;
        width: 100%;
        height: calc(100% - 158px);
        overflow: auto;
        top: 81px;
    }

    label.MuiFormControlLabel-root span.MuiTypography-root.MuiFormControlLabel-label {
        line-height: 1rem;
    }

    .btn-input-adornment-search {
        color: var(--white);
        background: var(--primary);
        background: -moz-linear-gradient(330deg,var(--primary) 11%,var(--secondary) 100%);
        background: -webkit-linear-gradient(330deg,var(--primary) 11%,var(--secondary) 100%);
        background: linear-gradient(330deg,var(--primary) 11%,var(--secondary) 100%);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5",endColorstr="#4354A6",GradientType=1);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5",endColorstr="#4354A6",GradientType=1);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5",endColorstr="#4354A6",GradientType=1);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61c5e5",endColorstr="#4354A6",GradientType=1);
        text-transform: capitalize !important;
        padding: 6px 20px;
    }

    table.responsiveTable th {
        text-align: left;
        -webkit-user-select4: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    table.responsiveTable td {
        padding: 5px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
        margin: 0 0 0 0px;
        width: calc(100%);
    }

    .MuiPickersToolbar-toolbar {
        background: var(--primary) !important;
    }

    .MuiPickersDay-daySelected {
        background-color: var(--primary) !important;
    }

    header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%);
        background: transparent !important;
    }

    .MuiMenuItem-root {
        font-family: var(--font-regular) !important;
    }

    .MuiTab-textColorPrimary {
        font-weight: 600;
    }

    .MuiTab-textColorPrimary.Mui-selected {
        color: var(--primary) !important;
    }

    .MuiTabs-indicator {
        background-color: var(--primary) !important;
    }

    div[role="tabpanel"] .MuiBox-root {
        padding: 10px 0 0 0 !important;
    }

    .MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot {}

    .MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:nth-child(1n) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: var(--white) !important;
        background: linear-gradient(137.04deg, #B8B8B8 21.21%, #868686 94.33%) !important;
        box-shadow: 0px 1px 9px -1px rgb(0 0 0 / 25%);
        border-radius: 5px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:nth-child(2n) {
        padding: 7px 20px !important;
        text-transform: capitalize !important;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: var(--white) !important;
        background: linear-gradient(137.04deg, var(--primary) 21.21%, var(--secondary) 94.33%) !important;
        box-shadow: 0px 1px 9px -1px rgb(0 0 0 / 25%);
        border-radius: 5px;
        margin: 0 0 0 15px !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnNextAppBarTabs {
        width: 54px;
        height: 54px;
        position: absolute;
        right: -12px;
        border-radius: 100%;
        min-width: 0;
        padding: 0 !important;
        top: -50px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnNextAppBarTabs img {
        min-width: 65px;
    }

    button.btnGerarProtuario {
        height: 54px;
        background: var(--white);
        border: 2px solid #01579B;
        box-sizing: border-box;
        border-radius: 5px;
        color: #01579B;
        padding: 0 20px;
        font-weight: 600;
    }

    .descriptionAccordionSummaryItem {
        display: flex;
        flex-direction: row;
    }

    .typographyAccordionSummaryItem.codigoColetatypographyAccordionSummaryItem {
        display: flex;
        flex-direction: row;
    }

    .typographyAccordionSummaryItem.codigoColetatypographyAccordionSummaryItem svg {
        min-width: 31px;
    }

    .typographyAccordionSummaryItem {
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;
        color: #1E1E1E;
    }

    .typographyInternalAccordionSummaryItem {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        color: #888888;
        text-transform: uppercase;
    }

    .descriptionAccordionSummaryItem {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .typographyAccordionSummaryItem.codigoColetatypographyAccordionSummaryItem {
        display: flex;
        flex-direction: row;
    }

    .typographyAccordionSummaryItem.codigoColetatypographyAccordionSummaryItem svg {
        min-width: 31px;
    }

    .typographyAccordionSummaryItem {
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;
        color: #1E1E1E;
    }

    .typographyInternalAccordionSummaryItem {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        color: #888888;
        text-transform: uppercase;
    }

    .typographyInternalAccordionSummaryItemCodColetaItem {
        display: flex;
        flex-direction: column;
        margin: 0 15px 0 25px;
    }

    .typographyAccordionSummaryItem.typographyAccordionSummaryItemTipoColeta {
        margin: 0 25px 0 25px;
        width: 50%;
    }

    .typographyAccordionSummaryItem.typographyAccordionSummaryItemDataColeta {
        margin: 0 15px 0 15px;
    }

    .MuiFilledInput-inputMultiline {
        font-family: var(--font-regular) !important;
    }

    input.MuiInputBase-input {
        font-family: var(--font-regular) !important;
    }

    .MuiTypography-caption {
        font-family: var(--font-regular) !important;
    }

    .MuiTypography-body2 {
        font-family: var(--font-regular) !important;
    }

    .MuiTypography-h4 {
        font-family: var(--font-regular) !important;
    }

    .MuiTypography-subtitle1 {
        font-family: var(--font-regular) !important;
    }

    .MuiTypography-body1 {
        font-family: var(--font-regular) !important;
    }

    div.accordionExpandPanel .MuiAccordionSummary-root.Mui-expanded {
        background: #FFF5FD;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    div.accordionExpandPanel .MuiAccordionSummary-root.Mui-expanded svg path {
        fill: #923F82;
    }

    .MuiPaper-root.MuiAccordion-root.accordionExpandPanel.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded .MuiCollapse-container.MuiCollapse-entered {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .MuiFilledInput-underline.Mui-error:after {
        transform: scaleX(1);
        border-color: #f44336 !important;
    }

    .visibilityHidden {
        visibility: hidden !important;
    }

    .visibilityVisible {
        visibility: visible !important;
    }

    .MuiChip-root {}

    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
        background: var(--primary);
        color: var(--white);
        border-bottom: 1px solid #828282;
    }

    .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperFullScreen.MuiPaper-elevation24.MuiPaper-rounded {
        border-radius: 0 !important;
    }

    label.mutiple-chip-label-description {
        z-index: 9;
        /* top: 8px; */
        /* left: 10px; */
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnAdicionarFuncaoAoUsuario {
        background: var(--white) !important;
        border: 2px solid var(--secondary);
        padding: 7px 22px;
        color: var(--secondary);
        font-weight: 700;
        width: 127px;
        height: 42px;
        svg {
            color: var(--secondary);
        }
    }

    .badgeResultNao {
        background: var(--red);
        border-radius: 100px;
        width: max-content;
        padding: 3px 10px 3px 10px;
        text-transform: uppercase;
        color: var(--white);
        font-size: 11px;
    }

    .badgeResultSim {
        background: #4dc16c;
        border-radius: 100px;
        width: max-content;
        padding: 3px 10px 3px 10px;
        text-transform: uppercase;
        color: var(--white);
        font-size: 11px;
    }

    button.btnDataTableAction {
        border: 2px solid;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 2px 20px;
        text-transform: capitalize;
        margin: 0 15px 0 0 !important;
        font-weight: 600;
    }

    .btnDataTableAction svg {
        margin: 0 8px 0 0;
    }

    .btnDataTableActionEdit {
        border-color: var(--purple);
        color: var(--purple);
    }

    button.btnDataTableActionDelete {
        border-color: var(--red) !important;
        color: var(--red) !important;
    }

    a.menuSkeletonLoading.active {
        position: relative;
        padding: 0;
        height: 36px;
        margin: 0 0 4px 0;
    }

    a.menuSkeletonLoading.active span.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-wave {
        position: absolute;
        top: 0;
        height: 100%;
        margin: 0;
        transform: scale(1);
    }

    .helperTextFilledInput {
        font-size: 12px;
        line-height: 12px;
        color: var(--red);
        margin: 5px 0 0 0;
    }

    .MuiSelect-filled.MuiSelect-filled {
        font-family: var(--font-regular) !important;
    }

    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
        background-color: var(--primary) !important;
        color: var(--white) !important;
    }

    button.btnOpenModalSearchItem {
        background-color: var(--grey) !important;
        color: var(--white);
        padding: 8px;
        margin: 0 -4px 0 0;
    }

    .MuiIconButton-root {
        padding: 8px !important;
        margin: 0 -4px 0 0 !important;
    }

    table.responsiveTable thead tr th {
        font-weight: bold;
    }

    button.btnDataTableActionEdit {
        border-color: var(--purple) !important;
        color: var(--purple) !important;
    }

    button.btnDataTableAction {
        border: 2px solid !important;
        box-sizing: border-box !important;
        border-radius: 5px !important;
        text-transform: capitalize !important;
        font-weight: 600 !important;
        padding: 3px 14px 3px 10px !important;
    }

    table.responsiveTable td {
        text-transform: capitalize;
    }

    .chipListItemPerfil {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .chipListItemPerfil>div {
        margin: 0 10px 0 0;
    }

    .chipItemPerfil {
        background: var(--grey) !important;
        color: var(--white) !important;
        max-width: 185px !important;
    }

    .chipItemPerfil svg {
        color: var(--white) !important;
    }

    .chipListItemPerfil:hover {}

    .MuiChip-root.chipItemPerfil.MuiChip-deletable:hover {
        background: var(--primary) !important;
    }

    @media (min-width: 994px) {
        td.actionResponsiveTable.pivoted {
            min-width: 250px;
        }
    }

    div.popover-adornement-filter-column-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 15px 0 15px 13px;
        -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 0%), 0 3px 1px -2px rgb(0 0 0 / 0%), 0 1px 5px 0 rgb(0 0 0 / 0%);
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 0%), 0 3px 1px -2px rgb(0 0 0 / 0%), 0 1px 5px 0 rgb(0 0 0 / 0%);
    }

    div#demo-popup-popover .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 15px 0 15px 13px;
        -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-add-new-item {
        background: aquamarine;
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
        -webkit-font-smoothing: antialiased;
        font-size: 0.95rem;
        letter-spacing: .25px;
        -webkit-align-items: center;
        align-items: center;
        background-color: #fff;
        background-image: none;
        -webkit-border-radius: 24px;
        border-radius: 24px;
        color: var(--white);
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: inline-flex;
        font-weight: 500;
        height: 48px;
        min-width: 56px;
        overflow: hidden;
        text-transform: none;
        background: linear-gradient(137.04deg, var(--primary) 21.21%, var(--secondary) 94.33%) !important;
        padding: 0 28px 0 22px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-add-new-item svg {
        font-size: 26px;
        margin: 0 8px 0 0;
    }

    div#DataTableComponentClearFilter button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-clear-filter-column-item {
        background: var(--grey);
        min-width: 123px;
        color: var(--white);
        margin: 0 12px 0 0;
    }

    .selectMenuItemBox {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .titleSelectMenuItem {
        font-weight: 600;
    }

    .subtitleSelectMenuItem {
        display: flex;
        flex-direction: row;
    }

    .itemSubTitleSelectMenuItem label,
    .itemSubTitleSelectMenuItem p {
        margin: 0;
        font-size: 13px;
        color: gray;
    }

    .itemSubTitleSelectMenuItem label {
        font-size: 10px;
        font-weight: 600;
        color: #a5a5a5;
        text-align: left;
    }

    .subtitleSelectMenuItem .itemSubTitleSelectMenuItem {
        width: 50%;
    }

    div#formControlGestacaoColeta div#txt-gestacao .subtitleSelectMenuItem {
        display: none;
    }

    div#formControlGestacaoColeta div#txt-gestacao .titleSelectMenuItem {
        font-weight: normal;
    }

    li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected .selectMenuItemBox label,
    li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected .selectMenuItemBox p {
        color: var(--white);
        font-weight: 500;
    }

    .dateDataGridType {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .dateDataGridType .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
        width: calc(100% - 41px);
    }

    .dateDataGridType button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-adornment-search-column {
        top: auto !important;
    }

    .MuiPickersCalendar-week div[role="presentation"] {
        flex: 1 0;
    }

    .MuiPickersCalendarHeader-daysHeader span.MuiTypography-root.MuiPickersCalendarHeader-dayLabel.MuiTypography-caption {
        flex: 1;
    }

    .MuiPickersBasePicker-pickerView {
        max-width: 129px !important;
        min-width: 280px !important;
        margin: 0 auto;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-add-new-item[disabled] {
        background: #b5b5b5 !important;
        cursor: not-allowed !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnDataTableAction.btnSelecionaItem.btnDataTableActionEdit {
        min-width: 127px !important;
    }

    button.btnSelecionaItem {
        min-width: 127px !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-imprimi-codebar {
        border: 2px solid #01579B;
        color: #01579B;
        padding: 6px 19px !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-imprimi-codebar svg {
        margin: 0 6px 0 0;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-imprimi-codebar.Mui-disabled.Mui-disabled {
        border-color: #bdbdbd;
        color: #bdbdbd;
        cursor: not-allowed;
    }

    .insideCodeBarPreview svg {
        width: 100%;
        height: 80px;
    }

    .insideCodeBarPreview {
        padding: 10px;
    }

    p.txt-msg-codebar-preview {
        font-size: 12px;
        text-align: center;
        color: #9e9e9e;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span.field-obrigatorio {
        color: var(--red);
        font-weight: bold;
    }

    .logo-sys-jucerja-mobile {
        margin: 15px 0 32px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .logo-sys-jucerja-mobile img {
        width: 100%;
        max-width: 160px;
        padding: 0 0 0 17px;
    }

    .menu-mobile-drawer-jucerja div#MenuList {
        justify-content: center;
        align-items: center;
    }

    .menu-mobile-drawer-jucerja a {
        font-size: 16px;
        width: calc(100% - 25px);
    }

    .menu-mobile-drawer-jucerja a svg {
        font-size: 25px;
    }

    .dados-usuario-mobile-drawer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .icon-usuario-mobile-drawer {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background: #fff;
        border-radius: 100%;
        overflow: hidden;
        padding: 45px;
    }

    p.nome-usuario-mobile-drawer {
        font-weight: 700;
        margin-bottom: 0;
        font-size: 16px;
        line-break: anywhere;
        padding: 0 23px;
        width: calc(100% - 35px);
        text-align: center;
    }

    p.email-usuario-mobile-drawer {
        font-size: 13px;
        margin: 6px 0 39px 0;
        line-break: anywhere;
        width: calc(100% - 35px);
        text-align: center;
    }

    .mensagem-error-login {
        margin: 0;
        font-size: 14px;
    }

    .MuiPopover-root.popover-notification-navbar .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        border-radius: 8px;
    }

    .MuiPopover-root.popover-usericon-info .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        border-radius: 8px;
        height:auto;
        
    }


    /* Editor Custom */

    .rdw-option-wrapper[title="Monospace"] {
        display: none !important;
    }

    .rdw-option-wrapper[title="Sobrescrito"] {
        display: none !important;
    }

    .rdw-option-wrapper[title="Subscrito"] {
        display: none !important;
    }

    a.rdw-dropdown-selectedtext[title="Tamanho da Fonte"] {
        display: none !important;
    }

    .rdw-fontfamily-wrapper[aria-label="rdw-font-family-control"] {
        display: none !important;
    }

    .rdw-fontsize-wrapper[aria-label="rdw-font-size-control"] {
        display: none !important;
    }

    .rdw-colorpicker-wrapper[aria-label="rdw-color-picker"] {
        display: none !important;
    }

    .rdw-embedded-wrapper[aria-label="rdw-embedded-control"] {
        display: none !important;
    }

    .rdw-remove-wrapper[aria-label="rdw-remove-control"] {
        display: none !important;
    }

    .rdw-image-wrapper[aria-label="rdw-image-control"] {
        display: none !important;
    }

    .rdw-option-wrapper.rdw-option-disabled[title="Remover link"] {
        display: none !important;
    }

    .rdw-option-wrapper.rdw-option-disabled[title="Aumentar recuo"] {
        display: none !important;
    }

    .rdw-option-wrapper.rdw-option-disabled[title="Diminuir recuo"] {
        display: none !important;
    }

    .rdw-option-wrapper {
        transition: all ease .35s;
        border-radius: 5px;
    }

    .rdw-option-wrapper:hover {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
        background-color: var(--white);
    }

    .rdw-editor-toolbar {
        padding: 10px 8px 4px 8px;
    }

    .rdw-dropdown-wrapper.rdw-block-dropdown:hover {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 12%) !important;
        background-color: var(--white);
    }

    .rdw-dropdown-wrapper.rdw-block-dropdown {
        transition: all ease .35s;
        border-radius: 5px;
    }

    a.active.link-navigation-loading {
        width: calc(100% - 5px);
        height: 66px;
        padding: 0;
        position: relative;
        overflow: hidden;
        display: inline-block;
    }

    a.active.link-navigation-loading span.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-wave {
        position: absolute;
        top: -161px;
        left: 0;
        width: 100%;
        height: 350px;
    }

    .MuiDialog-root.dialog-editor-message-request h2.MuiTypography-root.MuiTypography-h6 {
        color: var(--primary);
        font-size: 1.65rem;
        line-height: 1.65rem;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-close-modal-message-request {
        color: var(--white) !important;
        text-transform: capitalize !important;
        font-size: 17px !important;
        background: rgb(92, 92, 92);
        background: -moz-linear-gradient(20deg, rgba(92, 92, 92, 1) 0%, rgba(199, 199, 199, 1) 100%);
        background: -webkit-linear-gradient(20deg, rgba(92, 92, 92, 1) 0%, rgba(199, 199, 199, 1) 100%);
        background: linear-gradient(20deg, rgba(92, 92, 92, 1) 0%, rgba(199, 199, 199, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5c5c5c", endColorstr="#c7c7c7", GradientType=1);
        padding: 7px 20px;
    }

    .MuiDialog-root.dialog-editor-message-request div#alert-dialog-slide-title {
        padding: 25px 24px;
    }

    .MuiDialog-root.dialog-editor-message-request div#alert-dialog-slide-title h2.MuiTypography-root.MuiTypography-h6 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-close-dialog-message-request {
        background: transparent;
        min-width: 0;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        font-size: 23px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-close-dialog-message-request span.MuiButton-label {
        position: relative;
        top: 2px;
    }

    .popper-more-option-drops {
        z-index: 9;
    }

    .bottom-navigation-mobile-request .MuiBottomNavigation-root {
        height: 100px;
    }

    .bottom-navigation-mobile-request button#send-message-mobile-request-attach path {
        stroke: var(--green);
    }

    .bottom-navigation-mobile-request button#send-message-mobile-request-image svg {
        stroke: var(--yellow);
    }

    .bottom-navigation-mobile-request button#send-message-mobile-request-editor svg {
        color: var(--primary);
    }

    .bottom-navigation-mobile-request button#send-message-mobile-request-attach svg,
    .bottom-navigation-mobile-request button#send-message-mobile-request-image svg,
    .bottom-navigation-mobile-request button#send-message-mobile-request-editor svg {
        font-size: 23px;
        margin-bottom: 3px;
    }

    .bottom-navigation-mobile-request button span.MuiBottomNavigationAction-label.Mui-selected {
        font-size: 13px;
    }

    .bottom-navigation-mobile-request button span.MuiBottomNavigationAction-label {
        font-size: 13px;
        font-weight: 400;
    }

    span.MuiButtonBase-root.MuiIconButton-root.btn-send-attach.MuiIconButton-colorPrimary {
        min-width: 0;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        font-size: 21px;
        margin-right: 10px;
        background: var(--lightgreen);
        padding: 0;
    }

    div#btn-add-attachment span.MuiButtonBase-root.MuiIconButton-root.btn-add-more-attachments.MuiIconButton-colorPrimary {
        margin: 0 !important;
        padding: 0 !important;
        min-width: 0;
        width: 100px;
        height: 100px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--quarterly);
        border-radius: 4px;
        .MuiIconButton-label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    div#btn-add-attachment span.MuiButtonBase-root.MuiIconButton-root.btn-add-more-attachments.MuiIconButton-colorPrimary span.MuiIconButton-label span.text-add-more-attachments {
        font-size: 13px;
        line-height: 13px;
        font-weight: 700;
        font-family: var(--font-regular) !important;
        color: var(--primary);
        text-transform: uppercase;
    }

    div#btn-add-attachment span.MuiButtonBase-root.MuiIconButton-root.btn-add-more-attachments.MuiIconButton-colorPrimary span.MuiIconButton-label svg {
        font-size: 25px;
        margin: 0 0 5px 0;
        color: var(--primary);
    }

    .no-files-found-attachs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .no-files-found-attachs p {
        margin: 0;
        font-weight: 700;
        color: var(--primary);
        text-align: center;
        width: calc(100% - 20px);
        padding-bottom: 10px;
    }

    div#dialog-fullscreen-visualization-preview-file header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary {
        background: var(--black) !important;
    }

    div#dialog-fullscreen-visualization-preview-file .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiDialog-paperFullScreen {
        background-color: #000000ba;
    }

    h6#title-name-file-appbar {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    h6#title-name-file-appbar svg#icon-image-preview-file {
        color: var(--red);
        border-radius: 2px;
        position: relative;
        top: 0px;
        margin: 0 15px 0 0;
    }

    h6#title-name-file-appbar {
        width: 100%;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media (max-width: 450px) {
        max-width: 200px;
    }

    img#icon-image-preview-file {
        margin: 0 10px 0 0;
        position: relative;
        top: 4px;
    }

    button#send-message-mobile-request-attach span.MuiButtonBase-root.MuiIconButton-root.btn-send-attach.MuiIconButton-colorPrimary {
        background: transparent;
        width: 100% !important;
    }

    button#send-message-mobile-request-attach span.MuiButtonBase-root.MuiIconButton-root.btn-send-attach.MuiIconButton-colorPrimary span.MuiIconButton-label span {
        font-size: 13px;
        font-weight: 400;
    }

    button#send-message-mobile-request-attach span.MuiButtonBase-root.MuiIconButton-root.btn-send-attach.MuiIconButton-colorPrimary span.MuiIconButton-label {
        flex-direction: column;
    }

    label#label-btn-add-attachment {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div#dialog-detail-request .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
        width: 100%;
        max-width: 993px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message.btnDefault.btnDefaultPrimary.btn-send-message-editor-html {
        width: 120px;
        height: 42px;
    }

    .loading-send-message-editor-html {
        width: 120px;
        height: 42px;
        background: var(--primary);
        background: -moz-linear-gradient(330deg, var(--primary) 11%, var(--secondary) 100%);
        background: -webkit-linear-gradient( 330deg, var(--primary) 11%, var(--secondary) 100%);
        background: linear-gradient( 330deg, var(--primary) 11%, var(--secondary) 100%);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4354A6", endColorstr="#9ecaec", GradientType=1);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4354A6", endColorstr="#9ecaec", GradientType=1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
    }

    .loading-send-message-editor-html svg {
        color: var(--white);
    }

    #dialog-change-subject .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
        max-width: 650px !important;
        width: 100%;
    }

    .btn-close-dialog {
        background: #808080;
        background: -moz-linear-gradient(330deg, #808080 11%, #dedede 100%);
        background: -webkit-linear-gradient(330deg, #808080 11%, #dedede 100%);
        background: linear-gradient(330deg, #808080 11%, #dedede 100%);
        color: var(--white);
        text-transform: capitalize;
        padding: 10px 20px;
    }

    #dialog-associated-tasks .MuiDialog-paperWidthSm {
        max-width: 1024px !important;
    }

    #dialog-create-funcionario .MuiDialog-paperWidthSm {
        max-width: 1024px !important;
    }

    div#dialog-create-tasks .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
        max-width: 1024px;
        width: 100%;
    }

    div#circular-progress-send-attachment {
        position: relative;
        left: -3px;
    }

    div#circular-progress-send-attachment .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-determinate {
        width: 75px !important;
        height: 75px !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message-fast-request.btnDefault.btnDefaultPrimary[loading="true"] {
        background: var(--green);
        background: -moz-linear-gradient(330deg, var(--green) 11%, var(--lightgreen) 100%);
        background: -webkit-linear-gradient( 330deg, var(--green) 11%, var(--lightgreen) 100%);
        background: linear-gradient( 330deg, var(--green) 11%, var(--lightgreen) 100%);
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#009688", endColorstr="#91D2BC", GradientType=1);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#009688", endColorstr="#91D2BC", GradientType=1);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-download-file-previewer {
        background: var(--primary);
        color: var(--white);
        padding: 5px 15px;
        text-transform: capitalize;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-download-file-previewer svg {
        margin: 0 5px 0 0;
    }

    #dialog-fullscreen-visualization-preview-file .preview-bar {
        display: none !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnDefault.btnDefaultPrimary.btn-send-message-with-editor-and-attachment.btn-loading-message-editor-attachment {
        min-width: 0;
        padding: 0;
        font-size: 10px !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnDefault.btnDefaultPrimary.btn-send-message-with-editor-and-attachment {
        min-width: 0;
        width: 92px;
        height: 40px;
        font-size: 10px !important;
        padding: 0;
        border-radius: 100px;
        z-index: 999;
        margin: 19px 0;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnDefault.btnDefaultPrimary.btn-send-message-with-editor-and-attachment.btn-loading-message-editor-attachment {
        border-radius: 100px;
        width: 50px !important;
        height: 50px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnDefault.btnDefaultPrimary.btn-send-message-with-editor-and-attachment svg#icon-send-message {
        margin: 0 6px 0 0;
    }

    .displayNone {
        display: none !important;
    }

    p.request-description-dialog {
        color: var(--alternative3);
        margin: 0 0 15px 0;
        font-size: 18px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-attachment-item-form {
        background: var(--lightgreen);
        padding: 10px 20px;
        text-transform: capitalize;
        font-size: 14px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-attachment-item-form svg {
        margin: 0 9px 0 0;
    }

    .attachment-area-dialog-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .summary-grid {}

    .section-grid {
        height: 100%;
        padding-top: 5px;
        background-color: #fff;
    }

    .btn-loading-logout {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: calc(100% - 5px);
        border-left: 5px solid var(--background-body);
        padding: 17px 0;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: flex-start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        font-size: 19px;
        font-weight: 600;
        color: var(--alternative3);
        -webkit-transition: all ease 0.35s;
        transition: all ease 0.35s;
        border: 1px solid rgb(221 217 255 / 0%);
        border-radius: 10px;
        position: relative;
        cursor: pointer;
    }

    .btn-loading-logout:hover {
        color: var(--primary);
        background: #F6F5FF;
        box-shadow: 0px 1px 9px -1px rgb(0 0 0 / 12%);
        border-radius: 10px;
        border: 1px solid rgb(221 217 255 / 56%);
    }

    .btn-loading-logout .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate {
        margin: 0 20px 0 42px;
        font-size: 30px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnLogout.btnLogoutLoading.no-select svg.MuiCircularProgress-svg {
        margin: 0;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnLogout.btnLogoutLoading.no-select span#text-logout-user {
        margin: 0 0 0 9px;
    }

    .multiline-textfield-with-chips .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    div#textfield-with-chips {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
    }

    .MuiFormControl-root.MuiTextField-root.multiline-textfield-with-chips textarea#outlined-multiline-flexible {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0 0 0 10px;
    }

    div#textfield-with-chips .MuiChip-root.chip-item-textfield-with.MuiChip-deletable {
        background: var(--primary);
        color: var(--white);
    }

    div#textfield-with-chips .MuiChip-root.chip-item-textfield-with.MuiChip-deletable svg {
        color: var(--white);
    }

    div#dialog-standard-text .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthLg {
        width: 100%;
    }

    div#dialog-standard-text {
        z-index: 1369;
    }

    div#dialog-enviar-mensagem-editor {
        z-index: 1295 !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message-fast-request.btnDefault.btnDefaultPrimary {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.btn-visualiza-requisicao {
        background: #e3f2fd;
        color: #1565c0;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.btn-filtro-top-right-item01 {
        background: var(--bg-yellow);
        color: #afaf00;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.btn-filtro-top-right-item02 {
        background: var(--bg-orange);
        color: var(--orange);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.item-05 {
        background: transparent;
        color: var(--grey);
    }

    /* Rechars customizacoes*/
    ul.recharts-default-legend {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    /* Impressao das requisicoes */
    @media print {
        div#dialog-standard-text,
        div#dialog-fullscreen-visualization-preview-file,
        div#dialog-create-tasks,
        div#dialog-attachment,
        div#dialog-associated-tasks,
        div#Request_SendFastMessage,        
        div#dialog-change-subject,
        div#Request_Top,
        div#Request_ListOfMessages,
        div#ContainerRequest_ContainerComponent,
        div#dialog-detail-request,
        div#dialog-enviar-mensagem-editor,
        .rdw-editor-wrapper {
            display: none;
        }
        div#PrintRequest {
            display: flex !important;
        }
        div#PrintRequest_Content {
            padding-top: 0;
        }
        div#PrintRequest_MessageItem {
            break-inside: avoid;
        }
    }
`

export default GlobalStyle