import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import TextField from "@material-ui/core/TextField"
import { HiOutlineFilter, IoCloseSharp } from 'react-icons/all'
import { Autocomplete, Chip, Stack } from '@mui/material';
import { Filter } from './styles'
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grow from '@mui/material/Grow';
import FormControl from "@material-ui/core/FormControl"
import Checkbox from "@material-ui/core/Checkbox"

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Slider from "@material-ui/core/Slider"
import { TransitionGroup } from 'react-transition-group';
import api from '../../../services/api'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash';
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers"
import ptBRLocale from "date-fns/locale/pt-BR"

import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

const PrimaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'var(--primary)',
    color: 'var(--white)',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const filtroTemplate = {
  filtros: [],
  firstResult: 0,
  maxResults: 0
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

export default function FilterRequests({ aplicarFiltro, setAplicarFiltro, disabled, todosAssuntos, setSalvarFiltro }) {
  const colunaRequisicao = 0;
  const colunaAssunto = 1;
  const colunaStatus = 2;
  const colunaAtendente = 4;
  const colunaCPF = 7;
  const colunaEmail = 8;
  const colunaDataAbertura = 9
  const colunaDataAvaliacao = 11;
  const colunaDataFechamento = 18;
  const colunaNotaAvaliacao = 20;
  const colunaApenasAvaliacao = 21;
  const colunaSolicitante = 15;
  const colunaSetor = 5;
  const comparador = { igual: 0, menor: 1, maior: 2, igualMaior: 3, igualMenor: 4 };

  const [filtro, setFiltro] = useState(
    Object.keys(aplicarFiltro).length > 0
      ?
      aplicarFiltro
      :
      filtroTemplate)
  const classes = useStyles()
  const [carregar, setCarregar] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const [listaDeStatus, setListaDeStatus] = useState([]);
  const [listaDeAtendentes, setListaDeAtendentes] = useState([]);
  const [listaDeAssuntos, setListaDeAssuntos] = useState([]);
  const [listaDeSetor, setListaDeSetor] = useState([]);
  const [nota, setNota] = useState("")
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [filtroDataAbertura, setFiltroDataAbertura] = React.useState("");
  const [tipoDataAvaliacao, setTipoDataAvaliacao] = useState(comparador.igual);
  const [tipoNotaAvaliacao, setTipoNotaAvaliacao] = useState(comparador.igual);

  const handleChangeDataAbertura = (event) => {
    setFiltroDataAbertura(event.target.value)
  }

  const valueNota = (value) => {
    return value
  }

  const updateNota = (e, data) => {
    setNota(data)
  }

  const handleFiltro = (event, colunaFiltro) => {
    const value = event.target.value;
    crieObjetoFiltro(value, colunaFiltro)
  }

  const crieObjetoFiltro = (valor, colunaFiltro, comparador, unico = false) => {
    const comparadorConvertido = comparador ?? 0;
    const filtroEscopo = filtro.filtros.filter(x => x.Coluna == colunaFiltro && (comparadorConvertido == x.Comparador || unico))[0];
    const filtrosSemColunaEscopo = filtro.filtros.filter(x => x != filtroEscopo);
    const objFiltroColunaEscopo = { Coluna: [colunaFiltro], Comparador: comparadorConvertido, Valores: [`${valor}`] };

    if (Array.isArray(valor)) {
      objFiltroColunaEscopo.Valores = [...(valor.map(x => `${x}`))];
    }

    if (valor && valor != "" || colunaFiltro == colunaSolicitante && valor === 0)
      filtrosSemColunaEscopo.push(objFiltroColunaEscopo);

    setFiltro(values => ({
      ...values, "filtros": [...filtrosSemColunaEscopo]
    }))
  }

  const obtenhaValorFiltro = (colunaFiltro, comparador, unico = false) => {
    const comparadorConvertido = comparador ?? 0;
    const filtroColuna = filtro.filtros.filter(x => x.Coluna == colunaFiltro && (comparadorConvertido == x.Comparador || unico));

    if (!filtroColuna || filtroColuna.length <= 0)
      return "";

    const valores = filtroColuna[0].Valores;

    return colunaFiltro == colunaStatus || colunaFiltro == colunaAtendente || colunaFiltro == colunaAssunto || colunaFiltro == colunaSetor ? valores : valores[0];
  }

  const obtenhaComparadorFiltro = (colunaFiltro, comparador, unico = false) => {
    const comparadorConvertido = comparador ?? 0;
    const filtroColuna = filtro.filtros.filter(x => x.Coluna == colunaFiltro && (comparadorConvertido == x.Comparador || unico));

    if (!filtroColuna || filtroColuna.length <= 0)
      return "";

    return new Number(filtroColuna[0].Comparador);
  }

  const crieValoresParaAssuntos = (e, v) => {
    const listaDeIds = v.map(x => x.id);
    crieObjetoFiltro(listaDeIds, colunaAssunto);
  }

  const crieValoresParaStatus = (e, v) => {
    const listaDeIds = v.map(x => x.id);
    crieObjetoFiltro(listaDeIds, colunaStatus);
  }

  const crieValoresParaAtendentes = (e, v) => {
    const listaDeIds = v.map(x => x.referenciaUsuarioId);
    crieObjetoFiltro(listaDeIds, colunaAtendente);
  }

  const crieValoresParaSetores = (e, v) => {
    const listaDeIds = v.map(x => x.id);
    crieObjetoFiltro(listaDeIds, colunaSetor);
  }

  const obtenhaValorDefaultAssunto = () => {
    const listaIdsAssuntos = [...obtenhaValorFiltro(colunaAssunto)];

    if (listaIdsAssuntos == "")
      return [];

    const listaObjetosAssuntos = listaDeAssuntos.filter(x => listaIdsAssuntos.filter(y => y == x.id).length > 0);

    return listaObjetosAssuntos;
  }

  const obtenhaValorDefaultStatus = () => {
    const listaIdStatus = [...obtenhaValorFiltro(colunaStatus)];

    if (listaIdStatus == "")
      return [];

    const listaObjetosStatus = listaDeStatus.filter(x => listaIdStatus.filter(y => y == x.id).length > 0);

    return listaObjetosStatus;
  }

  const obtenhaValorDefaultResponsaveis = () => {
    const listaIdAtendente = [...obtenhaValorFiltro(colunaAtendente)];

    if (listaIdAtendente == "")
      return [];

    const listaObjetosAtendentes = listaDeAtendentes.filter(x => listaIdAtendente.filter(y => y == x.referenciaUsuarioId).length > 0);

    return listaObjetosAtendentes;
  }

  const obtenhaValorDefaultSetores = () => {
    const listaIdSetor = [...obtenhaValorFiltro(colunaSetor)];

    if (listaIdSetor == "")
      return [];

    const listaObjetosSetores = listaDeSetor.filter(x => listaIdSetor.filter(y => y == x.id).length > 0);

    return listaObjetosSetores;
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setCarregar(open);
    setState({ ...state, [anchor]: open })
  }

  const carregueListaDeAssuntos = async () => {
    setListaDeAssuntos([]);

    api.get((todosAssuntos ? `/jucerja/v1/assuntos/todos-assuntos` : `/jucerja/v1/Assuntos`))
      .then(response => {
        setListaDeAssuntos(response.data ?? [])
      })
      .catch(error => {

      })
  }

  const carregueListaDeStatus = async () => {
    setListaDeStatus([]);

    api.get(`/jucerja/v1/StatusRequisicao`)
      .then(response => {
        setListaDeStatus(response.data ?? [])
      })
      .catch(error => {

      })
  }

  const carregueListaDeAtendentes = async () => {
    setListaDeAtendentes([]);

    api.post(`/jucerja/v1/funcionario/atendentes-filtro`, {})
      .then(response => {
        let listaAtendentes = (response.data ?? []).map(x => ({ referenciaUsuarioId: x.id, nome: x.nome })).filter(x => x.referenciaUsuarioId !== 0);
        listaAtendentes = _(listaAtendentes)
          .groupBy('referenciaUsuarioId')
          .map(x => x[0])
          .groupBy('nome')
          .map(x => x[0])
          .orderBy(['nome'], 'asc')
          .value()

        listaAtendentes.unshift({ referenciaUsuarioId: 0, nome: 'SEM RESPONSÁVEL' });

        setListaDeAtendentes(listaAtendentes);
      })
      .catch(error => {

      })
  }

  const carregueListaDeSetor = async () => {
    setListaDeSetor([]);

    api.get(`/jucerja/v1/setor/setores-responsavel`)
      .then(response => {
        setListaDeSetor(response.data ?? [])
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    if (!carregar) return;

    carregueListaDeStatus();
    carregueListaDeAssuntos();
    carregueListaDeAtendentes();
    carregueListaDeSetor();

  }, [carregar])

  const list = (anchor) => (
    <Filter id="Filter">
      <div
        id="FilterListDrawer"
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
      >
        <Filter.TopContent id="Filter_TopContent">
          <Filter.TopInfo id="Filter_TopInfo">
            <HiOutlineFilter /> Filtros
          </Filter.TopInfo>
          <Button className="btnCloseFiltersDrawer" onClick={toggleDrawer(anchor, false)}><IoCloseSharp /></Button>
        </Filter.TopContent>

        <Filter.Top id="Filter_ContentFilters" >
          <TransitionGroup >

            <Grow key="filtroSolicitacaoFiltro" timeout={1000} style={{ height: "100%" }}>
              <Filter.Content id="Filter_Content">

                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Solicitação</Filter.ItemFilterTitle>
                    <FormControl>
                      <FormControlLabel
                        control={<Checkbox name="checkedB_solicitante" color="primary" checked={obtenhaValorFiltro(colunaSolicitante, comparador.igual)}
                          onChange={(e) => crieObjetoFiltro(e.target.checked ? 0 : '', colunaSolicitante, comparador.igual)} />}
                        label="Sem Solicitante"
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="filled-adornment-requisicao"
                        type="text"
                        label="Nº Solicitação"
                        variant="outlined"
                        onChange={e => handleFiltro(e, colunaRequisicao)}
                        value={obtenhaValorFiltro(colunaRequisicao)}
                      />
                    </FormControl>
                    <FormControl></FormControl>
                    <FormControl>
                      <TextField
                        id="filled-adornment-cpf"
                        type="text"
                        label="CPF"
                        variant="outlined"
                        onChange={e => handleFiltro(e, colunaCPF)}
                        value={obtenhaValorFiltro(colunaCPF)}
                      />
                    </FormControl>
                    <FormControl></FormControl>
                    <FormControl>
                      <TextField
                        id="filled-adornment-email"
                        type="text"
                        label="E-mail"
                        variant="outlined"
                        onChange={e => handleFiltro(e, colunaEmail)}
                        value={obtenhaValorFiltro(colunaEmail)}
                      />
                    </FormControl>
                  </Filter.ItemFilter>
                </Filter.ContentItem>

                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Avaliação</Filter.ItemFilterTitle>
                    <FormControl>
                      <FormControlLabel
                        control={<Checkbox name="checkedB" color="primary" checked={obtenhaValorFiltro(colunaApenasAvaliacao, comparador.igualMaior)}
                          onChange={(e) => crieObjetoFiltro(e.target.checked ? 1 : '', colunaApenasAvaliacao, comparador.igualMaior)} />}
                        label="Somente avaliados"
                      />
                    </FormControl>

                    {/* <FormControl variant="outlined" >
                  <InputLabel id="demo-simple-select-outlined-label">Nota maior/igual</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={filtroDataAbertura}
                    onChange={handleChangeDataAbertura}
                    label="Tipo Filtro"
                  >
                    <MenuItem value="">
                      <em>Escolha uma nota</em>
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </Select>
                </FormControl> */}
                    <FormControl></FormControl>
                    <Filter.ItemFilterTitle>Nota</Filter.ItemFilterTitle>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Tipo Filtro</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={obtenhaComparadorFiltro(colunaNotaAvaliacao, comparador.igual, true) == "" ? comparador.igual : obtenhaComparadorFiltro(colunaNotaAvaliacao, comparador.igual, true)}
                        onChange={e => {
                          if (obtenhaValorFiltro(colunaNotaAvaliacao, tipoNotaAvaliacao, true) != "") {
                            crieObjetoFiltro(obtenhaValorFiltro(colunaNotaAvaliacao, tipoNotaAvaliacao, true), colunaNotaAvaliacao, e.target.value, true)
                          }
                          setTipoNotaAvaliacao(e.target.value)
                        }}
                        label="Tipo Filtro"
                      >
                        <MenuItem value={comparador.igual}>Igual</MenuItem>
                        <MenuItem value={comparador.menor}>Menor</MenuItem>
                        <MenuItem value={comparador.maior}>Maior</MenuItem>
                        <MenuItem value={comparador.igualMenor}>Menor Igual</MenuItem>
                        <MenuItem value={comparador.igualMaior}>Maior Igual</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl></FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Avaliação</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={obtenhaValorFiltro(colunaNotaAvaliacao, tipoNotaAvaliacao, true)}
                        onChange={(e) => crieObjetoFiltro(e.target.value, colunaNotaAvaliacao, obtenhaComparadorFiltro(colunaNotaAvaliacao, comparador.igual, true) == "" ? tipoNotaAvaliacao : obtenhaComparadorFiltro(colunaNotaAvaliacao, comparador.igual, true), true)}
                        label="Avaliação"
                      >
                        <MenuItem value="">
                          <em>Escolha uma nota</em>
                        </MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl></FormControl>
                    <Filter.ItemFilterTitle>Data Avaliação</Filter.ItemFilterTitle>
                    <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                      <Filter.DateFilterWithSelect id="Filter_DateFilterWithSelect">
                        <KeyboardDatePicker
                          margin="normal"
                          id="datapicker-datavalidade-data-inicial"
                          label="Data Início"
                          format="dd/MM/yyyy"
                          value={obtenhaValorFiltro(colunaDataAvaliacao, comparador.igualMaior) != "" ? new Date(obtenhaValorFiltro(colunaDataAvaliacao, comparador.igualMaior)) : null}
                          onChange={newValue => { newValue.setHours(0, 0, 0, 0); crieObjetoFiltro(newValue ? newValue.toJSON() : "", colunaDataAvaliacao, comparador.igualMaior); }}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                          okLabel="Selecionar"
                          cancelLabel="Cancelar"
                          inputVariant="outlined"
                        />
                        <KeyboardDatePicker
                          margin="normal"
                          id="datapicker-datavalidade-data-final"
                          label="Data Término"
                          format="dd/MM/yyyy"
                          value={obtenhaValorFiltro(colunaDataAvaliacao, comparador.igualMenor) != "" ? new Date(obtenhaValorFiltro(colunaDataAvaliacao, comparador.igualMenor)) : null}
                          onChange={(newValue) => { newValue.setHours(23, 59, 0, 0); crieObjetoFiltro(newValue ? newValue.toJSON() : "", colunaDataAvaliacao, comparador.igualMenor) }}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                          okLabel="Selecionar"
                          cancelLabel="Cancelar"
                          inputVariant="outlined"
                        />
                      </Filter.DateFilterWithSelect>
                    </MuiPickersUtilsProvider>
                  </Filter.ItemFilter>
                </Filter.ContentItem>


                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Status</Filter.ItemFilterTitle>
                    <FormControl variant="outlined">
                      {
                        listaDeStatus.length > 0
                          ?
                          < Autocomplete
                            title='Atendentes'
                            multiple
                            id="tags-standard"
                            defaultValue={obtenhaValorDefaultStatus()}
                            options={listaDeStatus}
                            getOptionLabel={(option) => option.descricao}
                            // getOptionDisabled={(options) => (listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados.length >= 4 ? true : false)}
                            onChange={(e, v) => crieValoresParaStatus(e, v)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Selecione os status"
                              />
                            )}
                          />
                          :
                          <></>
                      }
                    </FormControl>
                  </Filter.ItemFilter>
                </Filter.ContentItem>

                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Responsáveis</Filter.ItemFilterTitle>
                    <FormControl variant="outlined">
                      {
                        listaDeAtendentes.length > 0
                          ?
                          < Autocomplete
                            title='Responsáveis'
                            multiple
                            id="tags-standard"
                            defaultValue={obtenhaValorDefaultResponsaveis()}
                            options={listaDeAtendentes}
                            getOptionLabel={(option) => option.nome}
                            // getOptionDisabled={(options) => (listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados.length >= 4 ? true : false)}

                            onChange={(e, v) => crieValoresParaAtendentes(e, v)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Selecione os responsáveis"
                              />
                            )}
                          />
                          :
                          <></>
                      }
                    </FormControl>
                  </Filter.ItemFilter>
                </Filter.ContentItem>

                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Setores</Filter.ItemFilterTitle>
                    <FormControl variant="outlined">
                      {
                        listaDeSetor.length > 0
                          ?
                          < Autocomplete
                            title='Setores'
                            multiple
                            id="tags-standard"
                            defaultValue={obtenhaValorDefaultSetores()}
                            options={listaDeSetor}
                            getOptionLabel={(option) => option.descricao}
                            // getOptionDisabled={(options) => (listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados.length >= 4 ? true : false)}

                            onChange={(e, v) => crieValoresParaSetores(e, v)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Selecione os setores"
                              />
                            )}
                          />
                          :
                          <></>
                      }
                    </FormControl>
                  </Filter.ItemFilter>
                </Filter.ContentItem>

                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Assuntos</Filter.ItemFilterTitle>
                    <FormControl variant="outlined">
                      {
                        listaDeAssuntos.length > 0
                          ?
                          < Autocomplete
                            title='Atendentes'
                            multiple
                            id="tags-standard"
                            defaultValue={obtenhaValorDefaultAssunto()}
                            options={listaDeAssuntos}
                            getOptionLabel={(option) => option.descricao}
                            // getOptionDisabled={(options) => (listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados.length >= 4 ? true : false)}
                            onChange={(e, v) => crieValoresParaAssuntos(e, v)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Selecione os assuntos"
                              />
                            )}
                          />
                          :
                          <></>
                      }
                    </FormControl>
                    {/* <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Aberta"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Andamento"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Cancelada"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Aguardando Solicitante"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Aguardando Órgão Externo"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Em Verificação Interna"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Fechada pelo Cliente"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Fechada Automaticamente"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Fechada pelo Atendente"
                /> */}
                  </Filter.ItemFilter>
                </Filter.ContentItem>
                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Período de abertura</Filter.ItemFilterTitle>
                    <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                      <Filter.DateFilterWithSelect id="Filter_DateFilterWithSelect">
                        <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                          <KeyboardDatePicker
                            margin="normal"
                            id="datapicker-data-inicial"
                            label="Data Início"
                            format="dd/MM/yyyy"
                            value={obtenhaValorFiltro(colunaDataAbertura, comparador.igualMaior) != "" ? new Date(obtenhaValorFiltro(colunaDataAbertura, comparador.igualMaior)) : null}
                            onChange={(newValue) => { newValue.setHours(0, 0, 0, 0); crieObjetoFiltro(newValue ? newValue.toJSON() : "", colunaDataAbertura, comparador.igualMaior) }}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                            okLabel="Selecionar"
                            cancelLabel="Cancelar"
                            inputVariant="outlined"
                          />
                        </Filter.DateFilterWithSelectItem>
                        <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                          <KeyboardDatePicker
                            margin="normal"
                            id="datapicker-data-inicial"
                            label="Data Término"
                            format="dd/MM/yyyy"
                            value={obtenhaValorFiltro(colunaDataAbertura, comparador.igualMenor) != "" ? new Date(obtenhaValorFiltro(colunaDataAbertura, comparador.igualMenor)) : null}
                            onChange={(newValue) => { newValue.setHours(23, 59, 0, 0); crieObjetoFiltro(newValue ? newValue.toJSON() : "", colunaDataAbertura, comparador.igualMenor) }}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                            okLabel="Selecionar"
                            cancelLabel="Cancelar"
                            inputVariant="outlined"
                          />
                        </Filter.DateFilterWithSelectItem>
                      </Filter.DateFilterWithSelect>
                    </MuiPickersUtilsProvider>
                  </Filter.ItemFilter>
                </Filter.ContentItem>

                {
                  setSalvarFiltro && filtro != filtroTemplate
                    ?
                    <Filter.ContentItem>
                      <Button onClick={e => { setSalvarFiltro(filtro); setFiltro(filtroTemplate); toggleDrawer(anchor, false)(e); }} style={{ width: '100%' }} className="btn-secondary-actions">Salvar Filtro</Button>
                    </Filter.ContentItem>
                    :
                    <></>
                }

                {/* <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <FormControl>
                  <TextField
                    id="filled-adornment-requisicao"
                    type="text"
                    label="Texto Contém"
                    variant="outlined"
                  />
                </FormControl>
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Atendente</Filter.ItemFilterTitle>
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Maria Pereira"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="João Paulo"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Hermano Guedes"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Beatriz A. Ramos"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Marilene B. Flaviana"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Hugo Nilbert"
                />
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Setor</Filter.ItemFilterTitle>
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Protocolo"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Secretária Administrativa"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Controle Externo"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Tecnologia da informação"
                />
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Nota</Filter.ItemFilterTitle>
                <Slider
                  defaultValue={1}
                  getAriaValueText={valueNota}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={5}
                  onChange={updateNota}
                />
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <FormControl>
                  <TextField
                    id="filled-adornment-cpf"
                    type="text"
                    label="CPF"
                    variant="outlined"
                  />
                </FormControl>
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <FormControl>
                  <TextField
                    id="filled-adornment-cpf"
                    type="text"
                    label="E-mail"
                    variant="outlined"
                  />
                </FormControl>
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Data Abertura</Filter.ItemFilterTitle>
                <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                  <Filter.DateFilterWithSelect id="Filter_DateFilterWithSelect">
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Tipo Filtro</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={filtroDataAbertura}
                          onChange={handleChangeDataAbertura}
                          label="Tipo Filtro"
                        >
                          <MenuItem value="">
                            <em>Escolha uma opção</em>
                          </MenuItem>
                          <MenuItem value={10}>Após</MenuItem>
                          <MenuItem value={20}>Antes</MenuItem>
                          <MenuItem value={30}>Exato</MenuItem>
                        </Select>
                      </FormControl>
                    </Filter.DateFilterWithSelectItem>
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <KeyboardDatePicker
                        margin="normal"
                        id="datapicker-data-inicial"
                        label="Data"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        okLabel="Selecionar"
                        cancelLabel="Cancelar"
                        inputVariant="outlined"
                      />
                    </Filter.DateFilterWithSelectItem>
                  </Filter.DateFilterWithSelect>
                </MuiPickersUtilsProvider>
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Data Atualização</Filter.ItemFilterTitle>
                <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                  <Filter.DateFilterWithSelect id="Filter_DateFilterWithSelect">
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Tipo Filtro</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={filtroDataAbertura}
                          onChange={handleChangeDataAbertura}
                          label="Tipo Filtro"
                        >
                          <MenuItem value="">
                            <em>Escolha uma opção</em>
                          </MenuItem>
                          <MenuItem value={10}>Após</MenuItem>
                          <MenuItem value={20}>Antes</MenuItem>
                          <MenuItem value={30}>Exato</MenuItem>
                        </Select>
                      </FormControl>
                    </Filter.DateFilterWithSelectItem>
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <KeyboardDatePicker
                        margin="normal"
                        id="datapicker-data-inicial"
                        label="Data"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        okLabel="Selecionar"
                        cancelLabel="Cancelar"
                        inputVariant="outlined"
                      />
                    </Filter.DateFilterWithSelectItem>
                  </Filter.DateFilterWithSelect>
                </MuiPickersUtilsProvider>
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Data Avaliação</Filter.ItemFilterTitle>
                <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                  <Filter.DateFilterWithSelect id="Filter_DateFilterWithSelect">
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Tipo Filtro</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={filtroDataAbertura}
                          onChange={handleChangeDataAbertura}
                          label="Tipo Filtro"
                        >
                          <MenuItem value="">
                            <em>Escolha uma opção</em>
                          </MenuItem>
                          <MenuItem value={10}>Após</MenuItem>
                          <MenuItem value={20}>Antes</MenuItem>
                          <MenuItem value={30}>Exato</MenuItem>
                        </Select>
                      </FormControl>
                    </Filter.DateFilterWithSelectItem>
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <KeyboardDatePicker
                        margin="normal"
                        id="datapicker-data-inicial"
                        label="Data"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        okLabel="Selecionar"
                        cancelLabel="Cancelar"
                        inputVariant="outlined"
                      />
                    </Filter.DateFilterWithSelectItem>
                  </Filter.DateFilterWithSelect>
                </MuiPickersUtilsProvider>
              </Filter.ItemFilter>
            </Filter.ContentItem> */}

              </Filter.Content>
            </Grow>
          </TransitionGroup>
        </Filter.Top>

        <Filter.Bottom id="Filter_Bottom">
          <Filter.BottomContent id="Filter_BottomContent">
            <Button onClick={e => { setFiltro(filtroTemplate); setAplicarFiltro({}); toggleDrawer(anchor, false)(e); }} className="btn-close-actions">Cancelar</Button>
            <Button onClick={e => { setAplicarFiltro(filtro); toggleDrawer(anchor, false)(e); }} className="btn-primary-actions">Aplicar</Button>
          </Filter.BottomContent>
        </Filter.Bottom>
      </div>
    </Filter>
  )

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button disabled={disabled ?? false} onClick={toggleDrawer(anchor, true)} className="btnFilter"><HiOutlineFilter /> Filtros</Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
