import Container from '../../../components/layout/Container'
import Grid from '@material-ui/core/Grid'
import { useEffect, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Card } from "../../../components/elements-ui/Card"
import { Badge } from "../../../components/elements-ui/Badge"
import { ActionTable } from "../../../components/elements-ui/ActionTable"
import { Button, Checkbox, FormControl, TextField } from '@material-ui/core'
import { AiOutlineEye, AiOutlineEdit, AiOutlineCaretUp, CgAdd, BiArrowBack, VscFilePdf, MdDelete, BsArrowUpShort, IoImageOutline, GrAttachment } from 'react-icons/all'
import { HiOutlineArrowsExpand } from 'react-icons/all'
import { Link, useHistory } from 'react-router-dom';
import { Autocomplete, Chip, Stack, Divider, CircularProgress, Backdrop } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip'
import api from '../../../services/api'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { v4 as uuid } from 'uuid'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DateTimePicker
} from "@material-ui/pickers"
import ptBRLocale from "date-fns/locale/pt-BR"
import DateFnsUtils from "@date-io/date-fns"
import { format } from 'date-fns'
import { DuvidaComponente } from './styles'
import { Request } from '../../../components/elements-ui/DialogSendMessageEditor/styles'
import { limitChar } from '../../../functions'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import PreviewFileImage from '../../../components/elements-ui/PreviewFileImage'
import DialogConfirmation from '../../../components/elements-ui/DialogConfirmation'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    arrow: {
        color: 'var(--white)',
    },
}))(Tooltip)

const CircularProgressWithLabel = (props) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="white" fontSize={20}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const coresStatus = {
    RASCUNHO: { f: "var(--purple)", b: "var(--bg-purple)", d: "Rascunho" },
    VERSAOFUTURA: { f: "var(--dark-blue)", d: "Versão Futura" },
    VERSAOATUAL: { f: "var(--green)", d: "Versão Atual" },
    VERSAOPASSADA: { f: "var(--orange)", d: "Versão Passada" }
};

export default function Duvida(props) {
    const history = useHistory();
    const fileInputRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar()
    const [codigoArtigoHistorico, setCodigoArtigoHistorico] = useState(props.match.params.id);
    const [artigoAtual, setArtigoAtual] = useState({});
    const [secoes, setSecoes] = useState([]);
    const [dataEditor, setDataEditor] = useState();
    const [loading, setLoading] = useState(false);

    const [attachs, setAttachs] = useState([]);
    const [openDialogPreviewFileImage, setOpenDialogPreviewFileImage] = useState(false)
    const [preview, setPreview] = useState({
        urlFile: null,
        openClose: null
    })

    const [anexoSelecionado, setAnexoSelecionado] = useState({});

    const [openDialogExclusao, setOpenDialogExclusao] = useState(false);
    const [exclusaoConfirm, setExclusaoConfirm] = useState(false);

    const [openDialogRascunho, setOpenDialogRascunho] = useState(false);
    const [rascunhoConfirm, setRascunhoConfirm] = useState(false);

    const [porcentagemEnvio, setPorcentagemEnvio] = useState(0);

    const carregueArtigo = async (dataEditor) => {
        if (ehCadastro()) {
            setDataEditor(dataEditor);
            return;
        }

        setLoading(true);
        await api.get(`/jucerja/v1/artigo/historico/${codigoArtigoHistorico}`)
            .then(response => {

                setArtigoAtual(response.data);

                dataEditor.set(response.data.conteudo);

            })
            .catch(error => {
                enqueueSnackbar(error.toString(), { variant: 'error' })
            })
            .finally(() => {
                setLoading(false);

                setDataEditor(dataEditor);
            });

        setLoading(false);
    }

    const ehEscopoVersaoPassada = () =>
        !artigoAtual ||
        artigoAtual?.tipoArtigo?.toUpperCase() == 'VERSAOPASSADA';

        const ehEscopoAtual = () =>
        !artigoAtual ||
        artigoAtual?.tipoArtigo?.toUpperCase() == 'VERSAOATUAL';

    const ehEscopoRascunho = () =>
        !artigoAtual ||
        artigoAtual?.tipoArtigo?.toUpperCase() == 'RASCUNHO';

    const previewFile = async (idAnexo) => {
        setOpenDialogPreviewFileImage(true);
        setLoading(true);

        if (attachs.find(a => a.id == idAnexo)) {
            let anexo = attachs.find(a => a.id == idAnexo);
            setPreview({
                base64: true,
                url: anexo.url,
                name: anexo.nome
            })

            setLoading(false)
        } else {

            let anexoArtigo = artigoAtual.arquivosArtigo.find(x => x.id == idAnexo);
            await api.get(`/jucerja/v1/arquivos/${anexoArtigo.arquivoDownloadId}/artigo`, { responseType: 'blob' })
                .then(async response => {
                    let extensionFile = anexoArtigo.nome.toLowerCase().includes('pdf') ? 'application/pdf' : `image/${anexoArtigo.extensao.replace('.', '')}`

                    const base = await blobToBase64(response.data);

                    setPreview({
                        base64: true,
                        url: `data:${extensionFile};base64,${base}`,
                        name: anexoArtigo.nome
                    })
                    setLoading(false)
                })
                .catch(error => {
                    setOpenDialogPreviewFileImage(false)
                    setLoading(false)
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                })
        }
    }

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result.split(',')[1]); // Remove o prefixo 'data:application/octet-stream;base64,' do resultado
                } else {
                    reject(new Error('Falha ao ler o conteúdo do arquivo.'));
                }
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    const salvarAtualizar = async (rascunho, dataObrigatoria) => {
        const conteudo = dataEditor.get();
        const dataObrigatoriaInterna = (!rascunho && dataObrigatoria == undefined) || dataObrigatoria;

        if (!artigoAtual?.titulo || artigoAtual?.titulo?.trim().length <= 0) {
            setArtigoAtual(artAtual => ({ ...artAtual, errorTitulo: "Título é obrigatório" }));
            return;
        }

        if (!artigoAtual?.secao?.id || artigoAtual?.secao?.id <= 0) {
            setArtigoAtual(artAtual => ({ ...artAtual, errorSecaoId: "Seção é obrigatória" }));
            return;
        }

        if (!artigoAtual?.url || artigoAtual?.url?.trim().length <= 0) {
            setArtigoAtual(artAtual => ({ ...artAtual, errorUrl: "Url é obrigatória" }));
            return;
        }

        if (dataObrigatoriaInterna && (!artigoAtual?.dataPublicacao || artigoAtual?.dataPublicacao?.trim().length <= 0)) {
            setArtigoAtual(artAtual => ({ ...artAtual, errorDataPublicacao: "Data da publicação é obrigatória" }));
            return;
        }

        if (conteudo.trim().length <= 0) {
            setArtigoAtual(artAtual => ({ ...artAtual, errorConteudo: true }));
            return;
        }

        let data = new FormData()

        if (attachs.length > 0)
            attachs.forEach(att => data.append('Arquivos', att.file));

        let arquivosMantidos = artigoAtual.arquivosArtigo?.filter(a => !a.tela).map(a => a.id);
        if (arquivosMantidos && arquivosMantidos.length > 0)
            arquivosMantidos.forEach(am => data.append('ArquivosMantidos', am));

        data.append('SecaoId', artigoAtual?.secao?.id);
        data.append('ArtigoHistorioId', codigoArtigoHistorico);
        data.append('id', artigoAtual?.artigoId ?? 0);
        data.append("conteudo", conteudo);
        if (artigoAtual?.dataPublicacao)
            data.append("dataPublicacao", artigoAtual?.dataPublicacao);
        data.append("url", artigoAtual?.url);
        data.append("titulo", artigoAtual?.titulo);

        if (rascunho) {
            data.delete("dataPublicacao");
        }

        setLoading(true)
        await api({
            method: ehCadastro() ? 'post' : 'put',
            url: '/jucerja/v1/artigo',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data,
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                setPorcentagemEnvio(percentCompleted);
            }
        })
            .then(async (response) => {

                if (rascunho) {
                    enqueueSnackbar(`Rascunho  ${ehCadastro() ? 'Criado' : 'Criado/Atualizado'} com sucesso.`, { variant: 'success' });
                    history.push(`/Duvida/${response.data.artigoHistorioId}`);
                    setCodigoArtigoHistorico(response.data.artigoHistorioId);
                } else {
                    enqueueSnackbar(`${ehCadastro() ? "Cadastrado" : "Atualizado"} com sucesso.`, { variant: 'success' });
                    history.push("/Duvidas");
                }
            })
            .catch(error => {
                var firstMensagem = error?.response?.data?.messages?.reverse().pop();

                if (firstMensagem?.campos) {
                    firstMensagem?.campos.forEach(campo => setArtigoAtual(artAtual => ({ ...artAtual, [`error${campo.campo ?? campo.Campo}`]: campo.mensagem ?? campo.Mensagem })))
                    return
                }

                if (firstMensagem?.mensagem) {
                    enqueueSnackbar(firstMensagem?.mensagem, { variant: 'error' })
                } else {
                    enqueueSnackbar('Não foi possível atualizar o Artigo. Por favor tente novamente', { variant: 'error' })
                }
            })
            .finally(() => {
                setLoading(false);
                setPorcentagemEnvio(0);
            })
    }

    const adicionarArquivoConteudo = (anexo) => {
        var conteudo = dataEditor.get();

        conteudo += `<a href="UPLOAD_REPLACE_ANEXO_${anexo.nome}">${anexo.nome}</a>`

        dataEditor.set(conteudo);
    }

    const carregarSecoes = async () => {
        await api.get(`/jucerja/v1/secao`)
            .then(response => {
                setSecoes(response.data);
            })
            .catch(error => {
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleChangeSecao = async (e, v) => {
        setArtigoAtual((artigo) => ({ ...artigo, secao: v, errorSecaoId: '' }))
    }

    const handleAttachment = event => {
        Array.from(event.target.files).map((file, index) => {
            if (Boolean(file) && !artigoAtual?.arquivosArtigo?.find(aq => aq.nome == file.name)) {

                let arquivos = artigoAtual?.arquivosArtigo ?? [];

                if (
                    file.type === "application/pdf"
                    || file.type === "image/jpg"
                    || file.type === "image/jpeg"
                    || file.type === "image/jpe"
                    || file.type === "image/jfif"
                    || file.type === "image/png"
                    || file.type === "image/bmp"
                    || file.type === "image/gif"
                    || file.type === "application/pkcs7-signature"
                ) {
                    const fileReader = new window.FileReader()
                    fileReader.onload = (fileLoad) => {
                        const { result } = fileLoad.target
                        const id = uuid();
                        arquivos.push({ id: id, extensao: file.type, nome: file.name, tela: true });

                        setArtigoAtual(artA => ({ ...artA, arquivosArtigo: arquivos }));
                        setAttachs((prevState) => [...prevState, {
                            id: id,
                            file: file,
                            name: file.name,
                            type: file.type,
                            url: result
                        }])
                    }

                    fileReader.readAsDataURL(file)
                }
                else {
                    enqueueSnackbar('A extensão do arquivo não é suportado', { variant: 'error' })
                }

            }
        })
    }

    const ehCadastro = () => !codigoArtigoHistorico || codigoArtigoHistorico <= 0;

    useEffect(() => {

        if (dataEditor) {
            carregueArtigo(dataEditor);
        } else {
            Editor
                .create(document.querySelector('#editor'), {
                    autosave: {
                        waitingTime: 200, // in ms
                        save(editor) {
                            setArtigoAtual(art => ({ ...art, errorConteudo: false }))
                        }
                    }

                })
                .then(async (x) => {
                    carregueArtigo(x.data);
                })
                .catch(error => {
                    console.log(error);
                });

            carregarSecoes();
        }


    }, [codigoArtigoHistorico])

    useEffect(async () => {
        if (!exclusaoConfirm)
            return;

        setArtigoAtual(ArtAtual => ({ ...ArtAtual, arquivosArtigo: [...ArtAtual?.arquivosArtigo.filter(aqArt => aqArt.id != anexoSelecionado.id)] }))
        setAttachs(atts => atts.filter(att => att.id != anexoSelecionado.id));

        setExclusaoConfirm(false);

    }, [exclusaoConfirm])

    useEffect(async () => {
        if (!rascunhoConfirm)
            return;

        await salvarAtualizar(true)
            .finally(() => {
                setRascunhoConfirm(false);
                setOpenDialogRascunho(false);
            })

    }, [rascunhoConfirm])

    return (
        <DuvidaComponente>

            <PreviewFileImage
                openDialogPreviewFileImage={openDialogPreviewFileImage}
                setOpenDialogPreviewFileImage={setOpenDialogPreviewFileImage}
                preview={preview}
                setPreview={setPreview}
                loading={loading}
                setLoading={setLoading}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={porcentagemEnvio != 0}
            >
                <CircularProgressWithLabel style={{ color: 'green', height: '60px', width: '60px' }} value={porcentagemEnvio} />
            </Backdrop>

            <DialogConfirmation
                openDialog={openDialogRascunho}
                setOpenDialog={setOpenDialogRascunho}
                setConfirm={setRascunhoConfirm}
                txtConfirm="Sim"
                txtCancel="Não"
                txtTitle="Rascunho"
                txtContainer={
                    ehCadastro()
                        ? `<span>Deseja realmente Criar este Artigo como <span style="color:${coresStatus.RASCUNHO.f}">Rascunho</span> ?</span>`
                        : `<span>Deseja realmente Criar/Atualizar o <span style="color:${coresStatus.RASCUNHO.f}">Rascunho</span> para este artigo?</span>`}
                closeControlOutsideOnConfirm
                disableBackdropClick
            />

            <DialogConfirmation
                openDialog={openDialogExclusao}
                setOpenDialog={setOpenDialogExclusao}
                setConfirm={setExclusaoConfirm}
                txtConfirm="Sim"
                txtCancel="Não"
                txtTitle="Excluir Anexo"
                txtContainer={`Deseja realmente excluir o anexo '${anexoSelecionado.nome}'' ?`}
            />

            <Container title={
                <>
                    <Button className="btn-arrow-back" onClick={() => history.push("/Duvidas")}><BiArrowBack /> </Button>
                    Dúvida
                </>} >
                <DuvidaComponente.title>
                    {
                        ehCadastro()
                            ?
                            <span style={{ color: 'green', }}>
                                Novo Artigo
                            </span>
                            :
                            <span style={{ color: coresStatus[artigoAtual.tipoArtigo?.toUpperCase()]?.f }}>
                                {coresStatus[artigoAtual.tipoArtigo?.toUpperCase()]?.d}
                            </span>
                    }
                </DuvidaComponente.title>
                <Card className='card_left_padding'>
                    <Card.Content>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl variant="outlined">
                                    <TextField
                                        id="outlined-basic-titulo"
                                        type="text"
                                        label="Título"
                                        variant="outlined"
                                        disabled={loading}
                                        required
                                        value={artigoAtual?.titulo ?? ""}
                                        onChange={e => {
                                            if (!artigoAtual) return;
                                            setArtigoAtual(art => ({ ...art, titulo: e.target.value, errorTitulo: '' }));
                                        }} error={artigoAtual?.errorTitulo}
                                        helperText={artigoAtual?.errorTitulo}
                                        inputProps={{ maxLength: 500 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                < Autocomplete
                                    style={{ height: '56px' }}
                                    title='Seções'
                                    id="tags-standard-secoes"
                                    disabled={loading}
                                    options={secoes}
                                    getOptionLabel={(option) => option.titulo}
                                    onChange={(e, v) => handleChangeSecao(e, v)}
                                    value={artigoAtual?.secao?.id ? { titulo: artigoAtual?.secao?.descricao ?? artigoAtual?.secao?.titulo, id: artigoAtual?.secao?.id } : null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            type="text"
                                            required
                                            variant="outlined"
                                            label="Selecione a Seção"
                                            error={artigoAtual?.errorSecaoId}
                                            helperText={artigoAtual?.errorSecaoId}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <TextField
                                    id="filled-Url"
                                    type="text"
                                    required
                                    value={artigoAtual.url ?? ""}
                                    disabled={loading}
                                    label="Url"
                                    variant="outlined"
                                    onChange={e => setArtigoAtual(art => ({ ...art, url: e.target.value, errorUrl: '' }))}
                                    inputProps={{ maxLength: 255 }}
                                    error={artigoAtual?.errorUrl}
                                    helperText={artigoAtual?.errorUrl}
                                />
                            </Grid>

                            <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>

                                    <FormControl variant="outlined">

                                        <DateTimePicker
                                            margin="normal"
                                            id="datapicker-data-publicacao"
                                            label="Data da Publicação"
                                            format="dd/MM/yyyy HH:mm"
                                            inputVariant="outlined"

                                            ampm={false}
                                            disabled={loading || ehEscopoVersaoPassada() || ehEscopoAtual()}
                                            value={artigoAtual.dataPublicacao ? new Date(artigoAtual.dataPublicacao) : null}
                                            onChange={(date) => setArtigoAtual(art => ({ ...art, dataPublicacao: date ? format(date, 'yyyy-MM-dd HH:mm:ss') : "", errorDataOperacao: '' }))}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            okLabel="Selecionar"
                                            cancelLabel="Cancelar"
                                            clearLabel="Limpar"
                                            clearable={ehEscopoRascunho() || ehCadastro()}
                                            error={artigoAtual.errorDataPublicacao}
                                            helperText={artigoAtual.errorDataPublicacao}
                                        />
                                    </FormControl>
                                </Grid>
                            </MuiPickersUtilsProvider>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <div style={{ border: artigoAtual?.errorConteudo ? '1px red solid' : '' }}>
                                    <div id="editor" />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack
                                    direction="row"
                                    divider={<Divider orientation="vertical" flexItem />}
                                    maxWidth
                                    spacing={2}
                                >

                                    <DuvidaComponente.SendMessageActionsDialog>
                                        <input

                                            accept=".jpg, jpeg, jpe, .jfif, .png, .bmp, .gif, .pdf, .p7s"
                                            id="input-file-send-message-editor"
                                            type="file"
                                            multiple
                                            ref={fileInputRef}
                                            onClick={e => (e.target.value = null)}
                                            onChange={handleAttachment}
                                            style={{ cursor: "pointer", display: "none" }}
                                        // onBlur={() => setPreview(null)}
                                        />
                                        <Request.SendMessageActionsAttachmentDialog id="Request_SendMessageActionsAttachmentDialog">
                                            <HtmlTooltip title="Anexar Arquivos" placement="top">
                                                <Button className="btn-send-attach btn-send-attach-mobile"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => fileInputRef.current.click()}
                                                    disabled={loading} >

                                                    <GrAttachment id="icon-attach-message" style={{ cursor: "pointer" }} />
                                                </Button>
                                            </HtmlTooltip>
                                        </Request.SendMessageActionsAttachmentDialog>
                                    </DuvidaComponente.SendMessageActionsDialog>

                                    <Item style={{ width: '100%' }}>
                                        <DuvidaComponente.Attachments id="Request_Attachments">
                                            <Request.TitleContainer id="Request_TitleContainer">
                                                {
                                                    artigoAtual?.arquivosArtigo?.length <= 0
                                                        ?
                                                        <Request.TitleText id="Request_TitleText">Nenhum Anexo Adicionado</Request.TitleText>
                                                        :
                                                        <Request.TitleText id="Request_TitleText">Anexos Adicionados</Request.TitleText>
                                                }
                                            </Request.TitleContainer>
                                            <Request.AttachmentContainer id="Request_AttachmentContainer">
                                                <DuvidaComponente.AttachmentContent id="Request_AttachmentContainer">
                                                    {artigoAtual?.arquivosArtigo?.map(attach => (
                                                        attach.extensao.toUpperCase().indexOf('PDF') > -1
                                                            ?
                                                            <Request.AttachmentItem key={uuid()} id="Request_AttachmentItemPdf" style={{ cursor: "default" }}>
                                                                <Request.AttachmentItemImage id="Request_AttachmentItemImage" style={{ cursor: "pointer" }} onClick={() => previewFile(attach.id)}><VscFilePdf /></Request.AttachmentItemImage>
                                                                <Request.AttachmentItemText id="Request_AttachmentItemText" style={{ cursor: "pointer" }} onClick={() => previewFile(attach.id)}>{limitChar(100, attach.nome)}</Request.AttachmentItemText>
                                                                <Button onClick={() => { setAnexoSelecionado(attach); setOpenDialogExclusao(true); }} className="Request_AttachmentItemClose" ><MdDelete /></Button>
                                                                <Button onClick={() => adicionarArquivoConteudo(attach)} className="Request_AttachmentItemClose" ><AiOutlineCaretUp /></Button>
                                                            </Request.AttachmentItem>
                                                            :
                                                            <Request.AttachmentItem key={uuid()} id="Request_AttachmentItemImg" style={{ cursor: "default" }}>
                                                                <Request.AttachmentItemImage id="Request_AttachmentItemImage" style={{ cursor: "pointer" }} onClick={() => previewFile(attach.id)}><IoImageOutline /></Request.AttachmentItemImage>
                                                                <Request.AttachmentItemText id="Request_AttachmentItemText" style={{ cursor: "pointer" }} onClick={() => previewFile(attach.id)}>{limitChar(100, attach.nome)}</Request.AttachmentItemText>
                                                                <Button onClick={() => { setAnexoSelecionado(attach); setOpenDialogExclusao(true); }} className="Request_AttachmentItemClose" ><MdDelete /></Button>
                                                                <Button onClick={() => adicionarArquivoConteudo(attach)} className="Request_AttachmentItemClose" ><AiOutlineCaretUp /></Button>
                                                            </Request.AttachmentItem>
                                                    ))}
                                                </DuvidaComponente.AttachmentContent>
                                            </Request.AttachmentContainer>
                                        </DuvidaComponente.Attachments>

                                    </Item>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "right" }}>
                                {
                                    artigoAtual?.tipoArtigo?.toUpperCase() == coresStatus.RASCUNHO.d.toUpperCase()
                                        ?
                                        <></>
                                        :
                                        <Button onClick={() => setOpenDialogRascunho(true)} className="btn-primary-actions" style={{ background: coresStatus.RASCUNHO.f, marginRight: "10px" }} >
                                            Rascunho
                                        </Button>
                                }
                                {
                                    ehEscopoVersaoPassada()
                                        ?
                                        <></>
                                        :
                                        <>
                                            {
                                                ehEscopoRascunho() && !artigoAtual?.dataPublicacao
                                                    ?
                                                    <Button style={{ marginRight: "10px" }} onClick={() => salvarAtualizar(false, false)} className="btn-primary-actions" >
                                                        Atualizar
                                                    </Button>
                                                    :
                                                    <></>
                                            }
                                            <Button style={{ background: 'var(--green)' }} onClick={() => salvarAtualizar(false, true)} className="btn-primary-actions" >
                                                {
                                                    ehEscopoRascunho() ||
                                                    ehCadastro()
                                                        ? 'Publicar'
                                                        : 'Atualizar'
                                                }
                                            </Button>
                                        </>
                                }
                            </Grid>
                        </Grid>
                    </Card.Content>
                </Card>

            </ Container >
        </DuvidaComponente >
    )
}