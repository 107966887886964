import axios from "axios";
import { useEffect } from "react";
import { getToken, validaToken } from "./auth";
import { useSnackbar } from "notistack";
import apm from "../services/apm-config";
import { useDispatch } from "react-redux";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const errorNaoAtendicado = ({ setError }) => {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 403) {
        setError("Você não têm permissão para acessar esta funcionalidade.");
      }
      if (error?.response?.status === 401) {
        validaToken(true);
      } else {
        apm.captureError(error);

        return Promise.reject(error);
      }
    }
  );
};
// Interceptor para respostas com erro
// api.interceptors.response.use(
//   response => {
//     return  response;
//   },
//   error => {
//     const dispatch = (()=> useDispatch())();
//     // const { enqueueSnackbar } = useSnackbar();
//     // if (error.response) {
//     //   // Tratar erros de resposta HTTP
//     //   const statusCode = error.response.status;
//     //   if (statusCode === 401) {
//     //     // Por exemplo, trate erros de autenticação
//     //     enqueueSnackbar('Erro de autenticação', { variant: 'error' });
//     //   } else if (statusCode === 403) {
//     //     // Por exemplo, trate erros de permissão
//     //     enqueueSnackbar('Sem permissão para acessar este recurso', { variant: 'error' });
//     //   }
//     // }
//     return error;
//   }
// );

export default api;
