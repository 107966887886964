import { useState, useEffect, forwardRef } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { DetailRequest } from './styles'
import Person from '../../../assets/img/identity-card.png'
import ReactStars from 'react-stars'
import { BiCalendarEvent, HiOutlineMail, FiPhone } from 'react-icons/all'
import { MdSubject, AiOutlineUser, FaTasks, AiOutlineMessage, RiMapPinUserLine, BsPerson, SiOpslevel, FiGitPullRequest, GrAttachment } from 'react-icons/all'
import { capitalize, convertISODateToDateView, formataTelefone, formataCNPJ, formataCPF, limitChar} from '../../../functions'
import { ItemSelected } from '../ItemSelected'
import { useHistory, Route, Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import api from '../../../services/api'
import Skeleton from 'react-loading-skeleton'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogDetailRequest({ stateChanger, ...rest }) {
    const history = useHistory();
    const [open, setOpen] = useState(false)
    const [requisicoesSolicitante, setRequisicoesSolicitante] = useState([])
    const [loading, setLoading] = useState(true);

    const handleClose = (e) => {
        e?.preventDefault()
        setOpen(false)
        stateChanger(false)
    }

    const valueAssessment = note => {
        let value = String(note)
        if (Boolean(value)) {
            if (value.length === 1)
                return value + '.0'
            else
                return value
        }
    }

    useEffect(() => {
        setOpen(rest.openDialogDetailRequest ? true : false)
    }, [rest])

    useEffect(async () => {
        if (!open) return;

        await api.get(`/jucerja/v1/requisicoes/dados/solicitacao/cliente/${rest.mensagens.email}?FirstResult=1&MaxResults=1000`)
            .then(response => {
                setRequisicoesSolicitante(response.data.requisicoes)
            })
            .catch(error => {
            })
            .finally(() => setLoading(false));

    }, [open])

    const getSolicitacoesLoad = () => {
        let itens = [];

        for (let index = 0; index < 3; index++) {
            itens.push(
                <Button className="btn-acesso-requisicao" disableRipple={true} disableTouchRipple={true}>
                    <ItemSelected.ListItemContent id="Request_ListItemContent">
                        <ItemSelected.ListItemInformations id="Requests_ListItemInformations">
                            <ItemSelected.ListItemInfo id="Request_ListItemInfo">
                                <ItemSelected.ListItemTitle id="Request_ListItemTitle"><Skeleton width={200} height={30} /></ItemSelected.ListItemTitle>
                                <ItemSelected.ListItemDescription id="Request_ListItemDescription"><Skeleton width={250} height={30} style={{ position: 'relative', top: '10px' }} /></ItemSelected.ListItemDescription>
                            </ItemSelected.ListItemInfo>
                            <ItemSelected.ListItemReq id="Request_ListItemReq">
                                <ItemSelected.ListItemNumberRequest id="Request_ListItemNumberRequest"><Skeleton width={280} height={30} /></ItemSelected.ListItemNumberRequest>
                                <ItemSelected.ListItemData id="Request_ListItemData"><Skeleton width={50} height={30} /></ItemSelected.ListItemData>
                            </ItemSelected.ListItemReq>
                        </ItemSelected.ListItemInformations>
                        <ItemSelected.ListItemSubInfo id="Request_ListItemSubInfo" style={{ width: '100%' }}>
                            <Skeleton width={180} height={30} style={{ position: 'relative', top: '-10px', width: '100%' }} />
                        </ItemSelected.ListItemSubInfo>
                    </ItemSelected.ListItemContent>
                </Button>
            );
        }
        return itens;

    }

    const routeChange = (rota) => {
        history.push(rota);
    }

    return (
        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-detail-request"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">{"Dados da Solicitação"}</DialogTitle>
            <DialogContent>
                <DetailRequest id="DetailRequest">
                    <DetailRequest.Info id="Info">
                        <DetailRequest.Icon id="Icon"><DetailRequest.Img id="" src={Person} /></DetailRequest.Icon>
                        <DetailRequest.Information id="Information">
                            <DetailRequest.Name id="Name">{capitalize(rest.mensagens.nome)}</DetailRequest.Name>
                            <DetailRequest.InfoContact id="DetailRequest.InfoContact">
                                {rest.mensagens.email ? <DetailRequest.Email id="DetailRequest_Email"><HiOutlineMail /> {rest.mensagens.email}</DetailRequest.Email> : null}
                                {rest.mensagens.telefone ? <DetailRequest.Phone id="DetailRequest_Phone"><FiPhone /> {formataTelefone(rest.mensagens.telefone)}</DetailRequest.Phone> : null}
                            </DetailRequest.InfoContact>
                            <DetailRequest.InfoPJ id="DetailRequest.InfoPJ">
                                {rest.mensagens.numeroProtocoloFormatado ? <DetailRequest.CPF id="CPF"><label>Protocolo</label> <p>{rest.mensagens.numeroProtocoloFormatado}</p></DetailRequest.CPF> : null}
                                {rest.mensagens.cpf ? <DetailRequest.CPF id="CPF"><label>CPF</label> <p>{formataCPF(rest.mensagens.cpf)}</p></DetailRequest.CPF> : null}
                                {rest.mensagens.nire ? <DetailRequest.Nire id="Nire"><label>Nire</label> <p>{rest.mensagens.nire === "" ? "-" : rest.mensagens.nire === null ? "-" : rest.mensagens.nire}</p></DetailRequest.Nire> : null}
                                {rest.mensagens.cnpj ? <DetailRequest.CNPJ id="CNPJ"><label>CNPJ</label> <p>{formataCNPJ(rest.mensagens.cnpj)}</p></DetailRequest.CNPJ> : null}
                            </DetailRequest.InfoPJ>
                        </DetailRequest.Information>
                    </DetailRequest.Info>
                    {
                        rest.mensagens.avaliacao === undefined || rest.mensagens.avaliacao?.nota <= 0
                            ?
                            null
                            :
                            <DetailRequest.Assessment id="Assessment">
                                <DetailRequest.AssessmentTitle id="AssessmentTitle">Avaliação</DetailRequest.AssessmentTitle>
                                <DetailRequest.AssessmentText id="AssessmentText">
                                    <DetailRequest.AssessmentInfo id="AssessmentInfo">
                                        {
                                            Boolean(rest.mensagens.avaliacao)
                                                ?
                                                Boolean(rest.mensagens.avaliacao.nota)
                                                    ?
                                                    <DetailRequest.AssessmentInfoNote id="AssessmentInfoNote">
                                                        <ReactStars
                                                            className="no-select"
                                                            count={5}
                                                            value={(Number(rest.mensagens.avaliacao.nota === undefined ? 0 : rest.mensagens.avaliacao.nota))}
                                                            edit={false}
                                                            size={24}
                                                            color2={'#ffd700'}
                                                        />
                                                        <span>{valueAssessment(Number(rest.mensagens.avaliacao.nota))}</span>
                                                    </DetailRequest.AssessmentInfoNote>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                        {
                                            Boolean(rest.mensagens.avaliacao)
                                                ?
                                                Boolean(rest.mensagens.avaliacao.data)
                                                    ?
                                                    <DetailRequest.AssessmentInfoDate id="AssessmentInfoDate"><BiCalendarEvent /> {convertISODateToDateView(rest.mensagens.avaliacao.data)}</DetailRequest.AssessmentInfoDate>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                    </DetailRequest.AssessmentInfo>
                                    <DetailRequest.AssessmentInformations id="AssessmentInformations">
                                        <DetailRequest.AssessmentInformationsTitle id="AssessmentInformationsTitle">
                                            {
                                                Boolean(rest.mensagens.avaliacao)
                                                    ?
                                                    Boolean(rest.mensagens.avaliacao.descricao)
                                                        ?
                                                        rest.mensagens.avaliacao.descricao
                                                        :
                                                        null
                                                    :
                                                    null
                                            }
                                        </DetailRequest.AssessmentInformationsTitle>
                                        <DetailRequest.AssessmentInformationsComment id="AssessmentInformationsComment">
                                            {
                                                Boolean(rest.mensagens.avaliacao)
                                                    ?
                                                    Boolean(rest.mensagens.avaliacao.comentario)
                                                        ?
                                                        rest.mensagens.avaliacao.comentario
                                                        :
                                                        null
                                                    :
                                                    null
                                            }
                                        </DetailRequest.AssessmentInformationsComment>
                                    </DetailRequest.AssessmentInformations>
                                </DetailRequest.AssessmentText>
                            </DetailRequest.Assessment>
                    }
                </DetailRequest>
                <ItemSelected>
                    {
                        loading ? getSolicitacoesLoad() :
                            requisicoesSolicitante.filter(x => x.numeroRequisicao != rest.mensagens.id).map(requisicao =>
                                <Button className="btn-acesso-requisicao" onClick={() => routeChange(`/${window.location.pathname.indexOf('requisicaoR') > -1 ? 'requisicao' : 'requisicaoR'}/${requisicao.numeroRequisicao}`)}>
                                    <ItemSelected.ListItemContent id="Request_ListItemContent">
                                        <ItemSelected.ListItemInformations id="Requests_ListItemInformations">
                                            <ItemSelected.ListItemInfo id="Request_ListItemInfo">
                                                <ItemSelected.ListItemTitle id="Request_ListItemTitle">{capitalize(requisicao.nomeCliente)}</ItemSelected.ListItemTitle>
                                                <ItemSelected.Subject id="Requests_Subject" ><MdSubject />{requisicao.assunto !== "" ? capitalize(requisicao.assunto) : requisicao.assunto}</ItemSelected.Subject>
                                                <ItemSelected.ListItemDescription id="Request_ListItemDescription">{limitChar(100, requisicao.previaMensagem)}</ItemSelected.ListItemDescription>
                                            </ItemSelected.ListItemInfo>
                                            <ItemSelected.ListItemReq id="Request_ListItemReq">
                                                <ItemSelected.ListItemNumberRequest id="Request_ListItemNumberRequest">{requisicao.numeroRequisicaoFormatada}</ItemSelected.ListItemNumberRequest>
                                                <ItemSelected.ListItemData id="Request_ListItemData">{convertISODateToDateView(requisicao.dataAbertura)}</ItemSelected.ListItemData>
                                            </ItemSelected.ListItemReq>
                                        </ItemSelected.ListItemInformations>
                                        <ItemSelected.ListItemSubInfo id="Request_ListItemSubInfo">
                                            <ItemSelected.ListItemStatus id="Request_ListItemStatus">{requisicao.status}</ItemSelected.ListItemStatus>
                                            {
                                                requisicao.nomeAtendente && requisicao.nomeAtendente.length > 0 ? <ItemSelected.ListItemResponsavel id="ItemSelected_ListItemResponsavel"><RiMapPinUserLine id="RiMapPinUserLine" /> {requisicao.nomeAtendente}</ItemSelected.ListItemResponsavel> : <></>
                                            }
                                            {
                                                requisicao.quantidadeAnexos && requisicao.quantidadeAnexos > 0 ? <ItemSelected.ListItemAttach id="Request_ListItemAttach"><GrAttachment /> {requisicao.quantidadeAnexos} arquivos</ItemSelected.ListItemAttach> : <></>
                                            }
                                            {
                                                requisicao.quantidadeTarefas && requisicao.quantidadeTarefas > 0 ? <ItemSelected.ListItemTask id="Request_ListItemTask"><FaTasks /> {requisicao.quantidadeTarefas} tarefas</ItemSelected.ListItemTask> : <></>
                                            }
                                            {
                                                requisicao.quantidadeMensagensAposUltimoAlteracaoStatus && requisicao.quantidadeMensagensAposUltimoAlteracaoStatus > 0 ? <ItemSelected.ListItemMessage id="Request_ListItemMessage"><AiOutlineMessage /> {requisicao.quantidadeMensagensAposUltimoAlteracaoStatus} novas mensagens</ItemSelected.ListItemMessage> : <></>
                                            }
                                        </ItemSelected.ListItemSubInfo>
                                    </ItemSelected.ListItemContent>
                                </Button>
                            )
                    }
                </ItemSelected>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Fechar</Button>
            </DialogActions>
        </Dialog >
        </Draggable>
    )
}