import { Fragment, useEffect, useRef, useState } from 'react'
import clsx from "clsx"
import { useSnackbar } from 'notistack'
import { MessageTask } from './styles'
import { v4 as uuid } from 'uuid'
import Skeleton from 'react-loading-skeleton'
import { makeStyles, withStyles, CircularProgress, Button, Tooltip, IconButton, BottomNavigation, BottomNavigationAction, TextField, Drawer } from "@material-ui/core"
import { GrAttachment, AiOutlineEdit, AiOutlineSend } from 'react-icons/all'
import api from '../../../services/api'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    arrow: {
        color: 'var(--white)',
    },
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250
    },
    fullList: {
        width: "auto"
    },
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    }
}))

export default function SendMessageBoxTask({
    idTarefa,
    mensagens,
    loading,
    handleAttachment,
    handleClickOpen,
    toggleDrawer,
    state,
    value,
    setValue
}) {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const searchInput = useRef(null)
    const [inputTextMessage, setInputTextMessage] = useState('')
    const [loadingMessage, setLoadingMessage] = useState(false)

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom"
            }, 'bottom-navigation-mobile-request')}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue)
                }}
                showLabels
                className={classes.root}
            >
                <BottomNavigationAction id="send-message-mobile-request-attach" label={
                    <div id="btn-add-attachment">
                        <input
                            accept=".jpg, .png, .bmp, .gif, .pdf, .doc, .docx, .xls, .xlsx, .p7s"
                            style={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            onChange={handleAttachment}
                        />
                        <label id="label-btn-add-attachment" htmlFor="icon-button-file">
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                className="btn-send-attach"
                                onChange={handleAttachment}
                                disableRipple={true}
                                disableFocusRipple={true}
                            >
                                <GrAttachment id="icon-attach-message" />
                                <span>Anexar Arquivos</span>
                            </IconButton>
                        </label>
                    </div>
                } />
                <BottomNavigationAction onClick={handleClickOpen} id="send-message-mobile-request-editor" label="Enviar Mens. C/ Editor" icon={<AiOutlineEdit />} />
            </BottomNavigation>
        </div>
    )

    const handleSubmitMessage = async () => {
        setLoadingMessage(true)

        let data = new FormData()
        data.append('TarefaId', String(idTarefa))
        data.append('Mensagem', searchInput.current.value)
        data.append('TipoPrivacidade', 1)

        await api({
            method: 'post',
            url: '/jucerja/v1/mensagens/tarefa',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        })
            .then(response => {
                setInputTextMessage('')
            })
            .catch(error => {
                setLoadingMessage(false)
                switch (error.response.status) {
                    case 422:
                        enqueueSnackbar(error.response.data.messages[0].mensagem, { variant: 'error' })
                        break
                    default:
                        enqueueSnackbar('Não foi possível enviar sua mensagem. Por favor tente novamente', { variant: 'error' })
                        break
                }
            })
            .finally(() => setTimeout(() => {
                setLoadingMessage(false);
                searchInput.current.value = '';
                searchInput.current.focus();
            }, 500))
    }

    const handleKeyDownMessage = event => {
        if (event.key === 'Enter') {
            handleSubmitMessage()
        }
    }

    const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const files = clipboardData.files;
        if (files && files.length > 0) {
            
          handleAttachment({target:{files}}, '');
        }
      };

    useEffect(() => {
        searchInput.current.focus();
        document.addEventListener('paste', handlePaste);
        return () => {
          document.removeEventListener('paste', handlePaste);
        };
      }, []);

    return (
        loading
            ?
            <MessageTask.SendFastMessage id="Request_SendFastMessage">
                <MessageTask.SendFastMessageContent id="Request_SendFastMessageContent" type={loading ? 'loading' : 'noloading'}>
                    <MessageTask.LoadingSendMessageBox id="Request_LoadingSendMessageBox">
                        <MessageTask.LoadingSendMessageBoxCircle id="Request_LoadingSendMessageBoxCircle"><Skeleton width={42} height={42} /></MessageTask.LoadingSendMessageBoxCircle>
                        <MessageTask.LoadingSendMessageBoxCircle id="Request_LoadingSendMessageBoxCircle"><Skeleton width={42} height={42} /></MessageTask.LoadingSendMessageBoxCircle>
                        <MessageTask.LoadingSendMessageBoxField id="MessageTask.LoadingSendMessageBoxField"><Skeleton /></MessageTask.LoadingSendMessageBoxField>
                        <MessageTask.LoadingSendMessageBoxCircle id="Request_LoadingSendMessageBoxCircle"><Skeleton width={42} height={42} /></MessageTask.LoadingSendMessageBoxCircle>
                    </MessageTask.LoadingSendMessageBox>
                </MessageTask.SendFastMessageContent>
            </MessageTask.SendFastMessage>
            :
            <MessageTask.SendFastMessage id="Request_SendFastMessage">
                <MessageTask.SendFastMessageContent id="Request_SendFastMessageContent" type={loading ? 'loading' : 'noloading'}>
                    {mensagens.acoesPermitidas.map((acao, index) => (
                        acao === "EnviarMensagem"
                            ?
                            <Fragment key={"EnviarMensagemTask"}>
                                <MessageTask.SendFastMessageGroupButtons id="Request_SendFastMessageGroupButtons">
                                    <HtmlTooltip title="Anexar Arquivos" placement="top">
                                        <div id="btn-add-attachment">
                                            <input
                                                accept=".jpg, .png, .bmp, .gif, .pdf, .doc, .docx, .xls, .xlsx, .p7s"
                                                style={{ display: "none" }}
                                                id="icon-button-file"
                                                type="file"
                                                multiple
                                                onChange={e => handleAttachment(e, inputTextMessage)}
                                            />
                                            <label htmlFor="icon-button-file">
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    className="btn-send-attach"
                                                    onChange={e => handleAttachment(e, inputTextMessage)}
                                                >
                                                    <GrAttachment id="icon-attach-message" />
                                                </IconButton>
                                            </label>
                                        </div>
                                    </HtmlTooltip>
                                    <HtmlTooltip title="Enviar Mens. C/ Editor" placement="top" onClick={handleClickOpen}>
                                        <Button className="btn-editor-message"><AiOutlineEdit id="icon-attach-image-message" /></Button>
                                    </HtmlTooltip>
                                </MessageTask.SendFastMessageGroupButtons>
                                <MessageTask.SendFastMessageBtnDialogMobileMessage id="Request_SendFastMessageBtnDialogMobileMessage">
                                    {["bottom"].map((anchor) => (
                                        <Fragment key={anchor}>
                                            <Button className="btn-send-message-fast-request-mobile btnDefault" onClick={toggleDrawer(anchor, true)}><GrAttachment id="icon-send-message" /></Button>
                                            <Drawer
                                                anchor={anchor}
                                                open={state[anchor]}
                                                onClose={toggleDrawer(anchor, false)}
                                            >
                                                {list(anchor)}
                                            </Drawer>
                                        </Fragment>
                                    ))}
                                </MessageTask.SendFastMessageBtnDialogMobileMessage>
                                <MessageTask.SendFastMessageBoxMessage id="Request_SendFastMessageBoxMessage">
                                    <TextField
                                        id="txt-send-message-request"
                                        variant="outlined"
                                        onKeyPress={handleKeyDownMessage}
                                        placeholder="Digite uma mensagem"
                                        disabled={loadingMessage}
                                        inputRef={searchInput}
                                    />
                                </MessageTask.SendFastMessageBoxMessage>
                                <MessageTask.SendFastMessageBtnSendMessage id="Request_SendFastMessageBtnSendMessage">
                                    {
                                        loadingMessage
                                            ?
                                            <Button className="btn-send-message-fast-request btnDefault btnDefaultPrimary btn-loading-send-message-fast-request">
                                                <CircularProgress size={22} />
                                            </Button>
                                            :
                                            <Button
                                                onClick={() => handleSubmitMessage()}
                                                className="btn-send-message-fast-request btnDefault btnDefaultPrimary"
                                            >
                                                <AiOutlineSend id="icon-send-message" />
                                            </Button>
                                    }
                                </MessageTask.SendFastMessageBtnSendMessage>
                            </Fragment>
                            :
                            null
                    ))}
                </MessageTask.SendFastMessageContent>
            </MessageTask.SendFastMessage>
    )
}