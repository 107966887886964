import { useState, useEffect, forwardRef, useRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Select from "@material-ui/core/Select"
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'
import { DialogSetor } from './styles'
import api from '../../../services/api'
import TextField from "@material-ui/core/TextField"
import { Autocomplete, Chip, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid'
import { setRef, Grid } from '@material-ui/core'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogSetorConfirm({
    openDialogSetor,
    listaDeSetoresDesconsiderar,
    setOpenDialogSetor,
    setSetorConfirmation,
    alterarSetorSelecionada,
    setAlterarSetorSelecionada
}) {
    const [errorSetor, setErrorSetor] = useState(false);
    const [setor, setSetor] = useState({ idSetor: undefined, descricao: '', ehSupervisor: false, respondeTarefa: false });
    const [listaSetores, setListaSetores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [limpar, setLimpar] = useState(false);

    const handleChange = (e, v) => {
        setErrorSetor(false);
        setSetor({ ...setor, idSetor: v ? v?.id : undefined, descricao: v ? v?.descricao : '' })
    }

    const handleClose = (e) => {
        e.preventDefault()

        if (loading) return;

        setOpenDialogSetor(false)
        setAlterarSetorSelecionada(undefined);
    }

    const getSetores = async () => {

        await api.get('/jucerja/v1/setor/setores-ativos')
            .then(response => {
                setListaSetores(response.data.filter(x => !listaDeSetoresDesconsiderar.some(y => y.id == x.id)))
            })
    }

    const handleConfirm = async (e) => {
        e?.preventDefault();

        if (!setor.idSetor) {
            setErrorSetor(true);
            return;
        }

        setLoading(true);
        setSetorConfirmation(setor);
    }

    const valorCarregado = () => {
        if (alterarSetorSelecionada && listaSetores.length > 0)
            return listaDeSetoresDesconsiderar.filter(x => x.id == alterarSetorSelecionada.id)[0];

        return null
    }

    useEffect(() => {
        if (openDialogSetor) {
            setLimpar(!limpar)
            if (alterarSetorSelecionada) {
                setSetor({ idSetor: alterarSetorSelecionada.id, descricao: alterarSetorSelecionada.descricao, ehSupervisor: alterarSetorSelecionada.supervisor, respondeTarefa: alterarSetorSelecionada.respondeTarefa });
            } else {
                setSetor({ idSetor: undefined, descricao: '', ehSupervisor: false, respondeTarefa: false });
            }

            getSetores()
        } else {
            setLoading(false);
        }
    }, [openDialogSetor])

    useEffect(() => {
        if (!alterarSetorSelecionada)
            return;

        setOpenDialogSetor(true);

    }, [alterarSetorSelecionada])

    return (
        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogSetor}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-setor"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">{alterarSetorSelecionada ? "Alterar setor" : "Selecionar setor"}</DialogTitle>
            <DialogContent>
                <DialogSetor id="DialogSetor">
                    <DialogSetor.Container id="DialogSetor_Container">
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {
                                    alterarSetorSelecionada
                                        ?
                                        < Autocomplete
                                            key={limpar}
                                            title='Setores'
                                            id="tags-standard"
                                            options={listaSetores}
                                            disabled
                                            value={valorCarregado()}
                                            getOptionLabel={(option) => option.descricao}
                                            onChange={(e, v) => handleChange(e, v)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={errorSetor}
                                                    variant="standard"
                                                    label="Selecione o setor"
                                                />
                                            )}
                                        />
                                        :

                                        < Autocomplete
                                            key={limpar}
                                            title='Setores'
                                            id="tags-standard"
                                            options={listaSetores}
                                            getOptionLabel={(option) => option.descricao}
                                            onChange={(e, v) => handleChange(e, v)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={errorSetor}
                                                    variant="standard"
                                                    label="Selecione o setor"
                                                />
                                            )}
                                        />
                                }

                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControlLabel control={<Checkbox checked={setor.ehSupervisor} onChange={e => setSetor({ ...setor, ehSupervisor: e.currentTarget.checked })} color='default' />} label="Supervisor" />
                                <FormControlLabel control={<Checkbox checked={setor.respondeTarefa} onChange={e => setSetor({ ...setor, respondeTarefa: e.currentTarget.checked })} color='default' />} label="Apto para Receber Tarefa" />
                            </Grid>
                        </Grid>
                    </DialogSetor.Container>
                </DialogSetor>
            </DialogContent>
            <DialogActions>
                <DialogSetor.Actions id="DialogSetor.Actions">
                    <div className={!loading ? "mostrarAnimacao" : "esconderAnimacao"}>
                        <Button onClick={handleClose} className="btn-close-actions">Cancelar</Button>
                    </div>
                    {
                        loading
                            ?
                            <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                            :
                            <Button className="btn-primary-actions" onClick={handleConfirm}>Confirmar</Button>
                    }

                </DialogSetor.Actions>
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}