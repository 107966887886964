import { useState, forwardRef, useEffect, useRef } from "react";
import Container from "../../../components/layout/Container";

import { Badge } from "../../../components/elements-ui/Badge";
import { useSnackbar } from "notistack";
import FormControl from "@material-ui/core/FormControl";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ActionTable } from "../../../components/elements-ui/ActionTable";
import Avatar from "@mui/material/Avatar";
import {
  BsInfoCircleFill,
  BsFillStarFill,
  FaUser,
  FaUserClock,
  HiOutlinePlusSmall,
  RxLinkNone2,
  FaWindowRestore,
  RxLinkBreak2,
  FaUserCheck,
  FaUserAlt,
  BsFillClockFill,
  AiOutlineDelete,
  MdTableRows,
  FaUserTimes,
  BsFillClipboard2CheckFill,
  BsFillCheckCircleFill,
  FaUserLock,
  FaUserTie,
  AiOutlineEdit,
  BiLinkExternal,
} from "react-icons/all";
import { ComponenteFuncionario } from "./styles";
import CountUp from "react-countup";
import {
  Button,
  Grid,
  Popper,
  Grow,
  Slide,
  ClickAwayListener,
  MenuList,
  MenuItem,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { Card } from "../../../components/elements-ui/Card";
import DialogConfirmation from "../../../components/elements-ui/DialogConfirmation";
import DialogSetorConfirm from "../../../components/elements-ui/DialogSetorConfirm";
import DialogAssuntoConfirm from "../../../components/elements-ui/DialogAssuntoConfirm";
import DialogAusenciaConfirm from "../../../components/elements-ui/DialogAusenciaConfirm";
import { Kpi } from "../../../components/elements-ui/KPI";
import api from "../../../services/api";
import { convertISODateToDateView } from "../../../functions/index";
import Tooltip from "@material-ui/core/Tooltip";

import { useHistory, Link } from "react-router-dom";

import { BiArrowBack } from "react-icons/all";
import DialogFuncionario from "../../../components/elements-ui/DialogFuncionario";
import { fontSize } from "@mui/system";

const PrimaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--primary)",
    color: "var(--white)",
  },
}))(Tooltip);

const statusStyle = {
  ATIVO: {
    border: "2px solid var(--green)",
    backgroundColor: "var(--green)",
    color: "white",
  },
  INATIVO: {
    border: "2px solid var(--red)",
    backgroundColor: "var(--red)",
    color: "white",
  },
  AUSENTE: {
    border: "2px solid var(--blue)",
    backgroundColor: "var(--blue)",
    color: "white",
  },
  FERIAS: {
    border: "2px solid #1c8ccb",
    backgroundColor: "#1c8ccb",
    color: "white",
  },
};

const PrimaryTooltipCards = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--white)",
    color: "var(--black)",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  solicitacoes: {
    color: "var(--dark-yellow)",
  },
  solicitacoesAguardandoSolicitante: {
    color: "var(--dark-orange)",
  },
  solicitacoesEmAndamento: {
    color: "var(--dark-blue)",
  },
  solicitacoesAguardandoTarefas: {
    color: "var(--dark-gree)",
  },
  solicitacoesVerificacaoInterna: {
    color: "var(--red)",
  },
  solicitacoesAtrasadas: {
    color: "var(--grey)",
  },
}));

const useAdicionar = makeStyles((theme) => ({
  span: {
    margin: "2px 2px 2px 5px;",
    fontWeight: "400",
    backgroundColor: "#4354a6",
    borderRadius: "1000px",
    color: "var(--white)",
    padding: "0px 7px 0 7px",
    fontSize: "1.2rem",
    userSelect: "none",
    cursor: "pointer",
    "& .adicionar": {
      display: "inline-block",
      verticalAlign: "sub",
      maxWidth: "0",
      opacity: "0",
      transition: "max-width 0.2s, opacity 0.2s",
    },
    "&:hover": {
      "& .adicionar": {
        verticalAlign: "sub",
        maxWidth: "200px",
        opacity: "1",
        transition: "max-width 0.5s, opacity 0.2s",
      },
    },
  },
}));

export default function Funcionario(props) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const classesIcons = useStyles();
  const idFuncionario = props.match.params.id;
  const [funcionario, setFuncionario] = useState({});
  const classesAdicionar = useAdicionar();
  const [carregarInfoFuncionario, setCarregarInfoFuncionario] = useState(false);

  const [rowSetorSelecionado, setRowSetorSelecionado] = useState(undefined);

  const [alterarSetorSelecionada, setAlterarSetorSelecionada] =
    useState(undefined);
  const [abrirSelecaoSetor, setAbrirSelecaoSetor] = useState(false);

  const [abrirMensagemConfirmacaoSetor, setAbrirMensagemConfirmacaoSetor] =
    useState(false);
  const [confirmacaoSetor, setConfirmacaoSetor] = useState(false);
  const [confirmacaoSetorTwo, setConfirmacaoSetorTwo] = useState(false);
  const [setorAdicionar, setSetorAdicionar] = useState(undefined);

  const [rowAssuntoSelecionado, setRowAssuntoSelecionado] = useState(undefined);
  const [abrirMensagemConfirmacaoAssunto, setAbrirMensagemConfirmacaoAssunto] =
    useState(false);
  const [confirmacaoAssunto, setConfirmacaoAssunto] = useState(false);
  const [confirmacaoAssuntoTwo, setConfirmacaoAssuntoTwo] = useState(false);
  const [abrirSelecaoAssunto, setAbrirSelecaoAssunto] = useState(false);
  const [assuntoAdicionar, setAssuntoAdicionar] = useState(false);

  const [rowAusenciaSelecionado, setRowAusenciaSelecionado] =
    useState(undefined);
  const [alterarAusenciaSelecionada, setAlterarAusenciaSelecionada] =
    useState(undefined);
  const [
    abrirMensagemConfirmacaoAusencia,
    setAbrirMensagemConfirmacaoAusencia,
  ] = useState(false);
  const [confirmacaoAusencia, setConfirmacaoAusencia] = useState(false);
  const [abrirSelecaoAusencia, setAbrirSelecaoAusencia] = useState(false);
  const [ausenciaAdicionar, setAusenciaAdicionar] = useState(undefined);

  const [solicitacoesAbertas, setSolicitacoesAbertas] = useState();
  const [
    solicitacoesAguardandoSolicitante,
    setSolicitacoesAguardandoSolicitante,
  ] = useState();
  const [solicitacoesEmAndamento, setSolicitacoesEmAndamento] = useState();
  const [solicitacoesVerificacaoInterna, setSolicitacoesVerificacaoInterna] =
    useState();
  const [solicitacoesAguardandoTarefas, setSolicitacoesAguardandoTarefas] =
    useState();
  const [solicitacoesFinalizadasMes, setSolicitacoesFinalizadasMes] =
    useState();

  const [openDialogFuncionario, setOpenDialogFuncionario] = useState(false);

  const [ultimasAvaliacoes, setUltimasAvaliacoesFuncionario] = useState([]);

  const payloadQuantAberto = {
    filtros: [
      { Coluna: [4], Comparador: 0, Valores: [`${idFuncionario}`] },
      { Coluna: [2], Comparador: 0, Valores: ["1"] },
    ],
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };
  const payloadAguarSolicitante = {
    filtros: [
      { Coluna: [4], Comparador: 0, Valores: [`${idFuncionario}`] },
      { Coluna: [2], Comparador: 0, Valores: ["4"] },
    ],
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };
  const payloadAndamento = {
    filtros: [
      { Coluna: [4], Comparador: 0, Valores: [`${idFuncionario}`] },
      { Coluna: [2], Comparador: 0, Valores: ["2"] },
    ],
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };
  const payloadAguarTarefas = {
    filtros: [{ Coluna: [4], Comparador: 0, Valores: [`${idFuncionario}`] }],
    Opcao: 7,
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };
  const payloadVerificacaoIntern = {
    filtros: [
      { Coluna: [4], Comparador: 0, Valores: [`${idFuncionario}`] },
      { Coluna: [2], Comparador: 0, Valores: ["6"] },
    ],
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };
  const payloadFinalizadasMes = {
    filtros: [{ Coluna: [4], Comparador: 0, Valores: [`${idFuncionario}`] }],
    Opcao: 6,
    firstResult: 1,
    maxResults: 10,
    gerarSomenteQuantidade: true,
    paginaAtual: 1,
    qtdeRegistros: 10,
  };

  const removerAssuntoFuncionario = (evento) => {
    if (rowAssuntoSelecionado) setAbrirMensagemConfirmacaoAssunto(true);
  };

  const removerAusenciaFuncionario = (evento) => {
    if (rowAusenciaSelecionado) setAbrirMensagemConfirmacaoAusencia(true);
  };

  const alterarAusenciaFuncionario = (evento) => {
    setAlterarAusenciaSelecionada(rowAusenciaSelecionado);
  };

  const alterarSetorFuncionario = (evento) => {
    setAlterarSetorSelecionada(rowSetorSelecionado);
  };

  const retireSetorSelecionado = () => {
    const idSetor = rowSetorSelecionado.id;
    let funcionarioAlterado = { ...funcionario };

    funcionarioAlterado.setores = funcionarioAlterado.setores.filter(
      (x) => x.id != idSetor
    );
    funcionarioAlterado.assuntos = funcionarioAlterado.assuntos.filter(
      (x) => x.setorId != idSetor
    );

    setFuncionario(funcionarioAlterado);
    setRowSetorSelecionado(undefined);

    if (rowAssuntoSelecionado && rowAssuntoSelecionado.setorId == idSetor)
      setRowAssuntoSelecionado(undefined);
  };

  const retireAssuntoSelecionada = () => {
    const idAssunto = rowAssuntoSelecionado.id;
    let funcionarioAlterado = { ...funcionario };

    funcionarioAlterado.assuntos = funcionarioAlterado.assuntos.filter(
      (x) => x.id != idAssunto
    );

    setFuncionario(funcionarioAlterado);
    setRowAssuntoSelecionado(undefined);
  };

  const retireAusenciaSelecionada = () => {
    const idAusencia = rowAusenciaSelecionado.id;
    let funcionarioAlterado = { ...funcionario };

    funcionarioAlterado.ausenciasFerias =
      funcionarioAlterado.ausenciasFerias.filter((x) => x.id != idAusencia);

    setFuncionario(funcionarioAlterado);
    setRowAusenciaSelecionado(undefined);
  };

  const obtenhaObjetoPadraoSetor = (
    idSetor,
    ehSupervisor = false,
    respondeTarefa = false
  ) => {
    const objAcaoSetor = {
      funcionarioId: idFuncionario,
      setores: [
        {
          setor: idSetor,
          supervisor: ehSupervisor,
        },
      ],
      redistribuir: confirmacaoSetorTwo,
      respondeTarefa: respondeTarefa,
    };

    return objAcaoSetor;
  };

  const obterDashBoardFuncionario = async () => {
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadQuantAberto)
      .then((response) => setSolicitacoesAbertas(response.data.totalRecords));
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadAguarSolicitante)
      .then((response) =>
        setSolicitacoesAguardandoSolicitante(response.data.totalRecords)
      );
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadAndamento)
      .then((response) =>
        setSolicitacoesEmAndamento(response.data.totalRecords)
      );
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadAguarTarefas)
      .then((response) =>
        setSolicitacoesAguardandoTarefas(response.data.totalRecords)
      );
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadVerificacaoIntern)
      .then((response) =>
        setSolicitacoesVerificacaoInterna(response.data.totalRecords)
      );
    api
      .post(`/jucerja/v1/requisicoes/filtro`, payloadFinalizadasMes)
      .then((response) =>
        setSolicitacoesFinalizadasMes(response.data.totalRecords)
      );
  };

  const obterFuncionario = () => {
    api
      .get(`/jucerja/v1/funcionario/dados_funcionario/${idFuncionario}`)
      .then((response) => {
        setCarregarInfoFuncionario(true);
        setFuncionario(response.data);
      });
  };

  const obterUltimasAvaliacaoFuncionario = () => {
    api
      .get(
        `/jucerja/v1/historicovisualizacao/ultimas-avaliacoes?idFuncionario=${idFuncionario}`
      )
      .then((response) =>
        setUltimasAvaliacoesFuncionario(response.data.retorno)
      );
  };

  useEffect(() => {
    obterFuncionario();
    obterDashBoardFuncionario();
    obterUltimasAvaliacaoFuncionario();
  }, []);

  useEffect(() => {
    if (!confirmacaoSetor && !confirmacaoSetorTwo) return;

    api
      .post(
        `/jucerja/v1/funcionario/remover-funcionario-setores`,
        obtenhaObjetoPadraoSetor(rowSetorSelecionado.id)
      )
      .then((response) => {
        retireSetorSelecionado();
        enqueueSnackbar("Setor desvinculado com sucesso.", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Houve um erro ao desvincular o setor.", {
          variant: "error",
        });
      })
      .finally(() => {
        setAbrirMensagemConfirmacaoSetor(false);
      });

    setConfirmacaoSetor(false);
    setConfirmacaoSetorTwo(false);
  }, [confirmacaoSetor, confirmacaoSetorTwo]);

  useEffect(() => {
    if (!setorAdicionar) return;

    let link = "cadastro-funcionario-setores";
    let method = "post";
    let obtenhaObjetoPadrao = obtenhaObjetoPadraoSetor(
      setorAdicionar.idSetor,
      setorAdicionar.ehSupervisor,
      setorAdicionar.respondeTarefa
    );

    if (alterarSetorSelecionada) {
      link = "alterar-funcionario-setores";
      method = "put";
    }

    api[method](`/jucerja/v1/funcionario/${link}`, obtenhaObjetoPadrao)
      .then((response) => {
        let listaSetores = [...funcionario.setores];
        let entidade = {
          id: setorAdicionar.idSetor,
          supervisor: setorAdicionar.ehSupervisor,
          descricao: setorAdicionar.descricao,
          respondeTarefa: setorAdicionar.respondeTarefa,
        };

        listaSetores.push(entidade);

        setFuncionario({ ...funcionario, setores: listaSetores });

        if (alterarSetorSelecionada) {
          setRowSetorSelecionado(entidade);
        }

        enqueueSnackbar(
          alterarSetorSelecionada
            ? "Setor alterado com sucesso."
            : "Setor vinculado com sucesso.",
          { variant: "success" }
        );
      })
      .catch(() => {
        enqueueSnackbar("Houve um erro ao alterar/vincular o setor.", {
          variant: "error",
        });
      })
      .finally(() => {
        setAbrirSelecaoSetor(false);
        setSetorAdicionar(undefined);
        setAlterarSetorSelecionada(undefined);
      });
  }, [setorAdicionar]);

  useEffect(() => {
    if (!assuntoAdicionar) return;

    api
      .post(`/jucerja/v1/funcionario/funcionarioassunto`, {
        funcionarioId: idFuncionario,
        assuntos: [assuntoAdicionar.id],
      })
      .then((response) => {
        let listaAssuntos = [...funcionario.assuntos];

        listaAssuntos.push({
          ...assuntoAdicionar,
          relacaoId: response.data.pop().id,
        });

        setFuncionario({ ...funcionario, assuntos: listaAssuntos });

        enqueueSnackbar("Assunto vinculado com sucesso.", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Houve um erro ao alterar/vincular o assunto.", {
          variant: "error",
        });
      })
      .finally(() => {
        setAbrirSelecaoAssunto(false);
        setAssuntoAdicionar(undefined);
      });
  }, [assuntoAdicionar]);

  useEffect(() => {
    if (!confirmacaoAssunto && !confirmacaoAssuntoTwo) return;

    api
      .delete(
        `/jucerja/v1/funcionario/funcionarioassunto/${
          rowAssuntoSelecionado.relacaoId
        }${confirmacaoAssuntoTwo ? "?redistribuirRequisicoes=true" : ""}`
      )
      .then((response) => {
        retireAssuntoSelecionada();

        enqueueSnackbar("Assunto desvinculado com sucesso.", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Houve um erro ao desvincular o assunto.", {
          variant: "error",
        });
      })
      .finally(() => {
        setAbrirMensagemConfirmacaoAssunto(false);
      });

    setConfirmacaoAssunto(false);
    setConfirmacaoAssuntoTwo(false);
  }, [confirmacaoAssunto, confirmacaoAssuntoTwo]);

  useEffect(() => {
    if (!ausenciaAdicionar) return;

    api[alterarAusenciaSelecionada ? "put" : "post"](
      `/jucerja/v1/funcionario/ausenciaferias`,
      ausenciaAdicionar
    )
      .then((response) => {
        let listaAusencias = [...funcionario.ausenciasFerias];

        if (alterarAusenciaSelecionada)
          listaAusencias = listaAusencias.filter(
            (x) => x.id != alterarAusenciaSelecionada.id
          );

        listaAusencias.push({
          dataInicio: ausenciaAdicionar.dataInicio,
          dataFim: ausenciaAdicionar.dataFim,
          descricao:
            ausenciaAdicionar.tipoAusenciaFerias == 1 ? "Férias" : "Ausência",
          justificativa: ausenciaAdicionar.justificativa,
          id: response.data.id,
        });

        setFuncionario({ ...funcionario, ausenciasFerias: listaAusencias });

        enqueueSnackbar(
          alterarAusenciaSelecionada
            ? "Ausência/Férias alterada com sucesso."
            : "Ausência/Férias cadastrada com sucesso.",
          { variant: "success" }
        );
      })
      .catch((ex) => {
        if (ex && ex.response && ex.response.status == 422) {
          ex.response.data.messages.forEach((element) => {
            enqueueSnackbar(element.campos[0].Mensagem, { variant: "error" });
          });
        } else
          enqueueSnackbar(
            "Houve um erro ao alterar/cadastrar ausência/férias.",
            { variant: "error" }
          );
      })
      .finally(() => {
        setAbrirSelecaoAusencia(false);
        setAusenciaAdicionar(undefined);
        setAlterarAusenciaSelecionada(undefined);
        setRowAusenciaSelecionado(undefined);
      });
  }, [ausenciaAdicionar]);

  useEffect(() => {
    if (!confirmacaoAusencia) return;

    api
      .delete(
        `/jucerja/v1/funcionario/ausenciaferias/${rowAusenciaSelecionado.id}`
      )
      .then((response) => {
        retireAusenciaSelecionada();

        enqueueSnackbar("Ausência/Férias removida com sucesso.", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Houve um erro ao remover ausência/férias.", {
          variant: "error",
        });
      })
      .finally(() => {
        setAbrirMensagemConfirmacaoAusencia(false);
      });

    setConfirmacaoAusencia(false);
  }, [confirmacaoAusencia]);

  return (
    <ComponenteFuncionario>
      <DialogConfirmation
        openDialog={abrirMensagemConfirmacaoSetor}
        setOpenDialog={setAbrirMensagemConfirmacaoSetor}
        setConfirm={setConfirmacaoSetor}
        // setConfirmTwo={setConfirmacaoSetorTwo}
        // txtConfirmTwo="Confirmar e Redistribuir"
        txtConfirm="Confirmar"
        txtCancel="Cancelar"
        txtTitle="Confirmar desvínculo do setor"
        txtContainer={`Deseja realmente desvincular o setor '${
          rowSetorSelecionado ? rowSetorSelecionado.descricao : ""
        }' ?`}
        closeControlOutsideOnConfirm={true}
      />

      <DialogFuncionario
        openDialogFuncionario={openDialogFuncionario}
        setOpenDialogFuncionario={setOpenDialogFuncionario}
        codigoFuncionarioSelecionado={funcionario.id}
        funcaoCallBack={obterFuncionario}
      />

      <DialogConfirmation
        openDialog={abrirMensagemConfirmacaoAssunto}
        setOpenDialog={setAbrirMensagemConfirmacaoAssunto}
        // txtConfirmTwo="Confirmar e Redistribuir"
        setConfirm={setConfirmacaoAssunto}
        // setConfirmTwo={setConfirmacaoAssuntoTwo}
        txtConfirm="Confirmar"
        txtCancel="Cancelar"
        txtTitle="Confirmar desvínculo do assunto"
        txtContainer={`Deseja realmente desvincular o assunto '${
          rowAssuntoSelecionado ? rowAssuntoSelecionado.descricao : ""
        }' ?`}
        closeControlOutsideOnConfirm={true}
      />

      <DialogConfirmation
        openDialog={abrirMensagemConfirmacaoAusencia}
        setOpenDialog={setAbrirMensagemConfirmacaoAusencia}
        setConfirm={setConfirmacaoAusencia}
        txtConfirm="Confirmar"
        txtCancel="Cancelar"
        txtTitle="Confirmar exclusão da ausência/férias"
        txtContainer={`Deseja realmente excluir a '${
          rowAusenciaSelecionado?.descricao
        } - ${convertISODateToDateView(
          rowAusenciaSelecionado?.dataInicio,
          true
        )} ${
          rowAusenciaSelecionado?.dataFim
            ? `A ${convertISODateToDateView(
                rowAusenciaSelecionado?.dataFim,
                true
              )}`
            : ""
        }' ?`}
        closeControlOutsideOnConfirm={true}
      />

      <DialogSetorConfirm
        openDialogSetor={abrirSelecaoSetor}
        setOpenDialogSetor={setAbrirSelecaoSetor}
        setSetorConfirmation={setSetorAdicionar}
        listaDeSetoresDesconsiderar={funcionario.setores}
        alterarSetorSelecionada={alterarSetorSelecionada}
        setAlterarSetorSelecionada={setAlterarSetorSelecionada}
      />

      <DialogAssuntoConfirm
        openDialogAssunto={abrirSelecaoAssunto}
        setOpenDialogAssunto={setAbrirSelecaoAssunto}
        listaDeSetores={funcionario.setores}
        listaDeAssuntosDesconsiderar={funcionario.assuntos}
        setAssuntoConfirmation={setAssuntoAdicionar}
      />

      <DialogAusenciaConfirm
        openDialogAusencia={abrirSelecaoAusencia}
        setOpenDialogAusencia={setAbrirSelecaoAusencia}
        setAusenciaConfirmation={setAusenciaAdicionar}
        idFuncionario={idFuncionario}
        alterarAusenciaSelecionada={alterarAusenciaSelecionada}
        setAlterarAusenciaSelecionada={setAlterarAusenciaSelecionada}
      />

      <Container
        title={
          <>
            <Slide direction="down" in={funcionario?.id}>
              <Grid container direction="row" style={{ paddingTop: "20px" }}>
                <Button
                  onClick={() => history.goBack()}
                  className="btn-arrow-back"
                >
                  <BiArrowBack />{" "}
                </Button>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  lg={1}
                  xl={1}
                  style={{ minWidth: "140px" }}
                  alignContent="center"
                >
                  <Avatar
                    alt={funcionario.nome}
                    sx={{
                      width: 130,
                      height: 130,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    style={statusStyle[funcionario.status?.toUpperCase()]}
                  >
                    <span style={{ fontSize: "70px" }}>
                      {funcionario.nome
                        ? `${funcionario.nome.trim().split(" ")[0][0]}${
                            funcionario.nome.trim().split(" ")[
                              funcionario.nome.trim().split(" ").length - 1
                            ][0]
                          }`.toUpperCase()
                        : ""}
                    </span>
                  </Avatar>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
                  <Stack paddingBottom={1} direction="column" spacing={-0.5}>
                    <Typography
                      paddingBottom={0}
                      noWrap
                      gutterBottom
                      variant="h3"
                      component="div"
                      color="black"
                    >
                      <PrimaryTooltipCards title={funcionario.nome}>
                        <b
                          style={{
                            textOverflow: "ellipsis",
                            paddingRight: "10px",
                            overflow: "hidden",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {funcionario.nome}
                        </b>
                      </PrimaryTooltipCards>

                      {funcionario.nota && parseInt(funcionario.nota) > 0 ? (
                        <Chip
                          style={{
                            border: "1px solid #e5a900",
                            marginLeft: "10px",
                            backgroundColor: "#e5a900",
                            color: "white",
                          }}
                          size="small"
                          variant="filled"
                          label={
                            <>
                              <BsFillStarFill
                                style={{ marginTop: "3px", color: "white" }}
                              />{" "}
                              <span>
                                {funcionario.nota ? funcionario.nota : ""}
                              </span>
                            </>
                          }
                        />
                      ) : (
                        ""
                      )}

                      <Stack
                        paddingBottom={1}
                        direction="row"
                        spacing={2}
                      ></Stack>
                    </Typography>
                    <Typography
                      fontSize={19}
                      noWrap
                      gutterBottom
                      variant="subtitle2"
                      component="span"
                      color="black"
                    >
                      <b
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {funcionario.email}
                      </b>
                    </Typography>
                    <Typography
                      fontSize={19}
                      noWrap
                      gutterBottom
                      variant="subtitle2"
                      component="span"
                      color="black"
                    >
                      <b
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        Login:
                      </b>
                      {funcionario.login}
                    </Typography>
                    <Typography
                      fontSize={19}
                      noWrap
                      gutterBottom
                      variant="subtitle2"
                      component="span"
                      color="black"
                    >
                      <b
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        Perfil:
                      </b>
                      {funcionario?.perfis?.length > 0
                        ? funcionario.perfis
                            .map((per) => per.descricao)
                            .join(", ")
                        : " -"}
                    </Typography>
                  </Stack>
                </Grid>
                <Chip
                  style={statusStyle[funcionario.status?.toUpperCase()]}
                  size="small"
                  variant="filled"
                  label={
                    funcionario.status?.toUpperCase() == "ATIVO"
                      ? "Ativo"
                      : funcionario.status?.toUpperCase() == "FERIAS"
                      ? "Férias"
                      : funcionario.status?.toUpperCase() == "AUSENTE"
                      ? "Ausente"
                      : "Inativo"
                  }
                />
              </Grid>
            </Slide>
            <Button
              onClick={() => setOpenDialogFuncionario(true)}
              className="btn-backround-primary-actions"
              style={{
                background: "var(--primary)!important",
                float: "inline-end",
              }}
            >
              Alterar Funcionário
            </Button>
          </>
        }
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ComponenteFuncionario.KPI id="Kpi">
            <Slide direction="up" in={solicitacoesAbertas > -1}>
              <PrimaryTooltipCards
                title={
                  <div
                    style={{
                      fontSize: "17px",
                      display: "flex",
                      lineHeight: "normal",
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        fontSize: "25px",
                        color: "var(--primary)",
                      }}
                    >
                      <BsInfoCircleFill />
                    </div>
                    <div>
                      Solicitações que estejam com status igual a Aberta.
                    </div>
                  </div>
                }
                placement="top"
              >
                <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                  <Link
                    to={`/requisicoes/(${JSON.stringify({
                      ...payloadQuantAberto,
                      gerarSomenteQuantidade: false,
                      gerarDadosComplementares: true,
                    })})`}
                  >
                    <ComponenteFuncionario.KPI_Content
                      id="Kpi_Content"
                      className="mouse_kpi"
                    >
                      <Kpi.Icon id="Kpi_Icon" className="icon_size">
                        <FaUser
                          id="RiUserUnfollowLine"
                          className={classesIcons.solicitacoes}
                        />
                      </Kpi.Icon>
                      <Kpi.Counter
                        id="Kpi.Counter"
                        className="count_center RiUserUnfollowLine"
                      >
                        <CountUp
                          start={0}
                          end={solicitacoesAbertas}
                          duration={2.75}
                          separator="."
                          id="RiUserUnfollowLine"
                        />
                      </Kpi.Counter>
                      <Kpi.Title id="Kpi_Title" className="title_bottom_count">
                        Solicitações Abertas
                      </Kpi.Title>
                    </ComponenteFuncionario.KPI_Content>
                  </Link>
                </Kpi.Card>
              </PrimaryTooltipCards>
            </Slide>
            <Slide direction="up" in={solicitacoesAguardandoSolicitante > -1}>
              <PrimaryTooltipCards
                title={
                  <div
                    style={{
                      fontSize: "17px",
                      display: "flex",
                      lineHeight: "normal",
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        fontSize: "25px",
                        color: "var(--primary)",
                      }}
                    >
                      <BsInfoCircleFill />
                    </div>
                    <div>
                      Solicitações que estejam com status igual a Aguardando
                      Solicitante.
                    </div>
                  </div>
                }
                placement="top"
              >
                <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                  <Link
                    to={`/requisicoes/(${JSON.stringify({
                      ...payloadAguarSolicitante,
                      gerarSomenteQuantidade: false,
                      gerarDadosComplementares: true,
                    })})`}
                  >
                    <ComponenteFuncionario.KPI_Content id="Kpi_Content">
                      <Kpi.Icon id="Kpi_Icon" className="icon_size">
                        <FaUserClock
                          id="RiUserUnfollowLine"
                          className={
                            classesIcons.solicitacoesAguardandoSolicitante
                          }
                        />
                      </Kpi.Icon>
                      <Kpi.Counter
                        id="Kpi.Counter"
                        className="count_center BiMessageSquareDetail"
                      >
                        <CountUp
                          start={0}
                          end={solicitacoesAguardandoSolicitante}
                          duration={2.75}
                          separator="."
                          id="RiUserUnfollowLine"
                        />
                      </Kpi.Counter>
                      <Kpi.Title id="Kpi_Title" className="title_bottom_count">
                        Solicitações Aguardando Solicitante
                      </Kpi.Title>
                    </ComponenteFuncionario.KPI_Content>
                  </Link>
                </Kpi.Card>
              </PrimaryTooltipCards>
            </Slide>
            <Slide direction="up" in={solicitacoesEmAndamento > -1}>
              <PrimaryTooltipCards
                title={
                  <div
                    style={{
                      fontSize: "17px",
                      display: "flex",
                      lineHeight: "normal",
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        fontSize: "25px",
                        color: "var(--primary)",
                      }}
                    >
                      <BsInfoCircleFill />
                    </div>
                    <div>
                      Solicitações que estejam com status igual a Andamento.
                    </div>
                  </div>
                }
                placement="top"
              >
                <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                  <Link
                    to={`/requisicoes/(${JSON.stringify({
                      ...payloadAndamento,
                      gerarSomenteQuantidade: false,
                      gerarDadosComplementares: true,
                    })})`}
                  >
                    <ComponenteFuncionario.KPI_Content id="Kpi_Content">
                      <Kpi.Icon id="Kpi_Icon" className="icon_size">
                        <FaUserCheck
                          id="RiUserUnfollowLine"
                          className={classesIcons.solicitacoesEmAndamento}
                        />
                      </Kpi.Icon>
                      <Kpi.Counter
                        id="Kpi.Counter"
                        className="count_center RiUserVoiceLine"
                      >
                        <CountUp
                          start={0}
                          end={solicitacoesEmAndamento}
                          duration={2.75}
                          separator="."
                          id="RiUserUnfollowLine"
                        />
                      </Kpi.Counter>
                      <Kpi.Title id="Kpi_Title" className="title_bottom_count">
                        Solicitações em Andamento
                      </Kpi.Title>
                    </ComponenteFuncionario.KPI_Content>
                  </Link>
                </Kpi.Card>
              </PrimaryTooltipCards>
            </Slide>
            <Slide direction="up" in={solicitacoesAguardandoTarefas > -1}>
              <PrimaryTooltipCards
                title={
                  <div
                    style={{
                      fontSize: "17px",
                      display: "flex",
                      lineHeight: "normal",
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        fontSize: "25px",
                        color: "var(--primary)",
                      }}
                    >
                      <BsInfoCircleFill />
                    </div>
                    <div>
                      Solicitações que estejam associadas a pelo menos uma
                      tarefa com status igual a Aberta, Andamento ou Aguardando
                      Demandante.
                    </div>
                  </div>
                }
                placement="top"
              >
                <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                  <Link
                    to={`/requisicoes/(${JSON.stringify({
                      ...payloadAguarTarefas,
                      gerarSomenteQuantidade: false,
                      gerarDadosComplementares: true,
                    })})`}
                  >
                    <ComponenteFuncionario.KPI_Content id="Kpi_Content">
                      <Kpi.Icon id="Kpi_Icon" className="icon_size">
                        <FaWindowRestore
                          id="RiUserUnfollowLine"
                          className={classesIcons.solicitacoesAguardandoTarefas}
                        />
                      </Kpi.Icon>
                      <Kpi.Counter
                        id="Kpi.Counter"
                        className="count_center BiMessageCheck"
                      >
                        <CountUp
                          start={0}
                          end={solicitacoesAguardandoTarefas}
                          duration={2.75}
                          separator="."
                          id="RiUserUnfollowLine"
                        />
                      </Kpi.Counter>
                      <Kpi.Title id="Kpi_Title" className="title_bottom_count">
                        Solicitações Aguardando Tarefas
                      </Kpi.Title>
                    </ComponenteFuncionario.KPI_Content>
                  </Link>
                </Kpi.Card>
              </PrimaryTooltipCards>
            </Slide>

            <Slide direction="up" in={solicitacoesVerificacaoInterna > -1}>
              <PrimaryTooltipCards
                title={
                  <div
                    style={{
                      fontSize: "17px",
                      display: "flex",
                      lineHeight: "normal",
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        fontSize: "25px",
                        color: "var(--primary)",
                      }}
                    >
                      <BsInfoCircleFill />
                    </div>
                    <div>
                      Solicitações que estejam com status igual a "Em
                      Verificação Interna".
                    </div>
                  </div>
                }
                placement="top"
              >
                <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                  <Link
                    to={`/requisicoes/(${JSON.stringify({
                      ...payloadVerificacaoIntern,
                      gerarSomenteQuantidade: false,
                      gerarDadosComplementares: true,
                    })})`}
                  >
                    <ComponenteFuncionario.KPI_Content id="Kpi_Content">
                      <Kpi.Icon id="Kpi_Icon" className="icon_size">
                        <BsFillClipboard2CheckFill
                          id="RiUserUnfollowLine"
                          className={
                            classesIcons.solicitacoesVerificacaoInterna
                          }
                        />
                      </Kpi.Icon>
                      <Kpi.Counter
                        id="Kpi.Counter"
                        className="count_center BiMessageError"
                      >
                        <CountUp
                          start={0}
                          end={solicitacoesVerificacaoInterna}
                          duration={2.75}
                          separator="."
                          id="RiUserUnfollowLine"
                        />
                      </Kpi.Counter>
                      <Kpi.Title id="Kpi_Title" className="title_bottom_count">
                        Verificação Interno
                      </Kpi.Title>
                    </ComponenteFuncionario.KPI_Content>
                  </Link>
                </Kpi.Card>
              </PrimaryTooltipCards>
            </Slide>

            <Slide direction="up" in={solicitacoesFinalizadasMes > -1}>
              <PrimaryTooltipCards
                title={
                  <div
                    style={{
                      fontSize: "17px",
                      display: "flex",
                      lineHeight: "normal",
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        fontSize: "25px",
                        color: "var(--primary)",
                      }}
                    >
                      <BsInfoCircleFill />
                    </div>
                    <div>
                      Solicitações que foram alteradas para Fechada pelo
                      atendente nos últimos 30 dias.
                    </div>
                  </div>
                }
                placement="top"
              >
                <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                  <Link
                    to={`/requisicoes/(${JSON.stringify({
                      ...payloadFinalizadasMes,
                      gerarSomenteQuantidade: false,
                      gerarDadosComplementares: true,
                    })})`}
                  >
                    <ComponenteFuncionario.KPI_Content id="Kpi_Content">
                      <Kpi.Icon id="Kpi_Icon" className="icon_size">
                        <BsFillCheckCircleFill
                          id="RiUserUnfollowLine"
                          style={{ color: "var(--dark-purple)" }}
                        />
                      </Kpi.Icon>
                      <Kpi.Counter
                        id="Kpi.Counter"
                        className="count_center ImClipboard"
                      >
                        <CountUp
                          start={0}
                          end={solicitacoesFinalizadasMes}
                          duration={2.75}
                          separator="."
                          id="RiUserUnfollowLine"
                        />
                      </Kpi.Counter>
                      <Kpi.Title id="Kpi_Title" className="title_bottom_count">
                        Solicitações Finalizadas no Mês
                      </Kpi.Title>
                    </ComponenteFuncionario.KPI_Content>
                  </Link>
                </Kpi.Card>
              </PrimaryTooltipCards>
            </Slide>
          </ComponenteFuncionario.KPI>
        </Grid>
        <ComponenteFuncionario.distanciaDivisor></ComponenteFuncionario.distanciaDivisor>

        <Grid container layout={"row"} spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ComponenteFuncionario.titleGrid>
              <span className="titulo">Ausências/Férias</span>
              <span
                onClick={() => setAbrirSelecaoAusencia(true)}
                className={classesAdicionar.span}
              >
                <span style={{ verticalAlign: "-webkit-baseline-middle" }}>
                  <HiOutlinePlusSmall />
                </span>
                <span className="adicionar"> Adicionar</span>
              </span>
            </ComponenteFuncionario.titleGrid>
            <ComponenteFuncionario.grid>
              <Card className="card_left_padding">
                <DataGrid
                  getRowId={(r) => r.id}
                  style={{ height: "100%" }}
                  pageSize={10}
                  disableSelectionOnClick={true}
                  columns={[
                    {
                      field: "descricao",
                      headerName: "Tipo",
                      width: 70,
                      editable: false,
                      disableColumnMenu: true,
                      sortable: false,
                      renderCell: (param) => (
                        <Tooltip title={param.value} placement="top">
                          <span>{param.value}</span>
                        </Tooltip>
                      ),
                    },
                    {
                      field: "dataInicio",
                      headerName: "Início",
                      width: 95,
                      disableColumnMenu: true,
                      sortable: false,
                      filterable: false,
                      editable: false,

                      renderCell: (param) => (
                        <Tooltip
                          title={convertISODateToDateView(param.value, true)}
                          placement="top"
                        >
                          <span>
                            {convertISODateToDateView(param.value, true)}
                          </span>
                        </Tooltip>
                      ),
                    },
                    {
                      field: "dataFim",
                      headerName: "Fim",
                      width: 99,
                      disableColumnMenu: true,
                      sortable: false,
                      filterable: false,
                      editable: false,
                      renderCell: (param) => (
                        <Tooltip
                          title={convertISODateToDateView(param.value, true)}
                          placement="top"
                        >
                          <span>
                            {convertISODateToDateView(param.value, true)}
                          </span>
                        </Tooltip>
                      ),
                    },
                    {
                      field: "justificativa",
                      headerName: "Justificativa",
                      width: 117,
                      disableColumnMenu: true,
                      sortable: false,
                      filterable: false,
                      editable: false,
                      renderCell: (params) => {
                        return (
                          <Tooltip title={params.value} placement="top">
                            <span>{params.value}</span>
                          </Tooltip>
                        );
                      },
                    },
                    {
                      field: "acao",
                      headerName: "Ações",
                      headerAlign: "center",
                      disableColumnMenu: true,
                      sortable: false,
                      width: 100,
                      align: "center",
                      filterable: false,
                      renderCell: (params) => {
                        return (
                          <ActionTable>
                            <PrimaryTooltip
                              title="Editar Ausência/Férias"
                              placement="top"
                            >
                              <Button
                                onClick={() => {
                                  setAlterarAusenciaSelecionada(
                                    funcionario.ausenciasFerias.find(
                                      (ausencia) =>
                                        ausencia.id === params.row.id
                                    )
                                  );
                                }}
                                className="btn-edit"
                              >
                                <AiOutlineEdit />
                              </Button>
                            </PrimaryTooltip>
                            <PrimaryTooltip
                              title="Excluir Ausência/Férias"
                              placement="top"
                            >
                              <Button
                                onClick={() => {
                                  setRowAusenciaSelecionado(params.row);
                                  setAbrirMensagemConfirmacaoAusencia(true);
                                }}
                                className="btn-delete"
                              >
                                <AiOutlineDelete />
                              </Button>
                            </PrimaryTooltip>
                          </ActionTable>
                        );
                      },
                    },
                  ]}
                  rows={
                    funcionario && funcionario.ausenciasFerias
                      ? funcionario.ausenciasFerias
                      : []
                  }
                  density={"small"}
                  loading={false}
                  localeText={ptBR.props.MuiDataGrid.localeText}
                />
              </Card>
            </ComponenteFuncionario.grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ComponenteFuncionario.titleGrid>
              <span className="titulo">Setores</span>
              <span
                onClick={() => setAbrirSelecaoSetor(true)}
                className={classesAdicionar.span}
              >
                <span style={{ verticalAlign: "-webkit-baseline-middle" }}>
                  <RxLinkNone2 />
                </span>
                <span className="adicionar"> Vincular</span>
              </span>
            </ComponenteFuncionario.titleGrid>
            <ComponenteFuncionario.grid>
              <Card className="card_left_padding">
                <DataGrid
                  getRowId={(r) => r.id}
                  style={{ height: "100%" }}
                  pageSize={10}
                  disableSelectionOnClick={true}
                  columns={[
                    {
                      field: "descricao",
                      headerName: " ",
                      width: 170,
                      editable: false,
                      renderCell: (param) => (
                        <Tooltip title={param.value} placement="top">
                          <span>{param.value}</span>
                        </Tooltip>
                      ),
                    },
                    {
                      field: "supervisor",
                      headerName: "Supervisor",
                      width: 110,
                      disableColumnMenu: true,
                      sortable: false,
                      editable: false,
                      valueGetter: (params) => (params.value ? "Sim" : "Não"),
                      renderCell: (params) => {
                        return (
                          <Badge
                            type={params.value == "Sim"}
                            className="no-select"
                          >
                            {params.value}
                          </Badge>
                        );
                      },
                    },
                    {
                      field: "respondeTarefa",
                      headerName: "Tarefas",
                      width: 110,
                      disableColumnMenu: true,
                      sortable: false,
                      editable: false,
                      valueGetter: (params) => (params.value ? "Sim" : "Não"),
                      renderCell: (params) => {
                        return (
                          <Badge
                            type={params.value == "Sim"}
                            className="no-select"
                          >
                            {params.value}
                          </Badge>
                        );
                      },
                    },
                    {
                      field: "acao",
                      headerName: "Ações",
                      headerAlign: "center",
                      disableColumnMenu: true,
                      sortable: false,
                      width: 100,
                      align: "center",
                      filterable: false,
                      renderCell: (params) => {
                        return (
                          <ActionTable>
                            <PrimaryTooltip
                              title="Editar Vínculo"
                              placement="top"
                            >
                              <Button
                                onClick={() => {
                                  setAlterarSetorSelecionada(params.row);
                                }}
                                className="btn-edit"
                              >
                                <AiOutlineEdit />
                              </Button>
                            </PrimaryTooltip>
                            <PrimaryTooltip
                              title="Desvincular Setor"
                              placement="top"
                            >
                              <Button
                                onClick={() => {
                                  setRowSetorSelecionado(params.row);
                                  setAbrirMensagemConfirmacaoSetor(true);
                                }}
                                className="btn-link-break"
                              >
                                <RxLinkBreak2 />
                              </Button>
                            </PrimaryTooltip>
                          </ActionTable>
                        );
                      },
                    },
                  ]}
                  rows={
                    funcionario && funcionario.setores
                      ? funcionario.setores
                      : []
                  }
                  density={"small"}
                  loading={false}
                  localeText={ptBR.props.MuiDataGrid.localeText}
                />
              </Card>
            </ComponenteFuncionario.grid>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ComponenteFuncionario.titleGrid>
              <span className="titulo">Assuntos</span>
              <span
                onClick={() => setAbrirSelecaoAssunto(true)}
                className={classesAdicionar.span}
              >
                <span style={{ verticalAlign: "-webkit-baseline-middle" }}>
                  <RxLinkNone2 />
                </span>
                <span className="adicionar"> Vincular</span>
              </span>
            </ComponenteFuncionario.titleGrid>
            <ComponenteFuncionario.grid>
              <Card className="card_left_padding">
                <DataGrid
                  getRowId={(r) => r.id}
                  disableSelectionOnClick={true}
                  style={{ height: "100%" }}
                  pageSize={10}
                  columns={[
                    {
                      field: "descricao",
                      headerName: " ",
                      flex: 1,
                      editable: false,
                    },
                    {
                      field: "acao",
                      headerName: "Ações",
                      headerAlign: "center",
                      disableColumnMenu: true,
                      sortable: false,
                      width: 100,
                      align: "center",
                      filterable: false,
                      renderCell: (params) => {
                        return (
                          <ActionTable>
                            <PrimaryTooltip
                              title="Desvincular Assunto"
                              placement="top"
                            >
                              <Button
                                onClick={() => {
                                  setRowAssuntoSelecionado(params.row);
                                  setAbrirMensagemConfirmacaoAssunto(true);
                                }}
                                className="btn-link-break"
                              >
                                <RxLinkBreak2 />
                              </Button>
                            </PrimaryTooltip>
                          </ActionTable>
                        );
                      },
                    },
                  ]}
                  rows={
                    funcionario && funcionario.assuntos
                      ? funcionario.assuntos
                      : []
                  }
                  density={"small"}
                  loading={false}
                  localeText={ptBR.props.MuiDataGrid.localeText}
                />
              </Card>
            </ComponenteFuncionario.grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ComponenteFuncionario.titleGrid>
              <span className="titulo">Últimas Avaliações</span>
            </ComponenteFuncionario.titleGrid>
            <ComponenteFuncionario.grid>
              <Card className="card_left_padding">
                <DataGrid
                  getRowId={(r) => r.idSolicitacao}
                  disableSelectionOnClick={true}
                  style={{ height: "100%" }}
                  columns={[
                    {
                      field: "nomeCliente",
                      headerName: "Nome do Usuário",
                      flex: 1,
                      editable: false,
                      headerAlign: "center",
                      filterable: false,
                      sortable: false,
                      disableColumnMenu: true,
                      renderCell: (param) => (
                        <Tooltip title={param.value} placement="top">
                          <span>{param.value}</span>
                        </Tooltip>
                      ),
                    },
                    {
                      field: "avaliacao",
                      headerName: "Avaliação",
                      width: 130,
                      editable: false,
                      align: "center",
                      headerAlign: "center",
                      filterable: false,
                      sortable: false,
                      disableColumnMenu: true,
                      renderCell: (param) => (
                        <span
                          style={{
                            color:
                              param.value > 3 ? "var(--green)" : "var(--red)",
                          }}
                        >
                          {param.value}
                        </span>
                      ),
                    },
                    {
                      field: "motivo",
                      headerName: "Motivo",
                      flex: 1,
                      editable: false,
                      headerAlign: "center",
                      filterable: false,
                      sortable: false,
                      disableColumnMenu: true,
                      renderCell: (param) => (
                        <Tooltip title={param.value} placement="top">
                          <span>{param.value}</span>
                        </Tooltip>
                      ),
                    },
                    {
                      field: "dataAbertura",
                      headerName: "Abertura",
                      flex: 1,
                      editable: false,
                      align: "center",
                      headerAlign: "center",
                      filterable: false,
                      sortable: false,
                      disableColumnMenu: true,
                    },
                    {
                      field: "dataFechamento",
                      headerName: "Fechamento",
                      flex: 1,
                      editable: false,
                      align: "center",
                      headerAlign: "center",
                      filterable: false,
                      sortable: false,
                      disableColumnMenu: true,
                    },
                    {
                      field: "idSolicitacaoFormatada",
                      headerName: "Solicitação",
                      flex: 1,
                      editable: false,
                      align: "center",
                      headerAlign: "center",
                      filterable: false,
                      sortable: false,
                      disableColumnMenu: true,
                      renderCell: (param) => (
                        <Tooltip title={param.value} placement="top">
                          <Link to={`/requisicao/${param.row.idSolicitacao}`}>
                            <Chip
                              style={{ minWidth: "150px" }}
                              variant="outlined"
                              label={
                                <span className={classesAdicionar.adicionar}>
                                  <span
                                    className="adicionar"
                                    style={{ marginRight: "10px" }}
                                  >
                                    <BiLinkExternal />
                                  </span>
                                  <span>{param.value}</span>
                                </span>
                              }
                            />
                          </Link>
                        </Tooltip>
                      ),
                    },
                  ]}
                  rows={ultimasAvaliacoes}
                  density={"small"}
                  loading={false}
                  localeText={ptBR.props.MuiDataGrid.localeText}
                />
              </Card>
            </ComponenteFuncionario.grid>
          </Grid>
        </Grid>
      </Container>
    </ComponenteFuncionario>
  );
}
