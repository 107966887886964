import axios from "axios";
import api from "./api";
import jwt from "jwt-decode";
import _ from "lodash";

export const TOKEN_KEY = "@jucerja-faleconosco";
export const REFRESH_TOKEN = "@jucerja-faleconosco-rtoken";

const USUARIO = "@jucerja-faleconosco-usuario";
const PERFIS = "@jucerja-faleconosco-perfis";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => obterTokenValido();

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);

  separeEntidadesLogin(token);
};

export const logout = async () => {
  await api({
    method: "post",
    url: "/jucerja/v1/Autenticacao/logoff",
    headers: {
      accept: "*/*",
      "Content-Type": "application/json-patch+json",
    },
    data: JSON.stringify({
      tokenSequencia: getRToken()
        ? getRToken().sequenciaTicketAutenticacao
        : null,
    }),
  })
    .then(() => {
      removeLocalStorageData();
      window.location.href = "/login";
    })
    .catch(() => {
      removeLocalStorageData();
      window.location.href = "/login";
    });
};

export const removeLocalStorageData = () => {
  localStorage.clear();
};

export const refreshToken = (data) => {
  let refreshTokenData = JSON.stringify(data);
  localStorage.setItem(REFRESH_TOKEN, refreshTokenData);
};

export const getRToken = () => {
  return JSON.parse(localStorage.getItem(REFRESH_TOKEN));
};

const validaTokenCall = _.debounce(async () => {
  await axios({
    method: "post",
    url: `${process.env.REACT_APP_URL}/jucerja/v1/Autenticacao/valida_token`,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json-patch+json",
    },
    data: JSON.stringify({
      token: getToken(),
    }),
  })
    .then((response) => {
      if (!response.data) {
        logout();
      }
    })
    .catch((error) => {
      console.log(error);
    });
}, 1000);

export const validaToken = async (limparToken = false) => {
  if (window.location.href.replace(window.location.origin, "") == "/login")
    return;

  if (getToken() == null || limparToken) {
    removeLocalStorageData();
    window.location.href = "/login";
    return;
  }
};

const obterTokenValido = () => {
  let tokenKey = localStorage.getItem(TOKEN_KEY);

  if (
    tokenKey == null &&
    window.location.href.replace(window.location.origin, "") !== "/login"
  ) {
    removeLocalStorageData();
    window.location.href = "/login";
    return;
  }

  return tokenKey;
};

export const getObjectJwt = () => {
  return jwt(getToken());
};

export const getPerfisUsuario = () => {
  const perfis = JSON.parse(localStorage.getItem(PERFIS));

  return perfis.filter(
    (x) =>
      x.indexOf("Administrador") === -1 &&
      x.indexOf("Gestor") === -1 &&
      x.indexOf("Cliente") === -1
  );
};

const separeEntidadesLogin = (token) => {
  const user = jwt(token);

  configurePerfis(user);
  configureUsuario(user);
};

const configurePerfis = (user) => {
  localStorage.setItem(PERFIS, JSON.stringify(user.perfis.split(",")));
};

const configureUsuario = (user) => {
  const usuario = {
    nome: user.name,
    login: user.login,
  };

  localStorage.setItem(USUARIO, JSON.stringify(usuario));
};

export const obtenhaUsuario = () => {
  return JSON.parse(localStorage.getItem(USUARIO));
};
