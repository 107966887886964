import { Button, Tooltip, Popper, Grow, Paper, ClickAwayListener, MenuList, Divider, MenuItem, withStyles } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { NavBarTask } from './styles'
import { GrMoreVertical, BiArrowBack, BsListTask, TiClipboard, BsCheckAll, AiOutlineLike, IoChatbubblesOutline } from 'react-icons/all'
import Skeleton from 'react-loading-skeleton'
import { capitalize } from '../../../functions'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    arrow: {
        color: 'var(--white)',
    },
}))(Tooltip)

export default function NavBarMessagesTask({
    error,
    loading,
    mensagens,
    handleOpenDialogDetailTask,
    anchorRef,
    openMoreOptions,
    setOpenMoreOptions,
    handleToggle,
    handleCloseMoreOptions,
    handleListKeyDown,
    handlePrintTask,
    setOpenDialogSendEmailContactTask,
    setLoading,
    setError,
    setErrorMessage,
    setOpenDialogResponsibleChange,
    setOpenDialogTaskAssociatedRequests,
    setOpenDialogReopen,
    setOpenDialogTaskClose,
    setOpenDialogCancelarTarefa,
    setOpenDialogDemandanteTarefa,
    setOpenDialogTaskEdit
}) {

    const history = useHistory();

    const acaoPermitida = (acao, rederizar) => {

        if (mensagens.acoesPermitidas.indexOf(acao) > -1)
            return rederizar

        return null
    }

    return (
        <NavBarTask.Top id="Request_Top">
            <NavBarTask.TopLeft id="Request_TopLeft">
                <NavBarTask.NavName id="Request_NavName">
                    {/* <Link to="/tarefas/1"> */}
                    <Button className="btn-arrow-back" onClick={() => history.goBack()}><BiArrowBack /></Button>
                    {/* </Link> */}
                    <NavBarTask.Information id="Request_Information">
                        {!error && (
                            <>
                                <NavBarTask.Name id="Request_Name">{loading ? <Skeleton width={100} height={15} /> : capitalize(mensagens.titulo)}</NavBarTask.Name>
                                <NavBarTask.Number id="Request_Number">{loading ? <Skeleton width={80} height={15} /> : `Tarefa #${mensagens.id}`}</NavBarTask.Number>
                            </>
                        )}
                    </NavBarTask.Information>
                </NavBarTask.NavName>
            </NavBarTask.TopLeft>
            {!error && (
                <NavBarTask.TopRight id="Request_TopRight">
                    <HtmlTooltip title="Solicitação da Tarefa" placement="top">
                        <Button className="btn-default-action-request btn-visualiza-requisicao" onClick={() => setOpenDialogTaskAssociatedRequests(true)}><IoChatbubblesOutline /></Button>
                    </HtmlTooltip>
                    {
                        acaoPermitida('FecharTarefa',
                            <HtmlTooltip title="Fechar Tarefa" placement="top">
                                <Button className="btn-default-action-request btn-filtro-top-right-item01" onClick={() => setOpenDialogTaskClose(true)}><BsCheckAll /></Button>
                            </HtmlTooltip>)
                    }
                    {
                        acaoPermitida('AlterarStatusAguardandoDemandante',
                            <HtmlTooltip title="Aguardar Demandante" placement="top">
                                <Button className="btn-default-action-request btn-filtro-top-right-item02" onClick={() => setOpenDialogDemandanteTarefa(true)}><AiOutlineLike /></Button>
                            </HtmlTooltip>)
                    }
                    <Button
                        className="btn-default-action-request item-05"
                        ref={anchorRef}
                        aria-controls={openMoreOptions ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <GrMoreVertical />
                    </Button>
                    <Popper
                        open={openMoreOptions}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        className="popper-more-option-drops"
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom"
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleCloseMoreOptions}>
                                        <MenuList
                                            autoFocusItem={openMoreOptions}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                            onClick={() => setOpenMoreOptions(false)}
                                        >
                                            {acaoPermitida('ImprimirTarefa', <MenuItem onClick={() => handlePrintTask()}>Imprimir Tarefa</MenuItem>)}
                                            {acaoPermitida('EnviarTarefaPorEmail', <MenuItem onClick={() => setOpenDialogSendEmailContactTask(true)}>Enviar Para Contato</MenuItem>)}


                                            <Divider />
                                            {acaoPermitida('ReabrirTarefa', <MenuItem onClick={() => setOpenDialogReopen(true)}>Reabrir</MenuItem>)}
                                            {acaoPermitida('AlterarResponsavelTarefa', <MenuItem onClick={() => setOpenDialogTaskEdit(true)}>Alterar Tarefa</MenuItem>)}
                                            {acaoPermitida('AlterarResponsavelTarefa', <MenuItem onClick={() => setOpenDialogResponsibleChange(true)}>Alterar Responsável</MenuItem>)}
                                            {acaoPermitida('CancelarTarefa', <MenuItem onClick={() => setOpenDialogCancelarTarefa(true)}>Cancelar</MenuItem>)}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </NavBarTask.TopRight>
            )}
        </NavBarTask.Top>
    )
}