import { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";
import { useSnackbar } from "notistack";
import routes from "./routes";

import Login from "./pages/Login";
import Error404 from "./pages/Error404";

import MessageHandleSendAll from "./components/elements-ui/MessageHandleSendAll";
import { apm } from "@elastic/apm-rum";
import { errorNaoAtendicado } from "./services/api";

export default function Routes(props) {
  const [erro, setError] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();

  errorNaoAtendicado({ setError });
  useEffect(() => {
    const transacao = apm.startTransaction("Inicialização da aplicação");

    transacao.end();
  }, []);

  useEffect(() => {
    if (erro) enqueueSnackbar(erro, { variant: "warning" });

    setError(undefined);
  }, [erro]);

  return (
    <BrowserRouter>
      <MessageHandleSendAll />
      <Switch>
        {routes.map(({ path, name, Component }, key) => (
          <Route
            exact
            path={path}
            key={key}
            render={(props) => {
              const crumbs = routes
                .filter(({ path }) => props.match.path.includes(path))
                .map(({ path, ...rest }) => ({
                  path: Object.keys(props.match.params).length
                    ? Object.keys(props.match.params).reduce(
                        (path, param) =>
                          path.replace(`:${param}`, props.match.params[param]),
                        path
                      )
                    : path,
                  ...rest,
                }));
              return isAuthenticated() ? (
                <Component {...props} crumbs={crumbs} />
              ) : (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              );
            }}
          />
        ))}
        <Route exact path="/login" component={Login} />
        <Route path="*" component={Error404} />
      </Switch>
    </BrowserRouter>
  );
}
