import { Fragment, useEffect, useRef, useState, createRef } from 'react'
import clsx from "clsx"
import { useSnackbar } from 'notistack'
import { Request } from './styles'
import { v4 as uuid } from 'uuid'
import Skeleton from 'react-loading-skeleton'
import { makeStyles, withStyles, CircularProgress, Button, Tooltip, IconButton, BottomNavigation, BottomNavigationAction, TextField, Drawer } from "@material-ui/core"
import { GrAttachment, AiOutlineEdit, AiOutlineSend } from 'react-icons/all'
import api from '../../../services/api'
import { useDispatch, useSelector  } from 'react-redux'
import { storeMessage, rascunho } from '../../../slices/requestMessageSlice'
import { crieRequisicaoObj } from '../../../functions'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    arrow: {
        color: 'var(--white)',
    },
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250
    },
    fullList: {
        width: "auto"
    },
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    }
}))

export default function SendMessageBox({
    idRequisicao,
    mensagens,
    loading,
    handleAttachment,
    handleClickOpen,
    toggleDrawer,
    state,
    value,
    setValue
}) {
    
    const rascunhoResult = useSelector((state) => state.requestMessage.rascunhos?.find(m => m.idRequisicao === idRequisicao))
    const dispatch = useDispatch()    
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const searchInput = useRef(null)
    const [inputTextMessage, setInputTextMessage] = useState(rascunhoResult?.mensagem)
    const [loadingMessage, setLoadingMessage] = useState(false)

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom"
            }, 'bottom-navigation-mobile-request')}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue)
                }}
                showLabels
                className={classes.root}
            >
                <BottomNavigationAction id="send-message-mobile-request-attach" label={
                    <div id="btn-add-attachment">
                        <input
                            accept=".jpg, .png, .bmp, .gif, .pdf, .doc, .docx, .xls, .xlsx, .p7s"
                            style={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            onChange={handleAttachment}
                        />
                        <label id="label-btn-add-attachment" htmlFor="icon-button-file">
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                className="btn-send-attach"
                                onChange={handleAttachment}
                                disableRipple={true}
                                disableFocusRipple={true}
                            >
                                <GrAttachment id="icon-attach-message" />
                                <span>Anexar Arquivos</span>
                            </IconButton>
                        </label>
                    </div>
                } />
                <BottomNavigationAction onClick={handleClickOpen} id="send-message-mobile-request-editor" label="Enviar Mens. C/ Editor" icon={<AiOutlineEdit />} />
            </BottomNavigation>
        </div>
    )

    const handleSubmitMessage = async () => {
        setLoadingMessage(true)

        dispatch(storeMessage(crieRequisicaoObj(idRequisicao, searchInput.current.value, 1, [])));
        setInputTextMessage('');
        setTimeout(() => {
            setLoadingMessage(false);
            searchInput.current.value = '';
            searchInput.current.focus();
        }, 500);
    }

    const handleKeyDownMessage = async event => {
        if (event.key === 'Enter') {
            await handleSubmitMessage();
        }       
    }

    const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const files = clipboardData.files;
        if (files && files.length > 0) {
            
          handleAttachment({target:{files}}, '');
        }
      };

    useEffect(() => {
        searchInput.current.focus();
        document.addEventListener('paste', handlePaste);
        return () => {
          document.removeEventListener('paste', handlePaste);
        };
      }, []);

    return (
        loading
            ?
            <Request.SendFastMessage id="Request_SendFastMessage">
                <Request.SendFastMessageContent id="Request_SendFastMessageContent" type={loading ? 'loading' : 'noloading'}>
                    <Request.LoadingSendMessageBox id="Request_LoadingSendMessageBox">
                        <Request.LoadingSendMessageBoxCircle id="Request_LoadingSendMessageBoxCircle"><Skeleton width={42} height={42} /></Request.LoadingSendMessageBoxCircle>
                        <Request.LoadingSendMessageBoxCircle id="Request_LoadingSendMessageBoxCircle"><Skeleton width={42} height={42} /></Request.LoadingSendMessageBoxCircle>
                        <Request.LoadingSendMessageBoxField id="Request.LoadingSendMessageBoxField"><Skeleton /></Request.LoadingSendMessageBoxField>
                        <Request.LoadingSendMessageBoxCircle id="Request_LoadingSendMessageBoxCircle"><Skeleton width={42} height={42} /></Request.LoadingSendMessageBoxCircle>
                    </Request.LoadingSendMessageBox>
                </Request.SendFastMessageContent>
            </Request.SendFastMessage>
            :
            <Request.SendFastMessage id="Request_SendFastMessage">
                <Request.SendFastMessageContent id="Request_SendFastMessageContent" type={loading ? 'loading' : 'noloading'}>
                    {mensagens.acoesPermitidas.map((acao, index) => (
                        acao === "EnviarMensagem"
                            ?
                            <Fragment key={"EnviarMensagem"}>
                                <Request.SendFastMessageGroupButtons id="Request_SendFastMessageGroupButtons">
                                    <HtmlTooltip title="Anexar Arquivos" placement="top">
                                        <div id="btn-add-attachment">
                                            <input
                                                accept=".jpg, .png, .bmp, .gif, .pdf, .doc, .docx, .xls, .xlsx, .p7s"
                                                style={{ display: "none" }}
                                                id="icon-button-file"
                                                type="file"
                                                multiple
                                                onChange={e => handleAttachment(e, inputTextMessage)}
                                            />
                                            <label htmlFor="icon-button-file">
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    className="btn-send-attach"
                                                    onChange={e => handleAttachment(e, inputTextMessage)}
                                                >
                                                    <GrAttachment id="icon-attach-message" />
                                                </IconButton>
                                            </label>
                                        </div>
                                    </HtmlTooltip>
                                    <HtmlTooltip title="Enviar Mens. C/ Editor" placement="top" onClick={handleClickOpen}>
                                        <Button className="btn-editor-message"><AiOutlineEdit id="icon-attach-image-message" /></Button>
                                    </HtmlTooltip>
                                </Request.SendFastMessageGroupButtons>
                                <Request.SendFastMessageBtnDialogMobileMessage id="Request_SendFastMessageBtnDialogMobileMessage">
                                    {["bottom"].map((anchor) => (
                                        <Fragment key={anchor}>
                                            <Button className="btn-send-message-fast-request-mobile btnDefault" onClick={toggleDrawer(anchor, true)}><GrAttachment id="icon-send-message" /></Button>
                                            <Drawer
                                                anchor={anchor}
                                                open={state[anchor]}
                                                onClose={toggleDrawer(anchor, false)}
                                            >
                                                {list(anchor)}
                                            </Drawer>
                                        </Fragment>
                                    ))}
                                </Request.SendFastMessageBtnDialogMobileMessage>
                                <Request.SendFastMessageBoxMessage id="Request_SendFastMessageBoxMessage">
                                    <TextField
                                        id="txt-send-message-request"
                                        variant="outlined"
                                        onKeyPress={handleKeyDownMessage}
                                        placeholder="Digite uma mensagem"
                                        disabled={loadingMessage}
                                        inputRef={searchInput}
                                        defaultValue = {inputTextMessage}
                                        onChange={e=> dispatch(rascunho({ idRequisicao, mensagem: e.target.value }))}

                                    />
                                </Request.SendFastMessageBoxMessage>
                                <Request.SendFastMessageBtnSendMessage id="Request_SendFastMessageBtnSendMessage">
                                    {
                                        loadingMessage
                                            ?
                                            <Button className="btn-send-message-fast-request btnDefault btnDefaultPrimary btn-loading-send-message-fast-request">
                                                <CircularProgress size={22} />
                                            </Button>
                                            :
                                            <Button
                                                onClick={() => handleSubmitMessage()}
                                                className="btn-send-message-fast-request btnDefault btnDefaultPrimary"
                                            >
                                                <AiOutlineSend id="icon-send-message" />
                                            </Button>
                                    }
                                </Request.SendFastMessageBtnSendMessage>
                            </Fragment>
                            :
                            null
                    ))}
                </Request.SendFastMessageContent>
            </Request.SendFastMessage>
    )
}