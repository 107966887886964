import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'

import Menu from '../../navigations/Menu'
import { Title } from '../../elements-ui/Title'
import { ContainerComponent } from './styles'

import LogoSys from '../../../assets/img/logo-dashboard.svg'
import IconUrlBack from '../../../assets/img/icon-url-back.svg'

export default function ContainerRequest(props) {
    return (
        <ContainerComponent id="ContainerRequest_ContainerComponent">
            <ContainerComponent.ContentMenu id="ContainerComponent_ContentMenu">
                <Link to={'/'}>
                <ContainerComponent.LogoSistema id="ContainerComponent_LogoSistema" src={LogoSys} />
                </Link>
                <Menu/>
            </ContainerComponent.ContentMenu>
            <ContainerComponent.Content id="ContainerRequest_Content">
                <Title>
                    {
                        props.urlEnabledBack
                        ?
                            <Link to={props.urlBack}>
                                <Button className="btnUrlBackContainer"><ContainerComponent.ImgIconUrlBack id="ContainerComponentImgIconUrlBack" src={IconUrlBack} /></Button>
                            </Link>
                        :
                            null
                    }
                    {props.title}
                </Title>
                {props.children}
            </ContainerComponent.Content>
            <ContainerComponent.Copyright id="ContainerRequest_Copyright">{new Date().getFullYear()} <span>©</span> Junta Comercial do Estado do Rio de Janeiro</ContainerComponent.Copyright>
        </ContainerComponent>
    )
}