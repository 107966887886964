import { useState, forwardRef, useEffect, useRef } from "react";
import Container from "../../../components/layout/Container";

import { Badge } from "../../../components/elements-ui/Badge";
import { useSnackbar } from "notistack";
import npm from "@material-ui/core/FormControl";

import { ComponenteSetor } from "./styles";
import {
  BsInfoCircleFill,
  FaUser,
  FaUserClock,
  HiOutlinePlusSmall,
  FaWindowRestore,
  FaUserCheck,
  FaClock,
  FaUserTimes,
  BsFillClipboard2CheckFill,
  BsFillCheckCircleFill,
  BiLinkExternal,
  FaUserLock,
  AiOutlineDelete,
  RxLinkBreak2,
  RxLinkNone2,
  BsListTask,
  FaUserTie,
  AiOutlineEdit,
  FaHouseUser,
} from "react-icons/all";
import CountUp from "react-countup";

import {
  Button,
  Grid,
  Popper,
  Slide,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  withStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Card } from "../../../components/elements-ui/Card";
import DialogDefaultTextConfirm from "../../../components/elements-ui/DialogDefaultTextConfirm";
import DialogConfirmation from "../../../components/elements-ui/DialogConfirmation";
import DialogAssuntoSetorConfirm from "../../../components/elements-ui/DialogAssuntoSetorConfirm";
import _ from "lodash";
import api from "../../../services/api";
import { Kpi } from "../../../components/elements-ui/KPI";
import {
  convertISODateToDateView,
  processarCatchComMensagensDeValidacao,
} from "../../../functions/index";
import { ActionTable } from "../../../components/elements-ui/ActionTable";

import { NavLink, Link } from "react-router-dom";

import { BiArrowBack } from "react-icons/all";
import DialogAssuntoAssociacaoConfirm from "../../../components/elements-ui/DialogAssuntoAssociacaoConfirm";
import DialogSetorFuncionarioConfirm from "../../../components/elements-ui/DialogSetorFuncionarioConfirm";

const PrimaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--primary)",
    color: "var(--white)",
  },
}))(Tooltip);

const PrimaryTooltipCards = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--white)",
    color: "var(--black)",
  },
}))(Tooltip);

const PrimaryTooltipVermelho = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--red)",
    color: "var(--white)",
  },
}))(Tooltip);

const PrimaryTooltipAmarelo = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#e5a900",
    color: "var(--white)",
  },
}))(Tooltip);

const PrimaryTooltipAzul = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#1c8ccb",
    color: "var(--white)",
  },
}))(Tooltip);

const PrimaryTooltipFerias = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#1c8bc9",
    color: "var(--white)",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  solicitacoes: {
    color: "var(--dark-yellow)",
  },
  solicitacoesAguardandoSolicitante: {
    color: "var(--dark-orange)",
  },
  solicitacoesEmAndamento: {
    color: "var(--dark-blue)",
  },
  solicitacoesAguardandoTarefas: {
    color: "var(--dark-gree)",
  },
  solicitacoesVerificacaoInterna: {
    color: "var(--red)",
  },
  solicitacoesAtrasadas: {
    color: "var(--grey)",
  },
}));

const useAdicionar = makeStyles((theme) => ({
  span: {
    margin: "2px 2px 2px 5px;",
    fontWeight: "400",
    backgroundColor: "#4354a6",
    borderRadius: "1000px",
    color: "var(--white)",
    padding: "0px 7px 0 7px",
    fontSize: "1.2rem",
    userSelect: "none",
    cursor: "pointer",
    "& .adicionar": {
      display: "inline-block",
      verticalAlign: "sub",
      maxWidth: "0",
      visibility: "hidden",
      opacity: "0",
      transition: "max-width 0.2s, opacity 0.2s",
    },
    "&:hover": {
      "& .adicionar": {
        verticalAlign: "sub",
        visibility: "visible",
        maxWidth: "200px",
        opacity: "1",
        transition: "max-width 0.5s, opacity 0.2s",
      },
    },
  },
  adicionar: {
    "& .adicionar": {
      display: "inline-block",
      verticalAlign: "sub",
      maxWidth: "0",
      visibility: "hidden",
      opacity: "0",
      transition: "max-width 0.2s, opacity 0.2s",
    },
    "&:hover": {
      "& .adicionar": {
        verticalAlign: "sub",
        visibility: "visible",
        maxWidth: "200px",
        opacity: "1",
        transition: "max-width 0.5s, opacity 0.2s",
      },
    },
  },
}));

const filtroTemplate = {
  filtros: [],
  firstResult: 0,
  maxResults: 0,
};

export default function Setor(props) {
  const setorId = props.match.params.id;
  const classesIcons = useStyles();
  const classesAdicionar = useAdicionar();
  const { enqueueSnackbar } = useSnackbar();

  const [setor, setSetor] = useState({
    id: 0,
    descricao: "",
    ativo: "",
    supervisor: [],
    assuntos: [],
    textosPadroes: [],
  });
  const [funcionarios, setFuncionarios] = useState([]);
  const [assuntos, setAssuntos] = useState([]);

  const [idAssuntoTextoPadrao, setIdAssuntoTextoPadrao] = useState(undefined);
  const [idAssuntoEdicao, setIdAssuntoEdicao] = useState(undefined);

  const [openDialogDefaultText, setOpenDialogDefaultText] = useState(false);

  const [openDialogAssuntoSetorConfirm, setOpenDialogAssuntoSetorConfirm] =
    useState(false);
  const [assuntoSetorConfirm, setAssuntoSetorConfirm] = useState(undefined);
  const [editModeAssuntoContent, setEditModeAssuntoContent] = useState(false);

  const [assuntoParaExclusao, setAssuntoParaExclusao] = useState(undefined);
  const [openAssuntoParaExclusao, setOpenAssuntoParaExclusao] = useState(false);
  const [confirmAssuntoParaExclusao, setConfirmAssuntoParaExclusao] =
    useState(false);

  const [supervisaoParaExclusao, setSupervisaoParaExclusao] =
    useState(undefined);
  const [openSupervisaoExclusao, setOpenSupervisaoExclusao] = useState(false);
  const [confirmSupervisaoExclusao, setConfirmSupervisaoExclusao] =
    useState(false);

  const [funcionarioParaExclusao, setFuncionarioParaExclusao] =
    useState(undefined);
  const [openFuncionarioParaExclusao, setOpenFuncionarioParaExclusao] =
    useState(false);
  const [confirmFuncionarioExclusao, setConfirmFuncionarioExclusao] =
    useState(false);

  const [selecaoFuncionario, setSelecaoFuncionario] = useState({});

  const [solicitacoesAbertas, setSolicitacoesAbertas] = useState();
  const [
    solicitacoesAguardandoSolicitante,
    setSolicitacoesAguardandoSolicitante,
  ] = useState();
  const [solicitacoesEmAndamento, setSolicitacoesEmAndamento] = useState();
  const [solicitacoesVerificacaoInterna, setSolicitacoesVerificacaoInterna] =
    useState();
  const [solicitacoesAguardandoTarefas, setSolicitacoesAguardandoTarefas] =
    useState();
  const [solicitacoesFinalizadasMes, setSolicitacoesFinalizadasMes] =
    useState();

  const [openDialogAssuntoAssociacao, setOpenDialogAssuntoAssociacao] =
    useState(false);
  const [assuntoAssociacaoConfirmation, setAssuntoAssociacaoConfirmation] =
    useState(undefined);

  const [openDialogSetorFuncionario, setOpenDialogSetorFuncionario] =
    useState(false);
  const [setorFuncionarioConfirm, setSetorFuncionarioConfirm] =
    useState(undefined);

  const [loadingFuncionario, setLoadingFuncionario] = useState(false);

  const obterFuncionariosSetor = async () => {
    const opcoesFiltro = {
      filtros: [
        {
          coluna: [5],
          comparador: 0,
          agruparCondicional: false,
          valores: [`${setorId}`],
        },
      ],
      opcao: 1,
      aplicarUnionCondicional: true,
      utilizarCache: true,
      firstResult: 1,
      maxResults: 999999,
    };

    setLoadingFuncionario(true);

    await api
      .post(`/jucerja/v1/funcionario/filtro`, opcoesFiltro)
      .then((response) => setFuncionarios(response.data.funcionarios))
      .finally(() => setLoadingFuncionario(false));
  };

  const prepararFuncionarioSelecionado = (funcionario) => {
    setSelecaoFuncionario(objetoVinculoSetor(funcionario));
    setOpenDialogSetorFuncionario(true);
  };

  const objetoVinculoSetor = (funcionario) => {
    let setorEscopo = funcionario.informacoes?.setores?.find(
      (s) => s.id == setorId
    );
    let selecionado = {
      id: funcionario.id,
      nome: funcionario.nome,
      supervisor: setorEscopo?.supervisor ?? false,
      recebeTarefas: setorEscopo?.respondetarefa ?? false,
      alteracao: true,
    };

    return selecionado;
  };

  const obterValoresAssuntos = () =>
    api
      .get(`/jucerja/v1/setor/${setorId}/listagem/assuntos`)
      .then((response) => setAssuntos(response.data));

  const funcionarioSetorVinculo = () => {
    let link = "cadastro-funcionario-setores";
    let method = "post";

    if (setorFuncionarioConfirm.alteracao) {
      link = "alterar-funcionario-setores";
      method = "put";
    }

    api[method](`/jucerja/v1/funcionario/${link}`, {
      funcionarioId: setorFuncionarioConfirm.id,
      setores: [
        {
          setor: setorId,
          supervisor: setorFuncionarioConfirm.supervisor ?? false,
        },
      ],
      respondeTarefa: setorFuncionarioConfirm.recebeTarefas ?? false,
    })
      .then((response) => {
        obterFuncionariosSetor();

        enqueueSnackbar(
          setorFuncionarioConfirm.alteracao
            ? "Alteração realizada com sucesso."
            : "Vínculo realizado com sucesso.",
          { variant: "success" }
        );
      })
      .catch(() => {
        enqueueSnackbar("Houve um erro ao adicionar o funcionário.", {
          variant: "error",
        });
      })
      .finally(() => {
        setOpenDialogSetorFuncionario(false);
        setSelecaoFuncionario({});
        setSetorFuncionarioConfirm(undefined);
        setOpenSupervisaoExclusao(false);
      });
  };

  const funcionarioDesvinculoSetor = () => {
    api
      .post(`/jucerja/v1/funcionario/remover-funcionario-setores`, {
        funcionarioId: funcionarioParaExclusao.id,
        setores: [
          {
            setor: setorId,
          },
        ],
      })
      .then((response) => {
        obterFuncionariosSetor();

        enqueueSnackbar("Desvínculo realizado com sucesso.", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Houve um erro ao desvincular o funcionário.", {
          variant: "error",
        });
      })
      .finally(() => {
        setOpenFuncionarioParaExclusao(false);
        setFuncionarioParaExclusao(undefined);
      });
  };

  useEffect(() => {
    api
      .get(`/jucerja/v1/Setor/setores-configuracao/?id=${setorId}`)
      .then((response) =>
        setSetor(response.data?.find((setor) => setor !== undefined))
      );

    obterValoresAssuntos();

    api
      .get(`/jucerja/v1/setor/${setorId}/quantidade/solicitacoes/status/1`)
      .then((response) => setSolicitacoesAbertas(response.data));
    api
      .get(`/jucerja/v1/setor/${setorId}/quantidade/solicitacoes/status/4`)
      .then((response) => setSolicitacoesAguardandoSolicitante(response.data));
    api
      .get(`/jucerja/v1/setor/${setorId}/quantidade/solicitacoes/status/2`)
      .then((response) => setSolicitacoesEmAndamento(response.data));
    api
      .get(`/jucerja/v1/setor/${setorId}/quantidade/solicitacoes/status/6`)
      .then((response) => setSolicitacoesVerificacaoInterna(response.data));
    api
      .get(
        `/jucerja/v1/setor/${setorId}/quantidade/solicitacoes/aguardando/tarefas`
      )
      .then((response) => setSolicitacoesAguardandoTarefas(response.data));
    api
      .get(
        `/jucerja/v1/setor/${setorId}/quantidade/solicitacoes/finalizadas/mes`
      )
      .then((response) => setSolicitacoesFinalizadasMes(response.data));

    obterFuncionariosSetor();
  }, []);

  useEffect(() => {
    if (!assuntoSetorConfirm) return;

    const assuntoObj = {
      ...assuntoSetorConfirm,
      funcionariosId: assuntoSetorConfirm?.funcionarios?.map((fun) => fun.id),
      descricao: assuntoSetorConfirm.assuntoDescricao,
      mensagemId: assuntoSetorConfirm.idMensagemAutomatica,
      mensagemAutomatica: assuntoSetorConfirm.mensagemAutomatica,
      setorId: setorId,
      Setores: [setorId],
      habilitar: true,
    };

    if (assuntoSetorConfirm.id) {
      api
        .put(`/jucerja/v1/Assuntos/alterar-assunto`, assuntoObj)
        .then((response) => {
          const setorAtualizado = response.data;

          let assuntosInterno = [...assuntos].filter(
            (x) => x.id != assuntoSetorConfirm.id
          );

          let assunto = {
            ...assuntoSetorConfirm,
            mensagemAutomatica: setorAtualizado.mensagemAutomatica,
            mensagemId: setorAtualizado.mensagemId,
          };

          assuntosInterno.push(assunto);

          setAssuntos(assuntos);
          obterValoresAssuntos();
          enqueueSnackbar("Assunto foi atualizado com sucesso.", {
            variant: "success",
          });

          setOpenDialogAssuntoSetorConfirm(false);
        })
        .catch((error) =>
          processarCatchComMensagensDeValidacao(enqueueSnackbar, error)
        )
        .finally(() => {
          setAssuntoSetorConfirm(undefined);
        });
    } else {
      api
        .post(`/jucerja/v1/Assuntos/novos-assunto`, assuntoObj)
        .then((response) => {
          obterValoresAssuntos();
          enqueueSnackbar("Assunto foi cadastrado com sucesso.", {
            variant: "success",
          });

          setOpenDialogAssuntoSetorConfirm(false);
        })
        .catch((error) =>
          processarCatchComMensagensDeValidacao(enqueueSnackbar, error)
        )
        .finally(() => {
          setAssuntoSetorConfirm(undefined);
        });
    }
  }, [assuntoSetorConfirm]);

  useEffect(() => {
    const idAssunto = assuntoParaExclusao?.assuntoId;
    if (!confirmAssuntoParaExclusao) return;

    setConfirmAssuntoParaExclusao(false);

    api
      .delete(`/jucerja/v1/Assuntos/remover-assunto?assuntoId=${idAssunto}`)
      .then((response) => {
        setAssuntos(assuntos.filter((x) => x.assuntoId != idAssunto));
        enqueueSnackbar("Assunto foi removido com sucesso.", {
          variant: "success",
        });
      })
      .catch((e) =>
        enqueueSnackbar("Houve um erro ao excluir o assunto.", {
          variant: "error",
        })
      )
      .finally(() => {
        setOpenAssuntoParaExclusao(false);
      });
  }, [confirmAssuntoParaExclusao]);

  useEffect(() => {
    if (!openAssuntoParaExclusao) setAssuntoParaExclusao(undefined);
  }, [openAssuntoParaExclusao]);

  useEffect(() => {
    if (!assuntoAssociacaoConfirmation) return;

    let assuntoAssociacao = {
      AssuntoId: assuntoAssociacaoConfirmation.id,
      SetorId: setorId,
    };

    api
      .put(`/jucerja/v1/Assuntos/associar-assunto-setor`, assuntoAssociacao)
      .then((response) => {
        obterValoresAssuntos();

        enqueueSnackbar("Assunto foi associado com sucesso.", {
          variant: "success",
        });
      })
      .catch((e) => {
        enqueueSnackbar("Houve um erro ao associar o assunto.", {
          variant: "error",
        });
      })
      .finally(() => {
        setOpenDialogAssuntoAssociacao(false);
        setAssuntoAssociacaoConfirmation(undefined);
      });
  }, [assuntoAssociacaoConfirmation]);

  useEffect(() => {
    if (idAssuntoTextoPadrao) setOpenDialogDefaultText(true);

    setIdAssuntoTextoPadrao(undefined);
  }, [idAssuntoTextoPadrao]);

  useEffect(() => {
    if (assuntoParaExclusao) setOpenAssuntoParaExclusao(true);
  }, [assuntoParaExclusao]);

  useEffect(() => {
    if (supervisaoParaExclusao) setOpenSupervisaoExclusao(true);
  }, [supervisaoParaExclusao]);

  useEffect(() => {
    if (!openSupervisaoExclusao) setSupervisaoParaExclusao(undefined);
  }, [openSupervisaoExclusao]);

  useEffect(() => {
    if (!!idAssuntoEdicao) setOpenDialogAssuntoSetorConfirm(true);
  }, [idAssuntoEdicao]);

  useEffect(() => {
    if (!setorFuncionarioConfirm) return;

    funcionarioSetorVinculo();
  }, [setorFuncionarioConfirm]);

  useEffect(() => {
    if (!confirmSupervisaoExclusao) return;

    let objVinculo = objetoVinculoSetor(supervisaoParaExclusao);
    objVinculo.supervisor = false;

    setSetorFuncionarioConfirm(objVinculo);
  }, [confirmSupervisaoExclusao]);

  useEffect(() => {
    if (!confirmFuncionarioExclusao) return;

    funcionarioDesvinculoSetor();
  }, [confirmFuncionarioExclusao]);

  return (
    <ComponenteSetor>
      <DialogDefaultTextConfirm
        openDialogDefaultText={openDialogDefaultText}
        setOpenDialogDefaultText={setOpenDialogDefaultText}
        idAssunto={idAssuntoTextoPadrao}
      />

      <DialogAssuntoSetorConfirm
        openDialogAssuntoSetorConfirm={openDialogAssuntoSetorConfirm}
        setOpenDialogAssuntoSetorConfirm={setOpenDialogAssuntoSetorConfirm}
        setAssuntoSetorConfirm={setAssuntoSetorConfirm}
        assuntoSetorConfirm={assuntoSetorConfirm}
        idAssunto={idAssuntoEdicao}
        setIdAssunto={setIdAssuntoEdicao}
        idSetor={setorId}
      />

      <DialogConfirmation
        openDialog={openAssuntoParaExclusao}
        setOpenDialog={setOpenAssuntoParaExclusao}
        setConfirm={setConfirmAssuntoParaExclusao}
        txtConfirm="Sim"
        txtCancel="Não"
        txtTitle="Desvincular assunto"
        txtContainer={`Deseja desvincular o '${assuntoParaExclusao?.assunto}' do setor atual ?`}
        closeControlOutsideOnConfirm={true}
      />

      <DialogConfirmation
        openDialog={openSupervisaoExclusao}
        setOpenDialog={setOpenSupervisaoExclusao}
        setConfirm={setConfirmSupervisaoExclusao}
        txtConfirm="Sim"
        txtCancel="Não"
        txtTitle="Desvincular supervisão"
        txtContainer={`Deseja desvincular '${supervisaoParaExclusao?.nome}' como supervisor(a)?`}
        closeControlOutsideOnConfirm={true}
      />

      <DialogConfirmation
        openDialog={openFuncionarioParaExclusao}
        setOpenDialog={setOpenFuncionarioParaExclusao}
        setConfirm={setConfirmFuncionarioExclusao}
        txtConfirm="Sim"
        txtCancel="Não"
        txtTitle="Desvincular funcionário"
        txtContainer={`Deseja desvincular '${funcionarioParaExclusao?.nome}' do setor?`}
        closeControlOutsideOnConfirm={true}
      />

      <DialogAssuntoAssociacaoConfirm
        openDialogAssuntoAssociacao={openDialogAssuntoAssociacao}
        setOpenDialogAssuntoAssociacao={setOpenDialogAssuntoAssociacao}
        setAssuntoAssociacaoConfirmation={setAssuntoAssociacaoConfirmation}
      />

      <DialogSetorFuncionarioConfirm
        setorId={setorId}
        selecaoFuncionario={selecaoFuncionario}
        setSelecaoFuncionario={setSelecaoFuncionario}
        idsFuncionariosDesconsiderar={funcionarios?.map((fun) => fun.id)}
        openDialogSetorFuncionario={openDialogSetorFuncionario}
        setOpenDialogSetorFuncionario={setOpenDialogSetorFuncionario}
        setSetorFuncionarioConfirm={setSetorFuncionarioConfirm}
      />

      <Container
        title={
          <>
            <Link to="/Setores">
              <Button className="btn-arrow-back">
                <BiArrowBack />{" "}
              </Button>
            </Link>
            Setor
            <div style={{ marginLeft: "56px" }}>
              {setor.setor}
              <Badge
                type={setor.ativo.toUpperCase() == "SIM"}
                className="no-select"
                style={{ display: "inline", marginLeft: "10px" }}
              >
                {setor.ativo.toUpperCase() == "SIM" ? "Ativo" : "Inativo"}
              </Badge>
            </div>
          </>
        }
      >
        <Grid container layout={"row"} spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ComponenteSetor.KPI id="Kpi">
              <Slide direction="up" in={solicitacoesAbertas > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que estejam com status igual a Aberta.
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/(%7B"filtros":[%7B"Coluna":[5],"Comparador":0,"Valores":["${setorId}"]%7D,%7B"Coluna":[2],"Comparador":0,"Valores":["1"]%7D],"firstResult":1,"maxResults":10,"gerarDadosComplementares":true,"paginaAtual":1,"qtdeRegistros":10%7D)`}
                    >
                      <ComponenteSetor.KPI_Content
                        id="Kpi_Content"
                        className="mouse_kpi"
                      >
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <FaUser
                            id="RiUserUnfollowLine"
                            className={classesIcons.solicitacoes}
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center RiUserUnfollowLine"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesAbertas}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Solicitações Abertas
                        </Kpi.Title>
                      </ComponenteSetor.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>
              <Slide direction="up" in={solicitacoesAguardandoSolicitante > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que estejam com status igual a Aguardando
                        Solicitante.
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/(%7B"filtros":[%7B"Coluna":[5],"Comparador":0,"Valores":["${setorId}"]%7D,%7B"Coluna":[2],"Comparador":0,"Valores":["4"]%7D],"firstResult":1,"maxResults":10,"gerarDadosComplementares":true,"paginaAtual":1,"qtdeRegistros":10%7D)`}
                    >
                      <ComponenteSetor.KPI_Content id="Kpi_Content">
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <FaUserClock
                            id="RiUserUnfollowLine"
                            className={
                              classesIcons.solicitacoesAguardandoSolicitante
                            }
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center BiMessageSquareDetail"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesAguardandoSolicitante}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Solicitações Aguardando Solicitante
                        </Kpi.Title>
                      </ComponenteSetor.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>
              <Slide direction="up" in={solicitacoesEmAndamento > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que estejam com status igual a Andamento.
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/(%7B"filtros":[%7B"Coluna":[5],"Comparador":0,"Valores":["${setorId}"]%7D,%7B"Coluna":[2],"Comparador":0,"Valores":["2"]%7D],"firstResult":1,"maxResults":10,"gerarDadosComplementares":true,"paginaAtual":1,"qtdeRegistros":10%7D)`}
                    >
                      <ComponenteSetor.KPI_Content id="Kpi_Content">
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <FaUserCheck
                            id="RiUserUnfollowLine"
                            className={classesIcons.solicitacoesEmAndamento}
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center RiUserVoiceLine"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesEmAndamento}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Solicitações em Andamento
                        </Kpi.Title>
                      </ComponenteSetor.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>
              <Slide direction="up" in={solicitacoesAguardandoTarefas > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que estejam associadas a pelo menos uma
                        tarefa com status igual a Aberta, Andamento ou
                        Aguardando Demandante.
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/7/(%7B"filtros":[%7B"Coluna":[5],"Comparador":0,"Valores":["${setorId}"]%7D],"firstResult":1,"maxResults":10,"gerarDadosComplementares":true,"paginaAtual":1,"qtdeRegistros":10%7D)`}
                    >
                      <ComponenteSetor.KPI_Content id="Kpi_Content">
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <FaWindowRestore
                            id="RiUserUnfollowLine"
                            className={
                              classesIcons.solicitacoesAguardandoTarefas
                            }
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center BiMessageCheck"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesAguardandoTarefas}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Solicitações Aguardando Tarefas
                        </Kpi.Title>
                      </ComponenteSetor.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>

              <Slide direction="up" in={solicitacoesVerificacaoInterna > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que estejam com status igual a "Em
                        Verificação Interna".
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/(%7B"filtros":[%7B"Coluna":[5],"Comparador":0,"Valores":["${setorId}"]%7D,%7B"Coluna":[2],"Comparador":0,"Valores":["6"]%7D],"firstResult":1,"maxResults":10,"gerarDadosComplementares":true,"paginaAtual":1,"qtdeRegistros":10%7D)`}
                    >
                      <ComponenteSetor.KPI_Content id="Kpi_Content">
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <BsFillClipboard2CheckFill
                            id="RiUserUnfollowLine"
                            className={
                              classesIcons.solicitacoesVerificacaoInterna
                            }
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center BiMessageError"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesVerificacaoInterna}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Verificação Interna
                        </Kpi.Title>
                      </ComponenteSetor.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>

              <Slide direction="up" in={solicitacoesFinalizadasMes > -1}>
                <PrimaryTooltipCards
                  title={
                    <div
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        lineHeight: "normal",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "25px",
                          color: "var(--primary)",
                        }}
                      >
                        <BsInfoCircleFill />
                      </div>
                      <div>
                        Solicitações que foram alteradas para Fechada pelo
                        atendente nos últimos 30 dias.
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                    <Link
                      to={`/requisicoes/6/(%7B"filtros":[%7B"Coluna":[5],"Comparador":0,"Valores":["${setorId}"]%7D],"firstResult":1,"maxResults":10,"gerarDadosComplementares":true,"paginaAtual":1,"qtdeRegistros":10%7D)`}
                    >
                      <ComponenteSetor.KPI_Content id="Kpi_Content">
                        <Kpi.Icon id="Kpi_Icon" className="icon_size">
                          <BsFillCheckCircleFill
                            id="RiUserUnfollowLine"
                            style={{ color: "var(--dark-purple)" }}
                          />
                        </Kpi.Icon>
                        <Kpi.Counter
                          id="Kpi.Counter"
                          className="count_center ImClipboard"
                        >
                          <CountUp
                            start={0}
                            end={solicitacoesFinalizadasMes}
                            duration={2.75}
                            separator="."
                            id="RiUserUnfollowLine"
                          />
                        </Kpi.Counter>
                        <Kpi.Title
                          id="Kpi_Title"
                          className="title_bottom_count"
                        >
                          Solicitações Finalizadas no Mês
                        </Kpi.Title>
                      </ComponenteSetor.KPI_Content>
                    </Link>
                  </Kpi.Card>
                </PrimaryTooltipCards>
              </Slide>
            </ComponenteSetor.KPI>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <ComponenteSetor.title>
              <span className="titulo">Assuntos</span>
              <span
                onClick={() => {
                  setEditModeAssuntoContent(true);
                  setOpenDialogAssuntoSetorConfirm(true);
                }}
                className={classesAdicionar.span}
              >
                <span style={{ verticalAlign: "-webkit-baseline-middle" }}>
                  <HiOutlinePlusSmall />
                </span>
                <span className="adicionar"> Adicionar</span>
              </span>
              <span
                onClick={() => {
                  setOpenDialogAssuntoAssociacao(true);
                }}
                className={classesAdicionar.span}
              >
                <span style={{ verticalAlign: "-webkit-baseline-middle" }}>
                  <RxLinkNone2 />
                </span>
                <span className="adicionar"> Vincular</span>
              </span>
            </ComponenteSetor.title>
            <ComponenteSetor.grid>
              <Card className="card_left_padding">
                <DataGrid
                  isRowSelectable={false}
                  getRowId={(r) => r.assuntoId}
                  style={{ height: "100%" }}
                  columns={[
                    {
                      field: "assunto",
                      flex: 1,
                      headerName: " ",
                      width: 120,
                      editable: false,
                      renderCell: (param) => (
                        <Tooltip title={param.value} placement="top">
                          <span>{param.value}</span>
                        </Tooltip>
                      ),
                    },
                    {
                      field: "distribuicao",
                      flex: 1,
                      align: "center",
                      headerAlign: "center",
                      headerName: "Distribuição",
                      width: 167,
                      type: "number",
                      editable: false,
                      renderCell: (params) => {
                        return `${params.row.funcionarios}`.split("/")[0];
                      },
                    },
                    {
                      field: "quantidadeFuncionarioAusente",
                      align: "center",
                      headerAlign: "center",
                      headerName: "Ausentes",
                      width: 167,
                      type: "number",
                      editable: false,
                    },
                    {
                      field: "quantidadeFuncionarioAusente",
                      flex: 1,
                      align: "center",
                      headerAlign: "center",
                      headerName: "Ausentes",
                      width: 167,
                      type: "number",
                      editable: false,
                    },
                    {
                      field: "ativo",
                      flex: 1,
                      align: "center",
                      headerAlign: "center",
                      headerName: "Ativo",
                      width: 165,
                      editable: false,
                      renderCell: (params) => {
                        return (
                          <Badge
                            type={params.value == "SIM"}
                            className="no-select"
                          >
                            {params.value == "SIM" ? "Sim" : "Não"}
                          </Badge>
                        );
                      },
                    },
                    {
                      field: "funcionarios",
                      align: "center",
                      headerAlign: "center",
                      flex: 1,
                      headerName: "Funcionários",
                      width: 169,
                      editable: false,
                      renderCell: (params) => {
                        return (
                          <Badge type={true} className="no-select">
                            {params.value}
                          </Badge>
                        );
                      },
                    },
                    {
                      field: "acao",
                      headerName: "Ações",
                      headerAlign: "center",
                      width: 150,
                      align: "center",
                      filterable: false,
                      renderCell: (params) => {
                        return (
                          <ActionTable>
                            <PrimaryTooltip
                              title="Texto Padrão"
                              placement="top"
                            >
                              <Button
                                onClick={() =>
                                  setIdAssuntoTextoPadrao(params.row.assuntoId)
                                }
                                className="btn-open"
                              >
                                <BsListTask />
                              </Button>
                            </PrimaryTooltip>
                            <PrimaryTooltip
                              title="Editar Assunto"
                              placement="top"
                            >
                              <Button
                                onClick={() => {
                                  setIdAssuntoEdicao(params.row.assuntoId);
                                }}
                                className="btn-edit"
                              >
                                <AiOutlineEdit />
                              </Button>
                            </PrimaryTooltip>
                            <PrimaryTooltip
                              title="Desvincular Setor"
                              placement="top"
                            >
                              <Button
                                onClick={() => {
                                  setAssuntoParaExclusao(params.row);
                                }}
                                className="btn-link-break"
                              >
                                <RxLinkBreak2 />
                              </Button>
                            </PrimaryTooltip>
                          </ActionTable>
                        );
                      },
                    },
                  ]}
                  rows={assuntos}
                  density={"small"}
                  pageSize={10}
                  hideFooterSelectedRowCount
                  loading={false}
                  localeText={ptBR.props.MuiDataGrid.localeText}
                />
              </Card>
            </ComponenteSetor.grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <ComponenteSetor.title>
              <span className="titulo">Supervisores</span>
            </ComponenteSetor.title>
            <ComponenteSetor.grid>
              <Card className="card_left_padding">
                <DataGrid
                  getRowId={(r) => r.id}
                  style={{ height: "100%" }}
                  columns={[
                    {
                      field: "nome",
                      flex: 1,
                      headerName: " ",
                      width: 200,
                      editable: false,
                      renderCell: (param) => (
                        <Tooltip title={param.value} placement="top">
                          <span>{param.value}</span>
                        </Tooltip>
                      ),
                    },
                    {
                      field: "status",
                      flex: 1,
                      align: "left",
                      headerAlign: "left",
                      headerName: "Status",
                      width: 167,
                      type: "number",
                      editable: false,
                      renderCell: (param) => (
                        <Badge
                          type={param.value?.toLowerCase() == "ativo"}
                          className="no-select"
                        >
                          {param.value}
                        </Badge>
                      ),
                    },
                    {
                      field: "acao",
                      headerName: "Ações",
                      headerAlign: "center",
                      width: 130,
                      align: "center",
                      filterable: false,
                      renderCell: (params) => {
                        return (
                          <ActionTable>
                            <PrimaryTooltip
                              title="Desvincular Supervisão"
                              placement="top"
                            >
                              <Button
                                className="btn-link-break"
                                onClick={() =>
                                  setSupervisaoParaExclusao(params.row)
                                }
                              >
                                <RxLinkBreak2 />
                              </Button>
                            </PrimaryTooltip>
                          </ActionTable>
                        );
                      },
                    },
                  ]}
                  rows={funcionarios.filter(
                    (fun) =>
                      fun.informacoes?.setores?.find(
                        (setor) => setor.id == setorId
                      )?.supervisor
                  )}
                  density={"small"}
                  loading={false}
                  pageSize={10}
                  hideFooterSelectedRowCount
                  localeText={ptBR.props.MuiDataGrid.localeText}
                />
              </Card>
            </ComponenteSetor.grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ComponenteSetor.title>
              <span className="titulo">Funcionários</span>
              <span
                onClick={() => {
                  if (!loadingFuncionario) {
                    setOpenDialogSetorFuncionario(true);
                  }
                }}
                className={classesAdicionar.span}
              >
                <span style={{ verticalAlign: "-webkit-baseline-middle" }}>
                  <RxLinkNone2 />
                </span>
                <span className="adicionar"> Vincular</span>
              </span>
            </ComponenteSetor.title>
            <ComponenteSetor.grid>
              <Card className="card_left_padding">
                <DataGrid
                  getRowId={(r) => r.id}
                  style={{ height: "100%" }}
                  pageSize={10}
                  columns={[
                    {
                      field: "nome",
                      flex: 1,
                      headerName: " ",
                      width: 200,
                      editable: false,
                      renderCell: (param) => (
                        <Tooltip title={param.value} placement="top">
                          <Link to={`/Funcionario/${param.row.id}`}>
                            <span className={classesAdicionar.adicionar}>
                              <span
                                className="adicionar"
                                style={{ marginRight: "10px" }}
                              >
                                <BiLinkExternal />
                              </span>
                              <span>{param.value}</span>
                            </span>
                          </Link>
                        </Tooltip>
                      ),
                    },
                    {
                      field: "situacao",
                      flex: 1,
                      align: "left",
                      headerAlign: "left",
                      headerName: "Situação",
                      width: 165,
                      editable: false,
                      renderCell: (param) => (
                        <div style={{ display: "contents", lineHeight: "0px" }}>
                          {param.row?.status.toUpperCase() == "AUSENTE" ? (
                            <PrimaryTooltipAzul title="Ausente" placement="top">
                              <span>
                                <FaUserLock
                                  style={{
                                    fontSize: "31px",
                                    paddingRight: "10px",
                                    color: "var(--blue)",
                                  }}
                                />
                              </span>
                            </PrimaryTooltipAzul>
                          ) : (
                            <></>
                          )}
                          {param.row?.status.toUpperCase() == "FERIAS" ? (
                            <PrimaryTooltipFerias
                              title="Férias"
                              placement="top"
                            >
                              <span>
                                <FaHouseUser
                                  style={{
                                    fontSize: "25px",
                                    color: "#1c8bc9",
                                    paddingRight: "10px",
                                  }}
                                />
                              </span>
                            </PrimaryTooltipFerias>
                          ) : (
                            <></>
                          )}
                          {param.row?.informacoes?.setores?.find(
                            (setor) => setor.id == setorId
                          )?.supervisor ? (
                            <PrimaryTooltipAmarelo
                              title="Supervisor"
                              placement="top"
                            >
                              <span>
                                <FaUserTie
                                  style={{
                                    fontSize: "25px",
                                    color: "#e5a900",
                                    paddingRight: "10px",
                                  }}
                                />
                              </span>
                            </PrimaryTooltipAmarelo>
                          ) : (
                            <></>
                          )}
                          {param.row?.status.toUpperCase() == "INATIVO" ? (
                            <PrimaryTooltipVermelho
                              title="Inativo"
                              placement="top"
                            >
                              <span>
                                <FaUserTimes
                                  style={{
                                    fontSize: "31px",
                                    paddingRight: "10px",
                                    color: "var(--red)",
                                  }}
                                />
                              </span>
                            </PrimaryTooltipVermelho>
                          ) : (
                            <></>
                          )}
                        </div>
                      ),
                    },
                    {
                      field: "informacoes",
                      flex: 1,
                      headerName: "Informações",
                      width: 165,
                      editable: false,
                      renderCell: (params) => (
                        <div
                          style={{
                            display: "contents",
                            color: "#4354a6",
                            lineHeight: "0px",
                          }}
                        >
                          {params.value?.quantidadeSolicitacoes != undefined &&
                          params.value?.quantidadeSolicitacoes >= 0 ? (
                            <PrimaryTooltip
                              title={`Solicitações ${params.value?.quantidadeSolicitacoes}`}
                              placement="top"
                            >
                              <span style={{ width: "70px" }}>
                                <FaUser
                                  style={{
                                    fontSize: "25px",
                                    paddingRight: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "20.5px",
                                    fontWeight: "bold",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {params.value?.quantidadeSolicitacoes}
                                </span>
                              </span>
                            </PrimaryTooltip>
                          ) : (
                            <></>
                          )}
                          {params.value?.quantidadeTarefasAssociadas !=
                            undefined &&
                          params.value?.quantidadeTarefasAssociadas >= 0 ? (
                            <PrimaryTooltip
                              title={`Tarefas ${params.value?.quantidadeTarefasAssociadas}`}
                              placement="top"
                            >
                              <span style={{ width: "70px" }}>
                                <FaWindowRestore
                                  style={{
                                    fontSize: "25px",
                                    paddingRight: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "20.5px",
                                    fontWeight: "bold",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {params.value?.quantidadeTarefasAssociadas}
                                </span>
                              </span>
                            </PrimaryTooltip>
                          ) : (
                            <></>
                          )}
                          {params.value
                            ?.quantidadeSolicitacoesEmVerificacaoInterna !=
                            undefined &&
                          params.value
                            ?.quantidadeSolicitacoesEmVerificacaoInterna >=
                            0 ? (
                            <PrimaryTooltip
                              title={`Solicitações em Verificação Interna ${params.value?.quantidadeSolicitacoesEmVerificacaoInterna}`}
                              placement="top"
                            >
                              <span style={{ width: "70px" }}>
                                <BsFillClipboard2CheckFill
                                  id="RiUserUnfollowLine"
                                  style={{
                                    fontSize: "25px",
                                    paddingRight: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "20.5px",
                                    fontWeight: "bold",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {
                                    params.value
                                      ?.quantidadeSolicitacoesEmVerificacaoInterna
                                  }
                                </span>
                              </span>
                            </PrimaryTooltip>
                          ) : (
                            <></>
                          )}
                          {params.value?.quantidadeSolicitacoesAtrasadas !=
                            undefined &&
                          params.value?.quantidadeSolicitacoesAtrasadas >= 0 ? (
                            <PrimaryTooltip
                              title={`Solicitações Atrasadas ${params.value?.quantidadeSolicitacoesAtrasadas}`}
                              placement="top"
                            >
                              <span style={{ width: "70px" }}>
                                <FaClock
                                  id="RiUserUnfollowLine"
                                  style={{
                                    fontSize: "25px",
                                    paddingRight: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "20.5px",
                                    fontWeight: "bold",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {
                                    params.value
                                      ?.quantidadeSolicitacoesAtrasadas
                                  }
                                </span>
                              </span>
                            </PrimaryTooltip>
                          ) : (
                            <></>
                          )}
                        </div>
                      ),
                    },
                    {
                      field: "status",
                      headerName: "Status",
                      width: 160,
                      editable: false,
                      renderCell: (params) => {
                        return (
                          <ComponenteSetor.FuncionarioBadge
                            type={params.value.toUpperCase()}
                            className="no-select"
                          >
                            <span>{params.value}</span>
                          </ComponenteSetor.FuncionarioBadge>
                        );
                      },
                    },
                    {
                      field: "acao",
                      headerName: "Ações",
                      width: 150,
                      filterable: false,
                      renderCell: (params) => {
                        return (
                          <ActionTable>
                            <PrimaryTooltip
                              title="Editar Vínculo"
                              placement="top"
                            >
                              <Button
                                className="btn-edit"
                                onClick={() => {
                                  prepararFuncionarioSelecionado(params.row);
                                }}
                              >
                                <AiOutlineEdit />
                              </Button>
                            </PrimaryTooltip>
                            <PrimaryTooltip
                              title="Desvincular Funcionário"
                              placement="top"
                            >
                              <Button
                                onClick={() => {
                                  setFuncionarioParaExclusao(params.row);
                                  setOpenFuncionarioParaExclusao(true);
                                }}
                                className="btn-link-break"
                              >
                                <RxLinkBreak2 />
                              </Button>
                            </PrimaryTooltip>
                          </ActionTable>
                        );
                      },
                    },
                  ]}
                  rows={funcionarios}
                  hideFooterSelectedRowCount
                  density={"small"}
                  loading={loadingFuncionario}
                  localeText={ptBR.props.MuiDataGrid.localeText}
                />
              </Card>
            </ComponenteSetor.grid>
          </Grid>
        </Grid>
      </Container>
    </ComponenteSetor>
  );
}
