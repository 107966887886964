import Dashboard from './pages/Dashboard'
import Requisicoes from './pages/Requisicoes'
import Requisicao from './pages/Requisicoes/Requisicao'
import Tarefas from './pages/Tarefas'
import Tarefa from './pages/Tarefas/Tarefa'
import Funcionarios from './pages/Funcionarios'
import Funcionario from './pages/Funcionarios/Funcionario'
import Setores from './pages/Setores'
import Setor from './pages/Setores/Setor'
import Assuntos from './pages/Assuntos'
import Assunto from './pages/Assuntos/Assunto'
import Redistribuicoes from './pages/Redistribuicoes'
import Redistribuicao from './pages/Redistribuicoes/Redistribuicao'
import Historico from './pages/Historico'
import Duvidas from './pages/Duvidas'
import Duvida from './pages/Duvidas/Duvida'
import Secao from './pages/Secao'
import LocalHost from './pages/LocalHost'
import ConfiguracaoUsuario from './pages/ConfiguracaoUsuario'
import Equalizar from './pages/Equalizar'

export default [
    { path: '/', name: 'Página Inicial', Component: LocalHost },
    { path: '/dashboard/:perfil', name: 'Dashboard', Component: Dashboard },
    { path: '/requisicoes/', name: 'Solicitações', Component: Requisicoes },
    { path: '/requisicoes/\\(:filtro\\)', name: 'Solicitações', Component: Requisicoes },
    { path: '/requisicoes/:tipo/\\(:filtro\\)', name: 'Solicitações', Component: Requisicoes },
    { path: '/requisicoes/:tipo', name: 'Solicitações ', Component: Requisicoes },
    { path: '/requisicao/:id', name: 'Solicitação', Component: Requisicao },
    { path: '/requisicaoR/:id', name: 'Solicitação', Component: Requisicao },
    { path: '/tarefas/', name: 'Tarefas', Component: Tarefas },
    { path: '/tarefas/\\(:filtro\\)', name: 'Tarefas', Component: Tarefas },
    { path: '/tarefas/:tipo', name: 'Tarefas', Component: Tarefas },
    { path: '/tarefa/:id', name: 'Tarefa', Component: Tarefa },
    { path: '/funcionarios/', name: 'Funcionários', Component: Funcionarios },
    { path: '/funcionario/:id', name: 'Funcionário', Component: Funcionario },
    { path: '/setores/', name: 'Setores', Component: Setores },
    { path: '/setor/:id', name: 'Setor', Component: Setor },
    { path: '/assuntos/', name: 'Assuntos', Component: Assuntos },
    { path: '/assunto/:id', name: 'Assunto', Component: Assunto },
    { path: '/Redistribuicoes', name: 'Redistribuicao', Component: Redistribuicoes },
    { path: '/Redistribuicao/:id', name: 'Redistribuicao', Component: Redistribuicao },
    { path: '/Historico', name: 'Historico', Component: Historico },
    { path: '/Secao', name: 'Secao', Component: Secao },
    { path: '/Duvidas', name: 'Duvidas', Component: Duvidas },
    { path: '/Duvida/:id', name: 'Duvida', Component: Duvida },
    { path: `/ConfiguracaoUsuario`, name: 'Configuração do Usuário', Component: ConfiguracaoUsuario },
    { path: `/Equalizar`, name: 'Equalização', Component: Equalizar },
]