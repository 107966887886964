import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import TextField from "@material-ui/core/TextField"
import { HiOutlineFilter, IoCloseSharp } from 'react-icons/all'
import { Autocomplete, Chip, Stack } from '@mui/material';
import { Filter } from './styles'
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { TransitionGroup } from 'react-transition-group';
import Grow from '@mui/material/Grow';
import FormControl from "@material-ui/core/FormControl"
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Checkbox from "@material-ui/core/Checkbox"
import Slider from "@material-ui/core/Slider"
import api from '../../../services/api'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash';

import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers"
import ptBRLocale from "date-fns/locale/pt-BR"

import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

const useStyles = makeStyles(theme => ({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const filtroTemplate = {
  filtros: [],
  firstResult: 0,
  maxResults: 0
};


export default function FilterTasks({ aplicarFiltro, setAplicarFiltro, setSalvarFiltro }) {
  const CODIGO_SEM_RESPONSAVEL = 9999999999999;
  const colunaTarefa = 17;
  const colunaSetor = 5;
  const colunaStatus = 2;
  const colunaDataAbertura = 9
  const colunaResponsavelTarefa = 13;
  const comparador = { igual: 0, menor: 1, maior: 2, igualMaior: 3, igualMenor: 4 };
  const [filtro, setFiltro] = useState(
    Object.keys(aplicarFiltro).length > 0
      ?
      aplicarFiltro
      :
      filtroTemplate)
  const classes = useStyles()
  const [carregar, setCarregar] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const [listaDeStatus, setListaDeStatus] = useState([]);
  const [listaDeSetor, setListaDeSetor] = useState([]);
  const [listaDeFuncionarioResponsavel, setListaDeFuncionarioResponsavel] = useState([])

  const handleFiltro = (event, colunaFiltro) => {
    const value = event.target.value;
    crieObjetoFiltro(value, colunaFiltro)
  }

  const crieValoresParaAtendentes = (e, v) => {
    crieObjetoFiltro(v == null ? '' : v.referenciaUsuarioId, colunaResponsavelTarefa);
  }

  const crieObjetoFiltro = (valor, colunaFiltro, comparador) => {
    const comparadorConvertido = comparador ?? 0;
    const filtroEscopo = filtro.filtros.filter(x => x.Coluna == colunaFiltro && comparadorConvertido == x.Comparador)[0];
    const filtrosSemColunaEscopo = filtro.filtros.filter(x => x != filtroEscopo);
    const objFiltroColunaEscopo = { Coluna: [colunaFiltro], Comparador: comparadorConvertido, Valores: [`${(valor == CODIGO_SEM_RESPONSAVEL ? 0 : valor)}`] };

    if (Array.isArray(valor)) {
      objFiltroColunaEscopo.Valores = [...(valor.map(x => `${x}`))];
    }

    if (valor && valor != "")
      filtrosSemColunaEscopo.push(objFiltroColunaEscopo);

    setFiltro(values => ({
      ...values, "filtros": [...filtrosSemColunaEscopo]
    }))
  }

  const obtenhaValorFiltro = (colunaFiltro, comparador) => {
    const comparadorConvertido = comparador ?? 0;
    const filtroColuna = filtro.filtros.filter(x => x.Coluna == colunaFiltro && comparadorConvertido == x.Comparador);

    if (!filtroColuna || filtroColuna.length <= 0)
      return "";

    const valores = filtroColuna[0].Valores;

    return colunaFiltro == colunaStatus ? valores : valores[0];
  }

  const crieValoresParaStatus = (e, v) => {
    const listaDeIds = v.map(x => x.id);
    crieObjetoFiltro(listaDeIds, colunaStatus);
  }

  const obtenhaValorDefaultStatus = () => {
    const listaIdStatus = [...obtenhaValorFiltro(colunaStatus)];

    if (listaIdStatus == "")
      return [];

    const listaObjetosStatus = listaDeStatus.filter(x => listaIdStatus.filter(y => y == x.id).length > 0);

    return listaObjetosStatus;
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setCarregar(open);
    setState({ ...state, [anchor]: open })
  }

  const carregueListaDeStatus = async () => {
    setListaDeStatus([]);

    api.get(`/jucerja/v1/StatusTarefa`)
      .then(response => {
        setListaDeStatus(response.data ?? [])
      })
      .catch(error => {

      })
  }

  const carregueListaDeSetor = async () => {
    setListaDeSetor([]);

    api.get(`/jucerja/v1/setor/setores-responsavel`)
      .then(response => {
        setListaDeSetor(response.data ?? [])
      })
      .catch(error => {

      })
  }

  const obtenhaValorDefaultResponsaveis = () => {
    const idAtendente = obtenhaValorFiltro(colunaResponsavelTarefa);

    if (idAtendente == "")
      return undefined;

    return listaDeFuncionarioResponsavel.filter(x => x.referenciaUsuarioId == (idAtendente == 0 ? CODIGO_SEM_RESPONSAVEL : idAtendente))[0];
  }

  const carregueListaDeFuncionarioResponsavel = async () => {
    setListaDeFuncionarioResponsavel([]);

    api.get(`/jucerja/v1/funcionario/responsaveis-tarefa`)
      .then(response => {
        let listaAtendentes = response.data;
        listaAtendentes = _(listaAtendentes)
          .groupBy('referenciaUsuarioId')
          .map(x => x[0])
          .groupBy('nome')
          .map(x => x[0])
          .orderBy(['nome'], 'asc')
          .value()

        listaAtendentes.unshift({ referenciaUsuarioId: CODIGO_SEM_RESPONSAVEL, nome: 'SEM RESPONSÁVEL' });

        setListaDeFuncionarioResponsavel(listaAtendentes ?? [])
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    if (!carregar) return;

    carregueListaDeStatus();
    carregueListaDeSetor();
    carregueListaDeFuncionarioResponsavel();

  }, [carregar])

  const list = (anchor) => (
    <Filter id="Filter">
      <div
        id="FilterListDrawer"
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
      >
        <Filter.TopContent id="Filter_TopContent">
          <Filter.TopInfo id="Filter_TopInfo">
            <HiOutlineFilter /> Filtros
          </Filter.TopInfo>
          <Button className="btnCloseFiltersDrawer" onClick={toggleDrawer(anchor, false)}><IoCloseSharp /></Button>
        </Filter.TopContent>

        <Filter.Top id="Filter_ContentFilters">
          <TransitionGroup >

            <Grow key="filtroSolicitacaoFiltro" timeout={1000} style={{ height: "100%" }}>
              <Filter.Content id="Filter_Content">

                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <FormControl>
                      <TextField
                        id="filled-adornment-requisicao"
                        type="text"
                        label="Nº Tarefa"
                        variant="outlined"
                        onChange={e => handleFiltro(e, colunaTarefa)}
                        value={obtenhaValorFiltro(colunaTarefa)}
                      />
                    </FormControl>
                  </Filter.ItemFilter>
                </Filter.ContentItem>

                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Status</Filter.ItemFilterTitle>
                    <FormControl variant="outlined">
                      {
                        listaDeStatus.length > 0
                          ?
                          < Autocomplete
                            title='Atendentes'
                            multiple
                            id="tags-standard"
                            defaultValue={obtenhaValorDefaultStatus()}
                            options={listaDeStatus}
                            getOptionLabel={(option) => option.descricao}
                            // getOptionDisabled={(options) => (listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados.length >= 4 ? true : false)}
                            onChange={(e, v) => crieValoresParaStatus(e, v)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Selecione os status"
                              />
                            )}
                          />
                          :
                          <></>
                      }
                    </FormControl>
                  </Filter.ItemFilter>
                </Filter.ContentItem>

                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Setores</Filter.ItemFilterTitle>
                    <FormControl variant="outlined">
                      <InputLabel id="select-outlined-label-assunto">Setor</InputLabel>
                      <Select
                        labelId="select-outlined-label-assunto"
                        id="select-outlined-assunto"
                        value={obtenhaValorFiltro(colunaSetor)}
                        name="SetorId"
                        onChange={e => { handleFiltro(e, colunaSetor); }}
                        label="Assunto"
                      >
                        <MenuItem value="">
                          <em>Selecione uma opção</em>
                        </MenuItem>
                        {
                          listaDeSetor.map(setor => (
                            <MenuItem key={setor.id} value={setor.id}>{setor.descricao}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>

                    {/* <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Aberta"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Andamento"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Cancelada"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Aguardando Solicitante"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Aguardando Órgão Externo"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Em Verificação Interna"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Fechada pelo Cliente"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Fechada Automaticamente"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Fechada pelo Atendente"
                /> */}
                  </Filter.ItemFilter>


                </Filter.ContentItem>

                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Responsáveis Tarefa</Filter.ItemFilterTitle>
                    {/* <FormControl variant="outlined">
                  <InputLabel id="select-outlined-label-assunto">Responsável Tarefa</InputLabel> */}

                    <FormControl variant="outlined">
                      {
                        listaDeFuncionarioResponsavel.length > 0
                          ?
                          < Autocomplete
                            title='Responsáveis'
                            id="tags-standard"
                            defaultValue={obtenhaValorDefaultResponsaveis()}
                            options={listaDeFuncionarioResponsavel}
                            getOptionLabel={(option) => option.nome}
                            // getOptionDisabled={(options) => (listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados.length >= 4 ? true : false)}

                            onChange={(e, v) => crieValoresParaAtendentes(e, v)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Selecione o responsável"
                              />
                            )}
                          />
                          :
                          <></>
                      }
                    </FormControl>

                    {/* <Select
                    labelId="select-outlined-label-assunto"
                    id="select-outlined-assunto"
                    value={obtenhaValorFiltro(colunaResponsavelTarefa)}
                    name="ResponsavelId"
                    onChange={e => { handleFiltro(e, colunaResponsavelTarefa); }}
                    label="Responsável Tarefa"
                  >
                    <MenuItem value="">
                      <em>Selecione uma opção</em>
                    </MenuItem>
                    {
                      listaDeFuncionarioResponsavel.map(funcionario => (
                        <MenuItem key={funcionario.referenciaUsuarioId} value={funcionario.referenciaUsuarioId}>{funcionario.nome}</MenuItem>
                      ))
                    }
                  </Select> */}
                    {/* </FormControl> */}
                  </Filter.ItemFilter>
                </Filter.ContentItem>

                <Filter.ContentItem id="Filter_ContentItem">
                  <Filter.ItemFilter id="Filter_ItemFilter">
                    <Filter.ItemFilterTitle>Período de Abertura</Filter.ItemFilterTitle>
                    <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                      <Filter.DateFilterWithSelect id="Filter_DateFilterWithSelect">
                        <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                          <KeyboardDatePicker
                            margin="normal"
                            id="datapicker-data-inicial"
                            label="Data Início"
                            format="dd/MM/yyyy"
                            value={obtenhaValorFiltro(colunaDataAbertura, comparador.igualMaior) != "" ? new Date(obtenhaValorFiltro(colunaDataAbertura, comparador.igualMaior)) : null}
                            onChange={(newValue) => { newValue.setHours(0, 0, 0, 0); crieObjetoFiltro(newValue ? newValue.toJSON() : "", colunaDataAbertura, comparador.igualMaior) }}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                            okLabel="Selecionar"
                            cancelLabel="Cancelar"
                            inputVariant="outlined"
                          />
                        </Filter.DateFilterWithSelectItem>
                        <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                          <KeyboardDatePicker
                            margin="normal"
                            id="datapicker-data-inicial"
                            label="Data Término"
                            format="dd/MM/yyyy"
                            value={obtenhaValorFiltro(colunaDataAbertura, comparador.igualMenor) != "" ? new Date(obtenhaValorFiltro(colunaDataAbertura, comparador.igualMenor)) : null}
                            onChange={(newValue) => { newValue.setHours(23, 59, 0, 0); crieObjetoFiltro(newValue ? newValue.toJSON() : "", colunaDataAbertura, comparador.igualMenor) }}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                            okLabel="Selecionar"
                            cancelLabel="Cancelar"
                            inputVariant="outlined"
                          />
                        </Filter.DateFilterWithSelectItem>
                      </Filter.DateFilterWithSelect>
                    </MuiPickersUtilsProvider>
                  </Filter.ItemFilter>
                </Filter.ContentItem>

                {
                  filtro != filtroTemplate
                    ?
                    <Filter.ContentItem>
                      <Button onClick={e => { setSalvarFiltro(filtro); setFiltro(filtroTemplate); toggleDrawer(anchor, false)(e); }} style={{ width: '100%' }} className="btn-secondary-actions">Salvar Filtro</Button>
                    </Filter.ContentItem>
                    :
                    <></>
                }

                {/* <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <FormControl>
                  <TextField
                    id="filled-adornment-requisicao"
                    type="text"
                    label="Texto Contém"
                    variant="outlined"
                  />
                </FormControl>
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Atendente</Filter.ItemFilterTitle>
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Maria Pereira"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="João Paulo"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Hermano Guedes"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Beatriz A. Ramos"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Marilene B. Flaviana"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Hugo Nilbert"
                />
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Setor</Filter.ItemFilterTitle>
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Protocolo"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Secretária Administrativa"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Controle Externo"
                />
                <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Tecnologia da informação"
                />
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Nota</Filter.ItemFilterTitle>
                <Slider
                  defaultValue={1}
                  getAriaValueText={valueNota}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={5}
                  onChange={updateNota}
                />
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <FormControl>
                  <TextField
                    id="filled-adornment-cpf"
                    type="text"
                    label="CPF"
                    variant="outlined"
                  />
                </FormControl>
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <FormControl>
                  <TextField
                    id="filled-adornment-cpf"
                    type="text"
                    label="E-mail"
                    variant="outlined"
                  />
                </FormControl>
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Data Abertura</Filter.ItemFilterTitle>
                <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                  <Filter.DateFilterWithSelect id="Filter_DateFilterWithSelect">
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Tipo Filtro</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={filtroDataAbertura}
                          onChange={handleChangeDataAbertura}
                          label="Tipo Filtro"
                        >
                          <MenuItem value="">
                            <em>Escolha uma opção</em>
                          </MenuItem>
                          <MenuItem value={10}>Após</MenuItem>
                          <MenuItem value={20}>Antes</MenuItem>
                          <MenuItem value={30}>Exato</MenuItem>
                        </Select>
                      </FormControl>
                    </Filter.DateFilterWithSelectItem>
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <KeyboardDatePicker
                        margin="normal"
                        id="datapicker-data-inicial"
                        label="Data"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        okLabel="Selecionar"
                        cancelLabel="Cancelar"
                        inputVariant="outlined"
                      />
                    </Filter.DateFilterWithSelectItem>
                  </Filter.DateFilterWithSelect>
                </MuiPickersUtilsProvider>
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Data Atualização</Filter.ItemFilterTitle>
                <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                  <Filter.DateFilterWithSelect id="Filter_DateFilterWithSelect">
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Tipo Filtro</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={filtroDataAbertura}
                          onChange={handleChangeDataAbertura}
                          label="Tipo Filtro"
                        >
                          <MenuItem value="">
                            <em>Escolha uma opção</em>
                          </MenuItem>
                          <MenuItem value={10}>Após</MenuItem>
                          <MenuItem value={20}>Antes</MenuItem>
                          <MenuItem value={30}>Exato</MenuItem>
                        </Select>
                      </FormControl>
                    </Filter.DateFilterWithSelectItem>
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <KeyboardDatePicker
                        margin="normal"
                        id="datapicker-data-inicial"
                        label="Data"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        okLabel="Selecionar"
                        cancelLabel="Cancelar"
                        inputVariant="outlined"
                      />
                    </Filter.DateFilterWithSelectItem>
                  </Filter.DateFilterWithSelect>
                </MuiPickersUtilsProvider>
              </Filter.ItemFilter>
            </Filter.ContentItem>

            <Filter.ContentItem id="Filter_ContentItem">
              <Filter.ItemFilter id="Filter_ItemFilter">
                <Filter.ItemFilterTitle>Data Avaliação</Filter.ItemFilterTitle>
                <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                  <Filter.DateFilterWithSelect id="Filter_DateFilterWithSelect">
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Tipo Filtro</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={filtroDataAbertura}
                          onChange={handleChangeDataAbertura}
                          label="Tipo Filtro"
                        >
                          <MenuItem value="">
                            <em>Escolha uma opção</em>
                          </MenuItem>
                          <MenuItem value={10}>Após</MenuItem>
                          <MenuItem value={20}>Antes</MenuItem>
                          <MenuItem value={30}>Exato</MenuItem>
                        </Select>
                      </FormControl>
                    </Filter.DateFilterWithSelectItem>
                    <Filter.DateFilterWithSelectItem id="Filter_DateFilterWithSelectItem">
                      <KeyboardDatePicker
                        margin="normal"
                        id="datapicker-data-inicial"
                        label="Data"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        okLabel="Selecionar"
                        cancelLabel="Cancelar"
                        inputVariant="outlined"
                      />
                    </Filter.DateFilterWithSelectItem>
                  </Filter.DateFilterWithSelect>
                </MuiPickersUtilsProvider>
              </Filter.ItemFilter>
            </Filter.ContentItem> */}

              </Filter.Content>

            </Grow>
          </TransitionGroup>
        </Filter.Top>

        <Filter.Bottom id="Filter_Bottom">
          <Filter.BottomContent id="Filter_BottomContent">
            <Button onClick={e => { setAplicarFiltro(filtroTemplate); toggleDrawer(anchor, false)(e); }} className="btn-close-actions">Cancelar</Button>
            <Button onClick={e => { setAplicarFiltro(filtro); toggleDrawer(anchor, false)(e); }} className="btn-primary-actions">Aplicar</Button>
          </Filter.BottomContent>
        </Filter.Bottom>
      </div>
    </Filter>
  )

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} className="btnFilter"><HiOutlineFilter /> Filtros</Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
