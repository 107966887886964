import { useState, forwardRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Slide from "@material-ui/core/Slide";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import Grow from "@mui/material/Grow";
import Divider from "@mui/material/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import Checkbox from "@material-ui/core/Checkbox";
import { ActionTable } from "../../../components/elements-ui/ActionTable";
import Select from "@material-ui/core/Select";
import { Card } from "../../../components/elements-ui/Card";
import TextField from "@material-ui/core/TextField";
import { Badge } from "../../../components/elements-ui/Badge";
import api from "../../../services/api";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import { Funcionario } from "./styles";
import {
  GrAttachment,
  VscFilePdf,
  RiCloseCircleFill,
  BsFillCheckCircleFill,
  BsFillPlusCircleFill,
  RxLinkBreak2,
  AiOutlineEdit,
  BsListTask,
} from "react-icons/all";
import { convertISODateToDateView, formataCPF } from "../../../functions";
import { v4 as uuid } from "uuid";

import { Request } from "../DialogSendMessageEditor/styles";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { object } from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Draggable from "react-draggable";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PrimaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--primary)",
    color: "var(--white)",
  },
}))(Tooltip);

export default function DialogFuncionario({
  setOpenDialogFuncionario,
  openDialogFuncionario,
  codigoFuncionarioSelecionado,
  funcaoCallBack,
}) {
  const [error, setError] = useState(false);
  const [errosDeCampos, setErrosDeCampos] = useState([]);
  const [errorMinimoMensagem, setErrorMinimoMensagem] = useState("");
  const [textoBotaoArquivos, setTextoBotaoArquivos] =
    useState("Anexar Arquivos");
  const [setores, setSetores] = useState([]);
  const [setoresSearch, setSetoresSearch] = useState([]);
  const [assuntos, setAssuntos] = useState([]);
  const [assuntosSearch, setAssuntosSearch] = useState([]);
  const [assuntosTxt, setAssuntosTxt] = useState("");
  const [funcionarioTxt, setFuncionarioTxt] = useState("");
  const [funcionarios, setFuncionarios] = useState([]);
  const [setoresTxt, setSetoresTxt] = useState("");
  const [inputs, setInputs] = useState((values) => ({ RequisicaoId: 1 }));
  const [vinculos, setVinculos] = useState([]);
  const [attachs, setAttachs] = useState([]);
  const [codigoFuncionario, setCodigoFuncionario] = useState(
    codigoFuncionarioSelecionado
  );
  const [funcionario, setFuncionario] = useState({});
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState({
    htmlValue: "",
    editorState: EditorState.createEmpty(),
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rowCount, setRowCount] = useState(undefined);
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  const [rowsState, setRowsState] = useState({
    page: 1,
    pageSize: 10,
  });

  const handleClose = (e) => {
    e?.preventDefault();
    setOpenDialogFuncionario(false);
    setCodigoFuncionario(undefined);
  };

  const getFuncionarios = () => {
    if (codigoFuncionarioSelecionado !== undefined) return;

    let options = {
      filtros: [
        {
          coluna: ["28"],
          comparador: "8",
          agruparCondicional: true,
          valores: [funcionarioTxt],
        },
        {
          coluna: ["SetorId"],
          comparador: "IsNull",
          agruparCondicional: true,
          valores: [""],
        },
      ],
      gerarDadosComplementares: true,
      gerarSomenteQuantidade: false,
      firstResult: (rowsState.page - 1) * rowsState.pageSize + 1,
      maxResults: rowsState.pageSize,
    };

    setLoading(true);

    api
      .post(`/jucerja/v1/funcionario/filtro`, options)
      .then((response) => {
        setFuncionarios(response.data.funcionarios);
        setRowCountState(response.data.totalRecords);
      })
      .finally(() => setLoading(false));
  };

  const getSetoresComAssuntos = () => {
    api
      .get(`/jucerja/v1/Setor`)
      .then((response) => {
        let setoresResponse = response.data ?? [];

        setSetores(setoresResponse);
        setSetoresSearch(setoresResponse);

        getAssuntos(setoresResponse);
      })
      .catch((error) => {
        enqueueSnackbar(
          "Não foi possível carregar os setores, contate o administrador",
          { variant: "error" }
        );
      });
  };

  const getAssuntos = (setoresResponse) => {
    api
      .get(`/jucerja/v1/assuntos`)
      .then((responseAssuntos) => {
        let assuntosReponse = (responseAssuntos.data ?? []).filter((ass) =>
          setoresResponse.find((setor) => setor.id === ass.setorId)
        );

        setAssuntos(assuntosReponse);
        setAssuntosSearch(assuntosReponse);
      })
      .catch((error) => {
        enqueueSnackbar(
          "Não foi possível carregar os assuntos, contate o administrador",
          { variant: "error" }
        );
      });
  };

  const handleSalvar = async () => {
    let payload = {
      id_funcionario: codigoFuncionario,
      setores: [...vinculos.filter((v) => v.setor && v.vinculoSetor)],
      assuntos: [...vinculos.filter((v) => !v.setor && v.vincularAssunto)],
    };

    setLoadingSubmit(true);

    api
      .post(`/jucerja/v1/funcionario`, payload)
      .then((response) => {
        handleClose();
        enqueueSnackbar("Operação realizada com sucesso", {
          variant: "success",
        });
        funcaoCallBack && funcaoCallBack();
      })
      .catch(() =>
        enqueueSnackbar(
          "Não foi possível atualizar os vínculos do funcionário",
          { variant: "error" }
        )
      )
      .finally(() => setLoadingSubmit(false));
  };

  const configurarVariavel = (entidades, id, prop, valor, ehSetor) => {
    let entidade =
      vinculos.find((x) => x.id === id && x.setor === !!ehSetor) ??
      entidades.find((x) => x.id === id);
    let vinculosCache = [...vinculos];
    let addEntidades = [];

    if (entidade) entidade[prop] = valor;

    entidade.setor = !!ehSetor;

    if ((prop === "supervisor" || prop === "respondeTarefa") && valor) {
      entidade.vinculoSetor = true;
    }

    if (prop === "vinculoSetor" && !valor) {
      vinculosCache
        .filter((vin) => vin.setorId === id)
        .forEach((vin) => (vin.vincularAssunto = false));

      let setorDesvincular = vinculosCache.find((vin) => vin.id === id);

      if (setorDesvincular) {
        setorDesvincular.respondeTarefa = false;
        setorDesvincular.supervisor = false;
      }
    }

    if (prop === "vincularAssunto" && valor) {
      let setorId = entidade.setorId;

      if (
        !vinculos.find((v) => v.id === setorId && v.setor && v.vinculoSetor) &&
        setores.find((s) => s.id === setorId)
      ) {
        let entidadeSetor = setores.find((s) => s.id === setorId);

        entidadeSetor.vinculoSetor = true;
        entidadeSetor.setor = true;

        addEntidades.push(entidadeSetor);
      }
    }

    addEntidades.push(entidade);

    setVinculos([
      ...vinculosCache.filter((v) => v.id !== id && v.setor === !!ehSetor),
      ...vinculosCache.filter((v) => v.setor === !ehSetor),
      ...addEntidades,
    ]);
  };

  useEffect(() => {
    let timer1 = setTimeout(
      () => setRowsState((prev) => ({ ...prev, page: 1 })),
      500
    );

    return () => {
      clearTimeout(timer1);
    };
  }, [funcionarioTxt]);

  useEffect(() => {
    getFuncionarios();
  }, [rowsState]);

  useEffect(() => {
    if (!codigoFuncionario) return;

    setLoading(true);

    api
      .get(`/jucerja/v1/funcionario/dados_funcionario/${codigoFuncionario}`) //, options)
      .then((response) => {
        // setFuncionario(response.data?.funcionarios?.find(x=> x));
        let vinculosFuncionario = [];
        setFuncionario(response.data);

        response.data?.setores?.forEach((setor) =>
          vinculosFuncionario.push({
            ...setor,
            setor: true,
            vinculoSetor: true,
          })
        );

        response.data?.assuntos?.forEach((assunto) =>
          vinculosFuncionario.push({
            ...assunto,
            vincularAssunto: true,
            setor: false,
          })
        );

        setVinculos(vinculosFuncionario);
      })
      .catch((error) => {
        enqueueSnackbar(
          "Não foi possível carregar dados do funcionário, contate o administrador",
          { variant: "error" }
        );
      })
      .finally(() => setLoading(false));
  }, [codigoFuncionario]);

  useEffect(() => {
    if (!openDialogFuncionario) return;

    setCodigoFuncionario(codigoFuncionarioSelecionado);
    getSetoresComAssuntos();
  }, [openDialogFuncionario]);

  useEffect(() => {
    let setoresFiltrados = setores.filter(
      (str) =>
        str.descricao.toLowerCase().indexOf(setoresTxt.toLowerCase()) > -1
    );
    let assuntosFiltrados = assuntos.filter(
      (ass) =>
        ass.descricao.toLowerCase().indexOf(assuntosTxt.toLowerCase()) > -1 &&
        setoresFiltrados.find((str) => ass.setorId == str.id)
    );
    setAssuntosSearch(assuntosFiltrados);
    setSetoresSearch(
      setoresFiltrados.filter((str) =>
        assuntosFiltrados.find((ass) => ass.setorId == str.id)
      )
    );
  }, [assuntosTxt]);

  useEffect(() => {
    let assuntosFiltrados = assuntos.filter(
      (ass) =>
        ass.descricao.toLowerCase().indexOf(assuntosTxt.toLowerCase()) > -1
    );
    let setoresFiltrados = setores.filter(
      (str) =>
        str.descricao.toLowerCase().indexOf(setoresTxt.toLowerCase()) > -1 &&
        assuntosFiltrados.find((ass) => ass.setorId == str.id)
    );
    setSetoresSearch(setoresFiltrados);
    setAssuntosSearch(
      assuntosFiltrados.filter((ass) =>
        setoresFiltrados.find((str) => ass.setorId == str.id)
      )
    );
  }, [setoresTxt]);

  return (
    <Draggable
      handle={'[class*="MuiDialog-root"]'}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Dialog
        hideBackdrop
        disableBackdropClick
        open={openDialogFuncionario}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="dialog-associated-tasks-request-slide-title"
        aria-describedby="dialog-associated-tasks-request-dialog-slide-description"
        fullWidth={"md"}
        id="dialog-create-funcionario"
      >
        <DialogTitle style={{ cursor: "move" }} id="alert-dialog-slide-title">
          {codigoFuncionario
            ? "Informações do Funcionário"
            : "Funcionários não cadastrados"}{" "}
        </DialogTitle>
        <DialogContent>
          {!codigoFuncionario ? (
            <TransitionGroup>
              <Grow id="growFuncionarioListagem" timeout={1000}>
                <Grid xs={12}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined">
                      <TextField
                        inputProps={{ maxLength: 100 }}
                        id="outlined-basic-ticket"
                        label="Nome Funcionário"
                        variant="outlined"
                        name="nomeFuncionario"
                        value={funcionarioTxt}
                        onChange={(e) => setFuncionarioTxt(e.target.value)}
                        disabled={loading}
                      />
                    </FormControl>
                  </Grid>
                  <Funcionario.grid>
                    <DataGrid
                      getRowId={(r) => r.id}
                      style={{ height: "100%" }}
                      pageSize={10}
                      disableSelectionOnClick={true}
                      disableColumnFilter
                      disableColumnMenu
                      columns={[
                        {
                          field: "nome",
                          headerName: "Nome",
                          width: 170,
                          flex: 1,
                          editable: false,
                          disableReorder: false,
                          filterable: false,
                          sortable: false,
                          renderCell: (param) => (
                            <Tooltip title={param.value} placement="top">
                              <span>{param.value}</span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: "login",
                          headerName: "Login",
                          width: 170,
                          editable: false,
                          disableReorder: false,
                          filterable: false,
                          sortable: false,
                          renderCell: (param) => (
                            <Tooltip title={param.value} placement="top">
                              <span>{param.value}</span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: "acao",
                          headerName: "Ações",
                          headerAlign: "center",
                          width: 150,
                          align: "center",
                          filterable: false,
                          renderCell: (params) => {
                            return (
                              <ActionTable>
                                <PrimaryTooltip
                                  title="Adicionar Funcionário"
                                  placement="top"
                                >
                                  <Button
                                    onClick={() => {
                                      setCodigoFuncionario(params.row.id);
                                    }}
                                    className="btn-open"
                                  >
                                    <BsFillPlusCircleFill />
                                  </Button>
                                </PrimaryTooltip>
                              </ActionTable>
                            );
                          },
                        },
                      ]}
                      page={rowsState.page > 0 ? rowsState.page - 1 : 0}
                      pagination
                      paginationMode="server"
                      onPageChange={(page) => {
                        setRowsState((prev) => ({ ...prev, page: page + 1 }));
                      }}
                      rows={funcionarios}
                      rowCount={rowCountState}
                      hideFooterPagination={loading}
                      density={"small"}
                      loading={loading}
                      localeText={ptBR.props.MuiDataGrid.localeText}
                    />
                  </Funcionario.grid>
                </Grid>
              </Grow>
            </TransitionGroup>
          ) : (
            <TransitionGroup>
              <Collapse id="growFuncionarioAdd" timeout={1000}>
                <>
                  <Funcionario.container>
                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className="request-description-dialog">
                          <strong>Informações do Funcionário: </strong> {true}
                        </p>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          id="outlined-Cpf-funcionario"
                          label="Cpf"
                          variant="outlined"
                          name="cpf-funcionario"
                          value={formataCPF(funcionario.cpf)}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          id="outlined-nome-funcionario"
                          label="Nome"
                          variant="outlined"
                          name="nome-funcionario"
                          value={funcionario.nome}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          id="outlined-login-funcionario"
                          label="Login"
                          variant="outlined"
                          name="login-funcionario"
                          value={funcionario.login}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          id="outlined-dataNascimento-funcionario"
                          label="Data de Nascimento"
                          variant="outlined"
                          name="dataNascimento-funcionario"
                          value={convertISODateToDateView(
                            funcionario.dataNascimento,
                            true
                          )}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          id="outlined-email-funcionario"
                          label="Email"
                          variant="outlined"
                          name="email-funcionario"
                          value={funcionario.email}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          id="outlined-perfis-funcionario"
                          label="Perfis"
                          variant="outlined"
                          name="perfis-funcionario"
                          value={
                            funcionario?.perfis?.length > 0
                              ? funcionario?.perfis
                                  ?.map((p) => p.descricao)
                                  ?.join(", ")
                              : "-"
                          }
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Funcionario.container>

                  <Grid
                    xs={12}
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <Divider>Vínculos</Divider>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item xs={12}>
                        <FormControl variant="outlined">
                          <TextField
                            inputProps={{ maxLength: 100 }}
                            id="outlined-basic-ticket"
                            label="Setores"
                            variant="outlined"
                            name="setorSearch"
                            onChange={(e) => {
                              let timer = setTimeout(() => {
                                setSetoresTxt(e.target.value);
                              }, 500);

                              return () => {
                                clearTimeout(timer);
                              };
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Funcionario.grid>
                        <DataGrid
                          getRowId={(r) => r.id}
                          style={{ height: "100%" }}
                          pageSize={10}
                          disableSelectionOnClick={true}
                          disableColumnMenu
                          disableColumnFilter
                          columns={[
                            {
                              field: "descricao",
                              headerName: "Descrição",
                              width: 150,
                              editable: false,
                              disableReorder: false,
                              filterable: false,
                              sortable: false,
                              renderCell: (param) => (
                                <Tooltip title={param.value} placement="top">
                                  <span>{param.value}</span>
                                </Tooltip>
                              ),
                            },
                            {
                              field: "receberTarefas",
                              headerName: "Recebe Tarefas",
                              width: 100,
                              editable: false,
                              disableReorder: false,
                              filterable: false,
                              sortable: false,
                              align: "center",
                              renderCell: (param) => (
                                <Checkbox
                                  onChange={(e, valor) => {
                                    configurarVariavel(
                                      setoresSearch,
                                      param.row.id,
                                      "respondeTarefa",
                                      valor,
                                      true
                                    );
                                  }}
                                  checked={
                                    !!vinculos.find(
                                      (v) => v.id === param.row.id && v.setor
                                    )?.respondeTarefa
                                  }
                                  icon={
                                    <BsFillPlusCircleFill
                                      style={{ color: "#dddddd" }}
                                    />
                                  }
                                  checkedIcon={
                                    <BsFillCheckCircleFill
                                      style={{ color: "var(--green)" }}
                                    />
                                  }
                                />
                              ),
                            },
                            {
                              field: "ehSupervisor",
                              headerName: "Supervisor",
                              width: 100,
                              editable: false,
                              disableReorder: false,
                              filterable: false,
                              sortable: false,
                              align: "center",
                              renderCell: (param) => (
                                <Checkbox
                                  onChange={(e, valor) => {
                                    configurarVariavel(
                                      setoresSearch,
                                      param.row.id,
                                      "supervisor",
                                      valor,
                                      true
                                    );
                                  }}
                                  checked={
                                    !!vinculos.find(
                                      (v) => v.id === param.row.id && v.setor
                                    )?.supervisor
                                  }
                                  icon={
                                    <BsFillPlusCircleFill
                                      style={{ color: "#dddddd" }}
                                    />
                                  }
                                  checkedIcon={
                                    <BsFillCheckCircleFill
                                      style={{ color: "var(--green)" }}
                                    />
                                  }
                                />
                              ),
                            },
                            {
                              field: "vincular",
                              headerName: "Vincular",
                              width: 100,
                              editable: false,
                              disableReorder: false,
                              filterable: false,
                              sortable: false,
                              align: "center",
                              renderCell: (param) => (
                                <Checkbox
                                  onChange={(e, valor) => {
                                    configurarVariavel(
                                      setoresSearch,
                                      param.row.id,
                                      "vinculoSetor",
                                      valor,
                                      true
                                    );
                                  }}
                                  checked={
                                    !!vinculos.find(
                                      (v) => v.id === param.row.id && v.setor
                                    )?.vinculoSetor
                                  }
                                  icon={
                                    <BsFillPlusCircleFill
                                      style={{ color: "#dddddd" }}
                                    />
                                  }
                                  checkedIcon={
                                    <BsFillCheckCircleFill
                                      style={{ color: "var(--green)" }}
                                    />
                                  }
                                />
                              ),
                            },
                          ]}
                          rows={setoresSearch}
                          density={"small"}
                          loading={false}
                          localeText={ptBR.props.MuiDataGrid.localeText}
                        />
                      </Funcionario.grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item xs={12}>
                        <FormControl variant="outlined">
                          <TextField
                            inputProps={{ maxLength: 100 }}
                            id="outlined-basic-ticket"
                            label="Assuntos"
                            variant="outlined"
                            name="assuntoSearch"
                            // value={assuntosTxt}
                            onChange={(e) => {
                              let timer = setTimeout(() => {
                                setAssuntosTxt(e.target.value);
                              }, 500);

                              return () => {
                                clearTimeout(timer);
                              };
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Funcionario.grid>
                        <DataGrid
                          getRowId={(r) => r.id}
                          style={{ height: "100%" }}
                          pageSize={10}
                          disableSelectionOnClick={true}
                          disableColumnMenu
                          disableColumnFilter
                          columns={[
                            {
                              field: "descricao",
                              headerName: "Descrição",
                              flex: 1,
                              width: 170,
                              editable: false,
                              disableReorder: false,
                              filterable: false,
                              sortable: false,
                              renderCell: (param) => (
                                <Tooltip title={param.value} placement="top">
                                  <span>{param.value}</span>
                                </Tooltip>
                              ),
                            },
                            {
                              field: "vincular",
                              headerName: "Vincular",
                              width: 130,
                              editable: false,
                              disableReorder: false,
                              filterable: false,
                              sortable: false,
                              align: "center",
                              renderCell: (param) => (
                                <Checkbox
                                  onChange={(e, valor) => {
                                    configurarVariavel(
                                      assuntosSearch,
                                      param.row.id,
                                      "vincularAssunto",
                                      valor,
                                      false
                                    );
                                  }}
                                  checked={
                                    !!vinculos.find(
                                      (v) => v.id === param.row.id && !v.setor
                                    )?.vincularAssunto
                                  }
                                  icon={
                                    <BsFillPlusCircleFill
                                      style={{ color: "#dddddd" }}
                                    />
                                  }
                                  checkedIcon={
                                    <BsFillCheckCircleFill
                                      style={{ color: "var(--green)" }}
                                    />
                                  }
                                />
                              ),
                            },
                          ]}
                          rows={assuntosSearch}
                          density={"small"}
                          loading={false}
                          localeText={ptBR.props.MuiDataGrid.localeText}
                        />
                      </Funcionario.grid>
                    </Grid>
                  </Grid>
                </>
              </Collapse>
            </TransitionGroup>
          )}
        </DialogContent>
        <DialogActions>
          {loadingSubmit ? (
            <></>
          ) : (
            <Button
              onClick={handleClose}
              disabled={loadingSubmit}
              className="btn-close-actions"
            >
              Cancelar
            </Button>
          )}

          {codigoFuncionario ? (
            loadingSubmit ? (
              <Button className="btn-primary-actions">
                <CircularProgress size={22} style={{ color: "var(--white)" }} />
              </Button>
            ) : (
              <Button className="btn-primary-actions" onClick={handleSalvar}>
                Salvar
              </Button>
            )
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>
    </Draggable>
  );
}
