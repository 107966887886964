import styled from 'styled-components'

export const ActionTable = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;

    button {
        min-width: 0px;
        padding: 6px;
        font-size: 18px;
        border-radius: 100%;
        border: 1px solid;
    }

    .btn-open {
        border-color: var(--dark-blue) !important;
        color: var(--dark-blue) !important;
    }

    .btn-link-break {
        border-color: var(--purple) !important;
        color: var(--purple) !important;
    }

    .btn-edit {
        border-color: #e5a900 !important;
        color: #e5a900 !important;
    }

    .btn-delete {
        border-color: var(--red) !important;
        color: var(--red) !important;
    }
`