import { useState, useEffect, forwardRef, useRef } from "react";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Switch from "@mui/material/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";

import List from "@mui/material/List";
import { BsFillCheckCircleFill, BsFillPlusCircleFill } from "react-icons/all";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "react-loading-skeleton";
import { DialogAssuntoSetor } from "./styles";
import api from "../../../services/api";
import { Autocomplete, Chip, Stack } from "@mui/material";
import { v4 as uuid } from "uuid";
import { setRef, Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Draggable from "react-draggable";
import Divider from "@material-ui/core/Divider";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TIPO_PRIMARIO_VAZIO = {
  id: undefined,
  assuntoDescricao: "",
  mensagemAutomatica: "",
  funcionarios: [],
};
const COLUNA_COMPARADOR_ASSUNTO = 1;

export default function DialogAssuntoSetorConfirm({
  setOpenDialogAssuntoSetorConfirm,
  openDialogAssuntoSetorConfirm,
  setAssuntoSetorConfirm,
  assuntoSetorConfirm,
  idAssunto,
  setIdAssunto,
  idSetor,
  selecionarSetor,
}) {
  const [errorAssunto, setErrorAssunto] = useState(undefined);
  const [errorSetor, setErrorSetor] = useState(undefined);
  const [listaSetores, setListaSetores] = useState([]);
  const [assunto, setAssunto] = useState(TIPO_PRIMARIO_VAZIO);
  const [funcionarios, setFuncionarios] = useState([]);
  const [nomeFuncionario, setNomeFuncionario] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limpar, setLimpar] = useState(false);

  const filtroNomeFuncionario = { coluna: [28], comparador: 8, valores: [""] };
  const filtroPadrao = {
    filtros: [],
    maxResults: 5,
    gerarDadosComplementares: false,
    firstResult: 1,
  };

  const handleClose = (e) => {
    e.preventDefault();

    if (loading) return;

    setOpenDialogAssuntoSetorConfirm(false);
  };

  const handleConfirm = async (e) => {
    e?.preventDefault();

    if (!assunto.assuntoDescricao || assunto.assuntoDescricao.length <= 0) {
      setErrorAssunto(true);
      return;
    }
    if (
      selecionarSetor &&
      (assunto.setorId == 0 || !assunto.setorId) &&
      !idAssunto
    ) {
      setErrorSetor(true);
      return;
    }

    setLoading(true);
    setAssuntoSetorConfirm(assunto);
  };

  const obterAssunto = async () => {
    let filtroAssunto = {
      filtros: [
        {
          coluna: [COLUNA_COMPARADOR_ASSUNTO],
          comparador: 0,
          valores: [`${idAssunto}`],
        },
      ],
      aplicarUnionCondicional: true,
      utilizarCache: true,
      firstResult: 1,
      maxResults: 1,
    };

    if (!idAssunto) {
      setNomeFuncionario(" ");
      setLoading(false);
      return;
    }

    await api
      .post(`/jucerja/v1/assuntos/filtro`, filtroAssunto)
      .then((response) => {
        let funcionarios = response.data.assuntos[0].funcionarios;
        setAssunto(response.data.assuntos[0]);
        setFuncionarios(funcionarios ?? []);

        if (funcionarios == undefined || funcionarios.length <= 0)
          setNomeFuncionario(" ");
      })
      .finally(() => {
        setIdAssunto(undefined);
        setLoading(false);
      });
  };

  const getSetores = () => {
    setLoading(true);
    api
      .get(`/jucerja/v1/Setor`)
      .then((response) => {
        setListaSetores(response.data ?? []);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const incluirFuncionarioAssunto = (idFuncionario) => {
    let check = !assunto.funcionarios.find((ssF) => ssF.id == idFuncionario);
    if (!check) {
      setAssunto((ass) => ({
        ...ass,
        funcionarios: ass.funcionarios.filter(
          (func) => func.id != idFuncionario
        ),
      }));
    } else {
      let funcAss = [
        ...assunto?.funcionarios,
        funcionarios.find((f) => f.id == idFuncionario),
      ];
      setAssunto((ass) => ({ ...ass, funcionarios: [...funcAss] }));
    }
  };

  const mapearFuncionarios = (funcionariosConsulta) => {
    setFuncionarios(
      funcionariosConsulta.map((fun) => ({ id: fun.id, descricao: fun.nome }))
    );
  };

  const consultarFuncionarioAssunto = () => {
    if (!(assunto.setorId ?? idSetor)) return;

    let filtro = {
      ...filtroPadrao,
      filtros: [
        { ...filtroNomeFuncionario, valores: [nomeFuncionario] },
        {
          coluna: [5],
          comparador: 0,
          agruparCondicional: false,
          valores: selecionarSetor ? [`${assunto.setorId}`] : [`${idSetor}`],
        },
      ],
    };

    if (
      (!nomeFuncionario || nomeFuncionario.length <= 0) &&
      assunto.funcionarios?.length > 0
    ) {
      setFuncionarios(assunto.funcionarios);
      return;
    }

    api.post("/jucerja/v1/funcionario/filtro", filtro).then((response) => {
      mapearFuncionarios(response.data?.funcionarios ?? []);
    });
  };

  useEffect(() => {
    setFuncionarios([]);
    setNomeFuncionario("");

    if (openDialogAssuntoSetorConfirm) {
      setLoading(true);
      obterAssunto();
      setLimpar(!limpar);
    } else {
      setLoading(false);
      setAssunto(TIPO_PRIMARIO_VAZIO);
      setFuncionarios([]);
    }

    if (selecionarSetor && listaSetores.length <= 0) getSetores();
  }, [openDialogAssuntoSetorConfirm]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (assunto.setorId || !selecionarSetor) consultarFuncionarioAssunto();
    }, 700);

    if (!assunto.setorId && selecionarSetor) {
      setFuncionarios([]);
      setAssunto((ass) => ({ ...ass, funcionarios: [] }));
    }

    return () => clearInterval(timeout);
  }, [nomeFuncionario, assunto.setorId]);

  useEffect(() => {
    if (assuntoSetorConfirm == undefined) setLoading(false);
  }, [assuntoSetorConfirm]);

  return (
    <Draggable
      handle={'[class*="MuiDialog-root"]'}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Dialog
        hideBackdrop
        disableBackdropClick
        open={openDialogAssuntoSetorConfirm}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="dialog-detail-request-slide-title"
        aria-describedby="dialog-detail-request-dialog-slide-description"
        id="dialog-setor"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {idAssunto ? "Atualizar Assunto" : "Cadastrar Assunto"}
        </DialogTitle>
        <DialogContent>
          <DialogAssuntoSetor id="DialogSetor">
            <DialogAssuntoSetor.Container id="DialogSetor_Container">
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                {selecionarSetor ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl variant="outlined">
                      <Autocomplete
                        key={limpar}
                        title="Setores"
                        id="tags-standard"
                        options={listaSetores}
                        disabled={loading}
                        value={
                          listaSetores.find(
                            (setor) => setor.id == assunto.setorId
                          ) ?? null
                        }
                        getOptionLabel={(option) => option.descricao}
                        onChange={(e, v) => {
                          setAssunto((ass) => ({
                            ...ass,
                            setorId: v?.id,
                            setorDescricao: v?.descricao,
                          }));
                          setErrorSetor(false);
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                          if (reason === "clear") {
                            setAssunto((ass) => ({
                              ...ass,
                              setorId: null,
                            }));
                            return;
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            disabled={loading}
                            variant="outlined"
                            label="Selecione o setor"
                            error={errorSetor}
                          />
                        )}
                      />
                    </FormControl>

                    <Divider
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>

                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid
                    style={{ padding: "5px 8px 0px 0px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl variant="outlined">
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="outlined-basic-titulo"
                        inputProps={{ maxLength: 255 }}
                        label="Descrição Assunto"
                        variant="outlined"
                        helperText={errorAssunto}
                        name="titulo"
                        value={assunto.assuntoDescricao}
                        onChange={(e) => {
                          setAssunto({
                            ...assunto,
                            assuntoDescricao: e.target.value,
                          });
                          setErrorAssunto(false);
                        }}
                        error={errorAssunto}
                        disabled={loading}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    style={{ padding: "8px 8px 8px 0px " }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl variant="outlined">
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="outlined-basic-titulo"
                        inputProps={{ maxLength: 255 }}
                        multiline
                        rows={5}
                        label="Mensagem Automática"
                        variant="outlined"
                        name="titulo"
                        value={assunto.mensagemAutomatica}
                        onChange={(e) => {
                          setAssunto({
                            ...assunto,
                            mensagemAutomatica: e.target.value,
                          });
                        }}
                        disabled={loading}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    height: "200px",
                    overflowY: "auto",
                    paddingTop: "5px",
                  }}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl variant="outlined">
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="outlined-basic-nome-func"
                        inputProps={{ maxLength: 255 }}
                        label="Nome Funcionário"
                        variant="outlined"
                        name="nomeFunc"
                        value={nomeFuncionario}
                        onChange={(e) => setNomeFuncionario(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <List dense={true}>
                    {funcionarios.map((f) => (
                      <>
                        <ListItem
                          key={f.id}
                          secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                              <Checkbox
                                key={f.id}
                                checked={assunto.funcionarios.find(
                                  (ssF) => ssF.id == f.id
                                )}
                                onChange={(e) =>
                                  incluirFuncionarioAssunto(f.id)
                                }
                                icon={
                                  <BsFillPlusCircleFill
                                    style={{ color: "#dddddd" }}
                                  />
                                }
                                checkedIcon={
                                  <BsFillCheckCircleFill
                                    style={{ color: "var(--green)" }}
                                  />
                                }
                                disabled={loading}
                              />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={f.descricao} />
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </DialogAssuntoSetor.Container>
          </DialogAssuntoSetor>
        </DialogContent>
        <DialogActions>
          <DialogAssuntoSetor.Actions id="DialogSetor.Actions">
            <div className={!loading ? "mostrarAnimacao" : "esconderAnimacao"}>
              <Button onClick={handleClose} className="btn-close-actions">
                Cancelar
              </Button>
            </div>
            {loading ? (
              <Button className="btn-primary-actions">
                <CircularProgress size={22} style={{ color: "var(--white)" }} />
              </Button>
            ) : (
              <Button className="btn-primary-actions" onClick={handleConfirm}>
                Confirmar
              </Button>
            )}
          </DialogAssuntoSetor.Actions>
        </DialogActions>
      </Dialog>
    </Draggable>
  );
}
